import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function ConfirmStatusModal({ message, handleClose, show, validateFunc }) {
    const { t } = useTranslation();

    const updateClickHandler = () => {
        validateFunc()
        handleClose();
    }

    const cancelClickHandler = () => {
        handleClose();
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                    <Modal.Title>{t('default:alert', "Alerte")}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="ADMCreationModal__body" style={{ width: "650px" }}>
                    <p>
                        {message}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={cancelClickHandler}>
                        {t('default:cancel', "Annuler")}
                    </Button>
                    <Button variant="primary" onClick={() => updateClickHandler()}>
                        {t('default:confirm', "Confirmer")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ConfirmStatusModal
