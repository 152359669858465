import React from 'react'
import CancelIcon from '@material-ui/icons/Cancel';
import ClearIcon from '@material-ui/icons/Clear';
import { useTranslation } from 'react-i18next'; 
import { getNotyfObject } from '../../../../../../../../shared/utility';
 

function RepresentativeCard({userIndex, agencyType, subMandIndex, userUpdateHandler, firstName, lastName, isLegalRepresentative, isSignataire, functionLabel, userDeleteHandler, userIsDeletable}) {
    let random = Math.floor(Math.random() * 10000);
    const {t} = useTranslation();
    let notyf = getNotyfObject();

    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row-reverse",
            marginRight: "20px"
        }}>
            <div 
                //className="ADMEdition_representant_delIcon"
            >{userIsDeletable ? 
                <ClearIcon 
                    className="AdmEdition_clearIcon"
                    style={{cursor: "pointer"}}
                    onClick={() => userDeleteHandler(agencyType, userIndex, subMandIndex)} />
                :
                <ClearIcon 
                    className="AdmEdition_clearIcon"
                    style={{cursor: "pointer"}}
                    onClick={() =>  notyf.open({closeWith: ['click'], type:'warning', background:'orange', message:t("mandat:imposedLegalRepresentativeMessage")})} />
            }
            </div>
            <div className="ADMEdition_annonceur_representantContainer ">
                
                <div className="ADMEdition_annonceur_representantName">{firstName + ' ' + lastName} </div>
                <div className="ADMEdition_annonceur_representantFunction">{functionLabel}</div>
                <div className="ADMEdition_annonceur_representantRoles">
                </div>
            </div>
        </div>
        
    )
}

export default RepresentativeCard
