import React, { useCallback, useEffect, useState } from 'react'
import './ADMGrantModal.css';
import Axios from '../../../../axios-edipub';
import frLocale from "date-fns/locale/fr";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';


import { Button, Modal } from 'react-bootstrap';
import { getNotyfObject } from '../../../../shared/utility';
import { useTranslation } from 'react-i18next';

function ADMGrantModal({ handleClose, show }) {
    const { t } = useTranslation();
    const notyf = getNotyfObject();
    const currentDate = new Date();
    const defaultStartDate = new Date((currentDate.getMonth() >= 8 ? currentDate.getFullYear() + 1 : currentDate.getFullYear()), 0, 1);
    const [type, setType] = useState('SIRET')
    const [adveriserIdentifier, setAdveriserIdentifier] = useState(null)
    const [date, setDate] = React.useState(defaultStartDate);
    const [dateError, setDateError] = useState(false);


    const handleDateChange = (date) => {
        if (date === null) {
            setDateError(true)
        } else if (date.toString() === "Invalid Date") {
            setDateError(true)
        } else if (date.getFullYear <= 1900 || date.getFullYear > 2100) {
            setDateError(true)
        } else {
            setDateError(false)
        }
        setDate(date);
    };

    const grantClickHandler = () => {
        grantADM();
        handleClose();
    }

    const grantADM = () => {
        Axios.post('/document//grantDocumentAccess', {
            year: date.getFullYear(),
            adveriserIdentifier: adveriserIdentifier,
            type: type
        }).then(response => {
            if (response.data.found) {
                notyf.success({
                    message: t('home:modalGrant_foundAndGranted', "L'attestation de mandat existe, nous informons le responsable de l'ADM de votre souhait de la visualiser."),
                    duration: 15000,
                    dismissible: true,
                    className: "toast-custom-notyf"
                })
            } else {
                notyf.open({
                    closeWith: ['click'],
                    type: 'warning',
                    background: 'orange',
                    message: t("home:modalGrant_notFound", "Attestation de mandat non trouvée."),
                    className: "toast-custom-notyf"
                });
            }
        }).catch(err => {
            if (err.response?.data?.error && (err.response?.data?.error === 'Expired token'))
                notyf.error('Une erreur s\'est produite ! Votre session a expiré. Veuillez vous déconnecter et vous reconnecter avant de renouveler l\'opération.')
            else
                notyf.error('Une erreur s\'est produite !')
        })

    }

    return (
        <>
            <Modal show={show} onHide={handleClose} contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t('home:modalGrant_title', "Accès à une attestation de mandat")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="ADMCreationModal__body" style={{ width: "650px" }}>
                    <div className="ADMCGrantModal__advertiserType">
                        <h5>
                            {t('home:modalGrant_advertiserIdentifierType', "Type d'identifiant")}
                        </h5>
                        <select
                            style={{ height: '33px' }}
                            class="form-control"
                            required
                            placeholder="Type"
                            name="type"
                            value={type || ''}
                            onChange={/*(e) => idNumInputChangeHandler(e) */
                                (e) => setType(e.target.value)
                            }
                            aria-label=".form-select-lg example">
                            <option value="SIRET">SIRET</option>
                            <option value="GLN">GLN</option>
                            <option value="DUNS">DUNS</option>
                            <option value="SIREN">SIREN</option>
                            <option value="TVA">TVA INTRACOMMUNAUTAIRE</option>
                            <option value="AUT">AUT</option>
                        </select>
                    </div>
                    <div className="ADMCreationModal__adveriserIdentifier__container">
                        <h5>
                            {t('home:modalGrant_advertiserIdentifier', "identifiant de l'Annonceur")}
                        </h5>
                        <textarea
                            className="form-control"
                            name="adveriserIdentifier"
                            placeholder={"SIRET, TVA, GLN, SIREN, DUNS, Autre ID connu"}
                            rows="1"
                            value={adveriserIdentifier || ''}
                            maxLength={500}
                            onChange={(e) => setAdveriserIdentifier(e.target.value)}
                        >
                        </textarea>
                    </div>
                    <div className="ADMCreationModal__selectionPeriod__container mt-4">
                        <h5>
                            {t('home:modalGrant_year', "Année de l'ADM")}
                        </h5>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                            <KeyboardDatePicker

                                views={["year"]}
                                format="yyyy"
                                value={date}
                                onChange={handleDateChange}
                                autoOk={true}
                                okLabel=''
                                invalidDateMessage={t('default:invalidYearMessage', "Format de l'année invalide")}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {(!adveriserIdentifier || adveriserIdentifier.length === 0) && <span style={{ color: "red" }}>{t("home:pleaseSelectAdvertiserIdentifier", "Veuillez entrer un identifiant de l'Annonceur")}</span>}
                    <Button variant="secondary" onClick={handleClose}>
                        {t('default:cancel', "Annuler")}
                    </Button>
                    <Button variant="primary" disabled={(dateError || ((!adveriserIdentifier || adveriserIdentifier.length == 0)))} onClick={() => grantClickHandler()}>
                        {t('home:modalGrant_askAccess', "Demander l'accès")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ADMGrantModal
