import {
    addDays,
    addQuarters,
    endOfDay,
    startOfDay,
    startOfMonth,
    endOfMonth,
    addMonths,
    startOfWeek,
    endOfWeek,
    startOfQuarter,
    endOfQuarter,
    startOfLastQuarter,
    endOfLastQuarter,
    isSameDay,
    differenceInCalendarDays,
  } from 'date-fns';
  
  const defineds = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    startOfQuarter: startOfQuarter(new Date()),
    endOfQuarter: endOfQuarter(new Date()),
    startOfLastQuarter: startOfQuarter(addQuarters(new Date(), -1)),
    endOfLastQuarter: endOfQuarter(addQuarters(new Date(), -1))
    
  };
  
  const staticRangeHandler = {
    range: {},
    isSelected(range) {
      const definedRange = this.range();
      return (
        isSameDay(range.startDate, definedRange.startDate) &&
        isSameDay(range.endDate, definedRange.endDate)
      );
    },
  };
  
  export function createStaticRanges(ranges) {
    return ranges.map(range => ({ ...staticRangeHandler, ...range }));
  }
  
  export const OverrideStaticRanges = createStaticRanges([
    {
      label: 'Aujourd\'hui',
      range: () => ({
        startDate: defineds.startOfToday,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: 'Hier',
      range: () => ({
        startDate: defineds.startOfYesterday,
        endDate: defineds.endOfYesterday,
      }),
    },
  
    // {
    //   label: 'Cette semaine',
    //   range: () => ({
    //     startDate: defineds.startOfWeek,
    //     endDate: defineds.endOfWeek,
    //   }),
    // },
    // {
    //   label: 'La semaine dernière',
    //   range: () => ({
    //     startDate: defineds.startOfLastWeek,
    //     endDate: defineds.endOfLastWeek,
    //   }),
    // },
    {
      label: 'Ce mois-ci',
      range: () => ({
        startDate: defineds.startOfMonth,
        endDate: defineds.endOfMonth,
      }),
    },
    {
      label: 'Le mois dernier',
      range: () => ({
        startDate: defineds.startOfLastMonth,
        endDate: defineds.endOfLastMonth,
      }),
    },
    {
      label: 'Ce trimestre',
      range: () => ({
        startDate: defineds.startOfQuarter,
        endDate: defineds.endOfQuarter,
      }),
    },
    {
      label: 'Le trimestre dernier',
      range: () => ({
        startDate: defineds.startOfLastQuarter,
        endDate: defineds.endOfLastQuarter,
      }),
    }
  ]);
  
  export const defaultInputRanges = [
    {
      label: 'Jours avant',
      range(value) {
        return {
          startDate: addDays(defineds.startOfToday, (Math.max(Number(value), 1) - 1) * -1),
          endDate: defineds.endOfToday,
        };
      },
      getCurrentValue(range) {
        if (!isSameDay(range.endDate, defineds.endOfToday)) return '-';
        if (!range.startDate) return '∞';
        return differenceInCalendarDays(defineds.endOfToday, range.startDate) + 1;
      },
    },
    {
      label: 'Jours Aprés',
      range(value) {
        const today = new Date();
        return {
          startDate: today,
          endDate: addDays(today, Math.max(Number(value), 1) - 1),
        };
      },
      getCurrentValue(range) {
        if (!isSameDay(range.startDate, defineds.startOfToday)) return '-';
        if (!range.endDate) return '∞';
        return differenceInCalendarDays(range.endDate, defineds.startOfToday) + 1;
      },
    },
  ];
  