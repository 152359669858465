import React, { useEffect, useState } from 'react'
import './EditCreatorAndRoleModal.css'
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Axios from '../../../../axios-edipub';
import { Tooltip, withStyles } from '@material-ui/core';
import { getNotyfObject } from '../../../../shared/utility';
import Spinner from 'react-bootstrap/Spinner';

const CTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 15,
    },
}))(Tooltip);

function EditCreatorAndRoleModal({handleClose, show, userUid, userRole, roleUpdateHandler, setUser}) {
    const {t} = useTranslation();
    const [agencies, setAgencies] = useState(null)
    const [agenciesCreator, setAgenciesCreator] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false);

    let notyf = getNotyfObject();

    const updateClickHandler = () => {
        let error = null;
        if(agencies && agencies?.length > 0 ) {
            let agenciesUid = agencies.map(agency => agency.uid);
            let errorMsg = t('masterData:editCreatorError', "Veuillez sélectionner un nouveau responsable pour chaque agence")
            if(agenciesCreator){
                agenciesUid.map(agencyUid => {
                    if(!agenciesCreator[agencyUid])
                        error = errorMsg
                })
            } else {
                error = errorMsg
            }
            if(error)
                setError(error)
            else {
                setUser(user => ({
                    ...user,
                    role : userRole,
                    creators : agenciesCreator
                }))
                handleClose();
            }
        }
    }
    
    useEffect(() => {
        Axios.get("/user/" + userUid + "/agenciesPossibleSubstitutes").then(response => {
            setAgencies(response?.data?.agenciesCreatorsList)
        }).catch(response => {
            notyf.error(t("mandat:generatedErrorMessage"));
        })
    }, [])







    const agencyCreatorChangeHandler = (agencyUid, creatorUid) => {
        if(agenciesCreator) {
            setAgenciesCreator({
                ...agenciesCreator,
                [agencyUid] : creatorUid
            })
        } else {
            setAgenciesCreator({
                [agencyUid] : creatorUid
            })
        }
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                <Modal.Title>
                    {t('mandat:creatorModal_title', "Modification du responsable du mandat")} 
                </Modal.Title>
                </Modal.Header>
                <Modal.Body className="ADMCreationModal__body" style={{width: "650px"}}>
                <div className="alert alert-warning" role="alert">
                    <p>
                        {t('masterData:editCreatorWarning1', "ATTENTION : cet utilisateur est responsable de mandats qui ne sont pas encore signés.")}<br/>
                        {t('masterData:editCreatorWarning2', "Vous devez définir un nouveau responsable pour chaque Mandataire.")}
                    </p>
                </div>
                    <div className="editCreatorModal__body__container">
                        <div className="editCreatorModal__header__container">
                            <h5 className="editCreatorModal__title__container">{t('default:mandate', "Mandat")}</h5>
                            {/* <button className="btn btn-danger btn-sm">Résilier tout</button> */}
                        </div>
                        <div className="editCreatorModal_possibleCreators_container">
                            {
                                error && (
                                    <div className="alert alert-danger" role="alert">
                                        <p>
                                            {error}
                                        </p>
                                    </div>)
                            }
                        {
                            agencies && agencies?.length > 0 ? agencies?.map(agency => (
                                <div className="editCreatorModal_possibleCreators_item"> 
                                    <div className="possibleCreators_label_container">
                                        <div className="possibleCreators_label_name">
                                            {agency?.official_name}
                                        </div>
                                        <CTooltip title={agency?.suspendedDocs + " Document(s) en cours"} className="tableActions__icon" className="">
                                            <div className="possibleCreators_label_suspendedDocs">
                                                {agency?.suspendedDocs}
                                            </div>
                                        </CTooltip>
                                    </div>
                                    <div className="possibleCreators_list_container">
                                        <select 
                                            className="form-control " 
                                            value={(agenciesCreator && agenciesCreator[agency?.uid]) ? agenciesCreator[agency?.uid] : ""} 
                                            onChange={(e) => agencyCreatorChangeHandler(agency?.uid, e.target.value)}
                                        >
                                            <option value="">
                                                {t('mandat:selectCreator', "Sélectionnez un nouveau responsable")}
                                            </option>
                                            {
                                                agency?.possibleCreators?.map(user => 
                                                    <option value={user.uid}>
                                                        {user.name + " (" + user.email + ")"}
                                                    </option>
                                                )
                                            }
                                        </select>
                                    </div>
                                </div>
                            )) : null
                        }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                {
                    loading ?
                        <Spinner animation="border" variant="secondary" />
                    : 
                        <>
                            <Button variant="secondary" onClick={handleClose}>
                                {t('default:cancel', "Annuler")}
                            </Button>
                            <Button 
                                variant="primary" 
                                onClick={() => updateClickHandler()}
                                disabled={!agenciesCreator || !agencies}
                            >
                                {t('masterData:update', "Modifier")}
                            </Button>
                        </>
                }
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default EditCreatorAndRoleModal