import React, { useState } from 'react'
import './ReasonModal.css'
import { Notyf } from 'notyf';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function ReasonModal({ handleClose, show, handleAction }) {
    const [reasonMessage, setReasonMessage] = useState(null)
    const [showConfirme, setShowConfirme] = useState(false)
    const { t } = useTranslation();

    const clickHandler = () => {
        handleAction(reasonMessage)
        handleClose();
    }

    const setMessage = (value) => {
        if (value.length > 0) {
            setShowConfirme(true)
        } else {
            setShowConfirme(false)

        }
        setReasonMessage(value)


    }

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
            //contentClassName="modal_w_mc" 
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t('mandat:resignSignMod', "Raison de refus")} </Modal.Title>
                </Modal.Header>
                <Modal.Body className="ResignReasonModal__body" >
                    <div className="ResignReasonModal__motif__container">
                        <h5>{t('default:reason', "Motif") + " (" + t('default:mandatory', "obligatoire") + ")"} </h5>
                        <textarea
                            class="form-control"
                            name="reason"
                            rows="1"
                            value={reasonMessage || ''}
                            onChange={(e) => setMessage(e.target.value)}
                        >
                        </textarea>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t('default:cancel', "Annuler")}
                    </Button>
                    <Button variant="primary" disabled={!showConfirme} onClick={() => clickHandler()}>
                        {t('default:confirm', "Confirmer")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ReasonModal
