import React, { useEffect, useState } from 'react'
import './ProductsModal.css';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import Axios from '../../../../../../../../axios-edipub'
import { Button, Modal } from 'react-bootstrap';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import CheckIcon from '@material-ui/icons/Check';
import { useTranslation } from 'react-i18next';

function ProductsModal({mandataireUid, agencyUid, advertiserUid, advertiserGroup, handleClose, show, addProducts}) {
    const [entityProducts , setEntityProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([])
    const [filtredProducts, setFiltredProducts] = useState([]);
    const {t} = useTranslation();

    useEffect(() => {
        let agencyFilter = (mandataireUid === agencyUid) ? 
                                mandataireUid 
                            :
                                "'" + mandataireUid + "', '" + agencyUid + "'";   
        Axios.get('entity2Brand//brands', {
            params : {
                agencyFilter,
                advertiserUid : advertiserUid,
                advertiserGroup
            }
        }).then(response => {
            setEntityProducts(response.data)
            setFiltredProducts(response.data)
        })
        return () => {
            setSelectedProducts([]);
        }
    }, [agencyUid])

    const productFilterHandler = (searchQuery) => {
        let arrayFiltred = filtredProducts;
        if (searchQuery == null || searchQuery.trim() === '' ) {
            setFiltredProducts(entityProducts)
        } else {
            arrayFiltred = entityProducts.filter(row => {
                if (row.label.toLowerCase().includes(searchQuery.trim().toLowerCase()))
                    return true
                else return false
            })
            setFiltredProducts(arrayFiltred)
        }    
    }

    const SelectProduct = (product) => {
        let productIndex = selectedProducts.findIndex(row => row.uid === product.uid);
        let selectedTemp = [...selectedProducts];
        if(productIndex >= 0)
            selectedTemp.splice(productIndex,1)
        else 
            selectedTemp = [...selectedProducts, product];
        setSelectedProducts(selectedTemp);
    }

    const selectAllProducts = (e) => {
        let selectedTemp = []
        if (e.target.checked) {
            selectedTemp = filtredProducts.map(product => ({uid : product.uid, label: product.label, new: true}))
        }
        setSelectedProducts(selectedTemp)
    }

    const addClickHandler = () => {
        addProducts(selectedProducts)
        handleClose()
    }

    return (
        <>
            
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>{t('mandat:productsModalTitle', "Ajouter des marques ou produits")} </Modal.Title>
                </Modal.Header>
                <Modal.Body className="productsModal__body" >
                    <div className="productsModal__header">
                        <div className="productsModal__searchInput input-group input-group-sm">
                            <input 
                                type="text" 
                                className="form-control" 
                                placeholder={t('default:search', "Rechercher")}  
                                aria-label="Recipient's username" 
                                aria-describedby="basic-addon2" 
                                style={{height: "100%"}}
                                onChange={(e) => productFilterHandler(e.target.value) } />
                            <div 
                                className="input-group-append"
                                style={{height: "100%"}} >
                                <span className="input-group-text" id="basic-addon2"><SearchIcon/> </span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <p className="productsModal_totalItems">{selectedProducts?.length} produit(s) sélectionné(s)</p>
                            <div className="form-check modals_selectAll_container">
                                <input className="form-check-input" type="checkbox" value="" id="selectAllProducts" onChange={(e) => selectAllProducts(e)} />
                                <label className="form-check-label" for="selectAllProducts" >
                                    {t('mandat:selectAll', "Selectionner tout")}
                                </label>
                            </div>
                        </div>
                        
                    </div>
                    <div className="productsModal_items">
                        {
                            filtredProducts?.length > 0 ? filtredProducts.map(product => (
                                <div className="productsModal__item">
                                    <div className="productsModal__itemIcon" onClick={() => SelectProduct({uid : product.uid, label : product.label, new : true})}>
                                        {
                                            selectedProducts.some(brand => brand.uid === product.uid) ? (
                                                <CheckIcon style={{backgroundColor: "#4BCA81", color: "white", borderRadius: "5px"}} />
                                            ) : <AddIcon style={{color: "blue",border: "1px solid rgb(112, 110, 107, 0.3)", borderRadius: "5px" }} />
                                        }
                                        
                                    </div>
                                    <div className="productsModal__itemName">
                                        {product.label}
                                    </div>
                                </div>
                            )) : <div>{t('mandat:noProducts', "Aucun produit à afficher")}  </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t('default:cancel', "Annuler")} 
                </Button>
                <Button variant="primary" onClick={() => addClickHandler()}>
                    {t('default:add', "Ajouter")} 
                </Button>
                </Modal.Footer>
            </Modal>
            
        </>
    )
}

export default ProductsModal
