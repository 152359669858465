import React, { useState, useEffect } from 'react'
import HeaderBar from '../../components/Home/HeaderBar/HeaderBar';
import Axios from '../../axios-edipub';
import './HomeRegie.css';
import HomeSearchView from '../HomeSearchView/HomeSearchView';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

function HomeRegie({role}) {
    const [documents, setDocuments] = useState([]);
    const [searchText, setSearchText] = useState(null);
    const [headerBarSearchInput, setHeaderBarSearchInput] = useState("")
    const [activeHeaderBarSearchInput, setActiveHeaderBarSearchInput] = useState(false)
    const [stepFilter, setStepFilter] = useState()
    const [stepsInitialStatus, setStepsInitialStatus ] = useState(true)
    const [admStepsDefaults, setAdmStepsDefaults] = useState()
    const [loading, setLoading] = useState(false)
    const [agencyFilter, setAgencyFilter] = useState(null);
    const [advertiserFilter, setAdvertiserFilter] = useState(null);
    const [yearFilter, setYearFilter] = useState(null);
    const [admSteps, setAdmSteps] = useState([])
    const {t} = useTranslation(); 
    
    useState(()=> {
        let admsStepTmp = [
            //{userRoles: "AE, MG, MR, MC", stepKey: "DRAFT", stepName :t('default:DRAFT', "Brouillon")  , if_checked: false},
            {userRoles: "AE, MG, MR, MC, AG, AC, RG, RC", stepKey: "TO_BE_APPROVED", stepName :t('default:TO_BE_APPROVED',"A approuver"), if_checked: true},
            {userRoles: "AE, MG, MR, MC, AG, AC, RG, RC", stepKey: "TO_BE_SIGNED", stepName :t('default:TO_BE_SIGNED', "Signature") , if_checked: true},
            {userRoles: "AE, MG, MR, MC, AG, AC, RG, RC", stepKey: "SIGNED", stepName :t('default:SIGNED', "Signé") , if_checked: true},
            // {userRoles: "AE, MG, MR, MC, AG", stepKey: "ABANDONED", stepName :t('default:ABANDONED', "Abandon"), if_checked: false},
            // {userRoles: "AE, MG, MR, MC, AG", stepKey: "EXPIRED", stepName :t('default:EXPIRED', "Expirée"), if_checked: false},
            // {userRoles: "AE, MG, MR, MC, AG", stepKey: "CANCELLED", stepName :t('default:CANCELLED', "Résilié"), if_checked: false}
        ] 
        let defaultStepFilterTmp = [
            //{userRoles: "AE, MG, MR, MC", stepKey: "DRAFT", stepName :t('default:DRAFT', "Brouillon")  , if_checked: true},
            {userRoles: "AE, MG, MR, MC, AG, AC, RG, RC", stepKey: "TO_BE_APPROVED", stepName :t('default:TO_BE_APPROVED',"A approuver"), if_checked: true},
            {userRoles: "AE, MG, MR, MC, AG, AC, RG, RC", stepKey: "TO_BE_SIGNED", stepName :t('default:TO_BE_SIGNED', "Signature") , if_checked: true},
            {userRoles: "AE, MG, MR, MC, AG, AC, RG, RC", stepKey: "SIGNED", stepName :t('default:SIGNED', "Signé") , if_checked: true}
        ]  
        setAdmSteps(admsStepTmp?.filter(step => step.userRoles.includes(role)));  
        setAdmStepsDefaults(JSON.parse(JSON.stringify(admsStepTmp?.filter(step => step.userRoles.includes(role))))) //deepcopy
      
    }, [])


    useEffect(() => {
        refreshContent()
    }, [activeHeaderBarSearchInput, stepFilter, agencyFilter, advertiserFilter, yearFilter])

    const refreshContent=()=>{
        setLoading(true);
        const steps = stepFilter ? stepFilter.map(f => f.stepKey).join(",") : "";
        Axios.get('/document', {params: {status: steps, searchFilter: headerBarSearchInput, filterAgency: agencyFilter, filterAdvertiser: advertiserFilter, filterYear: yearFilter}}).then(res => {
            setDocuments(res.data?.mandats);
            setLoading(false);
        })
    }

    const handleSearch = searchText => {
        refreshContent()
    }

    const handleAgencyFilterChange = agency => {
        setAgencyFilter(agency);
    }

    const handleYearFilterChange = year => {
        setYearFilter(year);
    }

    const handleAdvertiserFilterChange = advertiser => {
        setAdvertiserFilter(advertiser);
    }

    return (
        <div className="homeRegie">
            <div className="homeRegie__container">
                <HeaderBar 
                    setActiveHeaderBarSearchInput={setActiveHeaderBarSearchInput}
                    activeHeaderBarSearchInput={activeHeaderBarSearchInput}
                    setHeaderBarSearchInput={setHeaderBarSearchInput}
                    headerBarSearchInput={headerBarSearchInput}
                    admStepsDefaults={admStepsDefaults}
                    handleSearch={handleSearch} 
                    refresh_adms_list={setStepFilter} 
                    setStepsInitialStatus={setStepsInitialStatus}
                    handleAgencyFilterChange={handleAgencyFilterChange}
                    handleYearFilterChange={handleYearFilterChange}
                    handleAdvertiserFilterChange={handleAdvertiserFilterChange}
                    agencyFilter={agencyFilter}
                    advertiserFilter={advertiserFilter}
                    yearFilter={yearFilter}
                    admSteps={admSteps}
                    setAdmSteps={setAdmSteps}
                />
                <HomeSearchView documents={documents} stepsInitialStatus={stepsInitialStatus} loading={loading} refreshAdmList={refreshContent}  />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({ 
    role: state.auth.role
})
const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(HomeRegie)

 
