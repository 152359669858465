import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap';  
import Axios from './../../../../axios-edipub';

import "./DetailModal.css";
import { useTranslation } from 'react-i18next';

function DetailModal({handleClose, show, docUid, mediaUid}) { 
  
    const [detail, setDetail] = useState([])
    const {t} = useTranslation(); 

   
    useEffect(() => {
        if(docUid) { 
            let params = {};
            params.media = mediaUid; 
            Axios.get("document/"+docUid+"/kpiDocDetail", {'params' : params }).then(response => {
                setDetail(response.data);
            }) 
        }
    }, [])  

    return (
        <>
            <Modal show={show} onHide={handleClose} contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                <Modal.Title>{t('kpi:mediasList')} </Modal.Title>
                </Modal.Header>
                <Modal.Body className="ADMCreationModal__body">
                    {detail?.length > 0 ?( 
                    <div className="ADMCreationModal__description__container" style={{marginBottom: "15px"}}>
                        <h5>{t('kpi:regies', "")}</h5>
                        <ul className="Liste_regies"> 
                            {detail.map(c => ( 
                                <li>{c.official_name}</li>  
                                ))} 
                        </ul> 
                    </div>
                    ):(
                        <div className="Liste_regies"> 
                            <h5>{t('kpi:noregies')}</h5>
                        </div>
                    )
                    }
                     
                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                    {t('default:close')} 
                </Button>  
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default DetailModal
