import React, { useCallback, useEffect, useState } from 'react'
import './ADMCreationModal.css';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import Axios from '../../../../axios-edipub';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import frLocale from "date-fns/locale/fr";
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { useHistory } from 'react-router-dom';
import { Notyf } from 'notyf';
import { Button, Modal } from 'react-bootstrap';
import BusinessIcon from '@material-ui/icons/Business';
import AgenciesDropdown from './AgenciesDropdown/AgenciesDropdown';
import { getNotyfObject } from '../../../../shared/utility';
import { useTranslation } from 'react-i18next';

function ADMCreationModal({ handleClose, show }) {
    const { t } = useTranslation();
    const history = useHistory();
    const notyf = getNotyfObject();

    const [userAgencies, setUserAgencies] = useState(null);
    const [annonceurs, setAnnonceurs] = useState(null);
    const [groups, setGroups] = useState(null);
    const [selectedUserAgency, setSelectedUserAgency] = useState(null);
    const [selectedAnnonceur, setSelectedAnnonceur] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const currentDate = new Date();
    const defaultStartDate = new Date((currentDate.getMonth() >= 8 ? currentDate.getFullYear() + 1 : currentDate.getFullYear()), 0, 1);
    const defaultEndDate = new Date(defaultStartDate.getFullYear(), 12, 0);
    const [selectedStartDate, setSelectedStartDate] = React.useState(defaultStartDate);
    const [selectedEndDate, setSelectedEndDate] = React.useState(defaultEndDate);
    const [searchQuery, setSearchQuery] = useState(null);
    const [searchQueryGroup, setSearchQueryGroup] = useState(null);
    const [startDateError, setStartDateError] = useState(false);
    const [endDateError, setEndDateError] = useState(false);
    const [description, setDescription] = useState(null)
    const [langueForAdm, setLangueForAdm] = useState("FR")
    const [isPayer, setIsPayer] = useState("")

    const [advertiserType, setAdvertiserType] = useState("advertiser")

    const handleStartDateChange = (date) => {
        if (date === null) {
            setEndDateError(true)
        } else if (date.toString() === "Invalid Date") {
            setStartDateError(true)
        } else if (date.getFullYear <= 1900 || date.getFullYear > 2100) {
            setStartDateError(true)
        } else {
            setStartDateError(false)
        }
        setSelectedStartDate(date);
    };

    const handleEndDateChange = (date) => {
        if (date === null) {
            setEndDateError(true)
        } else if (date.toString() === "Invalid Date") {
            setEndDateError(true)
        } else if (date < selectedStartDate || date.getFullYear > 2100) {
            setEndDateError(true)
        } else {
            setEndDateError(false)
        }
        setSelectedEndDate(date);
    };

    const generateMaxEndDate = () => {
        let startDate = new Date(selectedStartDate);
        return new Date(startDate.setFullYear(startDate.getFullYear(), 12, 0))
    }

    const handleTypeChange = event => {
        const type = event.target.value;
        setAdvertiserType(type);
        if (type === "advertiser") {
            setSelectedGroup(null);
            setSearchQuery("");
        } else if (type === "group") {
            setSelectedAnnonceur(null);
            setSearchQueryGroup("");
        }
    };
    const setSelectedUserAgencyFunc = (e) => {
        const agencyIndex = e?.target.value
        setSelectedUserAgency(userAgencies[agencyIndex])
    }
    useEffect(() => {
        Axios.get('/entity//userEntities')
            .then(res => {
                setUserAgencies(res.data.entities)
                // if(res?.data.entities)
                //     setSelectedUserAgency(res?.data?.entities[0])
            })
            .catch(err => notyf.error(t("mandat:generatedErrorMessage")))
    }, [])

    useEffect(() => {
        let filter = {
            category: "annonceur",
            status: "ACTIVE",
            searchQuery: searchQuery
        }
        Axios.get('/entity', {
            params: filter
        })
            .then(res => {
                setAnnonceurs(res.data.entities)
            })
            .catch(err => notyf.error(t("mandat:generatedErrorMessage")))
    }, [searchQuery])

    useEffect(() => {
        let filter = {
            category: "Annonceur",
            searchQuery: searchQueryGroup
        }
        Axios.get('/group', {
            params: filter
        })
            .then(res => {
                setGroups(res.data.groupes)
            })
            .catch(err => notyf.error(t("mandat:generatedErrorMessage")))
    }, [searchQueryGroup])

    const createADM = useCallback(() => {
        if (!selectedUserAgency)
            notyf.error('Aucun Mandataire n\'a été sélectionné')
        else {
            switch (advertiserType) {
                case "advertiser":
                    if (!selectedAnnonceur)
                        notyf.error('Aucun Annonceur n\'a été sélectionné')
                    else {
                        Axios.post('/document', {
                            uidAnnonceur: selectedAnnonceur,
                            uidAgency: selectedUserAgency.uid,
                            startPeriod: selectedStartDate.getTime(),
                            endPeriod: selectedEndDate.getTime(),
                            description: description,
                            langue: langueForAdm,
                            isPayer: isPayer
                        }).then(response => {
                            // if (response?.data?.duplicatedInformation) {
                            //     notyf.error(t("default:entityControl", "Une entité avec le même annonceur, agence et la date des faits et en cours de validitée !!"))
                            // } else {
                            handleClose();
                            history.push('/mandat/' + response.data?.uidDocument)
                            if (response?.data?.duplicatedInformation) {
                                notyf.open({
                                    closeWith: ['click'],
                                    type: 'warning',
                                    background: 'orange',
                                    message: t("default:entityControl", "Une attestation de mandat avec le même annonceur ou groupe d’annonceur, la même agence existe déjà pour toute ou partie de la période indiquée")
                                });
                            }
                        }).catch(err => {
                            if (err.response?.data?.error && (err.response?.data?.error === 'Expired token'))
                                notyf.error('Une erreur s\'est produite ! Votre session a expiré. Veuillez vous déconnecter et vous reconnecter avant de renouveler l\'opération.')
                            else
                                notyf.error('Une erreur s\'est produite !')
                        })
                    };
                    break;
                case "group":
                    if (!selectedGroup)
                        notyf.error('Aucun groupe n\'a été sélectionné')
                    else {
                        Axios.post('/document', {
                            uidGroup: selectedGroup,
                            uidAgency: selectedUserAgency.uid,
                            startPeriod: selectedStartDate.getTime(),
                            endPeriod: selectedEndDate.getTime(),
                            description: description,
                            langue: langueForAdm,
                            isPayer: isPayer
                        }).then(response => {
                            handleClose();
                            history.push('/mandat/' + response.data?.uidDocument)
                            if (response?.data?.duplicatedInformation) {
                                notyf.open({
                                    closeWith: ['click'],
                                    type: 'warning',
                                    background: 'orange',
                                    message: t("default:entityControl", "Une attestation de mandat avec le même annonceur ou groupe d’annonceur, la même agence existe déjà pour toute ou partie de la période indiquée")
                                });
                            }
                        }).catch(err => {
                            if (err.response?.data?.error && (err.response?.data?.error === 'Expired token'))
                                notyf.error('Une erreur s\'est produite ! Votre session a expiré. Veuillez vous déconnecter et vous reconnecter avant de renouveler l\'opération.')
                            else
                                notyf.error('Une erreur s\'est produite !')
                        })
                    };
                    break;
                default: break;
            }
        }

    }, [selectedUserAgency, selectedAnnonceur, isPayer, selectedGroup, selectedStartDate, selectedEndDate, description, advertiserType])

    let annonceursList = null;

    (annonceurs && annonceurs.length > 0) ?
        annonceursList = (
            <table className="table">
                <thead className="thead-light">
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">{t('default:name', "NOM")} </th>
                        <th scope="col">{t('default:siret', "SIRET")}</th>
                        <th scope="col">{t('default:addressSS', "Adresse SS")}</th>
                    </tr>
                </thead>
                <tbody>
                    {annonceurs.map(row => (
                        <tr>
                            <td>
                                <div className="productsModal__itemIcon" onClick={() => setSelectedAnnonceur(row.uid)}>
                                    {(selectedAnnonceur && selectedAnnonceur === row.uid) ?
                                        (<ThumbUpIcon style={{ color: "green" }} />) :
                                        (<AddIcon style={{ color: "blue" }} />)
                                    }

                                </div>
                            </td>
                            <td>{row.commercial_name} </td>
                            <td>{row.siret} </td>
                            <td>{row.address_line} {row.post_code}, {row.label_primary} </td>
                        </tr>
                    ))}


                </tbody>
            </table>
        ) : annonceursList = (<h5>{t('home:noAdvertiser', "Aucun Annonceur à afficher")} </h5>);

    const groupsList = (groups && groups.length > 0) ?
        (<table className="table">
            <thead className="thead-light">
                <tr>
                    <th scope="col"></th>
                    <th scope="col">{t('default:name', "NOM")} </th>
                </tr>
            </thead>
            <tbody>
                {groups.map(row => (
                    <tr>
                        <td>
                            <div className="productsModal__itemIcon" onClick={() => setSelectedGroup(row.uid)}>
                                {(selectedGroup && selectedGroup === row.uid) ?
                                    (<ThumbUpIcon style={{ color: "green" }} />) :
                                    (<AddIcon style={{ color: "blue" }} />)
                                }

                            </div>
                        </td>
                        <td>{row.label} </td>
                    </tr>
                ))}


            </tbody>
        </table>)
        : <h5>{t('home:noGroup', "Aucun groupe à afficher")} </h5>;

    const addClickHandler = () => {
        createADM();

    }
    return (
        <>
            <Modal show={show} onHide={handleClose} contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t('home:modalCreation_title', "Creation d'une attestation de mandat")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="ADMCreationModal__body" style={{ width: "650px" }}>
                    <div className="ADMCreationModal__selectionContainer">
                        <div className="ADMCreationModal__AgencySelection_container">
                            <div className="row">
                                {/* <div className="AgencySelection__header_container col-6"> */}
                                <div className="col-6">
                                    {/* <h5>
                                        {t('home:modalCreation_selectAgency', "Sélectionner une agence")}
                                    </h5> */}
                                    {/* <AgenciesDropdown 
                                        agencies={userAgencies}
                                        selectUserAgency = {setSelectedUserAgency}
                                        zIndex="100"
                                    /> */}
                                    <label style={{ fontSize: "1.25rem" }} htmlFor="agence">{t('home:modalCreation_selectAgency', "Sélectionner une agence")} </label>
                                    <select className="form-control" name="agence"
                                        // value={selectedUserAgency} 
                                        onChange={(e) => setSelectedUserAgencyFunc(e)} >
                                        <option key={-1}> {t('home:modalCreation_selectAgencyOption', "-- Veuillez choisir une agence --")}</option>
                                        {
                                            userAgencies?.sort((a, b) => a.commercial_name.toLowerCase().localeCompare(b.commercial_name.toLowerCase())).map((agence, index) => {
                                                return <option key={index} value={index}>{agence?.commercial_name}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="ADMCreationModal__advertiserType col-6">
                                    <h5 style={{ fontSize: "18px" }}>{t('default:libellePayer', "Libellé payeur")} </h5>
                                    <select className="form-control" id="" name="language"
                                        onChange={(e) => setIsPayer(e.target.value)}
                                        disabled={false} >
                                        <option value="" selected={isPayer == ""}>
                                            {t('default:modalCreation_selectPayerOption', "-- Veuillez choisir un libellé payeur --")}
                                        </option>
                                        <option value="1" selected={isPayer === "1"}>{t('default:Payer', "Payeur")}</option>
                                        <option value="0" selected={isPayer === "0"}>{t('default:notPayer', "Non Payeur")}</option>
                                    </select>
                                </div>
                            </div>
                            {/* <div className="AgencySelection__selectedAgency"   style={{marginTop: "-40px"}}>
                                {selectedUserAgency?.commercial_name}
                            </div> */}
                        </div>
                        <hr className="hrElement" />
                        <div className="row">
                            <div className="ADMCreationModal__advertiserType col-6">
                                <h5 style={{ fontSize: "19px" }}>
                                    {t('home:modalCreation_selectAgencyType', "Sélectionner un type d'agence")}
                                </h5>
                                <select className="form-control" id="" name="advertiserType"
                                    onChange={(e) => handleTypeChange(e)}
                                    disabled={false} >
                                    <option value="advertiser">{t('default:modalCreation_selectAdv', "Annonceur")}</option>
                                    <option value="group">{t('default:modalCreation_selectAdv', "Groupe d'Annonceurs")}</option>
                                </select>
                                {/* <label><input type="radio" value="advertiser" onChange={handleTypeChange} checked={advertiserType === "advertiser"} />{t('default:modalCreation_selectAdv', "Annonceur")}</label>
                                <label><input type="radio" value="group" onChange={handleTypeChange} checked={advertiserType === "group"} />{t('default:modalCreation_selectAdv', "Groupe d'Annonceurs")}</label> */}
                            </div>
                            <div className="col-6 ">
                                <label style={{ fontSize: "1.25rem" }} htmlFor="language">{t('default:languageAdm', "Sélectionner une langue")} </label>

                                <select className="form-control" id="language" name="language"
                                    value={langueForAdm}
                                    onChange={(e) => setLangueForAdm(e.target.value)}
                                    disabled={false} >
                                    <option value="FR">FR</option>
                                    <option value="EN">EN</option>
                                </select>
                                {/* <input type="text" className="form-control" required minLength="1" id="language" name="language" value={userCopy?.language || user?.language || ''} onChange={(e) => (userCopy?.userId ? inputUserCopyChange(e.target.name, e.target.value) : inputUserChange(e.target.name, e.target.value)) } disabled={("MC, MR".includes(role) || ("MG".includes(role) && ("AG".includes(user?.role) || "RG".includes(user?.role))))? true : false}></input> */}
                            </div>
                        </div>

                        {/* <hr className="hrElement"/> */}
                        {advertiserType === "advertiser" &&
                            <div className="advertiser-container">
                                <div className="productsModal__header" style={{ padding: "0" }}>
                                    <h5>
                                        {t('home:modalCreation_selectAdv', "Sélectionner un Annonceur")}

                                    </h5>
                                    <div className="input-group input-group-sm" style={{ height: "30px" }}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t('home:modalCreation_searchPlaceholder', "Rechercher Annonceurs (Dénomination sociale, SIRET)")}
                                            name="searchQuery"
                                            style={{ height: "100%" }}
                                            onChange={(e) => setSearchQuery(e.target.value)} />
                                        <div
                                            className="input-group-append"
                                            style={{ height: "100%" }}
                                        >
                                            <span className="input-group-text" id="basic-addon2"><SearchIcon /> </span>
                                        </div>
                                    </div>
                                    {/* <p className="productsModal_totalItems">0 item(s) selected</p> */}
                                </div>
                                <div className="productsModal_items bg-white">
                                    {annonceursList}
                                </div>
                            </div>
                        }
                        {advertiserType === "group" &&
                            <div className="advertiser-container">
                                <div className="productsModal__header " style={{ padding: "0" }}>
                                    <h5>
                                        {t('home:modalCreation_selectAdvGroup', "Sélectionner un groupe d'Annonceurs")}
                                    </h5>
                                    <div className="input-group input-group-sm" style={{ height: "30px" }}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t('home:modalCreation_searchGroupPlaceholder', "Chercher des groupes d'Annonceurs (Nom)")}
                                            name="searchQuery"
                                            style={{ height: "100%" }}
                                            onChange={(e) => setSearchQueryGroup(e.target.value)} />
                                        <div
                                            className="input-group-append"
                                            style={{ height: "100%" }}
                                        >
                                            <span className="input-group-text" id="basic-addon2"><SearchIcon /> </span>
                                        </div>
                                    </div>
                                    {/* <p className="productsModal_totalItems">0 item(s) selected</p> */}
                                </div>

                                <div className="productsModal_items bg-white">
                                    {groupsList}
                                </div>
                            </div>
                        }
                    </div>
                    <div className="ADMCreationModal__description__container">
                        <h5>
                            {t('default:description', "Description")}
                        </h5>
                        <textarea
                            className="form-control"
                            name="description"
                            rows="1"
                            value={description || ''}
                            maxLength={500}
                            onChange={(e) => setDescription(e.target.value)}
                        >
                        </textarea>
                    </div>

                    <div className="ADMCreationModal__selectionPeriod__container mt-4">
                        <h5>
                            {t('home:modalCreation_periodTitle', "Définir la période de validité")}
                        </h5>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                            <Grid container justify="space-around">
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label={t('default:startAdmDate', "Date début ADM")}
                                    format="dd/MM/yyyy"
                                    value={selectedStartDate}
                                    onChange={handleStartDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    invalidDateMessage={t('default:invalidDateMessage', "Format de date invalide")}
                                    maxDateMessage={t('default:maxDateMessage', "La date ne doit pas être postérieure à la date maximale")}
                                    minDateMessage={t('default:minDateMessage', "La date ne doit pas être antérieure à la date minimale")}
                                    error={startDateError}
                                />
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label={t('default:endAdmDate', "Date fin ADM")}
                                    format="dd/MM/yyyy"
                                    value={selectedEndDate}
                                    onChange={handleEndDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    minDate={selectedStartDate}
                                    maxDate={generateMaxEndDate()}
                                    invalidDateMessage={t('default:invalidDateMessage', "Format de date invalide")}
                                    maxDateMessage={t('default:maxDateMessage', "La date ne doit pas être postérieure à la date maximale")}
                                    minDateMessage={t('default:minDateMessage', "La date ne doit pas être antérieure à la date minimale")}
                                    error={endDateError}
                                />

                            </Grid>
                        </MuiPickersUtilsProvider>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {(!selectedAnnonceur && !selectedGroup || !selectedUserAgency || isPayer.length == 0) && <span style={{ color: "red" }}>{t("mandat:pleaseSelectEntities", "Veuillez choisir une agence et un Annonceur")}</span>}
                    <Button variant="secondary" onClick={handleClose}>
                        {t('default:cancel', "Annuler")}
                    </Button>
                    <Button variant="primary" disabled={(startDateError || endDateError || !selectedAnnonceur && !selectedGroup || !selectedUserAgency || isPayer.length == 0)} onClick={() => addClickHandler()}>
                        {t('default:create', "Créer")}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* <button type="button" class="btn btn-light btn-sm text-primary" data-toggle="modal" data-target="#regiesModal">+ Créer une ADM</button>

            <div class="modal fade"  id="regiesModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" style={{maxWidth: "700px"}} role="document">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Creation d'une attestation de mandat</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="ADMCreationModal__body modal-body">
                        <div className="ADMCreationModal__selectionContainer">
                            <div className="productsModal__header" style={{padding: "0"}}>
                                <h5>Selectionner un Annonceur</h5>
                                <div class="input-group input-group-sm" style={{height: "30px"}}>
                                    <input 
                                        type="text" 
                                        class="form-control" 
                                        placeholder="Rechercher Annonceurs (Raison sociale, SIRET)" 
                                        name="searchQuery" 
                                        style={{height: "100%"}}
                                        onChange={(e) => setSearchQuery(e.target.value)} />
                                    <div 
                                        class="input-group-append" 
                                        style={{height: "100%"}}
                                    >
                                        <span class="input-group-text" id="basic-addon2"><SearchIcon/> </span>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="productsModal_items bg-white">

                                {annonceursList}
                                
                            </div>
                        </div>
                        <div className="ADMCreationModal__description__container">
                            <h5>Description</h5>
                            <textarea 
                                class="form-control" 
                                name="description"
                                rows="1"
                                value={description || ''}
                                onChange={(e) => setDescription(e.target.value)}
                            >
                            </textarea>
                        </div>

                        <div className="ADMCreationModal__selectionPeriod__container mt-4">
                            <h5>Définir la période de validité</h5>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                                <Grid container justify="space-around">
                                    <KeyboardDatePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        label="Date début ADM"
                                        format="dd/MM/yyyy"
                                        value={selectedStartDate}
                                        onChange={handleStartDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        invalidDateMessage="Format de date invalide"
                                        maxDateMessage="La date ne doit pas être postérieure à la date maximale"
                                        minDateMessage="La date ne doit pas être antérieure à la date minimale"
                                    />
                                    <KeyboardDatePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        label="Date fin ADM"
                                        format="dd/MM/yyyy"
                                        value={selectedEndDate}
                                        onChange={handleEndDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        minDate={selectedStartDate}
                                        invalidDateMessage="Format de date invalide"
                                        maxDateMessage="La date ne doit pas être postérieure à la date maximale"
                                        minDateMessage="La date ne doit pas être antérieure à la date minimale"
                                    />
                                    
                                </Grid>
                            </MuiPickersUtilsProvider>

                        </div>
                        

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
                        <button 
                            type="button" 
                            class="btn btn-primary" 
                            data-dismiss="modal" 
                            disabled={(startDateError || endDateError)}
                            onClick={() => createADM()}>
                                Créer
                            </button>
                    </div>
                    </div>
                </div>
            </div>  */}
        </>
    )
}

export default ADMCreationModal
