import React from 'react'
import './RegieMandat.css';
import ADMView from "../../ADMContext/ADMBody/ADMMain/ADMView/ADMView";
import { getNotyfObject } from '../../../shared/utility';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { useTranslation } from 'react-i18next';
import ModificationsChat from '../../ADMContext/ADMBody/ADMSide/ModificationsChat/ModificationsChat';
import Axios from '../../../axios-edipub';

function RegieMandat({ pdfFile, pdfFileName, mandatUid, admData, userRole }) {
    const tabActive = "mandat";
    let notyf = getNotyfObject();
    const { t } = useTranslation();

    const downloadPdf = () => {
        const source = `data:application/pdf;base64,${pdfFile}`;
        const a = document.createElement("a");
        a.href = source;
        a.download = pdfFileName;
        a.click();
    }

    const getXMLFile = (mandatUid) => {
        Axios.get('/document/' + mandatUid + '/ADMXMLFile').then(res => {
            //Axios.get('/document/'+mandatUid+'/BuildXMLFileForADM').then(res => {
            if (res?.data.error) {
                notyf.error(t("newHome:xmlFileError"))
            } else if (res?.data.fileContent) {
                const xmlFileContent = res?.data.fileContent
                const filename = res?.data.filename
                const url = window.URL.createObjectURL(new Blob([xmlFileContent]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                notyf.success(res?.data.filename + t('mandat:xmlToPdf'));
            }
        })
    }

    return (
        <div className="mandatEdition">
            {/* {pdfFile && <img src={pdfIcon} className="fileIcon" onClick={downloadPdf} title="Cliquez pour télécharger le mandat au format PDF" />} */}
            <div style={{ display: "flex", justifyContent: "inherit" }}>
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <a className="nav-link active" data-toggle="tab" href="#Mandat">{t('default:adm')}</a>
                    </li>
                    {/* <li className="nav-item">
                        <a className="nav-link" data-toggle="tab" href="#Edit">SIGNATURES</a>
                    </li> */}
                    {admData?.status === "SIGNED" && <li className="nav-item">
                        <a className="nav-link" data-toggle="tab" href="#Chat">{t('mandat:postSignChatTab').toUpperCase()}</a>
                    </li>}
                </ul>
                {/* {
                  admData?.status == "SIGNED" ?   
                    <img src={xmlIcon} className="fileIcon" onClick={() => getXMLFile(mandatUid)}  title="Télécharger le mandat au format XML" />
                    : null
                } */}
            </div>

            <div id="myTabContent" className="tab-content">
                <div className={tabActive === "mandat" ? "tab-pane fade show active" : "tab-pane fade"} id="Mandat">
                    <ADMView />
                </div>
                <div className={tabActive === "signatures" ? "tab-pane fade show active" : "tab-pane fade"} id="Edit">
                    {/* <div className="homeRegieBodyAdm__signatures">
                        <ADMSignature 
                            userRole={userRole}
                            mandatUid={mandatUid} 
                        />
                        
                    </div> */}
                </div>

                <div className="tab-pane fade" id="Chat">
                    <ModificationsChat documentObj={admData} type={"POST_SIGN_CHAT"} />
                </div>

            </div>

        </div>
    )
}

const mapStateToProps = (state) => ({
    pdfFile: state.mandat.admData?.pdfFile || null,
    pdfFileName: state.mandat.admData?.pdfFileName || null,
    mandatUid: state.mandat?.admData?.uid,
    admData: state.mandat.admData,
    userRole: state.auth.role
})
const mapDispatchToProps = dispatch => ({
})
export default connect(mapStateToProps, mapDispatchToProps)(RegieMandat)
