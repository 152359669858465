 import React from 'react'
import './TableActions.css';
import FilterListIcon from '@material-ui/icons/FilterList';
import ReplayIcon from '@material-ui/icons/Replay';

import { Tooltip, withStyles } from '@material-ui/core';

const CTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 15,
    },
}))(Tooltip);


function TableActions(props) {
    const {filterState, setShowFilters, resetFilters} = props;
    

    function handleFilters () {
        setShowFilters(!filterState)
    }  
    return (
        <div className="tableActions">
            <div className="tableActions__icons">
                <div className="tableActions__action" style={{border: "none"}}>
                    <CTooltip title="Reinitialiser les filtres" className="tableActions__icon">
                        <ReplayIcon onClick={() => resetFilters()} fontSize="small"  />
                    </CTooltip>
                </div>
                <div className="tableActions__action">
                    <CTooltip title="Filtres" className="tableActions__icon"  style={{color: filterState ? 'rgb(38, 146, 235)' : ''}}>
                        <FilterListIcon onClick={() => handleFilters()} fontSize="small"  />
                    </CTooltip>
                </div>
            </div>
        </div>
    )
}

export default TableActions
