import React, { useState } from 'react'
import './MandatClauses.css';
import EditClauseModal from './EditClauseModal/EditClauseModal';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

function MandatClauses({clauses, updateClauses, deleteClause, canEdit, agency}) {

    const [showEditClauseModal, setShowEditClauseModal] = useState(false) 
    const [editedClauseUid, setEditedClauseUid] = useState()
    const [editedClauseLabel, setEditedClauseLabel] = useState()

    const handleOnDragEnd = (result) => {
        if(result?.destination){
            const clausesTemp = Array.from(clauses);
            const [reorderedItem] = clausesTemp.splice(result.source.index, 1);
            clausesTemp.splice(result.destination.index, 0, reorderedItem);
            let clausesToUpdate = clausesTemp.map((clause,index) => {
                let clauseTemp = {...clause, orderIndex : index}
                return clauseTemp
            })
            updateClauses(clausesToUpdate)
        }
    } 

    const handleClauseEdition = (uid, newLabel) => { 
        const clausesTemp = clauses.map((c) =>
            c.uid === uid ? {  ...c,
                            label: newLabel,
                            updatedLabel: true                            
                        }
                    :   { ...c }
        )  
        updateClauses(clausesTemp);
    }

    const handleEditClauseClick = (uid, label) => {
        setEditedClauseUid(uid)
        setEditedClauseLabel(label)
        setShowEditClauseModal(true) 
    }

    return (<div> 
        <DragDropContext onDragEnd={handleOnDragEnd} >
            <Droppable droppableId="clauses">
                {(provided) => (
                    <div 
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className="mandatClauses__container">
                        {
                            clauses?.map((clause,index) => (
                                <Draggable key={clause.uid} draggableId={clause.uid} index={parseInt(clause.orderIndex)} isDragDisabled={!canEdit}>
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps} 
                                            className="mandatClauses__itemContainer">
                                            <DragHandleIcon className="mandatClause__dragIcon" fontSize="small" />
                                            <div className="mandatClause__content">
                                                {clause.label}
                                            </div>
                                            { clause.mandatory == 0 && canEdit  && <DeleteForeverIcon 
                                                className="mandatClause__deleteIcon" 
                                                fontSize="small" 
                                                onClick={() => deleteClause(clause)}
                                                />}
                                            {  clause.mandatory == 0 && canEdit  &&    <EditIcon 
                                                    className="mandatClause__deleteIcon"
                                                    onClick={() => handleEditClauseClick(clause.uid, clause.label)}
                                                />                                 
                                            } 
                                        </div>
                                    )}
                                </Draggable> 
                            ))
                        }
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
            
        </DragDropContext>
        
        {showEditClauseModal ? 
            <EditClauseModal
                show={showEditClauseModal}
                handleClose = {() => setShowEditClauseModal(false)}
                updateClauseLabel = {handleClauseEdition}  
                clauseUid= {editedClauseUid}
                clauseLabel = {editedClauseLabel} 
            /> 
            : null
        }
        </div>       
    )
}

export default MandatClauses
