import React, { useCallback, useEffect, useState } from 'react' 
import '../../../../containers/MasterData/MasterData.css'; 
import MUserForm from './MUserForm/MUserForm' 
import { useTranslation } from 'react-i18next';  
import { checkValidity, getNotyfObject,uuidv4  } from '../../../../shared/utility' 
import avatar from '../../../../assets/images/avatar.svg' 

function MUsersForm({users, setUsers, moveNext, moveBack, isAuth}) {

    const {t, i18n} = useTranslation(); 
    const [handleFormChanged, setHandleFormChanged] = useState(false);
    const [emailsErrors, setEmailsErrors] = useState([]); 

    const addUser = () => {  
        let usersTemp = [...users,
                         {"new" : true, "uid" : uuidv4(), "role": "AC", "function_label": "", "errors":[] }
                        ]   
        setUsers(usersTemp) 
        setHandleFormChanged(true);
    }  

    const updateUser = (userIndex,field, value) => {   
        let usersTemp = [...users] 
        usersTemp[userIndex][field] = value  
        usersTemp[userIndex].errors = []    
        setUsers(usersTemp) 
        setHandleFormChanged(true);
    } 

    const deleteUser = (e,userIndex) => {  
        e.preventDefault();  
        let usersTemp = [...users]  
        usersTemp.splice(userIndex,1);
        setUsers(usersTemp); 
        setHandleFormChanged(true);
    } 

    const moveBackClickHandler = (e) => { 
        let usersTemp = [...users] 
        let hasErrors = false
        e.preventDefault(); 
        usersTemp.forEach(userTemp =>{
            userTemp.errors = checkUserFormValidity(userTemp);
            if(userTemp.errors.length > 0)
                hasErrors = true 
        } );
        setUsers(usersTemp)  
        if(!hasErrors ){ 
            moveBack();  
        }  
    }

    const moveNextClickHandler = (e) => { 
        let usersTemp = [...users] 
        let hasErrors = false
        e.preventDefault(); 
        usersTemp.forEach(userTemp =>{
            userTemp.errors = checkUserFormValidity(userTemp);
            if(userTemp.errors.length > 0)
                hasErrors = true 
        } );
        setUsers(usersTemp)  
        if(!hasErrors ){ 
            moveNext();  
        }  
    } 
    
    const checkUserFormValidity = (user) => {
        let errors = [] 
        if(user?.first_name) {
            if(!user.hasOwnProperty('last_name') || !checkValidity(user?.last_name, {required: true})){
                errors.push(t("masterData:error_userLastName")) ;
            }
            if(!user.hasOwnProperty('first_name') || !checkValidity(user?.first_name, {required: true})){
                errors.push(t("masterData:error_userFirstName")) ;
            }
            if(!user.hasOwnProperty('role') || !checkValidity(user?.role, {required: true})){
                errors.push(t("masterData:error_userRole")) ;
            }
           
            if(!user.hasOwnProperty('email') || !checkValidity(user?.email, {required: true, isEmail: true})){ 
                    errors.push(t("masterData:error_userEmail")) ;  
            }else{
                let cptEmails = 0;
                for (let i = 0; i < users.length; i++) { 
                    if(users[i].email == user.email )
                        cptEmails++                    
                    if(users[i].uid == user.uid )
                        break
                }
                if(cptEmails>=2)
                    errors.push(t("masterData:alreadyAssociatedEmail")) ; 
            }  

            for (let i = 0; i < emailsErrors.length; i++) { 
                if(emailsErrors[i] == user.email )  
                    errors.push( t("manualEntity:errorEmailMessage")    + " : " + user.email ) ;                  
            }  
        } else {
            errors.push(t("masterData:error_userFirstName")) ;
        } 
        return errors;
    } 

    return (  
            <> 
                <div style={{padding : "20px", width:"100%", textAlign: "center"}}>  
                    <img className="rightSide_header_logo_big" src={avatar}></img>
                    <span style={{fontSize: "18.0pt" }} >{t('manualEntity:users', "Signataires et contacts de l'Annonceur")}</span>      
                </div>
                {users.map((user, index) =>  
                    <MUserForm   
                        key={index} 
                        user={user}
                        users={users}
                        userIndex={index}
                        updateUser={updateUser}
                        deleteUser={deleteUser}
                        addUser={addUser}
                        isAuth={isAuth}
                        emailsErrors={emailsErrors}
                        setEmailsErrors={setEmailsErrors}
                       /> 
                ) }  

                <div className="rightSide_form_submit_container"> 
                    <div className="rightSide_form_submit_container">      
                        <input 
                            type="submit" 
                            value={t('manualEntity:addUser', "Ajouter un contact")}     
                            className="btn btn-primary mt-3 rightSide_form_submitBtn" 
                            onClick={(e) => addUser()} />
                    </div>
                    <div className="rightSide_form_submit_container">      
                        <input 
                            type="submit" 
                            value={t('default:previous', "Précédent")}     
                            className="btn btn-primary mt-3 rightSide_form_submitBtn" 
                            onClick={(e) => moveBackClickHandler(e)} />
                    </div>
                    <div className="rightSide_form_submit_container">      
                        <input 
                            type="submit" 
                            value={t('manualEntity:defineProducts', "Définir les produits")}     
                            className="btn btn-primary mt-3 rightSide_form_submitBtn" 
                            onClick={(e) => moveNextClickHandler(e)} />
                    </div>
                </div>
            </>
		); 
}

export default MUsersForm
