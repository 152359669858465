import React, { useEffect, useState } from 'react'
import DescriptionIcon from '@material-ui/icons/Description';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ShareIcon from '@material-ui/icons/Share';
import SignatureIcon from '../../../../../../../assets/images/signatureIcon.png'
import {formatDate, getTimeDate} from '../../../../../../../shared/utility';
import { useTranslation } from 'react-i18next';

function SignatureEvent({isCreator, eventData, isLast}) {
    const [showContent, setShowContent] = useState(false)
    const {t} = useTranslation();

    useEffect(() => {
        if(isLast)
            setShowContent(true)
        else
            setShowContent(false)
    }, [isLast])

    return (
        <div className="timelineCard__container">
            <div className="timelineCard__hideicon" onClick={() => setShowContent(!showContent)}>
                {
                    showContent ? 
                        <ExpandMoreIcon />
                    :
                        <ChevronRightIcon />
                }
            </div>
            <div className="timelineCard__sideContainer">
                <div className="timelineCard__header">
                    <img src={SignatureIcon} style={{width: "24px", height: "24px", color: "#FFFFFF", borderRadius: "5px"}}  />
                    <div className="timelineCard__header_label">
                        {t('timeline:signedStatus', "Statut Signé")} 
                    </div>
                    <div className="timelineCard__header_time">
                        <span className="timelineCard__header_hour"> {getTimeDate(+eventData?.creationDate)}</span> 
                        <span className="timelineCard__header_date"> {formatDate(+eventData?.creationDate)}</span> 
                    </div>
                </div>
                <div className="timelineCard__content" style={{borderLeft: "2px solid rgb(0, 112, 210)",  display: showContent ? "block" : "none"}}>
                    <div className="timelineCard__content__info">
                        <p>{eventData?.data?.mr?.first_name + ' ' + eventData?.data?.mr?.last_name}</p>
                        <p>{t("timeline:youShallPublish", "Vous devrez publier l'attestation")}</p>
                    </div>
                    <div className="timelineCard__content__body">
                        <strong>{t('timeline:signatoriesList', "Liste des signataires")} </strong>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">{t('default:name', "Nom")}</th>
                                    <th scope="col">{t('default:society', "Société")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    eventData?.data?.signatureUsers  && eventData?.data?.signatureUsers.length > 0 ?
                                        eventData?.data?.signatureUsers.map(user => (
                                            <tr>
                                                <td>{user?.first_name + ' ' + user?.last_name} </td>
                                                <td>{user.official_name}</td>
                                            </tr>
                                        ))
                                    : null
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default SignatureEvent