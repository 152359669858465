import React, { useState, useEffect, useCallback, useMemo } from 'react'
import HeaderBar from '../../components/Home/HeaderBar/HeaderBar';
import HomeBody from '../../components/Home/HomeBody/HomeBody';
import HomeNotifications from '../../components/Home/HomeNotifications/HomeNotifications';
import './Home.css';
import Axios from '../../axios-edipub';
import { Refresh } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import { useTranslation } from 'react-i18next';

function Home({ userUid, setAdm, role }) {
    const [searchText, setSearchText] = useState(null);
    const [headerBarSearchInput, setHeaderBarSearchInput] = useState("")
    const [activeHeaderBarSearchInput, setActiveHeaderBarSearchInput] = useState(false)
    const [adms, setAdms] = useState([])
    const [stepFilter, setStepFilter] = useState()
    const [stepsInitialStatus, setStepsInitialStatus] = useState(true)
    const [admStepsDefaults, setAdmStepsDefaults] = useState()
    const [loading, setLoading] = useState(false)
    const [agencyFilter, setAgencyFilter] = useState(null)
    const [subAgencyFilter, setSubAgencyFilter] = useState(null)
    const [departmentFilter, setDepartmentFilter] = useState(null)
    const [advertiserFilter, setAdvertiserFilter] = useState(null)
    const [yearFilter, setYearFilter] = useState(null)
    const [admSteps, setAdmSteps] = useState([])
    const { t } = useTranslation();


    const initAdmSteps = () => {
        let admsStepTmp = [
            { userRoles: "AE, MG, MR, MC", stepKey: "DRAFT", stepName: t('default:DRAFT', "Brouillon"), if_checked: true },
            { userRoles: "AE, MG, MR, MC, AG, AC, RG, RC", stepKey: "TO_BE_APPROVED", stepName: t('default:TO_BE_APPROVED', "A approuver"), if_checked: true },
            { userRoles: "AE, MG, MR, MC, AG, AC, RG, RC", stepKey: "TO_BE_SIGNED", stepName: t('default:TO_BE_SIGNED', "Signature"), if_checked: true },
            { userRoles: "AE, MG, MR, MC, AG, AC, RG, RC", stepKey: "SIGNED", stepName: t('default:SIGNED', "Signé"), if_checked: true },
            { userRoles: "AE, MG, MR, MC, AG", stepKey: "ABANDONED", stepName: t('default:ABANDONED', "Abandon"), if_checked: false },
            { userRoles: "AE, MG, MR, MC, AG", stepKey: "EXPIRED", stepName: t('default:EXPIRED', "Expirée"), if_checked: false },
            { userRoles: "AE, MG, MR, MC, AG", stepKey: "CANCELLED", stepName: t('default:CANCELLED', "Résilié"), if_checked: false }
        ]
        let defaultStepFilterTmp = [
            { userRoles: "AE, MG, MR, MC", stepKey: "DRAFT", stepName: t('default:DRAFT', "Brouillon"), if_checked: true },
            { userRoles: "AE, MG, MR, MC, AG, AC, RG, RC", stepKey: "TO_BE_APPROVED", stepName: t('default:TO_BE_APPROVED', "A approuver"), if_checked: true },
            { userRoles: "AE, MG, MR, MC, AG, AC, RG, RC", stepKey: "TO_BE_SIGNED", stepName: t('default:TO_BE_SIGNED', "Signature"), if_checked: true },
            { userRoles: "AE, MG, MR, MC, AG, AC, RG, RC", stepKey: "SIGNED", stepName: t('default:SIGNED', "Signé"), if_checked: true }
        ]
        setStepsInitialStatus(true)
        setStepFilter(defaultStepFilterTmp?.filter(step => step.userRoles.includes(role)))
        setAdmSteps(admsStepTmp?.filter(step => step.userRoles.includes(role)));
        setAdmStepsDefaults(JSON.parse(JSON.stringify(admsStepTmp?.filter(step => step.userRoles.includes(role))))) //deepcopy
    }

    useState(() => {
        initAdmSteps();
    }, [])

    useEffect(() => {
        refreshContent()
        return () => {
            // setAdms([]);
            setAdm([])

        };
    }, [activeHeaderBarSearchInput, stepFilter, userUid, agencyFilter, subAgencyFilter, advertiserFilter, yearFilter, departmentFilter])

    const refreshContent = () => {
        setLoading(true);
        let steps = stepFilter ? stepFilter.map(f => f.stepKey).join(",") : "";
        steps = steps === "DRAFT,TO_BE_APPROVED,TO_BE_SIGNED,SIGNED" ? "" : steps; //deja step par defaut coté back           
        Axios.get('/document',
            {
                params: {
                    status: steps,
                    searchFilter: headerBarSearchInput,
                    filterAgency: agencyFilter,
                    filterSubAgency: subAgencyFilter,
                    filterDepartment: departmentFilter,
                    filterAdvertiser: advertiserFilter,
                    filterYear: yearFilter
                }
            }).then(res => {
                let data = res.data?.mandats
                if (data) {
                    // setAdms(data);
                    setAdm(data)
                }
                setLoading(false);
            })
    }

    const handleSearch = () => {
        refreshContent()
    }

    const handleAgencyFilterChange = useCallback((agency) => {
        setDepartmentFilter("");
        setAgencyFilter(agency);
    }, [])

    const handleSubAgencyFilterChange = useCallback((subagency) => {
        setSubAgencyFilter(subagency);
    }, [])

    const handleYearFilterChange = useCallback((year) => {
        setYearFilter(year);
    }, [])

    const handleAdvertiserFilterChange = useCallback((advertiser) => {
        setAdvertiserFilter(advertiser);
    }, [])

    const handleDepartmentFilterChange = useCallback((department) => {
        setDepartmentFilter(department);
    }, [])


    return (
        <div className="home">

            <div className="home__container">
                <HeaderBar
                    setActiveHeaderBarSearchInput={setActiveHeaderBarSearchInput}
                    activeHeaderBarSearchInput={activeHeaderBarSearchInput}
                    setHeaderBarSearchInput={setHeaderBarSearchInput}
                    headerBarSearchInput={headerBarSearchInput}
                    admStepsDefaults={admStepsDefaults}
                    stepFilter={stepFilter}
                    refresh_adms_list={setStepFilter}
                    handleSearch={handleSearch}
                    setStepsInitialStatus={setStepsInitialStatus}
                    handleAgencyFilterChange={handleAgencyFilterChange}
                    handleSubAgencyFilterChange={handleSubAgencyFilterChange}
                    handleYearFilterChange={handleYearFilterChange}
                    handleAdvertiserFilterChange={handleAdvertiserFilterChange}
                    handleDepartmentFilterChange={handleDepartmentFilterChange}
                    agencyFilter={agencyFilter}
                    subAgencyFilter={subAgencyFilter}
                    advertiserFilter={advertiserFilter}
                    yearFilter={yearFilter}
                    departmentFilter={departmentFilter}
                    admSteps={admSteps}
                    setAdmSteps={setAdmSteps} />
                <div className="home__homeBodyContainer">
                    <div className="home__homeBodyMain">
                        <HomeBody
                            activeHeaderBarSearchInput={activeHeaderBarSearchInput}
                            headerBarSearchInput={headerBarSearchInput}
                            setHeaderBarSearchInput={setHeaderBarSearchInput}
                            refresh_adms_list={refreshContent}
                            searchText={searchText}
                            setSearchText={setSearchText}
                            stepFilter={stepFilter}
                            stepsInitialStatus={stepsInitialStatus}
                            loading={loading}
                            initAdmSteps={initAdmSteps} />
                    </div>
                    {/* <div className="home__homeBodyNotifications">
                        <HomeNotifications />
                    </div> */}
                </div>


            </div>

        </div>
    )
}
const mapStateToProps = (state) => ({
    userUid: state.auth.userId,
    role: state.auth.role,
    listAdm: state.mandat.adm

})
const mapDispatchToProps = dispatch => ({
    setAdm: (a) => dispatch(actions.setAdm(a))
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)
