import React, { useState, useEffect, useCallback  } from 'react'
import './Header.css';
import Icon from '../../assets/images/Icone.png'
import {NavLink} from 'react-router-dom';
import Axios from './../../axios-edipub';
import SearchIcon from '@material-ui/icons/Search';
import HelpIcon from '@material-ui/icons/Help';
import EmailIcon from '@material-ui/icons/Email';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import NoteAddIcon from '@material-ui/icons/NoteAdd'; 
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PersonIcon from '@material-ui/icons/Person';
import { CTooltip} from '../../shared/utility';
import StorageIcon from '@material-ui/icons/Storage';
import UserDropdown from './UserDropdown/UserDropdown';
import NotificationsDropdown from './NotificationsDropdown/NotificationsDropdown';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import LanguageList from '../LanguageList/LanguageList';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next';

function Header({login, role, masterDatasPending/*, getCountMasterDatasPending*/, visualisation, connectedUserUid, exitVisualisation, userUid}) { //commente getCountMasterDatasPending car pas utilisé car on utilise pas flag pending
    const {t} = useTranslation();
    const [showProfilDropdown, setProfilShowDropdown] = useState(false)
    const [showNotificationsDropdown, setShowNotificationsDropdown] = useState(false)
    const [ events, setEvents ] = useState([]);
    const [ nbEvents, setNbEvent  ] = useState(0);
    const [ sortOrder, setSortOrder] = useState(false)
    const history = useHistory()
    const refresh_eventsContent = useCallback(() => {

        Axios.get('/documentevent/'+sortOrder+'/userEvent').then(res => {
            let userEvents = res.data?.userEvents
            if(userEvents) {
                setEvents(userEvents)
                setNbEvent(userEvents.length)
            }
        })
    }, [sortOrder, userUid])

    useEffect(() => {
        refresh_eventsContent(sortOrder)
    }, [sortOrder, userUid])


    //Commenté //pas utilisé car on utilise pas flag pending
    // useEffect(() => {
    //     if("AG, AE".includes(role))
    //         getCountMasterDatasPending()
    // }, [role])

    function eventsFilter (){
        refresh_eventsContent(sortOrder)
    }

    function handleEvent( eventId ){
        Axios.delete('/documentevent/'+eventId+'/deleteEvent').then(res => {
            if(res.data.success)
                refresh_eventsContent(sortOrder)
        })
    }

    const existVisualisationClickHandler = () => {
        exitVisualisation(connectedUserUid);
        history.push("/masterData")
        
        // window.location.reload(false);
    }

    return (
        <div className="header">
            <div className="header__container">
                <NavLink exact to="/" className="header__logo">
                    <img src={Icon} className="logo__image"  alt="logo" />
                </NavLink>
                <div className="header__actions">
                    {
                        visualisation ? (
                            <div className="header_visualisationLogin_container">
                                <div className="header_visualisationLogin_content">
                                    <div className="header_visualisationLogin_value">
                                        {login}
                                    </div>
                                    <div 
                                        className="header_visualisationLogin_exitIcon"
                                        onClick={() => existVisualisationClickHandler()}    
                                    >
                                        <VisibilityOffIcon />
                                    </div>
                                </div>
                            </div>
                        ) : <p>{login}</p>
                    }
                    <div className="header__Profil">
                        <div style={{position: "relative"}}>
                            <NotificationsIcon  
                                className="header__personIcon"
                                
                                onClick={() => setShowNotificationsDropdown(!showNotificationsDropdown)}
                                style={{color : showNotificationsDropdown ? "blue" : "#706E6B"}}/>
                                {
                                    (nbEvents > 0) ? (
                                        <p className="nbEvents_element">{nbEvents}</p>
                                    ):(
                                        ""
                                    )
                                }
                        </div>
                        <NotificationsDropdown
                            zIndex="100"
                            showNotifications={showNotificationsDropdown}
                            setShowNotifications={setShowNotificationsDropdown}
                            sortOrder={sortOrder}
                            setSortOrder={setSortOrder}
                            handleDeleteEvent={handleEvent} 
                            userEvents={events}
                            nbUserEvents={nbEvents}
                            handleEventsFilter={eventsFilter} 
                            userRole={role}
                            />
                    </div>
                    {role === "AE" &&
                        <NavLink exact to="/emails" className="header__action" activeClassName="header__action_active">
                            <CTooltip title="Liste des emails" >
                                <div style={{position: "relative"}}>
                                    <EmailIcon />
                                </div>
                            </CTooltip>
                        </NavLink>}
                    <NavLink exact to="/faq" className="header__action" activeClassName="header__action_active">
                        <CTooltip title={t('faq:faq', "Foire aux questions")} >
                            <div style={{position: "relative"}}>
                                <HelpIcon />
                            </div>
                        </CTooltip>
                    </NavLink>
                    {"AE, MG, MR".includes(role) &&
                        <>
                        <NavLink exact to="/kpi" className="header__action" activeClassName="header__action_active">
                            <CTooltip title={t('kpi:kpi', "Indicateurs clé de performance (ICP)")} >
                                <div style={{position: "relative"}}>
                                    <InsertChartIcon />
                                </div>
                            </CTooltip>
                        </NavLink>
                        <NavLink exact to="/template" className="header__action" activeClassName="header__action_active">
                        <CTooltip title={t('template:templates', "Templates")} >
                            <div style={{position: "relative"}}>
                                <NoteAddIcon />                              
                            </div>
                        </CTooltip>
                        </NavLink>

                        {
                        //WORK IN PROGRESS
                        //
                        <NavLink exact to="/manualentity" className="header__action" activeClassName="header__action_active">
                        <CTooltip title={"Saisie manuelle par Annonceur"} >
                            <div style={{position: "relative"}}>
                                <AlternateEmailIcon />                              
                            </div>
                        </CTooltip>
                        </NavLink>  
                        //
                        }

                        </>
                    }
                    {
                    !"AC, RC".includes(role) ? (
                        <NavLink exact to="/masterData" className="header__action" activeClassName="header__action_active">
                            <CTooltip title="Master data" >
                                <div style={{position: "relative"}}>
                                    <StorageIcon />
                                    {   // Commenté //pas utilisé car on utilise pas flag pending  
                                         //countUsersPending
                                        // ("AG, AE".includes(role) && /*usersPending*/masterDatasPending > 0) ? (
                                        //     <p 
                                        //         className="countMasterDatasPending"
                                        //         style={{
                                        //             backgroundColor: "goldenrod"
                                        //         }}    
                                        //     >
                                        //         {//usersPending
                                        //         masterDatasPending
                                        //         }
                                        //     </p>
                                        // ):(
                                        //     ""
                                        // )
                                    }
                                </div>
                                
                            </CTooltip>
                        </NavLink>
                        ) : (
                            ""
                        )
                    }

                    <LanguageList />

                    <div className="header__profil" style={{marginLeft: ("AC, RC".includes(role)? "10px" : "")}}>
                            <PersonIcon 
                                className="header__personIcon"
                                onClick={() => setProfilShowDropdown(!showProfilDropdown)}
                                style={{color : showProfilDropdown ? "blue" : "#706E6B"}}
                            />
                            <UserDropdown
                                zIndex="100"
                                show={showProfilDropdown}
                                setShow={setProfilShowDropdown}
                            />
                    </div>

                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => ({ 
    role: state.auth.role,
    login: state.auth.login,
    visualisation: state.auth.visualisation,
    connectedUserUid: state.auth.connectedUserUid,
    userUid: state.auth.userId,
    masterDatasPending : state.mandat.masterDatasPending

})
const mapDispatchToProps = dispatch => ({    
    // getCountMasterDatasPending: () => dispatch(actions.getCountMasterDatasPending()),  //pas utilisé car on utilise pas flag pending
    exitVisualisation: (userUid) => dispatch(actions.exitVisualisation(userUid)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
