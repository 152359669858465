import React, { useEffect, useState, useCallback } from 'react'
import './ADMProgressLine.css';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import Axios from '../../../axios-edipub';
import { Notyf } from 'notyf';
import { getNotyfObject } from '../../../shared/utility';
import ADMWorkFlow from '../ADMWorkFlow/ADMWorkFlow';
import _ from 'lodash';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import PublicationRegieModal from './PublicationRegieModal/PublicationRegieModal';
import CustomConfirmModal from './CustomConfirmModal/CustomConfirmModal';
import Spinner from 'react-bootstrap/Spinner'

function ADMProgressLine({ status, getMandatData, updateMandatStatus, mandatUid, createMandatTimelineEvent, role, isCreator, connectedUser, pdfFile, admData, admUpdate, setShowSignatureTab, setTabSideActive, error }) {
    const [showADMWorkflow, setShowADMWorkflow] = useState(false)
    const [showADMPublication, setShowADMPublication] = useState(false)
    const [autoApproved, setAutoApproved] = useState(false)
    const [showConfirmToSignModal, setShowConfirmToSignModal] = useState(false)
    const [showConfirmShareAdmModal, setShowConfirmShareAdmModal] = useState(false)
    const [validateAdmLoading, setValidateAdmLoading] = useState(false);
    const [shareAdmLoading, setShareAdmLoading] = useState(false);
    const [approveAdmLoading, setApproveAdmLoading] = useState(false);
    const [showPublicationButton, setShowPublicationButton] = useState(false);
    const [nbSelectedRegie, setNbSelectedRegie] = useState()
    const { t } = useTranslation();
    let notyf = getNotyfObject();
    const [classNames, setClassNames] = useState({
        revTriangle: ["rev_triangle_gray", "rev_triangle_gray", "rev_triangle_gray"],
        pipe: ["pipe_gray", "pipe_gray", "pipe_gray"],
        triangle: ["triangle_gray", "triangle_gray"]
    });
    const [messageInfo, setMessageInfo] = useState(null)

    const isActorWithoutSignatory = () => {
        let actorWithoutSignatory = (admData.annonceur.usersDoc.filter(u => u.is_signataire == 1).length == 0 && admData.advertiserContact === null)
            || (admData.mandataire.usersDoc.filter(u => u.is_signataire == 1).length == 0)
        admData.sousMandataires.forEach(sousMandataire => {
            actorWithoutSignatory = actorWithoutSignatory || (sousMandataire.usersDoc.filter(u => u.is_signataire == 1).length == 0)
        });
        return actorWithoutSignatory
    }

    const isActorMuchSignatory = () => {
        let advertiserSignatories = admData.annonceur.usersDoc.filter(u => u.is_signataire == 1).length
        if (admData?.advertiserContact !== null) {
            advertiserSignatories += 1
        }
        const isAdvertiserMuchSignatories = advertiserSignatories > 1;
        const isMandatoryMuchSignatories = admData?.mandataire?.usersDoc.filter(usr => +usr.is_signataire === 1).length > 1;
        let isSubmadatoriesMuchSignatories = false;
        if (admData?.sousMandataires.length > 0) {
            admData?.sousMandataires.map(subMandatory => {
                isSubmadatoriesMuchSignatories = isSubmadatoriesMuchSignatories || subMandatory?.usersDoc.filter(usr => +usr.is_signataire === 1).length > 1
            })
        }
        return isAdvertiserMuchSignatories || isMandatoryMuchSignatories || isSubmadatoriesMuchSignatories;
    }

    const isActorsMuchRepresentant = () => {
        const isAdvertisorMuchRep = admData?.annonceur?.usersDoc.filter(usr => +usr.is_legal_representative === 1).length > 1;
        const isMandatoryMuchRep = admData?.mandataire?.usersDoc.filter(usr => +usr.is_legal_representative === 1).length > 1;
        let isSubmadatoriesMuchRep = false;
        if (admData?.sousMandataires.length > 0) {
            admData?.sousMandataires.map(subMandatory => {
                isSubmadatoriesMuchRep = isSubmadatoriesMuchRep || subMandatory?.usersDoc.filter(usr => +usr.is_legal_representative === 1).length > 1
            })
        }
        return isAdvertisorMuchRep || isMandatoryMuchRep || isSubmadatoriesMuchRep;
    }

    const isMrWithoutDepartment = () => {
        let hasDepartment = (admData?.mandataire?.departments.length > 0)
        let hasDepartmentSelected = admData?.mandataire?.departments.filter(dpt => +dpt.selected === 1).length > 0;
        return hasDepartment && !hasDepartmentSelected;
    }

    // const isMissingMandatoryContributor = () => {  
    //     const isWithoutMandatoryContributor = admData?.mandataire?.usersDoc.filter(u => +u.is_contributor === 1).length === 0;
    //     return +admData.agencyContributorRequired === 1 && isWithoutMandatoryContributor;
    // }

    const initShowPublicationButton = () => {
        if (isCreator)
            setShowPublicationButton(true)
        else if ("MR, MG,MC".includes(role)) {
            Axios.get('/entity', {
                params: {
                    searchQuery: '',
                    category: 'Agence',
                    sortOrder: "ASC"
                }
            }).then(res => {
                if (res.data.entities.some(row => row.uid === admData?.mandataire?.uid))
                    setShowPublicationButton(true)
            }).catch(response => {
                notyf.error(t("mandat:generatedErrorMessage"));
            })
        }
    }

    const approveMandatHandle = (mandatUid) => {
        setApproveAdmLoading(true)
        Axios.put(`/document/${mandatUid}/approveDocument`, {}).then(response => {
            setApproveAdmLoading(false)
            getMandatData(mandatUid)
        }).catch(err => {
            setApproveAdmLoading(false)
            if (err.response?.data?.error && (err.response?.data?.error === 'Expired token'))
                notyf.error('Une erreur s\'est produite ! Votre session a expiré. Veuillez vous déconnecter et vous reconnecter avant de renouveler l\'opération.')
            else
                notyf.error('Une erreur s\'est produite !')
        })
    }
    const formatDate = useCallback((date) => {
        if (date !== null && date.valueOf() > 0) {
            let newDate = new Date(date);
            let day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
            let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
            return day + '/' + month + '/' + newDate.getFullYear()
        } else return null;
    }, [])


    const validateMandat = () => {
        const promise = new Promise((resolve, reject) => {
            var tt = {
                annonceur: admData.annonceur.official_name,
                mandataire: admData.mandataire.official_name,
                start_period: formatDate(parseInt(admData.start_period)),
                end_period: formatDate(parseInt(admData.end_period)),
                creatorName: admData.creatorName,
            }
            setValidateAdmLoading(true);
            Axios.put(`/document/${mandatUid}/validateDocument`, tt)
                .then(response => {
                    setValidateAdmLoading(false);
                    setShowConfirmToSignModal(false)
                    getMandatData(mandatUid)
                    resolve(response);
                }).catch(err => {
                    setValidateAdmLoading(false);
                    setShowConfirmToSignModal(false)
                    if (err?.response?.data?.emptySignatories)
                        notyf.error('Un signataire doit étre désigné pour chaque acteur')
                    else if (err.response?.data?.error && (err.response?.data?.error === 'Expired token'))
                        notyf.error('Une erreur s\'est produite ! Votre session a expiré. Veuillez vous déconnecter et vous reconnecter avant de renouveler l\'opération.')
                    else
                        notyf.error('Une erreur s\'est produite !')
                    reject(err);
                })
        })
        return promise;
    }

    const shareMandat = (mandatUid) => {
        updateMandatStatus(mandatUid, "TO_BE_APPROVED")
    }

    const shareMandatHandler = () => {
        const promise = new Promise((resolve, reject) => {
            setShareAdmLoading(true)
            Axios.put(`/document/${mandatUid}/documentStatus`, {
                status: 'TO_BE_APPROVED'
            }).then(response => {
                setShareAdmLoading(false)
                setShowConfirmShareAdmModal(false)
                getMandatData(mandatUid)
                notyf.success('Statut modifié')
                resolve(response);
            }).catch(response => {
                setShareAdmLoading(false)
                setShowConfirmShareAdmModal(false)
                if (response.response?.data?.error && (response.response?.data?.error === 'Expired token'))
                    notyf.error('Une erreur s\'est produite ! Votre session a expiré. Veuillez vous déconnecter et vous reconnecter avant de renouveler l\'opération.')
                else
                    notyf.error('Une erreur s\'est produite !')
                reject(response);
            })
        })
        return promise;
    }

    const checkUpdatedAdm = () => {
        return _.isEqual(admUpdate, {
            addresses: {},
            usersToAdd: [],
            usersToUpdate: [],
            usersToDelete: [],
            subAgencyToAdd: [],
            subAgencyToDelete: [],
            admObjects: {},
            clauses: [],
            departmentsToAdd: [],
            departmentsToDelete: [],
            showCommercialNameToUpdate: []
            // payer:[]

        })
    }
    const shareAdmClickHandler = () => {
        const mandatNotUpdated = checkUpdatedAdm();
        if (!mandatNotUpdated) {
            notyf.open({
                closeWith: ['click'],
                type: 'warning',
                background: 'orange',
                message: t("mandat:saveModificationsBefore")
            });
        } else if (!pdfFile) {
            notyf.open({
                type: 'warning',
                background: 'orange',
                message: t("mandat:genaratePDFBefore"),
                duration: 5000,
                dismissible: true
            });
        } else {
            if (isActorsMuchRepresentant() || isActorWithoutSignatory() || isActorMuchSignatory() /*|| isMissingMandatoryContributor()*/ || isMrWithoutDepartment()) {
                if (isActorsMuchRepresentant())
                    notyf.open({
                        closeWith: ['click'],
                        type: 'warning',
                        background: 'orange',
                        message: t("mandat:cantShareAdmMuchRepActor")
                    });
                if (isActorWithoutSignatory())
                    notyf.open({
                        closeWith: ['click'],
                        type: 'warning',
                        background: 'orange',
                        message: t("mandat:cantShareAdmNoSignActor")
                    });
                if (isActorMuchSignatory())
                    notyf.open({
                        closeWith: ['click'],
                        type: 'warning',
                        background: 'orange',
                        message: t("mandat:cantShareAdmMuchSignActor")
                    });
                // if(isMissingMandatoryContributor())
                //     notyf.open({
                //         closeWith: ['click'],
                //         type: 'warning',
                //         background: 'orange',
                //     message: t("mandat:cantShareAdmMissingMandatoryContributor")
                //   });
                if (isMrWithoutDepartment())
                    notyf.open({
                        closeWith: ['click'],
                        type: 'warning',
                        background: 'orange',
                        message: t("mandat:cantShareAdmNoDepartement")
                    });
            }
            else
                setShowConfirmShareAdmModal(true)
        }
    }

    const toSignClickHandler = () => {
        const mandatNotUpdated = checkUpdatedAdm();
        if (!mandatNotUpdated) {
            notyf.open({
                closeWith: ['click'],
                type: 'warning',
                background: 'orange',
                message: t("mandat:saveModificationsBefore")
            });
        } else if (!pdfFile) {
            notyf.open({
                type: 'warning',
                background: 'orange',
                message: t("mandat:genaratePDFBefore"),
                duration: 5000,
                dismissible: true
            });
        } else {
            if (isActorsMuchRepresentant() || isActorWithoutSignatory() || isActorMuchSignatory() || isMrWithoutDepartment()) {
                if (isActorsMuchRepresentant())
                    notyf.open({
                        closeWith: ['click'],
                        type: 'warning',
                        background: 'orange',
                        message: t("mandat:canttoSignAdmMuchRepActor")
                    });
                if (isActorWithoutSignatory())
                    notyf.open({
                        closeWith: ['click'],
                        type: 'warning',
                        background: 'orange',
                        message: t("mandat:cantShareAdmNoSignActor")
                    });
                if (isActorMuchSignatory())
                    notyf.open({
                        closeWith: ['click'],
                        type: 'warning',
                        background: 'orange',
                        message: t("mandat:cantShareAdmMuchSignActor")
                    });
                if (isMrWithoutDepartment())
                    notyf.open({
                        closeWith: ['click'],
                        type: 'warning',
                        background: 'orange',
                        message: t("mandat:cantShareAdmNoDepartement")
                    });
            }
            else
                setShowConfirmToSignModal(true)
        }
    }


    // const isActorWithMoreThanOneRepresentative    = () => {         
    //     let actorWithoutSignatory = (admData.annonceur.usersDoc.filter(u => u.is_signataire == 1).length == 0)       
    //     || (admData.mandataire.usersDoc.filter(u => u.is_signataire == 1).length == 0)         
    //     admData.sousMandataires.forEach(sousMandataire => {    
    //         actorWithoutSignatory = actorWithoutSignatory || (sousMandataire.usersDoc.filter(u => u.is_signataire == 1).length == 0)  
    //     });  
    //     return actorWithoutSignatory  
    // }

    useEffect(() => {
        switch (status) {
            case "DRAFT":
                setClassNames({
                    revTriangle: ["rev_triangle_gray", "rev_triangle_gray", "rev_triangle_gray"],
                    pipe: ["pipe_gray", "pipe_gray", "pipe_gray"],
                    triangle: ["triangle_gray", "triangle_gray"]
                });
                setMessageInfo(t('mandat:progressLine_draftMsg', 'Après préparation de MyMandat, pensez à configurer le processus d\'approbation avant de partager MyMandat avec les contributeurs.'))
                break;
            case "TO_BE_APPROVED":
                setClassNames({
                    revTriangle: ["rev_triangle_blue", "rev_triangle_gray", "rev_triangle_gray"],
                    pipe: ["pipe_blue", "pipe_gray", "pipe_gray"],
                    triangle: ["triangle_blue", "triangle_gray"]
                });
                if (!isCreator && +connectedUser?.is_contributor === 1)
                    setMessageInfo(t('mandat:progressLine_toBeAppMsg', 'Vérifiez tous les champs de MyMandat avant d’approuver. Si vous relevez une erreur, envoyez un message au mandataire responsable  via \'Demandes de modification\'.'));
                break;
            case "TO_BE_SIGNED":
                setClassNames({
                    revTriangle: ["rev_triangle", "rev_triangle_blue", "rev_triangle_gray"],
                    pipe: ["pipe", "pipe_blue", "pipe_gray"],
                    triangle: ["triangle", "triangle_blue"]
                })
                isCreator ?
                    setMessageInfo(t('mandat:progressLine_toBeSignMsgCr', 'Les signataires ont été informés qu’une attestation de mandat attendait leur signature. Vous pouvez suivre l’avancement dans le panneau Signatures '))
                    : +connectedUser?.is_signataire && !connectedUser?.signedDoc ?
                        setMessageInfo(t('mandat:progressLine_toBeSignMsg', 'Votre Signature est attendue pour ce mandat '))
                        : setMessageInfo('');
                if (isCreator && admData?.mandataireUsersSigned == "1" && admData?.offline == "1")
                    setTabSideActive("offline")
                break;
            case "SIGNED":
                setClassNames({
                    revTriangle: ["rev_triangle", "rev_triangle", "rev_triangle_gray"],
                    pipe: ["pipe", "pipe", "pipe_gray"],
                    triangle: ["triangle", "triangle"]
                })
                setMessageInfo(t('mandat:progressLine_signedMsg', 'L’attestation de mandat est signée.'))
                break;
            case "ABANDONED":
                setClassNames({
                    revTriangle: ["rev_triangle", "rev_triangle_red", "rev_triangle_gray"],
                    pipe: ["pipe", "pipe_red", "pipe_gray"],
                    triangle: ["triangle", "triangle_red"]
                })
                setMessageInfo(t('mandat:progressLine_abandonedMsg', 'L’attestation de mandat est abandonnée.'))
                break;
            case "EXPIRED":
                setClassNames({
                    revTriangle: ["rev_triangle", "rev_triangle", "rev_triangle"],
                    pipe: ["pipe", "pipe", "pipe"],
                    triangle: ["triangle", "triangle"]
                })
                break;
            case "CANCELLED":
                setClassNames({
                    revTriangle: ["rev_triangle", "rev_triangle_red", "rev_triangle_gray"],
                    pipe: ["pipe", "pipe_red", "pipe_gray"],
                    triangle: ["triangle", "triangle_red"]
                })
                setMessageInfo(t('mandat:progressLine_cancelledMsg', 'L’attestation de mandat est résiliée.'))
                break;
            default:
                break;
        }
    }, [status])

    useEffect(() => {
        initShowPublicationButton();
        Axios.get('/user/' + mandatUid + '/concernedUsers').then(response => {
            let users = null
            let filtered = null
            //if(response?.data?.users_regie != null && response?.data?.users_regie.length > 0) {
            users = response?.data?.users.concat(response?.data?.users_regie)
            filtered = users.filter(u => +u.is_contributor === 1);
            // setAutoApproved(filtered.length === 0 && (!isActorWithoutSignatory()));
            setAutoApproved(filtered.length === 0);
            //}
        })
    }, [])

    let actionButton = null;

    switch (status) {
        case "DRAFT":
            isCreator || role == "MG" ?
                actionButton = (
                    <Fragment>
                        <div className="signer_mandat" onClick={() => actorsClickHandler()}>
                            <p>
                                {t('mandat:workflowBtn', "Processus d'approbation")}
                            </p>
                        </div>
                        {/* <div className="signer_mandat" onClick={() => publicationClickHandler()}>
                            <p>
                                {t('mandat:admPublicationBtn', "Publication")} 
                            </p>
                        </div> */}

                        {isCreator ?
                            autoApproved /*&& +admData?.agencyContributorRequired === 0 */ ? (
                                (
                                    admData?.template && admData?.template === "0") &&
                                <div className="signer_mandat" onClick={() => toSignClickHandler()}>
                                    {/* <i class="fas fa-check"></i> */}
                                    <p>
                                        {t('mandat:toSignAdmBtn', "Passer le mandat en signature")}
                                    </p>
                                </div>
                            )
                                :
                                <div className="signer_mandat" onClick={() => shareAdmClickHandler()}>
                                    {/* <i class="fas fa-check"></i> */}
                                    <p>
                                        {t('mandat:shareAdmBtn', "Partager le mandat")}
                                    </p>
                                </div>
                            : null
                        }
                    </Fragment>
                )
                : actionButton = null
            break;
        case "TO_BE_APPROVED":
            +connectedUser?.is_contributor === 1 && +connectedUser?.validatedDoc === 0 ?
                actionButton = (
                    <Fragment>
                        {
                            approveAdmLoading ?
                                <Spinner animation="border" variant="primary" />
                                :
                                <Fragment>
                                    {(isCreator || +connectedUser?.is_contributor === 1) && <div className="signer_mandat" onClick={() => actorsClickHandler()}>
                                        <p>
                                            {t('mandat:workflowBtn', "Processus d'approbation")}
                                        </p>
                                    </div>}
                                    <div className="signer_mandat" onClick={() => approveMandatHandle(mandatUid)} >
                                        <p>
                                            {t('mandat:approveAdmBtn', "Approuver le mandat")}
                                        </p>
                                    </div>
                                </Fragment>
                        }
                    </Fragment>
                )
                : ((isCreator || role == "MG") ? actionButton = (
                    <>
                        <div className="signer_mandat" onClick={() => actorsClickHandler()}>
                            <p>
                                {t('mandat:workflowBtn', "Processus d'approbation")}
                            </p>
                        </div>
                        {!admData?.hasContributors && (admData?.template && admData?.template === "0") && <div className="signer_mandat" onClick={() => toSignClickHandler()}>
                            <p>
                                {t('mandat:toSignAdmBtn', "Passer le mandat en signature")}
                            </p>
                        </div>}
                    </>
                )
                    : actionButton = null
                )
            break;
        case "TO_BE_SIGNED":
            actionButton = <Fragment>
                <div className="signer_mandat" onClick={() => actorsClickHandler()}>
                    <p>
                        {t('mandat:workflowBtn', "Processus d'approbation")}
                    </p>
                </div>
                {+connectedUser?.is_signataire && !connectedUser?.signedDoc ?
                    <div className="signer_mandat" onClick={() => { setShowSignatureTab(true); setTabSideActive("signature") }} >
                        <p>
                            {t('mandat:signAdmBtn', "Signer le mandat")}
                        </p>
                    </div> : null
                }
            </Fragment>
            break;
        case "SIGNED":
            actionButton =
                <>
                    {!"RG, RC".includes(role) &&
                        <div className="signer_mandat" onClick={() => actorsClickHandler()}>
                            <p>
                                {t('mandat:workflowBtn', "Processus d'approbation")}
                            </p>
                        </div>
                    }{
                        showPublicationButton && (
                            <div className="signer_mandat" onClick={() => publicationClickHandler()}>
                                <p>
                                    {t('mandat:admPublicationBtn', "Publication")}
                                </p>
                            </div>
                        )
                    }
                </>
            break;

        default:
            break;
    }

    const actorsClickHandler = () => {
        let mandatNotUpdated = _.isEqual(admUpdate, {
            addresses: {},
            usersToAdd: [],
            usersToUpdate: [],
            usersToDelete: [],
            subAgencyToAdd: [],
            subAgencyToDelete: [],
            admObjects: {},
            clauses: [],
            departmentsToAdd: [],
            departmentsToDelete: [],
            showCommercialNameToUpdate: []
        })
        if (mandatNotUpdated) {
            setShowADMWorkflow(!showADMWorkflow)
        } else {
            notyf.open({
                closeWith: ['click'],
                type: 'warning',
                background: 'orange',
                message: t("mandat:saveModificationsBeforeActors")
            });
        }
    }

    const publicationClickHandler = () => {
        let mandatNotUpdated = _.isEqual(admUpdate, {
            addresses: {},
            usersToAdd: [],
            usersToUpdate: [],
            usersToDelete: [],
            subAgencyToAdd: [],
            subAgencyToDelete: [],
            admObjects: {},
            clauses: [],
            departmentsToAdd: [],
            departmentsToDelete: [],
            showCommercialNameToUpdate: []
            //   payer:[]
        })
        if (mandatNotUpdated) {
            setShowADMPublication(true)
        } else {
            notyf.open({
                closeWith: ['click'],
                type: 'warning',
                background: 'orange',
                message: t("mandat:saveModificationsBeforeActors")
            });
        }
    }


    if (error && error === 'Expired token') {
        notyf.error('Une erreur s\'est produite ! Votre session a expiré. Veuillez vous déconnecter et vous reconnecter avant de renouveler l\'opération.')
    }

    return (
        <div className="ProgressLine">
            <div className="ProgressLine-div">
                <div className="ProgressLine-container-form" style={actionButton ? null : { height: "65px" }}>
                    <div className="progressLine-container">

                        <div className="line_Hover">
                            <div className="pipe radiusStyleLeft">
                                <p>
                                    {t('default:DRAFT', "Brouillon")}
                                </p>
                            </div>
                            <div className="triangle"></div>
                        </div>

                        <div className="line_Hover">
                            <div className={classNames.revTriangle[0]} ></div>
                            <div className={classNames.pipe[0]}>
                                <p>
                                    {t('default:TO_BE_APPROVED', "A approuver")}
                                </p>
                            </div>
                            <div className={classNames.triangle[0]}></div>
                        </div>

                        <div className="line_Hover">
                            <div className={classNames.revTriangle[1]} ></div>
                            <div className={classNames.pipe[1]}>
                                <p>{t('default:SIGNED', "Signé")}</p>
                            </div>
                            <div className={classNames.triangle[1]}></div>
                        </div>

                        <div className="line_Hover">
                            <div className={classNames.revTriangle[2]} ></div>
                            <div className={classNames.pipe[2] + " radiusStyleRight"} >
                                <p>{t('default:EXPIRED', "Expiré")}</p>
                            </div>
                        </div>

                        {/* <div className="signer_mandat">
                            <i class="fas fa-check"></i>
                            <p>Signer le mandat</p>
                        </div> */}
                        {/* {isCreator && <div className="signer_mandat" onClick={() => actorsClickHandler()}>
                            <p>Processus d'approbation</p>
                        </div>} */}
                        {
                            showADMWorkflow &&
                            <ADMWorkFlow
                                show={showADMWorkflow}
                                handleClose={() => setShowADMWorkflow(false)}
                                getMandatData={getMandatData}
                                mandat={admData}
                            />
                        }
                        {
                            showADMPublication &&
                            <>
                                {/* <PublicationModal
                                    show={showADMPublication}
                                    handleClose={() => setShowADMPublication(false)}
                                    mandat = {admData}
                                /> */}
                                <PublicationRegieModal
                                    show={showADMPublication}
                                    handleClose={() => setShowADMPublication(false)}
                                    mandat={admData}
                                    nbSelectedRegie={nbSelectedRegie}
                                    setNbSelectedRegie={setNbSelectedRegie}
                                    mandatUid={mandatUid}
                                />
                            </>
                        }
                        {
                            showConfirmToSignModal ? (
                                <CustomConfirmModal
                                    show={showConfirmToSignModal}
                                    handleClose={() => setShowConfirmToSignModal(false)}
                                    text={t('mandat:confirmToSignMessage', "Étes vous sûr de vouloir passer le mandat en signature ?")}
                                    okCallback={validateMandat}
                                    loading={validateAdmLoading}
                                />
                            ) : null
                        }
                        {
                            showConfirmShareAdmModal ? (
                                <CustomConfirmModal
                                    show={showConfirmShareAdmModal}
                                    handleClose={() => setShowConfirmShareAdmModal(false)}
                                    text={t('mandat:confirmShareAdmMessage', "Êtes-vous sûr de vouloir partager le mandat ?")}
                                    okCallback={shareMandatHandler}
                                    loading={shareAdmLoading}
                                />
                            ) : null
                        }
                    </div>

                    <div className="notice_container">
                        <p><i className="fas fa-bell fa-2x"></i> {messageInfo} </p>
                        {actionButton && <div className="actionButtons">
                            {actionButton}
                        </div>}
                    </div>


                </div>

            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    role: state?.auth.role,
    isCreator: state?.auth?.userId === state.mandat?.admData?.creatorUid,
    error: state.mandat.error,
    mandatUid: state.mandat.admData.uid,
    status: state.mandat?.admData?.status,
    connectedUser: state.mandat?.admData?.connectedUser,
    pdfFile: state.mandat.admData?.pdfFile || null,
    admData: state.mandat.admData,
    admUpdate: state.mandat.admUpdate
})
const mapDispatchToProps = dispatch => ({
    getMandatData: (mandatUid, generatePdf = false) => dispatch(actions.getMandatData(mandatUid, generatePdf)),
    updateMandatStatus: (mandatUid, status) => dispatch(actions.updateMandatStatus(mandatUid, status)),
    createMandatTimelineEvent: (mandatUid, eventData) => dispatch(actions.createMandatTimelineEvent(mandatUid, eventData)),
    setTabSideActive: (tabSideActive) => dispatch(actions.setTabSideActive(tabSideActive))
})
export default connect(mapStateToProps, mapDispatchToProps)(ADMProgressLine)
