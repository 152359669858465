import React, { useCallback, useEffect, useState } from 'react'
import '../../../../containers/MasterData/MasterData.css';
import AddBoxIcon from '@material-ui/icons/AddBox';
import BusinessIcon from '@material-ui/icons/Business';
import ApartmentIcon from '@material-ui/icons/Apartment';
import logoHand from '../../../../assets/images/logo-hand.png'
import logoPackage from '../../../../assets/images/iconproduct.png'


import { useTranslation } from 'react-i18next';
import { getNotyfObject, uuidv4, CTooltip } from '../../../../shared/utility'
import CloseIcon from '@material-ui/icons/Close';
import BandAddressModal from './Modals/BandAddressModal/BandAddressModal'
import Spinner from 'react-bootstrap/Spinner'
import FileCopyIcon from '@material-ui/icons/FileCopy';
import FlipToFrontOutlinedIcon from '@material-ui/icons/FlipToFrontOutlined';

import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';



function MBrandsForm({ brands, setBrands, moveNext, moveBack, loading, isAuth }) {
    const { t, i18n } = useTranslation();
    let notyf = getNotyfObject()
    const [brandLabelToAdd, setBrandLabelToAdd] = useState(null)
    const [selectedBrandForAddress, setSelectedBrandForAddress] = useState(null)
    const [showBrandAddressModal, setShowBrandAddressModal] = useState(false)
    const [showBrandAddressMailModal, setShowBrandAddressMailModal] = useState(false)
    const [handleFormChanged, setHandleFormChanged] = useState(false);
    const [copiedAdress, setCopiedAdress] = useState();

    const addBrandHandler = () => {
        if (brandLabelToAdd && brandLabelToAdd.trim() !== "") {
            let existLabel = false;
            if (brands.length > 0) {
                existLabel = brands.findIndex(brand => brand.label.toUpperCase() === brandLabelToAdd.toUpperCase()) >= 0;
            }
            if (existLabel) {
                notyf.error(t("masterData:existBrandLabel", "ce produit existe déja!"))
            } else {
                let brandTemp = {
                    new: true,
                    label: brandLabelToAdd,
                    uid: uuidv4()
                }
                let brandsTemp = (brands && brands.length > 0) ? [...brands, brandTemp] : [brandTemp];
                setBrands(brandsTemp)
            }
            setBrandLabelToAdd(null);
            setHandleFormChanged(true);
        }
    }

    const deleteBrandHandler = (brand, index) => {
        let brandsTemp = [...brands];
        brandsTemp.splice(index, 1);
        setBrands(brandsTemp)
        setHandleFormChanged(true);
    }

    const addBrandAddressHandler = (address, brand, addressType) => {
        let brandsTemp = [...brands];
        let brandIndex = brandsTemp.findIndex(item => item.label === brand.label);
        let brandTemp = {
            ...brand
        }
        brandTemp[addressType] = address
        brandsTemp[brandIndex] = brandTemp;
        setBrands(brandsTemp)
    }

    const showBrandAdressModalHandler = (brand) => {
        setSelectedBrandForAddress(brand)
        setShowBrandAddressModal(true);
    }

    const showBrandAdressMailModalHandler = (brand) => {
        setSelectedBrandForAddress(brand)
        setShowBrandAddressMailModal(true);
    }

    const verificationCopiedElement = (element) => {
        if (copiedAdress && element && (copiedAdress.address_line == element.address_line) && (copiedAdress.city_cedex == element.city_cedex)
            && (copiedAdress.distribution_service == element.distribution_service) && (copiedAdress.email == element.email) && (copiedAdress.iso_country == element.iso_country)
            && (copiedAdress.label_primary == element.label_primary) && (copiedAdress.label_secondary == element.label_secondary) && (copiedAdress.post_code == element.post_code)) {
            return true
        }
        if (copiedAdress == element) {
            return true
        } else
            return false
    }

    const copierAddressHandler = (addressType, brand, address) => {
        if (copiedAdress) {
            let addressTemp = {
                new: true,
                uidTemp: uuidv4(),
                iso_country: address?.iso_country ? address.iso_country : "FR",
                ...address
            }
            addBrandAddressHandler(addressTemp, brand, addressType)
            setHandleFormChanged(true);
        }
    }

    const moveBackClickHandler = (e) => {
        moveBack();
    }

    const moveNextClickHandler = (e) => {
        moveNext(e);
    }

    return (
        <>
            <div style={{ padding: "20px", width: "100%", textAlign: "center", marginBottom: "40px" }}>
                <img className="rightSide_header_logo_big" src={logoPackage}></img>
                <span style={{ fontSize: "18.0pt" }}>{t('manualEntity:products', "Produits/Marques de l'Annonceur")}</span>
            </div>
            <div className="form_twoElements_container">
                <div className="form_element_container" style={{ marginBottom: "10px" }}>
                    <div style={{ display: "flex", alignItems: "center", zIndex: "100", position: "absolute", width: "500px", height: "30px", top: "-25px", backgroundColor: "white" }}>
                        <input
                            className="form-control"
                            type="text"
                            autoComplete="off"
                            name="label"
                            placeholder="Ajouter marque"
                            value={brandLabelToAdd || ''}
                            style={{ width: "500px" }}
                            onChange={(e) => setBrandLabelToAdd(e.target.value)}
                        />
                        <AddBoxIcon
                            className="form_element_addProduct_icon"
                            style={{ color: "#007bff", cursor: "pointer", height: "40px", width: "40px" }}
                            fontSize="large"
                            onClick={() => addBrandHandler()} />
                    </div>
                </div>
            </div>
            <div className="form_twoElements_container">
                <div className="form_element_container">
                    <div className="form_company_pillsElements_container">
                        {brands && brands.length > 0 ? brands.map((brand, index) =>
                            <div className="form_pillElement_container" key={brand.uid}>
                                <div className="pillElement_infos">
                                    <img className="pillElement_icon" src={logoHand}></img>
                                    <div style={{ fontSize: '12px' }}>{brand.label}</div>
                                </div>
                                <div className="form_company_brandActions_container">

                                    {brand?.address ?
                                        <CTooltip title={t(`masterData:${verificationCopiedElement(brand?.address) ? "copiedAddress" : "copyAddressInvoice"}`)}>
                                            {verificationCopiedElement(brand?.address) ?
                                                <FileCopyIcon style={{ cursor: "pointer", color: "#767675", width: "16px" }} fontSize="small" onClick={() => setCopiedAdress(brand?.address)} />
                                                :
                                                <FileCopyOutlinedIcon style={{ cursor: "pointer", color: "#767675", width: "16px" }} fontSize="small" onClick={() => setCopiedAdress(brand?.address)} />
                                            }
                                        </CTooltip>
                                        : <>&nbsp; &nbsp;&nbsp;&nbsp;</>
                                    }
                                    <CTooltip title={t("masterData:pasteAddress")} >
                                        < FlipToFrontOutlinedIcon style={{ cursor: "pointer", color: "#2d2f30", width: "16px" }} fontSize="small" onClick={() => copierAddressHandler("address", brand, copiedAdress)} />
                                    </CTooltip>

                                    <CTooltip title={t('masterData:addAddressInvoice', "Ajouter une adresse de facturation")}>
                                        <BusinessIcon
                                            style={{
                                                color: "lightblue",
                                                cursor: "pointer"
                                            }}
                                            fontSize="small"
                                            onClick={() => showBrandAdressModalHandler(brand)}
                                        />
                                    </CTooltip>

                                    &nbsp; &nbsp; &nbsp;
                                    {brand?.address_mail ?
                                        // <CTooltip title={t(`masterData:copyAddressMail`)}> 
                                        <CTooltip title={t(`masterData:${verificationCopiedElement(brand?.address_mail) ? "copiedAddress" : "copyAddressMail"}`)}>

                                            {verificationCopiedElement(brand?.address_mail) ?
                                                <FileCopyIcon style={{ cursor: "pointer", color: "#767675", width: "16px" }} fontSize="small" onClick={() => setCopiedAdress(brand?.address_mail)} />
                                                :
                                                <FileCopyOutlinedIcon style={{ cursor: "pointer", color: "#767675", width: "16px" }} fontSize="small" onClick={() => setCopiedAdress(brand?.address_mail)} />
                                            }
                                        </CTooltip>
                                        : <>&nbsp; &nbsp;&nbsp;&nbsp;  </>
                                    }
                                    <CTooltip title={t("masterData:pasteAddress")} >
                                        <FlipToFrontOutlinedIcon style={{ cursor: "pointer", color: "#2d2f30", width: "16px" }} fontSize="small" onClick={() => copierAddressHandler("address_mail", brand, copiedAdress)} />
                                    </CTooltip>

                                    <CTooltip title={t('masterData:addAddressMail', "Ajouter une adresse d'envoi des factures")}>
                                        <ApartmentIcon
                                            style={{
                                                color: "lightblue",
                                                cursor: "pointer"
                                            }}
                                            fontSize="small"
                                            onClick={() => showBrandAdressMailModalHandler(brand)}
                                        />
                                    </CTooltip>

                                    &nbsp; &nbsp;
                                    <CloseIcon
                                        style={{ color: "red", cursor: "pointer" }}
                                        fontSize="small"
                                        onClick={() => deleteBrandHandler(brand, index)}
                                    />
                                </div>
                            </div>
                        ) : null}
                        {
                            showBrandAddressModal ? (
                                <BandAddressModal
                                    handleClose={() => setShowBrandAddressModal(false)}
                                    show={showBrandAddressModal}
                                    brand={selectedBrandForAddress}
                                    addBrandAddressHandler={addBrandAddressHandler}
                                    title={t('masterData:addAddressInvoice', "Ajouter une adresse de facturation")}
                                    addressType="address"
                                />
                            ) : null
                        }
                        {
                            showBrandAddressMailModal ? (
                                <BandAddressModal
                                    handleClose={() => setShowBrandAddressMailModal(false)}
                                    show={showBrandAddressMailModal}
                                    brand={selectedBrandForAddress}
                                    addBrandAddressHandler={addBrandAddressHandler}
                                    title={t('masterData:addAddressMail', "Ajouter une adresse d'envoi des factures")}
                                    addressType="address_mail"
                                />
                            ) : null
                        }
                    </div>
                </div>
            </div>

            <div className="rightSide_form_submit_container">
                {
                    loading ?
                        <div className="rightSide_form_submit_container">
                            <Spinner animation="border" variant="primary" />
                        </div>
                        : <>
                            <div className="rightSide_form_submit_container">
                                <input
                                    type="submit"
                                    value={t('default:previous', "Précédent")}
                                    className="btn btn-primary mt-3 rightSide_form_submitBtn"
                                    onClick={(e) => moveBackClickHandler(e)} />
                            </div>
                            <div className="rightSide_form_submit_container">
                                <input
                                    type="submit"
                                    value={isAuth ? t('manualEntity:saveToMasterData', "Mettre à jour la fiche annonceur") : t('default:send', "Envoyer")}
                                    className="btn btn-primary mt-3 rightSide_form_submitBtn"
                                    onClick={(e) => moveNextClickHandler(e)} />
                            </div>
                        </>
                }
            </div>
        </>
    );

}

export default MBrandsForm
