import React from 'react'
import './TablePagination.css';
import Pagination from "react-js-pagination";
import { useTranslation } from 'react-i18next';

function TablePagination(props) {
    const {t} = useTranslation();
    const {currentPage, setCurrentPage, filters, filtersChange, applyFilters, totalElement, perPage} = props;
    const pageNumbers = [];
    const pageElement = perPage || 10;
    for (let i = 0; i < Math.ceil(totalElement / pageElement); i++) {
        pageNumbers.push(i);
    }


    function pageChange(page){
        let newFilters = {
            ...filters,
            page: page
        }
        setCurrentPage(page)
        filtersChange(newFilters)
        applyFilters(newFilters)
    }

  return (
      <>
        <nav className="paginationContainer">
            <div className="paginationList">
                <Pagination
                    activePage={currentPage}
                    itemsCountPerPage={pageElement}
                    totalItemsCount={totalElement}
                    pageRangeDisplayed={5}
                    onChange={(page) => pageChange(page)}
                    innerClass={"pagination"}
                    itemClass={"page-item"}
                    linkClass={"page-link"}
                />
                <span className="pagination__total">{totalElement} {t("newHome:paginationResult")}</span>
            </div>
            
        </nav>
    </>
  )
}

export default TablePagination
