import React, { useState } from 'react'
import './ADMTopCard.css';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';
import _ from 'lodash';
import { Notyf } from 'notyf';
import { getNotyfObject } from '../../../../shared/utility';
import { useTranslation } from 'react-i18next';

function ADMTopCard(props) { 
    let notyf = getNotyfObject();
    const [showDescModal, setShowDescModal] = useState(false)
    const [showPeriodModal, setShowPeriodModal] = useState(false)
    const [showADMWorkflow, setShowADMWorkflow] = useState(false)
    const {t} = useTranslation();

    const actorsClickHandler = () => {
        let mandatNotUpdated = _.isEqual(props.admUpdate, {
            addresses: {},
            usersToAdd: [],
            usersToUpdate: [],
            usersToDelete: [],
            subAgencyToAdd: [],
            subAgencyToDelete: [],
            admObjects : {},
            clauses: [],
            departmentsToAdd: [],
            departmentsToDelete: [],
            showCommercialNameToUpdate: []
        })
        if(mandatNotUpdated){
            setShowADMWorkflow(!showADMWorkflow)
        }else {
            notyf.open({
                type: 'warning',
                background: 'orange',
                message: t("mandat:saveModificationsBeforeActors")
              });
        }
    }
    return ( 
        <div className= "MandatTopCard">   
            <div className="MandatTopCard-div"> 
                <div className="MandatTopCard-attest">
                    <div className="attest-mandat">
                        <i className="fas fa-file-alt fa-3x" style={{color:"rgb(77, 202, 118)", marginRight:"20px"}}></i>
                        <div className="">
                            <p>{t("default:adm")} <span style={{color: "#0070D2", marginLeft: "0.25rem"}}>{props.name}</span> </p> 
                            <div className="MandatTopCardTitleBold"><p style={{fontWeight:"bold"}}></p></div> 
                        </div>  
                    </div>

                    <div className="workflow-buttons">
                        {/*<button type="button" className="btn btn-light btn-sm">Publier</button>*/}
                        {/* <button type="button" className="btn btn-light btn-sm" data-toggle="modal" data-target="#staticBackdrop">Acteurs</button> */}
                        
                        {/* <div className="btn-group" role="group">
                            <button type="button" className="btn btn-light btn-sm" data-toggle="modal" data-target="#staticBackdrop">Workflow</button>
                            <div className="btn-group" role="group">
                                <button id="btnGroupDrop1" type="button" style={{padding: "5px 10px 5px 10px"}} className="btn btn-light btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                                <div className="dropdown-menu" aria-labelledby="btnGroupDrop1" >
                                    <a className="dropdown-item" href="#">Dropdown link</a>
                                </div>
                            </div>
                        </div> */}
                        

                    </div> 
                    
                </div>

                <div className="Mandat-details">    
                    <div className="description-spec">
                        <p className="ADMTopCard__item__label">
                        {t("default:creationDate")}
                        </p>
                        <strong >{props.creationDate}</strong>
                    </div>
                    <div className="description-spec">
                        <p className="ADMTopCard__item__label">
                            Description
                            {/* <EditIcon 
                                className="ADMTopCard__editIcon"
                                onClick={() => setShowDescModal(!showDescModal)}
                            /> */}
                        </p>
                        <strong className="ADMTopCard__item__label_Description" >{props.description}</strong>
                        {/* {
                            showDescModal ? (
                                <DescriptionModal
                                    handleClose = {() => setShowDescModal(false)}
                                    show = {showDescModal}
                                /> 
                            ) : null
                        } */}
                    </div>
                     <div className="annonceur-spec">
                        <p className="ADMTopCard__item__label">{t("default:advertiser")}</p> 
                        <strong>{props.advertiser}</strong>
                        {/* <select class="form-control" id="exampleFormControlSelect1">
                            <option selected>{props.advertiser}</option>
                            <option>A-1</option>
                            <option>A-2</option>
                        </select> */}
                         
                         </div> 
                     <div className="periode-spec"> 
                        <p className="ADMTopCard__item__label">
                            {t("default:period")}
                            {/* <EditIcon 
                                className="ADMTopCard__editIcon"
                                onClick={() => setShowPeriodModal(!showPeriodModal)}
                            />     */}
                        </p> 
                        <strong>{props.period}</strong> 
                        {/* {
                            showPeriodModal ? (
                                <PeriodModal
                                    handleClose = {() => setShowPeriodModal(false)}
                                    show = {showPeriodModal}
                                /> 
                            ) : null
                        } */}
                    </div> 
                </div>

            </div>        
        </div>  
    )      
}
const mapStateToProps = (state) => ({
    admData: state.mandat.admData,
    admUpdate: state.mandat.admUpdate
})
const mapDispatchToProps = dispatch => ({
    getMandatData: (mandatUid) => dispatch(actions.getMandatData(mandatUid))
})
export default connect(mapStateToProps, mapDispatchToProps)(ADMTopCard)
