import React from 'react'
import './TableBody.css'
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getNotyfObject } from '../../../shared/utility';
import Axios from '../../../axios-edipub';
import ReplayIcon from '@material-ui/icons/Replay';

function TableBody(props) {
    const {t} = useTranslation();
    const {data, formatDate, modalOpenHandler} = props;
    const notyf = getNotyfObject();  

    const handleRemind = email => {
        Axios.post('/email/'+email.uid+'/remind').then(response => {
            if (response.data.success)
                notyf.success("Email relancé avec succès")
            else
                notyf.error("Erreur lors de la relance")
        }).catch(() => {
            notyf.error("Impossible de relancer l'email")
        })
    }

    return (
        <>    
            {
                data?.map(row => (
                    <tr key={row.id} className="tableBody__tr">
                        <td>{ formatDate(+row.creation_date) }</td>
                        <td>{ row.to_name }</td>
                        <td>{ row.to_email }</td>
                        <td>{ row.lang }</td>
                        <td>{ row.subject }</td>
                        <td className="tableBody__content"><a onClick={() => modalOpenHandler(row)} href="#">{row.message}</a></td>
                        <td>{ row.send === "1" ? t("default:yes", "Oui") : t("default:non", "Non") }</td>
                        <td>{ formatDate(+row.date_sent) }</td>
                        <td><button 
                                    type="button" 
                                    className="btn btn-primary mt-3"
                                    style={{padding: "8px"}} 
                                    onClick={() => handleRemind(row)} ><ReplayIcon /></button></td>
                    </tr>
                    
                ))
            }
        </>
    )
}

const mapStateToProps = (state) => ({
    isAuth : state.auth.token !== null,
    token: state.auth.token,
    role : state.auth.role
})
const mapDispatchToProps = dispatch => ({
})
export default connect(mapStateToProps, mapDispatchToProps) (TableBody)
