import React, { useEffect, useState } from 'react'
import './ApprovedEvent.css'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CheckIcon from '@material-ui/icons/Check';
import {formatDate, getTimeDate} from '../../../../../../../shared/utility';
import { useTranslation } from 'react-i18next';

function ApprovedEvent({isCreator, eventData, isLast}) {
    const [showContent, setShowContent] = useState(false)
    const {t} = useTranslation();


    useEffect(() => {
    
        if(isLast)
            setShowContent(true)
        else
            setShowContent(false)
    }, [isLast])

   
    
    return (
        <div className="timelineCard__container">
            <div className="timelineCard__hideicon" onClick={() => setShowContent(!showContent)}>
                {
                    showContent ? 
                        <ExpandMoreIcon />
                    :
                        <ChevronRightIcon />
                }
            </div>
            <div className="timelineCard__sideContainer">
                <div className="timelineCard__header">
                    <CheckIcon style={{backgroundColor: "rgb(80, 204, 122)", color: "#FFFFFF", borderRadius: "5px", padding: "2px"}}  />
                    <div className="timelineCard__header_label">
                        {t('timeline:approvedStatus', "Statut Approuvé")} 
                    </div>
                    <div className="timelineCard__header_time">
                        <span className="timelineCard__header_hour"> {getTimeDate(+eventData?.creationDate)}</span> 
                        <span className="timelineCard__header_date"> {formatDate(+eventData?.creationDate)}</span> 
                    </div>
                </div>
                <div className="timelineCard__content" style={{borderLeft: "2px solid rgb(80, 204, 122)",  display: showContent ? "block" : "none"}}>
                    <div className="timelineCard__content__info">
                        {
                            eventData?.data?.first_name + ' ' + eventData?.data?.last_name + t('timeline:approvedUser', " a approuvé ce mandat")
                        } 
                    </div>
                    <div className="timelineCard__content__body">
                        <div className="approvedEvent__content__header">
                            <div className="approvedEvent__header__name">
                                <div className="approvedEvent__header__nameLabel">
                                    {t('default:name', "Nom")}
                                </div>
                                <div className="approvedEvent__header__nameValue">{eventData?.data?.first_name + ' ' + eventData?.data?.last_name}</div>
                            </div>
                            <div className="approvedEvent__header__company">
                                <div className="approvedEvent__header__companyLabel">
                                    {t('default:society', "Société")}
                                </div>
                                <div className="approvedEvent__header__companyValue">{eventData?.data?.official_name }</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default ApprovedEvent