import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function DocSupModal({show, docReference, handleClose, deleteDocument}) {
    const {t} = useTranslation();
    return (
        <>
            {/* <div className="modal" id="exampleModal">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            {t('home:modalDltAdm_title', "Confirmation de suppression")} 
                        </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {t('home:modalDltAdm_body', "Voulez-vous vraiment supprimer le document")}
                        &nbsp;AM-{docReference} ?
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" >
                            {t('default:cancel', "Annuler")} 
                        </button>
                        <button type="button" className="btn btn-primary" onClick={() => deleteDocument()}>
                            {t('default:delete', "Supprimer")} 
                        </button>
                    </div>
                    </div>
                </div>
            </div> */}

            <Modal show={show} onHide={handleClose} contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                <Modal.Title>
                    {
                        t('home:modalDltAdm_title', "Confirmation de suppression")
                    } 
                </Modal.Title>
                </Modal.Header>
                <Modal.Body className="ADMCreationModal__body" style={{width: "650px"}}>
                    {
                        t('home:modalDltAdm_body', "Voulez-vous vraiment supprimer le document")
                    }
                     AM-{docReference} ?
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {
                        t('default:cancel', "Annuler")
                    } 
                </Button>
                <Button variant="primary" onClick={() => deleteDocument()}>
                    {
                        t('default:delete', "Supprimer")
                    } 
                </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default DocSupModal
