import React from 'react'
import './OriginalValue.css';
import { useTranslation } from 'react-i18next';

function OriginalValue({value}) {
    const {t} = useTranslation();

    return (
        <div className="originalValue_container">
            <em>
                {t("default:originalValue")} : 
                <span className="originalValue_value">
                    {value}
                </span>  
            </em>
        </div>
    )
}

export default OriginalValue
