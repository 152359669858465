import React, { useEffect, useState } from 'react'
import './ChangedEvent.css'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CreateIcon from '@material-ui/icons/Create';
import {formatDate, getTimeDate} from '../../../../../../../shared/utility';
import { useTranslation } from 'react-i18next';

function ChangedEvent({isCreator, eventData, isLast}) {
    const [showContent, setShowContent] = useState(false)
    const {t} = useTranslation();

    useEffect(() => {
        if(isLast)
            setShowContent(true)
        else
            setShowContent(false)
    }, [isLast])

    return (
        <div className="timelineCard__container">
            <div className="timelineCard__hideicon" onClick={() => setShowContent(!showContent)}>
                {
                    showContent ? 
                        <ExpandMoreIcon />
                    :
                        <ChevronRightIcon />
                }
            </div>
            <div className="timelineCard__sideContainer">
                <div className="timelineCard__header">
                    <CreateIcon style={{backgroundColor: "rgb(231, 128, 111)", color: "#FFFFFF", borderRadius: "5px", padding: "2px"}}  />
                    <div className="timelineCard__header_label">
                        {t('timeline:changedStatus', "Statut Modifié")} 
                    </div>
                    <div className="timelineCard__header_time">
                        <span className="timelineCard__header_hour"> {getTimeDate(+eventData?.creationDate)}</span> 
                        <span className="timelineCard__header_date"> {formatDate(+eventData?.creationDate)}</span> 
                    </div>
                </div>
                <div className="timelineCard__content" style={{borderLeft: "2px solid rgb(231, 128, 111)",  display: showContent ? "block" : "none"}}>
                    <div className="timelineCard__content__info">
                        
                            {/* {
                                isCreator ? t('timeline:changedBySelf', "Vous avez modifié ce mandat")  
                                : 
                                    eventData?.data?.first_name + ' ' + eventData?.data?.last_name + ' ' + t('timeline:changedByCreator', " a modifié ce mandat")
                            }  */}
                            {
                                eventData?.data?.first_name + ' ' + eventData?.data?.last_name + ' ' + t('timeline:changedByCreator', " a modifié ce mandat")
                            }
                            <br/><br/>
                                <p>
                                    {t("timeline:resetedApprovals", "Toutes les approbations ont été réinitialisées")}
                                    <br/> 
                                    {
                                        !isCreator ?
                                            t("timeline:changedAdm_info","Veuillez relire le document et le réapprouver")
                                        : null
                                    }
                                </p>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default ChangedEvent