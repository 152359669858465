import React, { useEffect, useState } from 'react'
import DescriptionIcon from '@material-ui/icons/Description';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ShareIcon from '@material-ui/icons/Share';
import ResignSignatureIcon from '../../../../../../../assets/images/resignsignatureIcon.png'
import { formatDate, getTimeDate } from '../../../../../../../shared/utility';
import { useTranslation } from 'react-i18next';

function UserDeletedEvent({ isCreator, eventData, isLast, userActionKey }) {
    const [showContent, setShowContent] = useState(false)
    const { t } = useTranslation();

    useEffect(() => {
        if (isLast)
            setShowContent(true)
        else
            setShowContent(false)
    }, [isLast])

    return (
        <div className="timelineCard__container">
            <div className="timelineCard__hideicon" onClick={() => setShowContent(!showContent)}>
                {
                    showContent ?
                        <ExpandMoreIcon />
                        :
                        <ChevronRightIcon />
                }
            </div>
            <div className="timelineCard__sideContainer">
                <div className="timelineCard__header">
                    <img src={ResignSignatureIcon} style={{ width: "24px", height: "24px", color: "#FFFFFF", borderRadius: "5px" }} />
                    <div className="timelineCard__header_label">
                        {
                            t('timeline:' + userActionKey + 'DeletedAndReplaced', "Utilisateur supprimé")
                        }
                    </div>
                    <div className="timelineCard__header_time">
                        <span className="timelineCard__header_hour"> {getTimeDate(+eventData?.creationDate)}</span>
                        <span className="timelineCard__header_date"> {formatDate(+eventData?.creationDate)}</span>
                    </div>
                </div>
                <div className="timelineCard__content" style={{ borderLeft: "2px solid #dc3545", display: showContent ? "block" : "none" }}>
                    <div className="timelineCard__content__info">
                        {/* <p>{eventData?.data?.first_name + ' ' + eventData?.data?.last_name + ' ' + t('timeline:userDeletedEvent', "a supprimé l'utilisateur ")}</p> */}
                        {
                            (userActionKey === "recipient") ?
                                <p> {eventData?.data?.first_name + ' ' + eventData?.data?.last_name  + ' ' +  t('timeline:deletedTheRecipient', "a supprimé le destinataire")  + ' ' +  eventData?.data?.oldUser?.first_name + ' ' + eventData?.data?.oldUser?.last_name}</p>
                                :
                                <>
                                    <p>{t('timeline:old' + userActionKey, "Ancien") + eventData?.data?.oldUser?.first_name + ' ' + eventData?.data?.oldUser?.last_name}</p>
                                    <p>{t('timeline:new' + userActionKey, "Nouveau") + eventData?.data?.newUser?.first_name + ' ' + eventData?.data?.newUser?.last_name}</p>
                                </>

                        }

                    </div>
                </div>
            </div>

        </div>
    )
}

export default UserDeletedEvent