import React from 'react'
import './AskedBy.css'
import InfoIcon from '@material-ui/icons/Info';
import DropDown from '../../../../UI/DropDown/DropDown'
import { useTranslation } from 'react-i18next';


function AskedBy({askedChanges, creators, dropdownZindex}) {
    const {t} = useTranslation();

    const getCreatorById = (creatorId) => {
        if(creators && creators?.length > 0){
            let creatorIndex = creators?.findIndex(creator => creator?.id == +creatorId)
            if(creatorIndex >= 0)
                return creators[creatorIndex]
            else
                return null;
        } else {
            return null
        }
    }

    return (
        <div className="askedBy_container">
            <em>{t("default:applicants")}</em>
            <DropDown 
                icon={<InfoIcon style={{width: "15px", marginLeft: "4px"}} className='infoIcon' />}
                zIndex={dropdownZindex}
            >
                <div className="askedBy_dropDown_container">
                    <ul className="askedBy_dropDownList">
                        {
                            askedChanges && askedChanges?.length > 0 ? askedChanges?.map(row => (
                                <>
                                    <li>
                                        <h5 className="askedBy_changeValue">{row?.value}</h5>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                            <th scope="col">{t("default:user")}</th>
                                            <th scope="col">{t("default:companies")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                row?.creators?.length > 0 ? row?.creators.map(creatorId => (
                                                    <tr>
                                                        <th scope="row">
                                                            <i> 
                                                                {getCreatorById(creatorId)?.firstName + ' ' + getCreatorById(creatorId)?.lastName}
                                                            </i>
                                                        </th>
                                                        <td>
                                                            <div className="askedBy_agenciesList">
                                                                {
                                                                    getCreatorById(creatorId)?.entities?.map(e => e.commercial_name).join()
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )) : null
                                            }
                                        </tbody>
                                    </table>
                                    </li>
                                </>
                            )) : null
                        }
                    </ul>
                </div>
                
            </DropDown>
        </div>
    )
}

export default AskedBy
