import React, { useEffect, useRef, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@material-ui/icons/Search';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import AddIcon from '@material-ui/icons/Add';
import frLocale from "date-fns/locale/fr";
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import Axios from '../../../../axios-edipub';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { te } from 'date-fns/locale';
import { getNotyfObject } from '../../../../shared/utility';
import { Notyf } from 'notyf';

function DocDuplicateModal({ setSearchQuery, show, handleClose, duplicateDocument, advertiserType, setAdvertiserType, advertisers, selectedAdvertiser, setSelectedAdvertiser, selectedGroup, setSelectedGroup, selectedStartDate, selectedEndDate, setSelectedStartDate, setSelectedEndDate, selectedTemplate, setSelectedTemplate }) {

    const { t } = useTranslation();
    const notyf = getNotyfObject();
    const [startDateError, setStartDateError] = useState(false);
    const [endDateError, setEndDateError] = useState(false);
    const [templates, setTemplates] = useState([]);
    const [groups, setGroups] = useState(null);
    const [searchQueryGroup, setSearchQueryGroup] = useState(null);
    const divRef = useRef();

    useEffect(() => {
        if (setSearchQuery)
            setSearchQuery("")
        if (setTemplates) {
            Axios.get("document//templateDocs?showAll=1&orderBy=creationDate&sortOrder=DESC&page=1").then(response => {
                setTemplates(response.data.mandats);
            });
        }
    }, [])

    useEffect(() => {
        let filter = {
            category: "Annonceur",
            searchQuery: searchQueryGroup
        }
        Axios.get('/group', {
            params: filter
        })
            .then(res => {
                setGroups(res.data.groupes)
            })
            .catch(err => notyf.error(t("mandat:generatedErrorMessage")))
    }, [searchQueryGroup])

    const handleStartDateChange = (date) => {
        if (date === null) {
            setEndDateError(true)
        } else if (date.toString() === "Invalid Date") {
            setStartDateError(true)
        } else if (date.getFullYear <= 1900 || date.getFullYear > 2100) {
            setStartDateError(true)
        } else {
            setStartDateError(false)
        }
        setSelectedStartDate(date);
    };

    const handleEndDateChange = (date) => {
        if (date === null) {
            setEndDateError(true)
        } else if (date.toString() === "Invalid Date") {
            setEndDateError(true)
        } else if (date < selectedStartDate || date.getFullYear > 2100) {
            setEndDateError(true)
        } else {
            setEndDateError(false)
        }
        setSelectedEndDate(date);
    };

    const generateMaxEndDate = () => {
        let startDate = new Date(selectedStartDate);
        return new Date(startDate.setFullYear(startDate.getFullYear(), 12, 0))
    }

    const refreshSelectedAdvertiserOrGroup = (selectedTemplate) => {
        if (selectedTemplate == "") {
            setAdvertiserType("advertiser")
            setSelectedAdvertiser(null);
            setSelectedGroup(null);
            setSearchQuery("");
            setSearchQueryGroup("");
        } else {
            let selectedTemplateObj = templates.find((element) => element.uid === selectedTemplate)
            if (selectedTemplateObj?.advertiserGroupUid) {
                setAdvertiserType("group");
                setSelectedAdvertiser(null);
                setSelectedGroup(selectedTemplateObj.advertiserGroupUid);
                setSearchQueryGroup("");
            } else {
                setAdvertiserType("advertiser")
                setSelectedGroup(null);
                setSelectedAdvertiser(selectedTemplateObj.advertiserUid);
                setSearchQuery("");
            }
        }
    };

    let advertisersList = null;

    (advertisers && advertisers.length > 0) ?
        advertisersList = (
            <table className="table">
                <thead className="thead-light">
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">{t('default:name', "NOM")} </th>
                        <th scope="col">{t('default:siret', "SIRET")}</th>
                        <th scope="col">{t('default:addressSS', "Adresse SS")}</th>
                    </tr>
                </thead>
                <tbody>
                    {selectedAdvertiser && advertisers.filter(row => row.uid === selectedAdvertiser).map(row => (
                        <tr>
                            <td>
                                <div className="productsModal__itemIcon" onClick={() => {
                                    setSelectedAdvertiser(row.uid)
                                    divRef.current.scrollTop = 0
                                }}>
                                    <ThumbUpIcon style={{ color: "green" }} />
                                </div>
                            </td>
                            <td>{row.commercial_name} </td>
                            <td>{row.siret} </td>
                            <td>{row.address_line} {row.post_code}, {row.label_primary} </td>
                        </tr>
                    ))}
                    {advertisers.filter(row => row.uid !== selectedAdvertiser).map(row => (
                        <tr>
                            <td>
                                <div className="productsModal__itemIcon" onClick={() => {
                                    setSelectedAdvertiser(row.uid)
                                    divRef.current.scrollTop = 0
                                }}>
                                    <AddIcon style={{ color: "blue" }} />
                                </div>
                            </td>
                            <td>{row.commercial_name} </td>
                            <td>{row.siret} </td>
                            <td>{row.address_line} {row.post_code}, {row.label_primary} </td>
                        </tr>
                    ))}

                </tbody>
            </table>
        ) : advertisersList = (<h5>{t('home:noAdvertiser', "Aucun Annonceur à afficher")} </h5>);



    const groupsList = (groups && groups.length > 0) ?
        (<table className="table">
            <thead className="thead-light">
                <tr>
                    <th scope="col"></th>
                    <th scope="col">{t('default:name', "NOM")} </th>
                </tr>
            </thead>
            <tbody>
                {selectedGroup && groups.filter(row => row.uid === selectedGroup).map(row => (
                    <tr>
                        <td>
                            <div className="productsModal__itemIcon" onClick={() => {
                                setSelectedGroup(row.uid)
                                divRef.current.scrollTop = 0
                            }}>
                                <ThumbUpIcon style={{ color: "green" }} />
                            </div>
                        </td>
                        <td>{row.label} </td>
                    </tr>
                ))}
                {groups.filter(row => row.uid !== selectedGroup).map(row => (
                    <tr>
                        <td>
                            <div className="productsModal__itemIcon" onClick={() => {
                                setSelectedGroup(row.uid)
                                divRef.current.scrollTop = 0
                            }}>
                                <AddIcon style={{ color: "blue" }} />
                            </div>
                        </td>
                        <td>{row.label} </td>
                    </tr>
                ))}
            </tbody>
        </table>)
        : <h5>{t('home:noGroup', "Aucun groupe à afficher")} </h5>;

    const agenciesList = (agenciesString) => {
        let tmp = agenciesString.split(', ')
        return Array.isArray(tmp) && tmp.length > 1
    }


    return (
        <>
            <Modal show={show} onHide={handleClose} contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                    <Modal.Title>{t('template:createAdmModalHeader', "Création d'un nouveau mandat à partir du modèle")} </Modal.Title>
                </Modal.Header>
                <Modal.Body className="ADMCreationModal__body" style={{ width: "650px" }}>
                    {
                        setSelectedTemplate ? <div style={{ marginBottom: "20px" }}>
                            <h5>{t('template:chooseTemplate', "Choisir un modèle")}</h5>
                            <select
                                className="form-control"
                                placeholder={t('home:modalCreation_searchPlaceholder', "Rechercher Annonceurs (Dénomination sociale, SIRET)")}
                                name="searchQuery"
                                style={{ height: "100%" }}
                                value={selectedTemplate}
                                onChange={(e) => {
                                    setSelectedTemplate(e.target?.value)
                                    refreshSelectedAdvertiserOrGroup(e.target?.value)
                                }}>
                                <option value="">{"-- " + t('template:chooseTemplate', "Choisir un modèle") + " --"}</option>
                                {templates?.map(t => <option value={t.uid}>
                                    {/* {t.id + " - " + t.mandataire + " - " +  t.official_name + (t.description ? " - " + t.description : "")} */}
                                    {
                                        (t?.description != null && t?.description != "") ?
                                            t?.description :
                                            ("ADM " + t.mandataire +
                                                (t?.sousMandataires ? " / " + (agenciesList(t?.sousMandataires) ? "sous mandataires " : t?.sousMandataires) : "") +
                                                (+t?.mPayer ? " payeur" : (+t?.smPayer ? " payeur" : " non payeur")))
                                    }
                                </option>)}

                            </select>
                        </div> : null
                    }

                    {advertiserType === "advertiser" &&
                        <div className="advertiser-container">
                            <div className="productsModal__header" style={{ padding: "0" }}>
                                <h5>{t('default:duplicateModalText', "Veuillez choisir un Annonceur")}</h5>
                                <div className="input-group input-group-sm" style={{ height: "30px" }}>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={t('home:modalCreation_searchPlaceholder', "Rechercher Annonceurs (Dénomination sociale, SIRET)")}
                                        name="searchQuery"
                                        style={{ height: "100%" }}
                                        onChange={(e) => setSearchQuery(e.target?.value)} />
                                    <div
                                        className="input-group-append"
                                        style={{ height: "100%" }}
                                    >
                                        <span className="input-group-text" id="basic-addon2"><SearchIcon /> </span>
                                    </div>
                                </div>
                                {/* <p className="productsModal_totalItems">0 item(s) selected</p> */}
                            </div>
                            <div ref={divRef} className="productsModal_items bg-white">
                                {advertisersList}
                            </div>
                        </div>
                    }

                    {advertiserType === "group" &&
                        <div className="advertiser-container">
                            <div className="productsModal__header " style={{ padding: "0" }}>
                                <h5>{t('default:duplicateModalTextGroup', "Veuillez choisir un groupe d'Annonceur")}</h5>
                                <div className="input-group input-group-sm" style={{ height: "30px" }}>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={t('home:modalCreation_searchGroupPlaceholder', "Chercher des groupes d'Annonceurs (Nom)")}
                                        name="searchQuery"
                                        style={{ height: "100%" }}
                                        onChange={(e) => setSearchQueryGroup(e.target.value)} />
                                    <div
                                        className="input-group-append"
                                        style={{ height: "100%" }}
                                    >
                                        <span className="input-group-text" id="basic-addon2"><SearchIcon /> </span>
                                    </div>
                                </div>
                            </div>
                            <div ref={divRef} className="productsModal_items bg-white">
                                {groupsList}
                            </div>
                        </div>
                    }

                    <div className="ADMCreationModal__selectionPeriod__container mt-4">
                        <h5> {t('home:modalDupplication_periodTitle', "Période de validité")}  </h5>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                            <Grid container justify="space-around">
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label={t('default:startAdmDate', "Date début ADM")}
                                    format="dd/MM/yyyy"
                                    value={selectedStartDate}
                                    onChange={handleStartDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    invalidDateMessage={t('default:invalidDateMessage', "Format de date invalide")}
                                    maxDateMessage={t('default:maxDateMessage', "La date ne doit pas être postérieure à la date maximale")}
                                    minDateMessage={t('default:minDateMessage', "La date ne doit pas être antérieure à la date minimale")}
                                    error={startDateError}
                                />
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label={t('default:endAdmDate', "Date fin ADM")}
                                    format="dd/MM/yyyy"
                                    value={selectedEndDate}
                                    onChange={handleEndDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    minDate={selectedStartDate}
                                    maxDate={generateMaxEndDate()}
                                    invalidDateMessage={t('default:invalidDateMessage', "Format de date invalide")}
                                    maxDateMessage={t('default:maxDateMessage', "La date ne doit pas être postérieure à la date maximale")}
                                    minDateMessage={t('default:minDateMessage', "La date ne doit pas être antérieure à la date minimale")}
                                    error={endDateError}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t('default:cancel', "Annuler")}
                    </Button>
                    <Button variant="primary" onClick={() => duplicateDocument()} disabled={(!selectedAdvertiser && !selectedGroup || (setSelectedTemplate && !selectedTemplate))} >
                        {t('default:create', "Créer")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default DocDuplicateModal
