import React, { useEffect, useRef, useState } from 'react'
import './ADMStepsDropdown.css';
import Checkbox from '@material-ui/core/Checkbox';
import { formatDateD, relativeDate, eventHeaderFromKey } from '../../../../shared/utility';
import { useTranslation } from 'react-i18next';

function ADMStepsDropdown({admStepsDefaults, setStepsInitialStatus, refresh_admsList, adm_steps, set_admSteps, showSteps, setShowSteps, zIndex}) {
    const {t} = useTranslation();
    const divRef = useRef();
    const clickOutSideHandler = e => {
        if(divRef.current.contains(e.target)) {
            return
        }
        setShowSteps(false)
    }

    function onHandleChange(e){
        //si aucun status coché, alors on reset : coche tous ceux par defaut
        let leftSelectedSteps = adm_steps?.filter(step => {
            if (step.if_checked === true && step.stepName !== e.target.value)  //etait true et a pas été cliqué
                return true;
            else if (step.if_checked === false && step.stepName === e.target.value)  //etait false et a été cliqué
                return true;
            else
                return false;
        })
        if (leftSelectedSteps.length === 0){ 
            set_admSteps(adm_steps.map(adm_step=> { 
                 adm_step.if_checked = admStepsDefaults.find((s) => s.stepKey === adm_step.stepKey ).if_checked 
                 return adm_step
            })) 
            setStepsInitialStatus(true) 
        }else{ 
            set_admSteps(adm_steps.map(adm_step=>{
                if(adm_step.stepName === e.target.value ){
                    adm_step.if_checked = (!adm_step.if_checked) 
                }
                return adm_step
            }))  
            let selectedDefaultSteps = admStepsDefaults?.filter(adms_tep => adms_tep.if_checked === true)
            let selectedAdmSteps = adm_steps?.filter(adms_tep => adms_tep.if_checked === true)
            let hasStepsOutsideInitialStatus = selectedAdmSteps.length !== selectedDefaultSteps.length || selectedAdmSteps.some((s) => selectedDefaultSteps.some((ds) => ds.stepKey === s.stepKey)===false)   
            hasStepsOutsideInitialStatus ? setStepsInitialStatus(false) : setStepsInitialStatus(true) 
        }
        refresh_admsList(adm_steps?.filter(adms_tep => adms_tep.if_checked === true))        
    }    

    useEffect(() => {
        document.addEventListener("mousedown", clickOutSideHandler);
        return () => {
            document.removeEventListener("mousedown", clickOutSideHandler);
        }
    }, [])

    return (
            <div ref={divRef} className="home_admSteps" style={{zIndex: zIndex, display: showSteps ? "inherit" : "none",boxShadow: "1px 2px 0px #dbdbdb"}}>
                <div className="home_admSteps__header">
                    <div className="">
                        
                        {
                            adm_steps?.map( checkbox => (
                                
                                <div className="home_admSteps__steps" key={checkbox.stepKey}>
                                    {/* <input type="checkbox" name="steps" value={checkbox.stepName}
                                    checked={checkbox?.if_checked}
                                    onClick={(e) => onHandleChange(e)} /> */}
                                    <Checkbox
                                        size="small"
                                        style={{padding: "0", color: "rgb(83, 152, 211)"}}
                                        checked={checkbox?.if_checked}
                                        onChange={(e) => onHandleChange(e)}
                                        value={checkbox.stepName}
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    />
                                    <label className={checkbox.stepKey} style={{color:"white"}}>
                                        {/* {t(`default:${checkbox.stepKey}`)} */}
                                        {
                                         checkbox.stepName
                                        }
                                    </label>
                                </div>
                            ))
                        }
                        
                    </div>
                </div>
                
            </div>
        
    )
}


export default ADMStepsDropdown
