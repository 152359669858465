import React, {  useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'; 
import Axios from '../../../../axios-edipub';
import { getNotyfObject } from '../../../../shared/utility';
import Spinner from 'react-bootstrap/Spinner';

function ConfirmDeleteModal({handleClose, show, deleteUser, userUid}) {
    const {t} = useTranslation();
     const [loading, setLoading] = useState(false);

     let notyf = getNotyfObject();


    const deleteClickHandler = () => {      
        deleteUser(userUid,{ 
            agenciesCreator:[],
            agenciesValidator: [],
            agenciesSignatory: [],
            agenciesRepresentative: []
        })
        handleClose(); 
    }

    const cancelClickHandler = () => { 
        handleClose();
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                <Modal.Title>{t('default:alert', "Alerte")}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="ADMCreationModal__body" style={{width: "650px"}}>
                    <p>
                        {t('masterData:confirmDeleteUser', "confirmer la suppression")} 
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    {
                        loading ?
                                <Spinner animation="border" variant="secondary" />
                            :
                            <>
                                <Button variant="secondary" onClick={cancelClickHandler}>
                                    {t('default:cancel', "Annuler")} 
                                </Button>
                                <Button variant="primary" onClick={() => deleteClickHandler()}>                     
                                    {t('default:confirm', "Confirmer")}
                                </Button>
                            </>
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ConfirmDeleteModal
