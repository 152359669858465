import React, { useCallback, useRef, useState } from 'react'
import './TableFilters.css';
import Axios from '../../../axios-edipub'
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import CloseIcon from '@material-ui/icons/Close';
import ListIcon from '@material-ui/icons/List';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import BusinessIcon from '@material-ui/icons/Business';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DateRange from './DateRange/DateRange';
import RangeSlider from './RangeSlider/RangeSlider';
import { useTranslation } from 'react-i18next';

function TableFilters(props) {
    const {formatDate, filters, filtersChange, applyFilters, inputChangeHandler} = props;    
    const {t} = useTranslation();
    
    const [show, setShow] = useState(null)
    const [dateField, setDateField] = useState('')

    const closeModalHandler = () => {
        setShow(false);
    }
    const dateShowHandler = (field) => {
        setShow({
            [field]: true
        })
        setDateField(field);
    }
    const convertDate = (date) => {
        let dateArray = date.split(':');
        return [formatDate(+dateArray[0]) , formatDate(+dateArray[1])]
    }

    let [dateInputValue,setDateInputValue] = useState({
        creationDate: filters?.creationDate ? convertDate(filters.creationDate) : '',
        start_period: filters?.start_period ? convertDate(filters.start_period) : '',
        end_period: filters?.end_period ? convertDate(filters.end_period) : ''
    });

    const getCreationdate = () => {
        return filters?.creationDate ? convertDate(filters.creationDate) : [];
    }

    
    const dateChangeHandler = (date) => {
        let startDateTemp = new Date(date.startDate);
        let endDateTemp = new Date(date.endDate);
        if(startDateTemp.getTime() === endDateTemp.getTime()){
            startDateTemp.setHours(0,0,0,0); // set Date to midnight
        }
        setDateInputValue({
            ...dateInputValue,
            [dateField]: formatDate(startDateTemp) + '-' + formatDate(endDateTemp)
        })
        let key = date.field;
        let newFilters = {
            ...filters,
            [key]: startDateTemp.getTime() + ':' + endDateTemp.getTime(),
            noOffset : true
        }
        filtersChange(newFilters)
        applyFilters(newFilters)
    }

    const dateResetHandler = (field) => {
        setDateInputValue({
            ...dateInputValue,
            [field]: ''
        })
        let newFilters = {
            ...filters,
            [field]: "",
        }
        filtersChange(newFilters)
        applyFilters(newFilters)
    }
    
    return (
        <>
            <tr className="tableFilters" style={{display: props.show ? 'table-row' : 'none'}}> 
                <td >
                    <div className="filterContainer">
                        <input className="filter__input" onChange={(e) => inputChangeHandler(e)} value={filters?.id || ''} name="id" type="text" autoComplete="off" />
                    </div>
                </td> 
                <td style={{padding: 0}}>
                    <div className="filterContainer">
                        <div className="filter__amountInterval">
                            <h6 className="filter__input" style={{marginBottom: "5px"}}> {t("newHome:De", "De")}: {getCreationdate()[0] || ''}</h6>
                            <h6 className="filter__input" style={{marginBottom: "5px"}}> {t("newHome:À", "À")}: {getCreationdate()[1] || ''}</h6>
                        </div>
                        <DateRangeIcon className="filter__icon" style={{color: "#022f67"}} onClick={() => dateShowHandler("creationDate")} />
                        <DateRange format={formatDate} show={show?.creationDate} clicked={closeModalHandler} changed={(date) =>dateChangeHandler(date)} field={dateField} reset={dateResetHandler} />
                        <CloseIcon className="filter__icon" style={{color: "red", display: filters?.creationDate ? "block" : "none"}} onClick={() => dateResetHandler("creationDate")} />
                    </div>  
                </td>  
                <td>
                    <div className="filterContainer">
                        <input className="filter__input" onChange={(e) => inputChangeHandler(e)} value={filters?.mandataire || ''} name="mandataire" type="text" autoComplete="off" />
                    </div>
                </td>  
                <td>
                    <div className="filterContainer">
                        <input className="filter__input" onChange={(e) => inputChangeHandler(e)} value={filters?.annonceur || ''} name="annonceur" type="text" autoComplete="off" />
                    </div>
                </td>  
            </tr>
            <tr></tr>
        </>
    )
}

export default TableFilters
