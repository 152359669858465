import React, { useContext, useEffect, useState } from 'react'
import './SignatureTab.css';
import axios from 'axios'
import Axios from '../../../../../axios-edipub'
import DoneIcon from '@material-ui/icons/Done';
import { Notyf } from 'notyf';
import { Link } from 'react-router-dom';
import ResignReasonModal from './ResignReasonModal/ResignReasonModal';
import { checkValidity, getNotyfObject } from '../../../../../shared/utility';
import SignatureCanvas from './SignatureCanvas/SignatureCanvas';
import Spinner from 'react-bootstrap/Spinner'
import { useTranslation } from 'react-i18next';
import ViewListIcon from '@material-ui/icons/ViewList';
import SignaturesDropdown from './SignaturesDropdown/SignaturesDropdown';
import { b64toBlob } from "../../../../../shared/utility";
import { connect } from 'react-redux';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import fr from 'react-phone-input-2/lang/fr.json'
import EmailIcon from '@material-ui/icons/Email';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';

function SignatureTab({ accessToken, setSignedSuccess, user, admScrolledEnd, setTabSideActive, mandatUid, mandatId, getMandatData, tokenSignature, generateAdmPdf, status, admData, signatureSuccess, setPdfLoading }) {
    const notyf = getNotyfObject();
    const [randSmsCode, setRandSmsCode] = useState(Math.floor(100000 + Math.random() * 900000));
    const [confirmIdentity, setConfirmIdentity] = useState(false);////// to false
    const [docApproved, setDocApproved] = useState(false);
    const [smsCode, setSmsCode] = useState(null);
    const [matchedSmsCode, setMatchedSmsCode] = useState(false);/// to false
    const [telNum, setTelNum] = useState(user?.telephone_number)
    const [telNumError, setTelNumError] = useState(null);
    const [errors, setErrors] = useState(null)
    const [showReasonModal, setShowReasonModal] = useState(false);
    const [signatureDataB64, setSignatureDataB64] = useState(null);
    const [sendSmsLoading, setSendSmsLoading] = useState(false);
    const [signADMLoading, setSignADMLoading] = useState(false);
    const [signdoc, setSigndoc] = useState(null);
    const [isMyTurnToSign, setIsMyTurnToSign] = useState(false);
    const [showSignaturesDD, setShowSignaturesDD] = useState(false)
    const [userSignatures, setUserSignatures] = useState(null)
    const [selectedSignature, setSelectedSignature] = useState(null)
    const { t } = useTranslation();
    const [ipinfo, setIpinfo] = useState(null);
    const [admScrolledEnd1, setAdmScrolledEnd1] = useState(null);
    const [pdf1, setPdf1] = useState(null);
    const contentType = "application/pdf";
    const [sendEmail, setSendEmail] = useState(false)


    //var ttt=user?.user_id;
    var post = {
        docid: admData?.uid,
        userUid: accessToken ? user?.uid : user?.userUid,
        tokenSignature: accessToken
    }

    const sendSmsCode = (messageText) => {
        Axios.get('/document//sendSmsToSign', {
            params: {
                // phoneNumber : user?.telephone_number,
                phoneNumber: telNum,
                smsMessage: messageText
            }
        }).then(response => {
            notyf.success(t("mandat:sendMessageText"))
            setSendSmsLoading(false)
            setConfirmIdentity(true)
        }).catch(response => {
            setSendSmsLoading(false)
            notyf.error(t("mandat:failedSendingMessageText"));
        })
        // axios.get("https://www.ovh.com/cgi-bin/sms/http2sms.cgi", {
        //     params : {
        //         noStop : 1,
        //         contentType : "text/json",
        //         account : "sms-ct5560-1",
        //         login : "avasseur",
        //         password : "mer1440b",
        //         from : "ICD",
        //         to : user?.telephone_number,
        //         message : messageText
        //     }
        // }).then(response => {
        //     let status = response.data.status;
        //     if(status === 100){
        //         notyf.success("Un sms vient de vous être envoyé")
        //     }else if(status === 200) {
        //         notyf.error("Échec d'envoi du code ")
        //     }
        // })
    }

    const getUserSignatures = () => {
        Axios.get('/signature2user//userSignatures')
            .then(response => {
                setUserSignatures(response?.data)
            })
            .catch(err => {
                //notyf.error(t("mandat:savedSignature", "Signature enregistrée"))
            })
    }

    const getUserSignaturesWithToken = () => {
        Axios.get(`/signature2user/${user.userUid}/userSignaturesWithToken`)
            .then(response => {
                setUserSignatures(response?.data)
            })
            .catch(err => {
                //notyf.error(t("mandat:savedSignature", "Signature enregistrée"))
            })
    }


    const checkSignOrder = () => {
        Axios.get(`/document/${mandatUid}/checkSignOrder`, {
            params: {
                tokenSignature,
                userUid: user?.uid
            }
        }).then(response => {
            if (response?.data?.hasOwnProperty("canSign") && response?.data?.canSign) {
                setIsMyTurnToSign(true)
            }
        }).catch(response => {
            notyf.error("mandat:receivingSignatureError")

        })
    }


    useEffect(() => {
        Axios.get('/document//issigned', { params: { ...post } })
            .then(response => {
                setSigndoc(response?.data?.issigned);
                checkSignOrder()
            })
            .catch(response => {
                notyf.error("mandat:receivingSignatureError")

            })



    }, [])

    useEffect(() => {
        if (!accessToken) {
            if (showSignaturesDD)
                getUserSignatures()
        } else {
            if (showSignaturesDD)
                getUserSignaturesWithToken()
        }
    }, [showSignaturesDD])

    const telNumChangeHandler = (phone) => {
        setTelNumError(null)
        setTelNum(phone)
    }

    const checkIdentityClickHandler = (checked) => {
        if (!confirmIdentity && checked) {
            if (!telNum || !checkValidity(telNum, { required: true, isPhone: true })) {
                setTelNumError(t("mandat:error_telNum", "Veuillez saisir un numéro de portable valide"));
            } else {
                setTelNumError(null)
                let messageText = t("mandat:confirmationCodeMessageText") + randSmsCode
                setSendSmsLoading(true);
                sendSmsCode(messageText)
            }
        }
    }

    const smsCodeChangeHandler = (e) => {
        let code = e.target.value;
        if (code.length < 7) {
            setSmsCode(code)
            if (code.length == 6 && +code == randSmsCode) {
                setMatchedSmsCode(true)
                if (!confirmIdentity) setConfirmIdentity(true)
            }
        }
    }

    const addpreuve = () => {
        let post = {
            ...ipinfo,
            randSmsCode,
            uidDoc: admData?.uid,
            description: admData?.description,
            uidUser: user?.userUid,
            phoneNumber: telNum
        }
        Axios.post('/document//createpreuve', { ...post })
            .then(response => {
                generateAdmPdf(admData?.uid)
            })
            .catch(response => {
                notyf.error("mandat:preuve not generated ")
            })
    }

    const saveSignature = () => {
        Axios.post('/signature2user', {
            signatureDataB64
        }).then(response => {
            notyf.success(t("mandat:savedSignature", "Signature enregistrée"))
            setSignatureDataB64(null)
        }).catch(err => {
            //notyf.error(t("mandat:savedSignature", "Signature enregistrée"))
        })
    }

    const signMandat = (mandatUid) => {
        if (setPdfLoading)
            setPdfLoading(true)
        Axios.put(`/document/${mandatUid}/signDocument`, {
            ...ipinfo,
            tokenSignature,
            signatureDataB64,
            userUid: user?.userUid,
            randSmsCode,
            description: admData?.description,
            phoneNumber: telNum
        }).then(response => {
            if (response?.data?.hasOwnProperty("canSign") && !response?.data?.canSign) {
                notyf.error(t("mandat:cantSignAdmOrder", "Ce n'est pas encore votre tour pour signer "))
            } else {
                signatureSuccess(response)
                //addpreuve();
                // pour info ,on success de addpreuve on appelle generatePdf  qui setSignedSuccess(true)
            }
            // setSignADMLoading(false)
        }).catch(err => {
            setSignADMLoading(false)
            console.error(err)
            notyf.error(t("mandat:generatedErrorMessage"))
        }).finally(() => {
            if (setPdfLoading)
                setPdfLoading(false)
        })
    }

    const resignSignMandat = (reasonMessage) => {
        Axios.put(`/document/${mandatUid}/resignSignDocument`, {
            reasonMessage,
            tokenSignature,
            userUid: user?.uid
        }).then(response => {
            if (!accessToken)
                setTabSideActive("timeline")
            getMandatData(mandatUid)
        }).catch(err => {

        })
    }

    const checkValidityForm = () => {
        let errors = []
        if (!confirmIdentity) {
            errors.push(t("mandat:confirmIdentityError"));
        }
        if (!admScrolledEnd) {
            errors.push(t("mandat:readDocumentMessage"));
        }
        if (!docApproved) {
            errors.push(t("mandat:approuveDocMessage"));
        }
        if (!matchedSmsCode) {
            errors.push(t("mandat:checkCodeMessage"));
        }
        if (!signatureDataB64) {
            errors.push(t("mandat:noSignDetectedMessage"));
        }
        return errors;
    }

    const signClickHandler = () => {
        let errors = checkValidityForm();
        if (errors && errors.length > 0) {
            setErrors(errors)
        } else {
            setErrors(null)
            setSignADMLoading(true)
            signMandat(mandatUid)
        }
    }

    const resignSignClickHandler = (reasonMessage) => {
        setErrors(null)
        resignSignMandat(reasonMessage)
    }

    const sendEmailHandle = (checked) => {

        if (!sendEmail && !matchedSmsCode && checked) {
            // const encoder = new TextEncoder();
            // const data = encoder.encode(randSmsCode);
            // const hashBuffer = crypto.subtle.digest('SHA-256', data);
            // const hashArray = Array.from(new Uint8Array(hashBuffer));
            // const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
            Axios.get('/signature2user/' + user?.email + '/authenticateEmailCode', { params: { 'code': randSmsCode } }).then(response => {
                notyf.success(t("mandat:savedSignature", "Le code vous a été envoyé par email à l'adresse " + user?.email))
                setSendEmail(checked)
            }).catch(err => {
                setSendEmail(false)
                notyf.error(t("mandat:savedSignature", "Envoi de mail échoué"))
            })
        } else if (!matchedSmsCode) {
            notyf.error(t("mandat:savedSignature", "Un mail vous a été envoyé à l'adresse " + user?.email + ".<br/><br/> Veuillez réessayer dans une minute !!"))
        }
    }

    const toggleValueForEmailTimer = () => {
        if (sendEmail)
            setSendEmail(false)
    };
    useEffect(() => {
        const intervalId = setInterval(toggleValueForEmailTimer, 60000);
        return () => clearInterval(intervalId);
    }, [sendEmail])

    return (
        <>
            {!signdoc ?
                !isMyTurnToSign ?
                    <div class="alert alert-warning" role="alert">
                        {t('mandat:cantSignAdmOrder', "Ce n'est pas encore votre tour pour signer")}
                    </div>
                    :
                    <div className="signatureTab__container">

                        <div className="signatureTab__header">
                            <h5 className="signatureTab__header__title">
                                {t('mandat:signatureTab', "Signature de l’attestation de mandat")}
                            </h5>
                            <div className="signatureTab__header__resignButton__container">

                                <Link to={{ pathname: "/Home" }}
                                    className="btn btn-outline-danger btn-sm signatureTab__header__resignButton">
                                    {t('default:cancel', "Annuler")}
                                </Link>
                                <button
                                    className="btn btn-danger btn-sm signatureTab__header__resignButton"
                                    onClick={() => setShowReasonModal(true)}
                                >
                                    {t('default:deny', "Refuser")}
                                </button>
                                {
                                    showReasonModal ?
                                        <ResignReasonModal
                                            show={showReasonModal}
                                            handleClose={() => setShowReasonModal(false)}
                                            resignSignature={resignSignClickHandler}
                                        />
                                        : null
                                }
                            </div>
                            {
                                errors && errors.length > 0 ? (
                                    <div class="alert alert-danger mt-2" role="alert">
                                        <ul>
                                            {
                                                errors.map(error => (<li>{error}</li>))
                                            }
                                        </ul>
                                    </div>
                                ) : (
                                    <div class="alert alert-light" role="alert">
                                        {t('mandat:signatureAlert', "Avant de poursuivre, assurez vous d'avoir bien verifier et respecter toutes les étapes")}
                                    </div>
                                )
                            }
                        </div>
                        <div className="signatureTab__steps">
                            <div className="signatureTab__step">
                                <div className="singatureTab__step_header">
                                    {
                                        confirmIdentity ?
                                            <div className="signatureTab__stepDoneIcon"><DoneIcon /> </div>
                                            : sendSmsLoading ?
                                                <Spinner animation="border" variant="primary" />
                                                :
                                                <>
                                                    <div className="signatureTab__stepNumber">
                                                        <span>1</span>
                                                    </div>
                                                </>
                                    }
                                    <div className="signatureTab__stepCheckbox__container">
                                        <input
                                            type="checkbox"
                                            className="step__checkbox__input"
                                            id="identityConfirmCheckbox"
                                            checked={confirmIdentity}
                                            disabled={confirmIdentity}
                                            onChange={(e) => checkIdentityClickHandler(e.target.checked)}
                                        />
                                        <label
                                            className="step__checkbox__label"
                                            for="identityConfirmCheckbox">
                                            {t('mandat:confirmIdentity', "Je confirme mon identité")}
                                        </label>
                                    </div>

                                </div>
                                <div className="signatureTab__stepContent">
                                    <div className="stepContent__identityContent">
                                        <fieldset disabled>
                                            <input
                                                type="text"
                                                className="form-control form-control-sm stepContent__identityInput"
                                                placeholder="NOM PRENOM"
                                                value={user?.last_name + ' ' + user?.first_name} />
                                            <input
                                                type="text"
                                                className="form-control form-control-sm stepContent__identityInput"
                                                placeholder="Email"
                                                value={user?.email}
                                            />
                                            {/* <input 
                                                type="text"  
                                                className="form-control form-control-sm stepContent__identityInput" 
                                                placeholder="Numéro de portable"
                                                value={user?.telephone_number}  /> */}
                                        </fieldset>
                                        <PhoneInput
                                            disabled={user?.telephone_number || confirmIdentity}
                                            localization={fr}
                                            country={'fr'}
                                            inputClass={"form-control w-100"}
                                            dropdownStyle={{ width: "auto" }}
                                            preferredCountries={['fr', 'us']}
                                            enableSearch={false}
                                            inputProps={{ name: "telephone_number" }}
                                            placeholder={"33 0 00 00 00 00"}
                                            value={telNum?.substr(2) || ''}
                                            onChange={phone => telNumChangeHandler("00" + phone)}
                                        />
                                        {
                                            !matchedSmsCode && telNumError ?
                                                <span style={{ color: "red" }}>{telNumError}</span>
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="signatureTab__step">
                                <div className="singatureTab__step_header">
                                    {
                                        admScrolledEnd ?
                                            <div className="signatureTab__stepDoneIcon"><DoneIcon /> </div>
                                            :
                                            <div className="signatureTab__stepNumber"><span>2</span></div>
                                    }
                                    <div className="step__header__title">
                                        {t('mandat:pdfLectureM', "Scroller le document jusqu’en bas, pour justifier de votre lecture ")}
                                    </div>
                                </div>
                            </div>

                            <div className="signatureTab__step">
                                <div className="singatureTab__step_header">
                                    {
                                        docApproved ?
                                            <div className="signatureTab__stepDoneIcon"><DoneIcon /> </div>
                                            :
                                            <div className="signatureTab__stepNumber"><span>3</span></div>
                                    }
                                    <div className="signatureTab__stepCheckbox__container">
                                        <input
                                            type="checkbox"
                                            className="step__checkbox__input"
                                            id="docConfirmCheckbox"
                                            checked={docApproved}
                                            onChange={(e) => setDocApproved(e.target.checked)}
                                        />
                                        <label
                                            className="step__checkbox__label"
                                            for="docConfirmCheckbox">
                                            {t('mandat:approvePdfContent', "J’ai pris connaissance du document et j’approuve le contenu")}
                                        </label>
                                    </div>

                                </div>
                            </div>

                            <div className="signatureTab__step">
                                <div className="singatureTab__step_header">
                                    {
                                        matchedSmsCode ?
                                            <div className="signatureTab__stepDoneIcon"><DoneIcon /> </div>
                                            :
                                            <div className="signatureTab__stepNumber"><span>4</span></div>
                                    }
                                    <div className="step__header__title">
                                        {t('mandat:enterSmsCode', "Entrez le code reçu par SMS/Email")}
                                    </div>
                                </div>
                                <div className="signatureTab__stepContent">
                                    <div className="stepContent__identityContent">
                                        <input
                                            type="text"
                                            className="form-control form-control-sm "
                                            placeholder={t('mandat:receivedCode', "Code reçu")}
                                            value={smsCode || ''}
                                            disabled={matchedSmsCode}
                                            onChange={(e) => smsCodeChangeHandler(e)} />
                                        <input
                                            style={{ marginTop: "5px" }}
                                            type='checkbox'
                                            className="step__checkbox__input"
                                            id="docConfirmCheckbox"
                                            checked={sendEmail}
                                            onChange={(e) => sendEmailHandle(e.target.checked)}
                                            disabled={matchedSmsCode}
                                        />
                                        <label
                                            className="step__checkbox__label"
                                            style={{ fontSize: "13px", color: "#2d2c2c !important", fontWeight: "100" }}
                                            for="docConfirmCheckbox">
                                            {t('mandat:receiveEmailCode', "Recevoir le code par email")}
                                            <AlternateEmailIcon style={{ color: "rgb(89 147 179)", fontSize: "17px" }} />
                                        </label>
                                    </div>
                                </div>
                                {/* <div style={{ cursor: 'pointer' }} className="signatureTab__stepCheckbox__container">

                                    <input
                                        type='checkbox'
                                        className="step__checkbox__input"
                                        id="docConfirmCheckbox"
                                        checked={sendEmail}
                                        onChange={(e) => setSendEmail(e.target.checked)}
                                    />
                                    <label
                                        className="step__checkbox__label"
                                        for="docConfirmCheckbox">
                                        {t('mandat:approvePdfConten', "Recevoir le code par email")}
                                    </label>
                                    
                                </div> */}
                            </div>

                            <div className="signatureTab__step">
                                <div className="singatureTab__step_header">
                                    {
                                        signatureDataB64 ?
                                            <div className="signatureTab__stepDoneIcon"><DoneIcon /> </div>
                                            :
                                            <div className="signatureTab__stepNumber"><span>5</span></div>
                                    }
                                    <div className="step__header__title">
                                        {t('mandat:handSign', "Dessiner ou déposer votre signature dans le carré ci-dessous")}
                                    </div>
                                    {
                                        // !accessToken ? (
                                        <div className="signaturesList_container">
                                            <ViewListIcon
                                                style={{
                                                    color: 'blue',
                                                    cursor: "pointer"
                                                }}
                                                onClick={() => setShowSignaturesDD(true)}
                                            />

                                            <SignaturesDropdown
                                                zIndex="100"
                                                show={showSignaturesDD}
                                                setShow={setShowSignaturesDD}
                                                userSignatures={userSignatures}
                                                setSelectedSignature={setSelectedSignature}
                                            />
                                        </div>
                                        //     ) : null
                                    }
                                </div>
                                <div className="signatureTab__canvasSignContent">
                                    <SignatureCanvas
                                        setSignatureDataB64={setSignatureDataB64}
                                        signatureDataB64={signatureDataB64}
                                        saveSignature={saveSignature}
                                        selectedSignature={selectedSignature}
                                        setSelectedSignature={setSelectedSignature}
                                        accessToken={accessToken}
                                    />
                                </div>
                            </div>

                        </div>
                        <div className="signatureTab__signButton__container">
                            {
                                signADMLoading ?
                                    <Spinner animation="border" variant="success" />
                                    :
                                    <button className="btn btn-success signatureTab__signButton" onClick={() => signClickHandler()}>
                                        {t('default:sign', "Signer")}
                                    </button>
                            }
                        </div>
                    </div >
                :
                <div className="signatureTab__step">
                    <div
                        className="singatureTab__step_header"
                        style={{
                            justifyContent: "center",
                            margin: "35px",
                            fontSize: 80,
                            marginTop: "150px"
                        }}
                    >

                        <div className="signatureTab__stepDoneIcon" style={{ justifyContent: "center", padding: "30px" }} >
                            <DoneIcon style={{ fontSize: "1.9rem" }} />
                        </div>
                    </div>
                    <div >
                        {//t('mandat:pdfLecture', "Lisez attentivement l’attestation de mandat jusqu’à la fin du document")
                        }
                        <h2 style={{ textAlign: "center" }}>{t("mandat:justSigned")}<br></br><br></br></h2>


                    </div>
                </div>
            }

        </>
    )
}




const mapStateToProps = (state) => ({
    status: state.mandat?.admData?.status,
})
const mapDispatchToProps = dispatch => ({

})
export default connect(mapStateToProps, mapDispatchToProps)(SignatureTab)