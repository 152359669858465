import React, { useEffect, useState, useCallback } from 'react'
import './ADMProgressLine.css';
// import { connect } from 'react-redux';
// import * as actions from '../../../../../store/actions/index';
import Axios from '../../../../../axios-edipub';
import { Notyf } from 'notyf';
import { getNotyfObject } from '../../../../../shared/utility';
import _ from 'lodash';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner'

function ADMProgressLine({ approveAdmLoading, status, getMandatData, updateMandatStatus, mandatUid, createMandatTimelineEvent, role, isCreator, connectedUser, pdfFile, admData, admUpdate, setShowSignatureTab, setTabSideActive, error }) {
    const [messageInfo, setMessageInfo] = useState(null)
    const { t } = useTranslation();
    let notyf = getNotyfObject();
    const [classNames, setClassNames] = useState({
        revTriangle: ["rev_triangle_gray", "rev_triangle_gray", "rev_triangle_gray"],
        pipe: ["pipe_gray", "pipe_gray", "pipe_gray"],
        triangle: ["triangle_gray", "triangle_gray"]
    });

    const formatDate = useCallback((date) => {
        if (date !== null && date.valueOf() > 0) {
            let newDate = new Date(date);
            let day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
            let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
            return day + '/' + month + '/' + newDate.getFullYear()
        } else return null;
    }, [])


    // const validateMandat = () => {
    //     const promise = new Promise((resolve, reject) => {
    //         var tt = {
    //             annonceur: admData.annonceur.official_name,
    //             mandataire: admData.mandataire.official_name,
    //             start_period: formatDate(parseInt(admData.start_period)),
    //             end_period: formatDate(parseInt(admData.end_period)),
    //             creatorName: admData.creatorName,
    //         }
    //         setValidateAdmLoading(true);
    //         Axios.put(`/document/${mandatUid}/validateDocument`, tt)
    //             .then(response => {
    //                 setValidateAdmLoading(false);
    //                 setShowConfirmToSignModal(false)
    //                 getMandatData(mandatUid)
    //                 resolve(response);
    //             }).catch(err => {
    //                 setValidateAdmLoading(false);
    //                 setShowConfirmToSignModal(false)
    //                 if (err?.response?.data?.emptySignatories)
    //                     notyf.error('Un signataire doit étre désigné pour chaque acteur')
    //                 else if (err.response?.data?.error && (err.response?.data?.error === 'Expired token'))
    //                     notyf.error('Une erreur s\'est produite ! Votre session a expiré. Veuillez vous déconnecter et vous reconnecter avant de renouveler l\'opération.')
    //                 else
    //                     notyf.error('Une erreur s\'est produite !')
    //                 reject(err);
    //             })
    //     })
    //     return promise;
    // }



    useEffect(() => {
        switch (status) {
            case "DRAFT":
                setClassNames({
                    revTriangle: ["rev_triangle_gray", "rev_triangle_gray", "rev_triangle_gray"],
                    pipe: ["pipe_gray", "pipe_gray", "pipe_gray"],
                    triangle: ["triangle_gray", "triangle_gray"]
                });
                setMessageInfo(t('mandat:progressLine_draftMsg', 'Après préparation de MyMandat, pensez à configurer le processus d\'approbation avant de partager MyMandat avec les contributeurs.'))
                break;
            case "TO_BE_APPROVED":
                setClassNames({
                    revTriangle: ["rev_triangle_blue", "rev_triangle_gray", "rev_triangle_gray"],
                    pipe: ["pipe_blue", "pipe_gray", "pipe_gray"],
                    triangle: ["triangle_blue", "triangle_gray"]
                });
                if (!isCreator && +connectedUser?.is_contributor === 1)
                    setMessageInfo(t('mandat:progressLine_toBeAppMsg', 'Vérifiez tous les champs de MyMandat avant d’approuver. Si vous relevez une erreur, envoyez un message au mandataire responsable  via \'Demandes de modification\'.'));
                break;
            case "TO_BE_SIGNED":
                setClassNames({
                    revTriangle: ["rev_triangle", "rev_triangle_blue", "rev_triangle_gray"],
                    pipe: ["pipe", "pipe_blue", "pipe_gray"],
                    triangle: ["triangle", "triangle_blue"]
                })
                isCreator ?
                    setMessageInfo(t('mandat:progressLine_toBeSignMsgCr', 'Les signataires ont été informés qu’une attestation de mandat attendait leur signature. Vous pouvez suivre l’avancement dans le panneau Signatures '))
                    : +connectedUser?.is_signataire && !connectedUser?.signedDoc ?
                        setMessageInfo(t('mandat:progressLine_toBeSignMsg', 'Votre Signature est attendue pour ce mandat '))
                        : setMessageInfo('');
                if (isCreator && admData?.mandataireUsersSigned == "1" && admData?.offline == "1")
                    setTabSideActive("offline")
                break;
            case "SIGNED":
                setClassNames({
                    revTriangle: ["rev_triangle", "rev_triangle", "rev_triangle_gray"],
                    pipe: ["pipe", "pipe", "pipe_gray"],
                    triangle: ["triangle", "triangle"]
                })
                setMessageInfo(t('mandat:progressLine_signedMsg', 'L’attestation de mandat est signée.'))
                break;
            case "ABANDONED":
                setClassNames({
                    revTriangle: ["rev_triangle", "rev_triangle_red", "rev_triangle_gray"],
                    pipe: ["pipe", "pipe_red", "pipe_gray"],
                    triangle: ["triangle", "triangle_red"]
                })
                setMessageInfo(t('mandat:progressLine_abandonedMsg', 'L’attestation de mandat est abandonnée.'))
                break;
            case "EXPIRED":
                setClassNames({
                    revTriangle: ["rev_triangle", "rev_triangle", "rev_triangle"],
                    pipe: ["pipe", "pipe", "pipe"],
                    triangle: ["triangle", "triangle"]
                })
                break;
            case "CANCELLED":
                setClassNames({
                    revTriangle: ["rev_triangle", "rev_triangle_red", "rev_triangle_gray"],
                    pipe: ["pipe", "pipe_red", "pipe_gray"],
                    triangle: ["triangle", "triangle_red"]
                })
                setMessageInfo(t('mandat:progressLine_cancelledMsg', 'L’attestation de mandat est résiliée.'))
                break;
            default:
                break;
        }
    }, [status])


    let actionButton = null;

    switch (status) {
        case "TO_BE_APPROVED":
            +connectedUser?.is_contributor === 1 && +connectedUser?.validatedDoc === 0 ?
                actionButton = (
                    <Fragment>
                        {
                            approveAdmLoading ?
                                <Spinner animation="border" variant="primary" />
                                :
                                <Fragment>
                                    <div className="signer_mandat" onClick={() => setTabSideActive("Action")} >
                                        <p>
                                            {t('mandat:approveAdmBtn', "Approuver le mandat")}
                                        </p>
                                    </div>
                                </Fragment>
                        }
                    </Fragment>
                )
                : actionButton = null
            break;

        default:
            break;
    }


    if (error && error === 'Expired token') {
        notyf.error('Une erreur s\'est produite ! Votre session a expiré. Veuillez vous déconnecter et vous reconnecter avant de renouveler l\'opération.')
    }

    return (
        <div className="ProgressLine">
            <div className="ProgressLine-div">
                <div className="ProgressLine-container-form" style={actionButton ? null : { height: "65px" }}>
                    <div className="progressLine-container">

                        <div className="line_Hover">
                            <div className="pipe radiusStyleLeft">
                                <p>
                                    {t('default:DRAFT', "Brouillon")}
                                </p>
                            </div>
                            <div className="triangle"></div>
                        </div>

                        <div className="line_Hover">
                            <div className={classNames.revTriangle[0]} ></div>
                            <div className={classNames.pipe[0]}>
                                <p>
                                    {t('default:TO_BE_APPROVED', "A approuver")}
                                </p>
                            </div>
                            <div className={classNames.triangle[0]}></div>
                        </div>

                        <div className="line_Hover">
                            <div className={classNames.revTriangle[1]} ></div>
                            <div className={classNames.pipe[1]}>
                                <p>{t('default:SIGNED', "Signé")}</p>
                            </div>
                            <div className={classNames.triangle[1]}></div>
                        </div>

                        <div className="line_Hover">
                            <div className={classNames.revTriangle[2]} ></div>
                            <div className={classNames.pipe[2] + " radiusStyleRight"} >
                                <p>{t('default:EXPIRED', "Expiré")}</p>
                            </div>
                        </div>

                        {/* {
                            showConfirmToSignModal ? (
                                <CustomConfirmModal
                                    show={showConfirmToSignModal}
                                    handleClose={() => setShowConfirmToSignModal(false)}
                                    text={t('mandat:confirmToSignMessage', "Étes vous sûr de vouloir passer le mandat en signature ?")}
                                    okCallback={validateMandat}
                                    loading={validateAdmLoading}
                                />
                            ) : null
                        } */}
                        {/* {
                            showConfirmShareAdmModal ? (
                                <CustomConfirmModal
                                    show={showConfirmShareAdmModal}
                                    handleClose={() => setShowConfirmShareAdmModal(false)}
                                    text={t('mandat:confirmShareAdmMessage', "Êtes-vous sûr de vouloir partager le mandat ?")}
                                    okCallback={shareMandatHandler}
                                    loading={shareAdmLoading}
                                />
                            ) : null
                        } */}
                    </div>

                    <div className="notice_container">
                        <p><i className="fas fa-bell fa-2x"></i> {messageInfo} </p>
                        {actionButton && <div className="actionButtons">
                            {actionButton}
                        </div>}
                    </div>


                </div>

            </div>
        </div>
    )
}

export default (ADMProgressLine)
