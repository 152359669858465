import React, { useEffect, useState } from 'react'
import './DeleteUserModalConfirmation.css'
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Tooltip, withStyles } from '@material-ui/core';
// import { getNotyfObject } from '../../../../shared/utility';
import Spinner from 'react-bootstrap/Spinner';

const CTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 15,
    },
}))(Tooltip);

function DeleteUserModalConfirmation({ handleClose, show, handleContinueConfirmation, warningRepresentative, warningValidator }) {
    const { t } = useTranslation();
    const updateClickHandler = () => {
        handleClose()
        handleContinueConfirmation()
    }

    // useEffect(() => {

    // }, [])


    return (
        <>
            <Modal show={show} onHide={handleClose} contentClassName="modal_w_mc"  >
                <Modal.Header closeButton>
                    <Modal.Title>

                        {/* {t('mandat:creatorModal_title', "Modification du responsable/approbateurs/signataires du mandat")}  */}
                        {t('mandat:creatorContributorSignatoryModalTit', "Confirmation de la suppression")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="ADMCreationModal__body" style={{ width: "650px" }}>
                    {
                        warningRepresentative && warningRepresentative?.length > 0 ?
                            <>
                                <div className="alert alert-warning" role="alert">
                                    <p>
                                        {/* {t('masterData:editCreatorWarning3', "Une approbation(s) était attendue par cet utilisateur pour les ADM suivantes :")}<br /> */}
                                        {t('masterData:editCreatorWarning4', "……..Pensez à choisir un nouveau représentant légal dans le workflow d’approbation de ces ADM")}
                                    </p>
                                </div>
                            </>
                            : null
                    }

                    {
                        warningValidator && warningValidator?.length > 0 ?
                            <>
                                <div className="alert alert-warning" role="alert">
                                    <p>
                                        {/* {t('masterData:editCreatorWarning3', "Une approbation(s) était attendue par cet utilisateur pour les ADM suivantes :")}<br /> */}
                                        {t('masterData:editCreatorWarning4', "……..Pensez à choisir un nouvel approbateur dans le workflow d’approbation de ces ADM")}
                                    </p>
                                </div>
                            </>
                            : null
                    }

                </Modal.Body>
                <Modal.Footer>
                    {
                        <>
                            <Button variant="secondary" onClick={handleClose}>
                                {t('default:cancel', "Annuler")}
                            </Button>
                            <Button
                                variant="primary"
                                onClick={() => updateClickHandler()}
                            >
                                {t('masterData:updateAndDele', "Continue")}
                            </Button>
                        </>
                    }
                </Modal.Footer>
            </Modal>
        </>
    )

}

export default DeleteUserModalConfirmation