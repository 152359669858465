import React, { useEffect, useState } from 'react'
import DescriptionIcon from '@material-ui/icons/Description';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ShareIcon from '@material-ui/icons/Share';
import ResignSignatureIcon from '../../../../../../../assets/images/resignsignatureIcon.png'
import {formatDate, getTimeDate} from '../../../../../../../shared/utility';
import { useTranslation } from 'react-i18next';

function DocumentCancelledEvent({isCreator, eventData, isLast}) {
    const [showContent, setShowContent] = useState(false)
    const {t} = useTranslation();

    useEffect(() => {
        if(isLast)
            setShowContent(true)
        else
            setShowContent(false)
    }, [isLast])

    return (
        <div className="timelineCard__container">
            <div className="timelineCard__hideicon" onClick={() => setShowContent(!showContent)}>
                {
                    showContent ? 
                        <ExpandMoreIcon />
                    :
                        <ChevronRightIcon />
                }
            </div>
            <div className="timelineCard__sideContainer">
                <div className="timelineCard__header">
                    <img src={ResignSignatureIcon} style={{width: "24px", height: "24px", color: "#FFFFFF", borderRadius: "5px"}}  />
                    <div className="timelineCard__header_label">
                        {t('timeline:cancelADM', "Résiliation du mandat")} 
                    </div>
                    <div className="timelineCard__header_time">
                        <span className="timelineCard__header_hour"> {getTimeDate(+eventData?.creationDate)}</span> 
                        <span className="timelineCard__header_date"> {formatDate(+eventData?.creationDate)}</span> 
                    </div>
                </div>
                <div className="timelineCard__content" style={{borderLeft: "2px solid #dc3545",  display: showContent ? "block" : "none"}}>
                    <div className="timelineCard__content__info">
                    {
                        eventData?.data?.first_name + ' ' + eventData?.data?.last_name + ' ' + t('timeline:cancelledDocument', "a résilié ce mandat")
                    } </div>
                    <div className="timelineCard__content__body">
                        <div className="approvedEvent__content__header">
                            <div className="approvedEvent__header__name">
                                <div className="approvedEvent__header__nameLabel">{t('default:name', "Nom")}</div>
                                <div className="approvedEvent__header__nameValue">{eventData?.data?.first_name + ' ' + eventData?.data?.last_name}</div>
                            </div>
                            <div className="approvedEvent__header__company">
                                <div className="approvedEvent__header__companyLabel">{t('default:society', "Société")}</div>
                                <div className="approvedEvent__header__companyValue">{eventData?.data?.official_name }</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default DocumentCancelledEvent