import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import "./AddMissionModal.css";
import { useTranslation } from 'react-i18next';

function AddMissionModal({handleClose, show, missions, addMission, lang, admData}) {
    const [content, setContent] = useState("")
    const [canAdd, setCanAdd] = useState(false)
    const [alreadyExists, setAlreadyExists] = useState(false)
    const {t} = useTranslation();

    useEffect(() => {
        if (content) {
            const exists = missions?.find(m => {
                if(lang === 'FR') {
                    if(!m.label)
                        return false
                    return m.label.replaceAll(" ", "").replaceAll("\r\n", "").toUpperCase() === content.replaceAll(" ", "").replaceAll("\r\n", "").toUpperCase()
                }
                else if (lang === 'EN') {
                    if(!m.label_en)
                        return false
                    return m.label_en.replaceAll(" ", "").replaceAll("\r\n", "").toUpperCase() === content.replaceAll(" ", "").replaceAll("\r\n", "").toUpperCase()
                }
            });
            setAlreadyExists(exists);
            setCanAdd(!exists);
        } else
            setCanAdd(false);
    }, [content])

    const addClickHandler = () => {
        if(canAdd){
            let newMission = {}
            if(lang === 'FR') { 
                newMission.label = content
                newMission.label_en = null
            }  else if (lang === 'EN') {
                newMission.label = null
                newMission.label_en = content
            }  
            addMission(newMission)
            handleClose()
        } 
    } 

    return (
        <>
            <Modal show={show} onHide={handleClose} contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                <Modal.Title>{t('mandat:addMission', "Ajouter une mission")} </Modal.Title>
                </Modal.Header>
                <Modal.Body className="ADMCreationModal__body">
                    <div className="ADMCreationModal__description__container">
                        <h5>{t('default:content', "Contenu")} </h5>
                        {
                            alreadyExists ? 
                                <div style={{color: "red"}} > {t('masterData:existMissionLabel', "Cette mission existe déja!")}</div> 
                            :   null
                        }  
                        <textarea 
                            className="form-control" 
                            name="content"
                            rows="2"
                            value={content || ''}
                            onChange={(e) => setContent(e.target.value)}
                        >
                        </textarea>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t('default:cancel', "Annuler")}
                </Button>
                <Button variant="primary" onClick={() => addClickHandler()} disabled={!canAdd}>
                    {t('default:add', "Ajouter")}
                </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => ({
    admData: state.mandat.admData     
})

const mapDispatchToProps = dispatch => ({ })

export default connect(mapStateToProps, mapDispatchToProps) (AddMissionModal)

