  
import { Tooltip, withStyles } from '@material-ui/core';

export const CTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 15,
      zIndex:200, 
      place:'top', 
       

    },
}))(Tooltip);


export default CTooltip



