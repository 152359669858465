import React, { useCallback, useState, useEffect } from 'react'
import Contributor from '../../../../../../assets/images/contributor.png'
import { useTranslation } from 'react-i18next';

import { b64toBlob } from '../../../../../../shared/utility';
import axios from 'axios';
import Axios from '../../../../../../axios-edipub';
import { getNotyfObject } from '../../../../../../shared/utility';
import { connect } from 'react-redux';
import * as actions from '../../../../../../store/actions/index';
import RelanceSignatureModal from './RelanceSignatureModal';
let notyf = getNotyfObject();



function UserContributeurCard({ signUser, mandatid, userRole, mandatUid, getMandatTimelineEvents, isCreator }) {
    const { t } = useTranslation();
    const contentType = "application/pdf";
    const [relanceShow, setRelanceShow] = useState(false)
    const [userContact, setUserContact] = useState()
    const userUid = localStorage.getItem("userId");
    var post = {
        preuveUid: signUser?.preuveUid,
        docid: mandatid,
        user: signUser.user_id
    }



    useEffect(() => {

        Axios.get(`/document/${mandatUid}/contactReminderSignature`).then(response => {

            setUserContact(response?.data.user)
        })



    }, [])
    const relanceContributeur = () => {
        Axios.put(`/document/${mandatUid}/userReminderContributeur`, {
            uid: signUser?.user_uid
        }).then(response => {
            getMandatTimelineEvents(mandatUid);
        })
            .catch(err => {
                if (err.response?.data?.error && (err.response?.data?.error === 'Expired token'))
                    notyf.error('Une erreur s\'est produite ! Votre session a expiré. Veuillez vous déconnecter et vous reconnecter avant de renouveler l\'opération.')
                else
                    notyf.error('Une erreur s\'est produite !')
            })
        setRelanceShow(false)

    }



    const dawnloadPdfPreuve = () => {

        if (signUser?.signedDoc) {
            //notyf.error(t("mandat:generatedErrorMessage")))

            notyf.success(t("mandat:downloadedfile"))
        } else {
            notyf.error(t("mandat:theuserhasnotyetsigned"))
        }

        Axios.get(`/document//preuveByToken`, { params: { ...post } }).then(response => {

            const blob = b64toBlob(response.data.pdf64, contentType);
            const blobUrl = URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = "data:application/pdf;base64," + response.data.pdf64;
            a.download = "preuve_signature";
            document.body.appendChild(a);
            a.click();
            // window.open(blobUrl, "_blank");
        }).catch(error => {

        })
    }
    return (
        <div
            className="ADMSignature__card_container"
            style={{
                backgroundColor: signUser?.validatedDoc === "1" ? "rgba(80,204,122,0.8)" : "lightgray",
                //color: "#FFFFFF"
            }}>

            {
                relanceShow ? (
                    <RelanceSignatureModal
                        show={relanceShow}
                        handleClose={() => setRelanceShow(false)}
                        relanceSignatureForUser={relanceContributeur}
                        signUser={signUser}
                        mandatUid={mandatUid}
                        userContact={userContact}
                        contributor={true}
                    />
                ) : null
            }

            <div className="ADMSignature__card_content">
                <div className="ADMSignature__card_icon">
                    <img src={Contributor} style={{ width: "48px", height: "48px", color: "#FFFFFF", borderRadius: "5px" }} />
                </div>
                <div className="ADMSignature__card_infos">
                    {
                        userRole != "RG" && userRole != "RC" ?
                            <div className="ADMSignature__card_infosHead">
                                {/* <h5>{(signUser?.last_name || '') + ' ' + (signUser?.first_name || '')} </h5> */}
                                <h5>
                                    {
                                        signUser?.user_id !== null ?

                                            (<span>{`${signUser?.last_name} ${signUser?.first_name}`}</span>)
                                            :
                                            null
                                    }
                                </h5>
                                <div class="dropdown">
                                    <button class="btn btn-outline-secondary btn-sm dropdown-toggle" style={{ display: signUser?.validatedDoc === "1" ? "none" : "block", }} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        {signUser?.validatedDoc != "1" ?
                                            isCreator && userUid === signUser.user_uid ?
                                                <strong className="dropdown-item" ><span>{`${signUser?.last_name} ${signUser?.first_name}`} {t('mandat:isTheCreatorOfThisAdm')}</span></strong> :

                                                <button className="dropdown-item" onClick={() => setRelanceShow(true)} >Relancer</button>
                                            :
                                            null
                                            //<button className="dropdown-item" onClick={dawnloadPdfPreuve} >Voir le dossier de preuves</button>
                                        }
                                    </div>
                                </div>
                            </div> : null
                    }
                    <p>{t('default:society', "Société")}  : <span>{signUser?.official_name || ''} </span></p>
                    <p>{t('default:siret', "Siret")}  : <span>{signUser?.siret || ''} </span></p>

                </div>

            </div>
            <p className="ADMSignature__card_address">
                {t('mandat:headquarterAddress', "Adresse siège social")} : <span>{(signUser?.address_line || '') + ', ' + (signUser?.post_code || '')} </span></p>
        </div>
    )
}


const mapStateToProps = (state) => ({

    //  admUpdate: state.mandat.admUpdate,
    // updateLoading: state.mandat.updateLoading
})
const mapDispatchToProps = dispatch => ({

    getMandatTimelineEvents: (mandatUid) => dispatch(actions.getMandatTimelineEvents(mandatUid)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserContributeurCard)