import React, { useEffect, useRef, useState } from 'react'
import './UserDropdown.css';
import { NavLink, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { useTranslation } from 'react-i18next';

function UserDropdown({ show, setShow, zIndex, isAuthenticated, logout }) {
    const divRef = useRef();
    const { t } = useTranslation();

    const clickOutSideHandler = e => {
        if (divRef.current.contains(e.target)) {
            return
        }
        setShow(false)
    }

    useEffect(() => {
        document.addEventListener("mousedown", clickOutSideHandler);

        return () => {
            document.removeEventListener("mousedown", clickOutSideHandler);
        }
    }, [])

    return (
        <div ref={divRef} className="header__profilDropdown" style={{ zIndex: zIndex, display: show ? "flex" : "none" }}>
            <NavLink className="header__logoutBtn" to="/profile">{t("default:monCompte")}</NavLink>
            <div className="header__profilItem" onClick={() => logout()}>
                <div>
                    <NavLink className="header__logoutBtn" to="/">
                        {isAuthenticated ? t("default:seDeconnecter") : t("default:seConnecter")}
                    </NavLink>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        login: state.auth.login
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (email, password) => dispatch(actions.auth(email, password)),
        onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/')),
        logout: () => dispatch(actions.logout())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDropdown)
