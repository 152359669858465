import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './SiretsModal.css'
import SearchIcon from '@material-ui/icons/Search';

function SiretsModal({handleClose, show, sirets, searchAdvertiserEntityBySiretOrUpdateNewEntity}) {
    
    const {t} = useTranslation(); 
    
    const searchClickHandler = (siret) => {         
        searchAdvertiserEntityBySiretOrUpdateNewEntity(siret)
        handleClose();
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} dialogClassName="modal-xl">                 
                <Modal.Header closeButton>
                <Modal.Title>{t('default:alert', "Alerte")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {t("masterData:errorMuchSiret", "Plusieurs établissements correspondent à vos critères de recherche. Cliquez sur l’établissement à créer ou modifier (ou sur la loupe)")}
                    </p>  
                    <div className ='siretsList'> 
                        <ul>{sirets?.map(etablissement => (
                            <li>
                                <a href="#" onClick={() => {searchClickHandler(etablissement.siret)}}>{etablissement.siret  +  (etablissement.siege ? ' (siège)' : '') +  " - " + etablissement.deno + " - "  +  etablissement.adresse}</a>
                                <SearchIcon style={{color: "red", cursor: "pointer"}} fontSize="small" onClick={() => searchClickHandler(etablissement.siret)} />
                            </li>
                        ))}</ul>
                    </div> 
                </Modal.Body>
                <Modal.Footer> 
                <Button variant="secondary" onClick={handleClose}>
                    {t('default:cancel', "Annuler")}
                </Button> 
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default SiretsModal
