import React, { useCallback, useEffect, useState } from 'react'
import PersonDropDown from '../DropDowns/PersonDropDown/PersonDropDown';
import './FormEdition.css';
import RepresentativeCard from './RepresentativeCard/RepresentativeCard';
import ClearIcon from '@material-ui/icons/Clear';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { useTranslation } from 'react-i18next';
import { CTooltip } from '../../../../../../../shared/utility';
import EmailIcon from '@material-ui/icons/Email';
import Select from "react-select";

function FormEdition(props) {
    const {
        agency,
        agencyType,
        addressChangeHandler,
        subMandIndex,
        userAddHandler,
        userUpdateHandler,
        userDeleteHandler,
        getAddressByid,
        getAddressPrimaryAndSecondaryByid,
        publicService,
        handleSelectDepartment,
        showCommercialNameHandler
    } = props

    useEffect(() => {
        if (agencyType === "sousMandataire" && agency.new) {
            addressChangeHandler(agencyType, "address_hq", agency?.address_hq, agency?.uid, subMandIndex);
            addressChangeHandler(agencyType, "address_invoice", agency?.address_invoice, agency?.uid, subMandIndex);
            addressChangeHandler(agencyType, "address_mail", agency?.address_mail, agency?.uid, subMandIndex);
        }
    }, [])

    const { t } = useTranslation();

    const getFunctionWithUserRole = (role, dataRow) => {
        if ("AC, AG".includes(role)) return dataRow.annonceurFunctionLabel
        if ("MR, MG, MC".includes(role)) return dataRow.agenceFunctionLabel
        if ("AE".includes(role)) return "Administrateur"
        // les AE n'ont pas de role    =>>>> donc ???   Administrateur par defaut
        return dataRow.regieFunctionLabel
    }

    const getSiret = (identNumbers) => {
        let siretIndex = agency?.identNumbers?.findIndex(ident => ident.type === "SIRET");
        if (siretIndex >= 0) {
            return identNumbers[siretIndex]?.number
        } else {
            return null
        }
    }

    const getTva = (identNumbers) => {
        let siretIndex = agency?.identNumbers?.findIndex(ident => ident.type === "TVA");
        if (siretIndex >= 0) {
            return identNumbers[siretIndex]?.number
        } else {
            return null
        }
    }

    const setAddressOnClick = (addressField) => {
        const showEmailAddress = addressField === "address_mail";
        let addressRow = null
        if (addressField === "address_hq")
            addressRow = agency?.addresses.filter(row => row.isHq === "1")[0];
        else if (addressField === "address_invoice")
            addressRow = agency?.addresses.filter(row => row.isInvoice === "1")[0];
        else if (addressField === "address_mail")
            addressRow = agency?.addresses.filter(row => row.isMail === "1")[0];
        let addressStr = getAddressPrimaryAndSecondaryByid(agency, addressRow.id)
        addressStr += (addressStr.length > 0 ? "\n" : '')
        addressStr += getAddressByid(agency, addressRow.id, showEmailAddress)
        addressChangeHandler(agencyType, addressField, addressStr, agency?.uid, subMandIndex)
    }


    return (
        <>
            <div className="ADMEdition_annonceur_title">
                {t('mandat:undersigned', "Nous soussignés")}
            </div>
            <div className="ADMEdition_annonceur_body">
                <div className="ADMEdition_annonceur_infoItems">
                    <div className="ADMEdition_annonceur_infoItem">
                        <div className="ADMEdition_annonceur_infoItemTitle">
                            <label htmlFor="denominationSociale">
                                {t('mandat:companyName', "Dénomination sociale")}
                            </label>
                        </div>
                        <div className="ADMEdition_annonceur_infoItemInput">
                            <input type="text" className="form-control" id="denominationSociale" name="official_name" value={agency?.official_name || ''} disabled />
                        </div>
                    </div>

                    <div className="ADMEdition_annonceur_infoItem">
                        <div className="ADMEdition_annonceur_infoItemTitle">
                            <label htmlFor="siret">{t('mandat:identsoc', "Identifiant société (SIRET)")} </label>
                        </div>
                        <div className="ADMEdition_annonceur_infoItemInput">
                            <input type="text" className="form-control" id="siret" name="siret" value={getSiret(agency?.identNumbers) || ''} disabled />
                        </div>
                    </div>
                    <div className="ADMEdition_annonceur_infoItem">
                        <div className="ADMEdition_annonceur_infoItemTitle">
                            <label htmlFor="TVAIntracommunautaire">{t('mandat:vatNumber', "N°TVA Intracommunautaire")}</label>
                        </div>
                        <div className="ADMEdition_annonceur_infoItemInput">
                            <input type="text" className="form-control" id="TVAIntracommunautaire" name="vat_number" value={getTva(agency?.identNumbers) || ''} disabled />
                        </div>
                    </div>
                </div>

                {agencyType && agencyType === "annonceur" && agency?.show_commercial_name === '0' && agency?.official_name != agency?.commercial_name &&
                    <div className="ADMEdition_annonceur_infoItemsSubLine">
                        <div className="ADMEdition_annonceur_infoItem">
                            <div className="ADMEdition_annonceur_infoItemTitle">
                                <label htmlFor="commercial_name">
                                    {t('mandat:companyCommercialName', "Nom commercial")}
                                </label>
                            </div>
                            <div className="ADMEdition_annonceur_infoItemInput"  >
                                <AddBoxIcon className="AdmEdition_clearIcon" onClick={() => showCommercialNameHandler("annonceur", '1')} />
                            </div>
                        </div>
                    </div>
                }
                {agencyType && agencyType === "annonceur" && agency?.show_commercial_name === '1' && agency?.official_name != agency?.commercial_name &&
                    <div className="ADMEdition_annonceur_infoItemsSubLine">
                        <div className="ADMEdition_annonceur_infoItem">
                            <div className="ADMEdition_annonceur_infoItemTitle">
                                <label htmlFor="commercial_name">
                                    {t('mandat:companyCommercialName', "Nom commercial")}
                                </label>
                            </div>
                            <div className="ADMEdition_annonceur_infoItemInput" style={{ display: "flex" }}>
                                <input type="text" className="form-control" id="commercial_name" name="commercial_name" value={agency?.commercial_name || ''} disabled />
                                <ClearIcon className="AdmEdition_clearIcon" onClick={() => showCommercialNameHandler("annonceur", '0')} />
                            </div>
                        </div>
                    </div>
                }
                {
                    publicService == "1" &&
                    <div className="ADMEdition_annonceur_infoItemsSubLine">
                        {
                            agency?.category && agency?.category.toLowerCase() === "annonceur" && (
                                <>
                                    <div className="ADMEdition_annonceur_infoItem">
                                        <CTooltip title={t('mandat:mandatingSiretToolTip', "SIRET de la structure mandante dédiée aux activités Média")}  >
                                            <div>
                                                <div className="ADMEdition_annonceur_infoItemTitle">
                                                    <label htmlFor="mandatingSiret">
                                                        {t('mandat:mandatingSiret', "SIRET structure mandante Chorus Pro")}
                                                    </label>
                                                </div>
                                                <div className="ADMEdition_annonceur_infoItemInput">
                                                    <input type="text" className="form-control" id="mandatingSiret" name="mandating_siret" value={agency?.mandating_siret || ''} disabled />
                                                </div>
                                            </div>
                                        </CTooltip>
                                    </div>
                                    <div className="ADMEdition_annonceur_infoItem">
                                        <CTooltip title={t('mandat:engagementNumberToolTip', "Si communiqué par l'annonceur")}  >
                                            <div>
                                                <div className="ADMEdition_annonceur_infoItemTitle">
                                                    <label htmlFor="engagementNumber">
                                                        {t('mandat:engagementNumber', "Numéro d’engagement Chorus Pro")}
                                                    </label>
                                                </div>
                                                <div className="ADMEdition_annonceur_infoItemInput">
                                                    <input type="text" className="form-control" id="engagementNumber" name="engagement_number" value={agency?.engagement_number || ''} disabled />
                                                </div>
                                            </div>
                                        </CTooltip>
                                    </div>
                                </>
                            )
                        }
                        {
                            agency?.service_code &&
                            <div className="ADMEdition_annonceur_infoItem">
                                <CTooltip title={t('mandat:serviceCodeToolTip', "Code service dédié à l’Agence Média")} >
                                    <div>
                                        <div className="ADMEdition_annonceur_infoItemTitle">
                                            <label htmlFor="serviceCode">
                                                {t('mandat:serviceCode', "Code service agence Chorus Pro")}
                                            </label>
                                        </div>
                                        <div className="ADMEdition_annonceur_infoItemInput">
                                            <input type="text" className="form-control" id="codeService" name="service_code" value={agency?.service_code || ''} disabled />
                                        </div>
                                    </div>
                                </CTooltip>
                            </div>
                        }

                    </div>
                }

                {agencyType === "mandataire" && <div className="ADMEdition_annonceur_representants" style={{ marginTop: "30px" }}>
                    <div className="ADMEdition_annonceur_representantsTitle">
                        {t('mandat:departments', "Départements")}
                    </div>
                    <div className="ADMEdition_annonceur_representantsList">
                        <Select
                            classNames={{ control: () => "form-control" }}
                            styles={{
                                control: (baseStyles) => ({ ...baseStyles, backgroundColor: "white", borderRadius: "0.25rem", transition: "border-color .15s", padding: "0 0 0 6px" }),
                                container: (baseStyles) => ({ ...baseStyles, width: "50%" })
                            }}
                            id="departments"
                            name="departments"
                            placeholder="-- Sélectionnez un ou plusieurs départements --"
                            isMulti
                            options={agency?.departments.map(d => ({ value: d.id, label: d.label }))}
                            value={agency?.departments.filter(d => +d.selected).map(d => ({ value: d.id, label: d.label }))}
                            onChange={handleSelectDepartment}
                        />
                    </div>
                </div>}

                <div className="ADMEdition_annonceur_addresses">
                    <div className="ADMEdition_annonceur_address">
                        <div className="ADMEdition_annonceur_addressTitle">
                            <label htmlFor="siegeSocialeAddress">{t('mandat:headquarterAddress', "Adresse siège social")}  </label>
                            {
                                agency?.addresses.some(row => row.isHq === "1") &&
                                <div className="UIdropDown__icon" onClick={() => setAddressOnClick("address_hq")}>
                                    <EmailIcon fontSize="small" />
                                </div>
                            }
                        </div>
                        <div className="ADMEdition_annonceur_addressInput">
                            <textarea type="text" className="form-control" id="siegeSocialeAddress" name="address_hq" value={agency?.address_hq || ''} disabled />
                            <ClearIcon
                                className="AdmEdition_clearIcon"
                                //style={{color:"#e8e8e8", cursor:"pointer"}} 
                                onClick={() => addressChangeHandler(agencyType, "address_hq", null, agency?.uid, subMandIndex)}
                            />
                        </div>
                    </div>
                    <div className="ADMEdition_annonceur_address">
                        <div className="ADMEdition_annonceur_addressTitle">
                            <label htmlFor="facturationAddress">{t('mandat:billingAddress', "Adresse de facturation")} </label>
                            {
                                agency?.addresses.some(row => row.isInvoice === "1") &&
                                <div className="UIdropDown__icon" onClick={() => setAddressOnClick("address_invoice")}>
                                    <EmailIcon fontSize="small" />
                                </div>
                            }
                        </div>
                        <div className="ADMEdition_annonceur_addressInput">
                            <textarea type="text" className="form-control" id="facturationAddress" name="address_invoice" value={agency?.address_invoice || ''} disabled />
                            <ClearIcon
                                className="AdmEdition_clearIcon"
                                //style={{color:"#706E6B", cursor:"pointer"}} 
                                onClick={() => addressChangeHandler(agencyType, "address_invoice", null, agency?.uid, subMandIndex)}
                            />
                        </div>
                    </div>
                    <div className="ADMEdition_annonceur_address">
                        <div className="ADMEdition_annonceur_addressTitle">
                            <label htmlFor="facturationAddress2">{t('mandat:sendInvAddress', "Adresse d'envoi des factures")} </label>
                            {
                                agency?.addresses.some(row => row.isMail === "1") &&
                                <div className="UIdropDown__icon" onClick={() => setAddressOnClick("address_mail")}>
                                    <EmailIcon fontSize="small" />
                                </div>
                            }
                        </div>
                        <div className="ADMEdition_annonceur_addressInput">
                            <textarea type="text" className="form-control" id="facturationAddress2" name="address_mail" value={agency?.address_mail || ''} disabled />
                            <ClearIcon
                                className="AdmEdition_clearIcon"
                                //style={{color:"#706E6B", cursor:"pointer"}} 
                                onClick={() => addressChangeHandler(agencyType, "address_mail", null, agency?.uid, subMandIndex)}
                            />
                        </div>
                    </div>
                </div>
                <div className="ADMEdition_annonceur_representants">
                    <div className="ADMEdition_annonceur_representantsTitle">
                        {t('mandat:representedBy', "Représenté par ")}
                        <PersonDropDown
                            entityUid={agency?.uid}
                            personsList={agency?.users}
                            userAddHandler={userAddHandler}
                            agencyType={agencyType}
                            subMandIndex={subMandIndex}
                        />
                    </div>
                    <div className="ADMEdition_annonceur_representantsList">
                        {
                            agency?.usersDoc.map((row, index) => (
                                +row.is_legal_representative ?
                                    <RepresentativeCard
                                        key={row.id}
                                        userIndex={index}
                                        agencyType={agencyType}
                                        subMandIndex={subMandIndex}
                                        firstName={row.first_name}
                                        lastName={row.last_name}
                                        isLegalRepresentative={row.is_legal_representative}
                                        isSignataire={row.is_signataire}
                                        functionLabel={getFunctionWithUserRole(row.role, row)}
                                        userUpdateHandler={userUpdateHandler}
                                        userDeleteHandler={userDeleteHandler}
                                        userIsDeletable={!(agency?.legal_representative_imposed && agency?.legal_representative_imposed === '1')}
                                    />
                                    : null
                            ))
                        }


                    </div>



                </div>

            </div>
        </>
    )
}

export default FormEdition
