import React, { useEffect, useState } from 'react'
import DescriptionIcon from '@material-ui/icons/Description';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ShareIcon from '@material-ui/icons/Share';
import SignatureIcon from '../../../../../../../assets/images/signatureIcon.png'
import {formatDate, getTimeDate} from '../../../../../../../shared/utility';
import { useTranslation } from 'react-i18next';
import LoopRoundedIcon from '@material-ui/icons/LoopRounded';
function ContributeurReminder({isCreator, eventData, isLast}) {
    const [showContent, setShowContent] = useState(false)
    const {t} = useTranslation();
    useEffect(() => {
        if(isLast)
            setShowContent(true)
        else
            setShowContent(false)
    }, [isLast])

    return (
        <div className="timelineCard__container">
            <div className="timelineCard__hideicon" onClick={() => setShowContent(!showContent)}>
                {
                    showContent ? 
                        <ExpandMoreIcon />
                    :
                        <ChevronRightIcon />
                }
            </div>
            <div className="timelineCard__sideContainer">
                <div className="timelineCard__header">
                    <LoopRoundedIcon color="" style={{width: "24px", height: "24px", borderRadius: "5px",backgroundColor:"rgb(0 123 255 / 82%)"}}  />
                    <div className="timelineCard__header_label">
                        {t('timeline:contributeurReminder', "Contributeur relancé")} 
                    </div>
                    <div className="timelineCard__header_time">
                        <span className="timelineCard__header_hour"> {getTimeDate(+eventData?.creationDate)}</span> 
                        <span className="timelineCard__header_date"> {formatDate(+eventData?.creationDate)}</span> 
                    </div>
                </div>
                <div className="timelineCard__content" style={{borderLeft: "2px solid rgb(0, 112, 210)",  display: showContent ? "block" : "none"}}>
                    <div className="timelineCard__content__info">

                          {
                        isCreator ?  t('default:youRemindedTheContributor', "Vous avez relancé le contributeur ci-dessous") : eventData?.data?.user?.first_name + ' ' + eventData?.data?.user?.last_name +t('default:youRemindedTheContributorCreator', "a relancé le contributeur ci dessous") 
                    }


                    {/* {
                        eventData?.data?.userCreator?.first_name + ' ' + eventData?.data?.userCreator?.last_name 
                    } */}
                     </div>
                    <div className="timelineCard__content__body">
                        <strong>{t('timeline:contributor', "Contributeur :")} </strong>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">{t('default:name', "Nom")}</th>
                                    <th scope="col">{t('default:society', "Société")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    
                                        
                                            <tr>
                                                <td>{eventData?.data?.contributorUsers[0]?.first_name + ' ' + eventData?.data?.contributorUsers[0]?.last_name} </td>
                                                <td style={{width: "50%",
                                                            whiteSpace: "nowrap",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis"}}>{eventData?.data?.contributorUsers[0]?.entities}</td>
                                            </tr>
                                       
                                  
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default ContributeurReminder
