import React, { useCallback, useEffect, useState } from 'react';
import { getNotyfObject } from '../../shared/utility';
import Axios from '../../axios-edipub';
import { connect } from 'react-redux';
import Spinner from '../../components/UI/Spinner/Spinner';
import './ManualEntity.css'; 
import NoteAddIcon from '@material-ui/icons/NoteAdd'; 
import TableBody from '../../components/ManualEntity/TableBody/TableBody';
import TableActions from '../../components/ManualEntity/TableAction/TableActions';
import TableFilters from '../../components/ManualEntity/TableFilters/TableFilters';
import TableHead from '../../components/ManualEntity/TableHead/TableHead';
import { formatDate } from '../../shared/utility';
import TablePagination from '../../components/ManualEntity/TablePagination/TablePagination'; 
import { useTranslation } from 'react-i18next';
 

function ManualEntity({role}) {

    const {t} = useTranslation();  
    const columns = [ 
        {title: t('manualEntity:adveriserContact'), field: 'login', width: "200px"},  
        {title: t('manualEntity:creator'), field: 'creatorName', width: "200px"},  
        {title: t('manualEntity:officialName', "Annonceur"), field: 'entity_official_name', width: "150px"}, 
        {title: t('manualEntity:creationDate', "Date"), field: 'creationDate', width: "150px"},
        {title: t('manualEntity:status', "Statut"), field: 'status', width: "200px"} 
    ]  
    const initialFiltersState = {
        login: "",   official_name: "", status: "", creationDate: "",  orderBy: "creationDate", sortOrder: "DESC", page:1, selectedRows: [], selectedAllRows: false,
        selectedAllPerPage : []
    }
    const reverseTable = {
        'Status' : false ,'creationDate': false,'official_name': false,
        'mandataire': false,'start_period': false,'end_period': false,
        'description': false,'medias': false,'produits': false
    }
    const [filters, setFilters] = useState(initialFiltersState);
    const [sortQuery, setSortQuery] = useState("creationDate");
    const [sortOrder, setSortOrder] = useState("DESC");
    const [entityTokens, setEntityTokens] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const [loading, setLoading] = useState(false);
    const notyf = getNotyfObject()
    const [currentPage, setCurrentPage] = useState(1);
    const [count, setCount] = useState(0);
    const [reverse, setReverse] = useState(reverseTable);

    useEffect(() => {
        refreshContent(initialFiltersState)
    }, [])

    
    const refreshContent = (filters) => {
        setLoading(true); 
        Axios.get('/manualEntityToken', {  params : {...filters} }     
        ).then(res => {
            let data = res.data?.manualEntities
            let countItems = res.data?.nbItems
            if(data){
                setEntityTokens(data)
            } 
            setCount(countItems)
        })
        .catch(err => {
            if(err.response?.data?.error && (err.response?.data?.error === 'Expired token'))
                notyf.error('Une erreur s\'est produite ! Votre session a expiré. Veuillez vous déconnecter et vous reconnecter avant de renouveler l\'opération.')
            else 
                notyf.error('Une erreur s\'est produite !')
        }) 
        .finally(() => {
            setLoading(false);
        })
    }  

    const inputChangeHandler = (e) => {
        let key = e.target.name;
        let newFilters = {
            ...filters,
            [key]: e.target.value,
            selectedAllPerPage: [],
            selectedRows: []
        }
        setFilters(newFilters)
        refreshContent(newFilters)
    } 

    const handleReset = () => {
        setFilters(initialFiltersState)
        setCurrentPage(1)
        refreshContent(initialFiltersState)
    }

    let tBody = null; 
    if (loading)
        tBody = <tr><td colSpan="12"><Spinner /></td></tr>
    else if( entityTokens?.length > 0){
        tBody = (
            <TableBody  
                data={entityTokens} 
                formatDate={formatDate}  
                refreshContent={refreshContent}
                filters={filters}
              />
        )
    }

    return (  
        <div className="home"> 
            <div className="home__container">  
                <div className="headerBar__containerkpi">
                    <div className="headerBar__left"> 
                        <div className="header_actionTypes_containerkpi">  
                            <NoteAddIcon className="header_icon_kpi" style={{width: "50px", height: "95%"}} /> 
                        </div> 
                        <div className="left__elementskpi"> 
                            <h1 className="leftElement_title">{t('manualEntity:manualEntities')}   </h1>
                        </div> 
                    </div>  
                </div>   
                    <div className="invoiceHeader">
                        <TableActions 
                            filterState={showFilters} 
                            setShowFilters={setShowFilters} 
                            resetData={handleReset}
                            formatDate={formatDate}/>
                        <TablePagination
                            filters={filters} 
                            filtersChange={setFilters} 
                            applyFilters={refreshContent} 
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage} 
                            totalElement={count} 
                            perPage={25}/>
                    </div>
                
                <div className="home__homeBodyContainer" style={{marginTop: "7px"}}>
                    <div className="home__homeBodyMain">
                        <table className="table tableFixHead">
                            <TableHead 
                                columns={columns} 
                                refreshContent ={refreshContent} 
                                reverse={reverse}
                                sortQuery={sortQuery}
                                setSortOrder={setSortOrder}
                                sortOrder={sortOrder}
                                setReverse={setReverse}
                                setSortQuery={setSortQuery}
                                filters={filters}
                                setFilters={setFilters}/>
                            <tbody>
                                {showFilters && 
                                    <TableFilters 
                                    show={showFilters} 
                                    formatDate={formatDate} 
                                    filters={filters} 
                                    inputChangeHandler={inputChangeHandler}
                                    filtersChange={setFilters} 
                                    applyFilters={refreshContent} 
                                    /> 
                                }
                                {tBody}
                            </tbody>            
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    isAuth : state.auth.token !== null,
    token: state.auth.token,
    role : state.auth.role
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps) (ManualEntity)



