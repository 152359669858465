import React, { useCallback, useState } from 'react'
import Axios from '../../../../../axios-edipub'
import MandatEdition from './Forms/MandatEdition/MandatEdition';
import './ADMEdition.css';
import FormEdition from './Forms/FormEdition/FormEdition';
import { connect } from 'react-redux';
import * as actions from '../../../../../store/actions/index';
import { useParams } from 'react-router-dom';
import { Notyf } from 'notyf';
import SubMandataireModal from './Forms/Modals/SubMandatairesModal/SubMandataireModal';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { formatDate, getNotyfObject } from '../../../../../shared/utility';
import MandatClauses from './Forms/MandatClauses/MandatClauses';
import AddBoxIcon from '@material-ui/icons/AddBox';
import AddClauseModal from './Forms/Modals/AddClauseModal/AddClauseModal';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner'
import countries from '../../../../../assets/countries.json'


function ADMEdition(props) {
    const {
        updateLoading,
        setTabActive,
        admData,
        updateMandatData,
        updateAdmAddresses,
        admUpdate,
        updateMandat,
        addMandatuser,
        addMandatUserToUpdate,
        updateMandatUserToAdd,
        updateMandatUserToUpdate,
        deleteMandatUser,
        deleteMandatUserToAdd,
        addMandatSubagency,
        deleteMandatSubAgency,
        deleteMandatSubAgencyToAdd,
        updateMandatAdmObjectsAgency,
        updateMandatObjects,
        addMandatAdmObjects,
        deleteMandatAdmObjects,
        deleteMandatProduct,
        updateMandatClauses,
        deleteMandatClause,
        updateAdmPayer,
        updateMandatAdmObject,
        updateMandatDepartments,
        updateMandatShowCommercialName
    } = props

    const { t } = useTranslation();
    let { uid } = useParams();
    let notyf = getNotyfObject();

    const [addClauseShow, setAddClauseShow] = useState(false);
    const [uncheckEntity, setUncheckEntity] = useState();

    const addressChangeHandler = (agencyType, addressField, addressValue, entityUid, subMandIndex = null) => {
        if (agencyType !== "sousMandataire") {
            let mandatNew = {
                ...admData,
                [agencyType]: {
                    ...admData[agencyType],
                    [addressField]: addressValue
                }
            }
            updateMandatData(mandatNew)
        } else {
            let sousMandatairesTemp = [...admData.sousMandataires]
            sousMandatairesTemp[subMandIndex] = {
                ...sousMandatairesTemp[subMandIndex],
                [addressField]: addressValue
            }
            let mandatNew = {
                ...admData,
                sousMandataires: sousMandatairesTemp
            }
            updateMandatData(mandatNew)
        }
        updateAdmAddresses(entityUid, addressField, addressValue)
    }

    const showCommercialNameHandler = (agencyType, showCommercialNameValue) => {
        if (agencyType == "annonceur") {
            let mandatNew = {
                ...admData,
                [agencyType]: {
                    ...admData[agencyType],
                    show_commercial_name: showCommercialNameValue
                }
            }
            updateMandatData(mandatNew)
            updateMandatShowCommercialName(showCommercialNameValue)
        }
    }

    const departmentChangeHandler = departments => {
        if (departments?.length === 0) {
            notyf.open({
                type: 'warning',
                message: t("mandat:noDepartementMessage", "Sélectionnez au moins un département !"),
                duration: 5000,
                dismissible: true
            });
        } else {
            const mandataire = { ...admData.mandataire };
            mandataire.departments = mandataire?.departments.map(d => ({ ...d, selected: (departments?.findIndex(d2 => d2.value === d.id) > -1 ? 1 : 0) }));
            const mandatNew = {
                ...admData,
                mandataire: mandataire
            }
            updateMandatData(mandatNew)
            updateMandatDepartments(mandataire.departments)
        }
    }

    const userAddFoNewSubAgencyHandler = (userToAdd, entityUid) => {
        let userTemp = {
            entityUid,
            uid: userToAdd.uid,
            is_legal_representative: userToAdd.is_legal_representative,
            is_signataire: userToAdd.is_signataire
        }
        addMandatuser(userTemp)
    }

    const userAddHandler = (agencyType, userToAdd, entityUid, subMandIndex = null) => {
        let usersDoc = agencyType === "sousMandataire" ? admData.sousMandataires[subMandIndex].usersDoc : admData[agencyType].usersDoc;

        let userIndex = usersDoc.findIndex(row => row.id === userToAdd.id)
        let existUser = userIndex >= 0
        let userToAddTemp = { ...usersDoc[userIndex] };
        if (existUser) {
            if (+userToAddTemp.is_legal_representative === 0) {
                let fakeEvent = {
                    target: {
                        name: 'is_legal_representative',
                        checked: true
                    }
                }
                userUpdateHandler(fakeEvent, agencyType, userIndex, subMandIndex);
            } else
                notyf.error(t("mandat:admEditionMessage"));
        } else {
            userToAdd.is_legal_representative = 1
            if (agencyType !== "sousMandataire") {
                let mandatNew = {
                    ...admData,
                    [agencyType]: {
                        ...admData[agencyType],
                        usersDoc: [...usersDoc, userToAdd]
                    }
                }
                updateMandatData(mandatNew);
            } else {
                let sousMandatairesTemp = [...admData.sousMandataires];
                sousMandatairesTemp[subMandIndex].usersDoc = [...usersDoc, userToAdd]
                let mandatNew = {
                    ...admData,
                    sousMandataires: sousMandatairesTemp
                }
                updateMandatData(mandatNew);
            }
            let userTemp = {
                entityUid,
                uid: userToAdd.uid,
                is_legal_representative: userToAdd.is_legal_representative,
                is_signataire: userToAdd.is_signataire
            }
            addMandatuser(userTemp)
        }
    }

    const userUpdateHandler = (e, agencyType, userIndex, subMandIndex = null) => {
        let mandatTemp = { ...admData }
        let field = e.target.name
        let value = e.target.checked ? 1 : 0
        let agency = agencyType === "sousMandataire" ? mandatTemp.sousMandataires[subMandIndex] : mandatTemp[agencyType]
        let user = agency.usersDoc[userIndex]
        user[field] = value
        updateMandatData(mandatTemp)

        if (user.hasOwnProperty('d2uUid')) {
            let existInUsersToUpdate = admUpdate.usersToUpdate.findIndex(row => row.d2uUid === user.d2uUid) >= 0;
            if (existInUsersToUpdate) {
                updateMandatUserToUpdate(user.d2uUid, field, value)
            } else {
                let userTemp = {
                    d2uUid: user.d2uUid,
                    is_legal_representative: user.is_legal_representative,
                    is_signataire: user.is_signataire
                }
                addMandatUserToUpdate(userTemp);
            }
        } else {
            updateMandatUserToAdd(agency.uid, user.uid, field, value)
        }
    }

    const userDeleteHandler = (agencyType, userIndex, subMandIndex = null) => {
        let mandatTemp = { ...admData }
        let agency = agencyType === "sousMandataire" ? mandatTemp.sousMandataires[subMandIndex] : mandatTemp[agencyType]
        let user = agency.usersDoc[userIndex]
        if (agency.new == 1) {
            //adddd to user 
            agency.usersDoc.splice(userIndex, 1);
            let mandatTemp = { ...admData }
            updateMandatData(mandatTemp)
            // let usersTemp = [...admUpdate.usersToAdd];
            // let entityIndex =usersTemp.findIndex(row => row.uid === user.uid);
            // usersTemp.splice(entityIndex,1)
            deleteMandatUserToAdd(agency.uid, user.uid)

        } else {
            if (user.is_signataire == "1" || user.is_contributor == "1" || user.is_recipient == "1") {
                if (+user.is_legal_representative === 1) {
                    let fakeEvent = {
                        target: {
                            name: 'is_legal_representative',
                            checked: false
                        }
                    }
                    userUpdateHandler(fakeEvent, agencyType, userIndex, subMandIndex);
                } else
                    notyf.error(t("mandat:admEditionMessage"));
            } else {
                agency.usersDoc.splice(userIndex, 1);
                updateMandatData(mandatTemp)
                if (user.hasOwnProperty('d2uUid')) {
                    deleteMandatUser(user.d2uUid);
                } else {
                    deleteMandatUserToAdd(agency.uid, user.uid)
                }
            }
        }
    }

    function uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    // on ajoute social name ici
    const getAddressPrimaryAndSecondaryByid = (agency, addressId) => {
        let addresses = agency?.addresses || [];
        let addressRow = addresses.filter(row => row.id == addressId)[0];
        if (addressRow) {

            let corporate = ''
            if ((addressRow?.corporate_name !== null) && addressRow?.corporate_name)
                corporate += addressRow.corporate_name

            let primaryAndSecondary = ''
            if ((addressRow?.label_primary !== null) && addressRow?.label_primary)
                primaryAndSecondary += addressRow.label_primary
            if ((addressRow?.label_secondary !== null) && addressRow?.label_secondary) {
                primaryAndSecondary += (primaryAndSecondary.length > 0 ? ' ' : '')
                primaryAndSecondary += addressRow.label_secondary
            }
            return corporate + ((corporate !== '' && primaryAndSecondary !== '') ? "\n" : '') + primaryAndSecondary
        }
        else return '';
    }

    const getAddressByid = (agency, addressId, isEmailAddress) => {
        let addresses = agency?.addresses || [];
        let addressRow = addresses.filter(row => row.id == addressId)[0];
        if (addressRow) {
            let address = ''
            if ((addressRow?.address_line !== null) && addressRow?.address_line)
                address += addressRow.address_line
            if ((addressRow?.distribution_service !== null) && addressRow?.distribution_service) {
                address += (address.length > 0 ? ' ' : '')
                address += addressRow.distribution_service
            }
            if (((addressRow?.address_line !== null) && addressRow?.address_line) || ((addressRow?.city_cedex !== null) && addressRow?.city_cedex))
                address += ', '
            if ((addressRow?.post_code !== null) && addressRow?.post_code)
                address += addressRow.post_code
            if ((addressRow?.city_cedex !== null) && addressRow?.city_cedex) {
                address += (((addressRow?.post_code !== null) && addressRow?.post_code) ? " " : "")
                address += addressRow.city_cedex
            }

            if (((addressRow?.iso_country !== null) && addressRow?.iso_country && addressRow.iso_country.length > 0) && (((addressRow?.post_code !== null) && addressRow?.post_code) || ((addressRow?.city_cedex !== null) && addressRow?.city_cedex)))
                address += ', '
            else
                address += ' '
            if ((addressRow?.iso_country !== null) && addressRow?.iso_country && addressRow.iso_country.length > 0) {
                let country = countries.find(element => element.alpha2 === addressRow.iso_country)
                address += country[(admData?.lang === 'EN' ? 'nameEn' : 'name')].toUpperCase();
            }
            if (isEmailAddress && (addressRow?.email !== null) && addressRow?.email) {
                address += (address.length > 0 ? "\n" : '')
                address += addressRow.email
            }
            return address.trimStart()
        }
        else return '';
    }

    const initEntitysAddresses = entity => {
        entity.addresses.forEach(a => {
            let addressField = null;
            if (parseInt(a.isHq))
                addressField = "address_hq";
            else if (parseInt(a.isInvoice))
                addressField = "address_invoice";
            else if (parseInt(a.isMail))
                addressField = "address_mail";

            if (addressField) {
                let addressStr = getAddressPrimaryAndSecondaryByid(entity, a.id)
                addressStr += (addressStr.length > 0 ? "\n" : '');
                addressStr += getAddressByid(entity, a.id, a.isMail);
                entity[addressField] = addressStr;
            }
        })
    }

    const subAgencyAddHandler = (entityUid) => {
        let params = {};
        params.docUid = admData.uid;
        Axios.get(`/entity/${entityUid}/entityDetails`, { 'params': params }).then(response => {
            let subAgency = response.data.entity
            subAgency.new = true
            subAgency.usersDoc = subAgency.users.filter(row => ((row.is_legal_representative == 1 && (subAgency?.legal_representative_imposed === '0' || subAgency?.legal_representative == row.id))))
            subAgency.usersDoc.forEach(u => {
                u.d2uUid = uuidv4()
                userAddFoNewSubAgencyHandler(u, entityUid)
            })
            initEntitysAddresses(subAgency);

            let sousMandataires = [...admData?.sousMandataires, subAgency]
            let admObjectsTemp = [...admData.admObjects]
            Axios.get('geographicalarea').then(response => {
                const nationalArea = response.data.find(ga => ga.code_geo === "NAT");
                let uuid = uuidv4();
                let newAdmObject = {
                    new: true,
                    commercial_name: subAgency.commercial_name,
                    agencyUid: subAgency.uid,
                    dspUid: uuid,
                    products: [],
                    allProducts: 1,
                    medias: [],
                    allMedias: 1,
                    regies: [],
                    allAddeps: 1,
                    supports: [],
                    allSupports: 1,
                    marketingModes: [],
                    allMarketingmodes: 1,
                    geographicalAreas: [nationalArea],
                    missions: JSON.parse(JSON.stringify(admObjectsTemp[0].missions)) //parse + stringify equivalent to spread operator but deep copies nested objects
                }
                let purposesObject = {}
                newAdmObject.missions?.forEach(p => {
                    let checkedMission = (p.code === null || (['PAYER', 'PAYER_UNCHECKED'].includes(p.code) && (admData.mandataire.payer === "1")))
                    p.responsible = (checkedMission) ? 'SUBPROXY' : null
                    purposesObject["_" + p.id] = (checkedMission) ? 'SUBPROXY' : null
                })
                admObjectsTemp.push(newAdmObject)
                addMandatAdmObjects(uuid, subAgency.uid, purposesObject)
                updateMandatAdmObject(uuid, "geographicalAreasToAdd", [nationalArea]);
                if (admObjectsTemp.length === 2)
                    handleShowInPdf("0", admObjectsTemp[0])

                let mandatTemp = {
                    ...admData,
                    sousMandataires: sousMandataires,
                    admObjects: admObjectsTemp
                }
                updateMandatData(mandatTemp)
                addMandatSubagency(entityUid)
            }).catch(error => notyf.error(t("mandat:generatedErrorMessage")))

        }).catch(response => notyf.error(t("mandat:generatedErrorMessage")))

    }

    const subAgencyDeleteHandler = (entityUid, subAgencyIndex) => {
        let mandatTemp = { ...admData }
        const subAgency = mandatTemp.sousMandataires[subAgencyIndex]

        mandatTemp.sousMandataires.splice(subAgencyIndex, 1);
        const admObjectsTemp = [...admData.admObjects]

        if (subAgency.hasOwnProperty('new')) {
            deleteMandatSubAgencyToAdd(entityUid)
            deleteMandatAdmObjects(entityUid)
            let admObjIndex = admObjectsTemp.findIndex(obj => obj.agencyUid === entityUid)
            admObjectsTemp.splice(admObjIndex, 1);
        } else {
            deleteMandatSubAgency(entityUid)
            let admObjIndex = admObjectsTemp.findIndex(obj => obj.agencyUid === entityUid)
            admObjectsTemp.splice(admObjIndex, 1);

            deleteMandatAdmObjects(entityUid)
        }
        if (admObjectsTemp.length === 1)
            handleShowInPdf("1", admObjectsTemp[0])

        mandatTemp = {
            ...mandatTemp,
            admObjects: admObjectsTemp
        }
        updateMandatData(mandatTemp)
    }

    const updateClausesHandler = (clausesToUpdate) => {
        let mandatNew = {
            ...admData,
            clauses: clausesToUpdate
        }
        updateMandatData(mandatNew);
        updateMandatClauses(clausesToUpdate)
    }

    const addClauseHandler = (clauseToAdd) => {
        let mandatClausesTemp = [...admData.clauses, clauseToAdd];
        updateClausesHandler(mandatClausesTemp)
    }

    const deleteClauseHandler = (clauseToDelete) => {
        let mandatClausesTemp = [...admData.clauses];
        mandatClausesTemp.splice(clauseToDelete.orderIndex, 1)
        let clausesToUpdate = mandatClausesTemp.map((clause, index) => {
            let clauseTemp = { ...clause, orderIndex: index }
            return clauseTemp
        })
        updateClausesHandler(clausesToUpdate)
        if (!clauseToDelete.hasOwnProperty("agencyUid"))
            deleteMandatClause(clauseToDelete.uid)
    }

    const getAgencyNameById = (agencyId) => {
        let agencyName = null;
        if (admData.mandataire.id === agencyId)
            agencyName = admData.mandataire.commercial_name;
        else {
            let agencyIndex = admData.sousMandataires.findIndex(row => row.id === agencyId);
            if (agencyIndex >= 0)
                agencyName = admData.sousMandataires[agencyIndex].commercial_name;
        }
        return agencyName
    }

    const updateMandatClickHandler = () => {
        if ((admData.admObjects[0].supports.length === 0) && (admData.admObjects[0].allSupports === "0")) {

            updateMandatAdmObject(admData.admObjects[0].dspUid, "allSupports", "1")
            let admObjectsTemp = [...admData.admObjects]
            admObjectsTemp[0] = {
                ...admObjectsTemp[0],
                allSupports: "1"
            }
            let mandatDataTemp = {
                ...admData,
                admObjects: admObjectsTemp
            }

            updateMandatData(mandatDataTemp)

            let admObjectsUp = { ...admUpdate.admObjects }
            admObjectsUp[admData.admObjects[0].dspUid] = {
                ...admObjectsUp[admData.admObjects[0].dspUid],
                allSupports: "1"
            }

            let admUpdatetemp = {
                ...admUpdate,
                admObjects: admObjectsUp
            }
            updateMandat(uid, admUpdatetemp);
        } else {
            updateMandat(uid, admUpdate);
        }

        setTabActive("edit");
    }

    const handleShowInPdf = (value, admObject) => {
        updateMandatAdmObject(admObject.dspUid, "showInPdf", value)
        const mandatDataTemp = { ...admData }
        const admObjectTemp = mandatDataTemp.admObjects.find(o => o.dspUid === admObject.dspUid)
        admObjectTemp.showInPdf = value;
        updateMandatData(mandatDataTemp)
    }

    const isMandatUpdated = useCallback(() => {
        let mandatNotUpdated = _.isEqual(admUpdate, {
            addresses: {},
            usersToAdd: [],
            usersToUpdate: [],
            usersToDelete: [],
            subAgencyToAdd: [],
            subAgencyToDelete: [],
            admObjects: {},
            clauses: [],
            departmentsToAdd: [],
            departmentsToDelete: [],
            showCommercialNameToUpdate: []
        })
        return mandatNotUpdated
    }, [admUpdate]);

    const resetListSubmandataires = () => {
        setUncheckEntity(true);
    }

    return (
        <div>
            <div className="admEdition__actionsContainer">
                <div className="admEdition__actionsLeft">
                    {/* <button type="button" className="btn btn-outline-secondary btn-sm">Ajouter un Sous-Mandataire</button> */}
                    <button type="button" className="btn btn-outline-secondary btn-sm" data-toggle="modal" onClick={resetListSubmandataires} data-target="#subMandataireModal">{t("mandat:addSubAgency")}</button>
                </div>
                <div className="admEdition__actionsRight">
                    {
                        updateLoading ?
                            <Spinner animation="border" variant="primary" />
                            :
                            <button
                                type="button"
                                className="btn btn-outline-secondary btn-sm saveBtn mr-2"
                                onClick={() => updateMandatClickHandler()}
                                disabled={isMandatUpdated()}
                            >{t('default:save', "Sauvegarder")} </button>
                    }
                    {/* <button 
                        type="button" 
                        className="btn btn-primary btn-sm" 
                        onClick={() => generateAdmPdf(uid) } >
                            {t('mandat:generateAdm', "Générer MyMandat")}
                    </button> */}
                </div>
            </div>
            <div id="accordion">
                <div className="card border-light">
                    <div className="card-header bg-transparent border-light" id="headingOne">
                        <h5 className="mb-0">
                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseAnnonceur" aria-expanded="true" aria-controls="collapseAnnonceur">
                                <span className="text-dark">{t('default:advertiser', "Annonceur")} :</span>  {admData.annonceur?.commercial_name || ''}
                                <ExpandMoreIcon className="admEdition__expand" />
                                <ChevronRightIcon className="admEdition__collapse" />
                            </button>
                        </h5>
                    </div>

                    <div id="collapseAnnonceur" className="collapse" aria-labelledby="headingOne" data-parent="#accordion" >
                        <div className="card-body bg-light p-1">
                            <FormEdition
                                agency={admData?.annonceur}
                                agencyType="annonceur"
                                addressChangeHandler={addressChangeHandler}
                                userAddHandler={userAddHandler}
                                userUpdateHandler={userUpdateHandler}
                                userDeleteHandler={userDeleteHandler}
                                getAddressByid={getAddressByid}
                                getAddressPrimaryAndSecondaryByid={getAddressPrimaryAndSecondaryByid}
                                publicService={admData?.annonceur?.public_service}
                                showCommercialNameHandler={showCommercialNameHandler}
                            />
                        </div>
                    </div>
                </div>
                <div className="card border-light">
                    <div className="card-header bg-transparent border-light" id="headingTwo">
                        <h5 className="mb-0">
                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseMandataire" aria-expanded="false" aria-controls="collapseMandataire">
                                <span className="text-dark">{t('default:representative', "Mandataire")} :</span>  {admData.mandataire?.commercial_name || ''}
                                <ExpandMoreIcon className="admEdition__expand" />
                                <ChevronRightIcon className="admEdition__collapse" />
                            </button>
                        </h5>
                    </div>
                    <div id="collapseMandataire" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                        <div className="card-body bg-light p-1">
                            <FormEdition
                                agency={admData?.mandataire}
                                agencyType="mandataire"
                                addressChangeHandler={addressChangeHandler}
                                userAddHandler={userAddHandler}
                                userUpdateHandler={userUpdateHandler}
                                userDeleteHandler={userDeleteHandler}
                                getAddressByid={getAddressByid}
                                getAddressPrimaryAndSecondaryByid={getAddressPrimaryAndSecondaryByid}
                                publicService={admData?.annonceur?.public_service}
                                handleSelectDepartment={departmentChangeHandler}
                            />
                        </div>
                    </div>
                </div>

                {
                    admData?.admObjects?.map((admObject, index) => (
                        admData.mandataire.uid === admObject.agencyUid && <div className="card border-light" key={admObject.id}>
                            <div className="card-header bg-transparent border-light" id="headingThree">
                                <h5 className="mb-0">
                                    {admData?.sousMandataires?.length > 0 && <input type="checkbox" checked={admObject.showInPdf === "1" || false} onChange={e => handleShowInPdf(e.target.checked ? "1" : "0", admObject)} />}
                                    <button className="btn btn-link collapsed" data-toggle="collapse" data-target={"#collapseMandat" + index} aria-expanded="false" aria-controls="collapseMandat" disabled={admObject.showInPdf === "0"}>
                                        <span className="text-dark">{t('mandat:generalMandatePurposes', "Mandats / Missions générales de ")}</span>
                                        {admObject.commercial_name}
                                        {admData?.sousMandataires?.length > 0 && <span className="text-dark"> ({t('default:optional', "optionnel")})</span>}
                                        {//getAgencyNameById(admObject.subproxy)
                                        }
                                        <ExpandMoreIcon className="admEdition__expand" />
                                        <ChevronRightIcon className="admEdition__collapse" />
                                    </button>
                                </h5>
                            </div>
                            <div id={"collapseMandat" + index} className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                <div className="card-body bg-light p-1">
                                    <MandatEdition

                                        admObject={admObject}
                                        admOIndex={index}
                                        documentUid={admData.uid}
                                        advertiserUid={admData.annonceur.uid}
                                        isMandataire={true}
                                        period={formatDate(+admData?.start_period) + "-" + formatDate(+admData?.end_period)}
                                    />
                                </div>
                            </div>
                        </div>
                    ))
                }

                {
                    admData?.sousMandataires?.map((sousMandataire, index) => (
                        <div key={sousMandataire.id}><div className="card border-light">
                            <div className="card-header bg-transparent border-light d-flex justify-content-between align-items-center" id="headingThree">
                                <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-toggle="collapse" data-target={"#collapseSousMandataire" + sousMandataire.id} aria-expanded="false" aria-controls={"collapseSousMandataire" + sousMandataire.id}>
                                        <span className="text-dark">{t('default:subrepresentative', "Sous mandataire")} :</span>  {sousMandataire?.commercial_name || ''}
                                        <ExpandMoreIcon className="admEdition__expand" />
                                        <ChevronRightIcon className="admEdition__collapse" />
                                    </button>
                                </h5>
                                <DeleteForeverIcon
                                    className="AdmEdition_clearIcon"
                                    //style={{cursor: "pointer", color: "red"}}
                                    onClick={() => subAgencyDeleteHandler(sousMandataire.uid, index)} />
                            </div>
                            <div id={"collapseSousMandataire" + sousMandataire.id} className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                <div className="card-body bg-light p-1">
                                    <FormEdition
                                        agency={sousMandataire}
                                        agencyType="sousMandataire"
                                        addressChangeHandler={addressChangeHandler}
                                        subMandIndex={index}
                                        userAddHandler={userAddHandler}
                                        userUpdateHandler={userUpdateHandler}
                                        userDeleteHandler={userDeleteHandler}
                                        getAddressByid={getAddressByid}
                                        getAddressPrimaryAndSecondaryByid={getAddressPrimaryAndSecondaryByid}
                                        publicService={admData?.annonceur?.public_service}
                                    />
                                </div>
                            </div>
                        </div>
                            {
                                admData?.admObjects?.map((admObject, indexO) => (
                                    sousMandataire.uid === admObject.agencyUid && <div className="card border-light">
                                        <div className="card-header bg-transparent border-light" id="headingThree">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-toggle="collapse" data-target={"#collapseMandat" + indexO} aria-expanded="false" aria-controls="collapseMandat">
                                                    <span className="text-dark">{t('mandat:specificMandatePurposes', "Mandats / Missions particulières entre ")}</span>
                                                    {admData.mandataire?.commercial_name + ' /'}  {admObject.commercial_name}
                                                    {//getAgencyNameById(admObject.subproxy)
                                                    }
                                                    <ExpandMoreIcon className="admEdition__expand" />
                                                    <ChevronRightIcon className="admEdition__collapse" />
                                                </button>
                                            </h5>
                                        </div>
                                        <div id={"collapseMandat" + indexO} className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                            <div className="card-body bg-light p-1">
                                                <MandatEdition

                                                    admObject={admObject}
                                                    admOIndex={indexO}
                                                    documentUid={admData.uid}
                                                    advertiserUid={admData.annonceur.uid}
                                                    isMandataire={false}
                                                    period={formatDate(+admData?.start_period) + "-" + formatDate(+admData?.end_period)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    ))
                }


                <div className="card border-light">
                    <div className="card-header bg-transparent border-light d-flex justify-content-between align-items-center" id="headingThree">
                        <h5 className="mb-0">
                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseClauses" aria-expanded="false" aria-controls="collapseClauses">
                                <span className="text-dark">{t('default:clauses', "Clauses")}</span>
                                <ExpandMoreIcon className="admEdition__expand" />
                                <ChevronRightIcon className="admEdition__collapse" />
                            </button>
                        </h5>
                        <AddBoxIcon
                            className="admEdition__addClauseIcon"
                            onClick={() => setAddClauseShow(true)}
                        />
                        {
                            addClauseShow ? (
                                <AddClauseModal
                                    show={addClauseShow}
                                    handleClose={() => setAddClauseShow(false)}
                                    clausesLength={admData?.clauses?.length}
                                    clauses={admData?.clauses}
                                    addClause={addClauseHandler}
                                    agencyUid={admData?.mandataire?.uid}
                                    lang={(admData?.lang === 'FR' || admData?.lang === 'EN') ? admData?.lang : 'FR'}
                                />
                            ) : null
                        }
                    </div>
                    <div id="collapseClauses" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                        <div className="card-body bg-light p-1">
                            {
                                admData?.clauses && admData?.clauses.length > 0 ? (
                                    <MandatClauses
                                        agency={admData.mandataire.uid}
                                        clauses={admData?.clauses}
                                        updateClauses={updateClausesHandler}
                                        deleteClause={deleteClauseHandler}
                                        canEdit={true}
                                    />
                                ) : null
                            }

                        </div>
                    </div>
                </div>
                {/* <div class="card border-light">
                    <div class="card-header bg-transparent border-light" id="headingThree">
                    <h5 class="mb-0">
                        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseComplements" aria-expanded="false" aria-controls="collapseComplements">
                            <span className="text-dark">Compléments (Groupe d'annonceurs)</span>
                        </button>
                    </h5>
                    </div>
                    <div id="collapseComplements" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                    <div class="card-body bg-light p-1">
                        Accordion details - C
                    </div>
                    </div>
                </div> */}

            </div>
            <div className="admEdition__actionsContainer" style={{ borderTop: "1px solid  lightgray" }}>
                <div className="admEdition__actionsLeft">
                    <button type="button" className="btn btn-outline-secondary btn-sm" data-toggle="modal" onClick={resetListSubmandataires} data-target="#subMandataireModal">{t("mandat:addSubAgency")}</button>
                </div>
                <div className="admEdition__actionsRight">
                    {
                        updateLoading ?
                            <Spinner animation="border" variant="primary" />
                            :
                            <button
                                type="button"
                                className="btn btn-outline-secondary btn-sm saveBtn mr-2"
                                onClick={() => updateMandatClickHandler()}
                                disabled={isMandatUpdated()}
                            >{t('default:save', "Sauvegarder")} </button>
                    }
                    {/* <button 
                        type="button" 
                        className="btn btn-primary btn-sm" 
                        onClick={() => generateAdmPdf(uid) } >
                            {t('mandat:generateAdm', "Générer MyMandat")}
                    </button> */}
                </div>
            </div>
            <SubMandataireModal
                subAgencyAddHandler={subAgencyAddHandler}
                sousMandataires={admData?.sousMandataires}
                mandataireUid={admData?.mandataire?.uid}
                uncheckEntity={uncheckEntity}
                setUncheckEntity={setUncheckEntity}
            />
        </div>
    )
}

const mapStateToProps = (state) => ({
    admData: state.mandat.admData,
    admUpdate: state.mandat.admUpdate,
    updateLoading: state.mandat.updateLoading
})
const mapDispatchToProps = dispatch => ({
    updateMandatData: (admNewData) => dispatch(actions.updateAdmData(admNewData)),
    updateAdmAddresses: (entityUid, addressField, addressValue) => dispatch(actions.updateAdmAddresses(entityUid, addressField, addressValue)),
    updateMandat: (mandatUid, toUpdateData, callback) => dispatch(actions.updateMandat(mandatUid, toUpdateData, callback)),
    addMandatuser: (user) => dispatch(actions.addMandatuser(user)),
    addMandatUserToUpdate: (user) => dispatch(actions.addMandatUserToUpdate(user)),
    updateMandatUserToAdd: (entityUid, uid, field, value) => dispatch(actions.updateMandatUserToAdd(entityUid, uid, field, value)),
    updateMandatUserToUpdate: (d2uUid, field, value) => dispatch(actions.updateMandatUserToUpdate(d2uUid, field, value)),
    deleteMandatUser: (d2uUid) => dispatch(actions.deleteMandatUser(d2uUid)),
    deleteMandatUserToAdd: (entityUid, uid) => dispatch(actions.deleteMandatUserToAdd(entityUid, uid)),
    addMandatSubagency: (entityUid) => dispatch(actions.addMandatSubagency(entityUid)),
    deleteMandatSubAgency: (entityUid) => dispatch(actions.deleteMandatSubAgency(entityUid)),
    deleteMandatSubAgencyToAdd: (entityUid) => dispatch(actions.deleteMandatSubAgencyToAdd(entityUid)),
    updateMandatAdmObjectsAgency: (admOUid, agencyUid) => dispatch(actions.updateMandatAdmObjectsAgency(admOUid, agencyUid)),
    updateMandatObjects: (admOUid, object) => dispatch(actions.updateMandatObjects(admOUid, object)),
    addMandatAdmObjects: (admOUid, agencyUid, purposesObject) => dispatch(actions.addMandatAdmObjects(admOUid, agencyUid, purposesObject)),
    deleteMandatAdmObjects: (entityUid) => dispatch(actions.deleteMandatAdmObjects(entityUid)),
    deleteMandatProduct: (admOUid, product) => dispatch(actions.deleteMandatProduct(admOUid, product)),
    updateMandatClauses: (clausesToUpdate) => dispatch(actions.updateMandatClauses(clausesToUpdate)),
    deleteMandatClause: (clausesToDelete) => dispatch(actions.deleteMandatClause(clausesToDelete)),
    updateAdmPayer: (target, ispayer) => dispatch(actions.updateAdmPayer(target, ispayer)),
    updateMandatAdmObject: (admOUid, field, value) => dispatch(actions.updateMandatAdmObject(admOUid, field, value)),
    updateMandatDepartments: (departments) => dispatch(actions.updateMandatDepartments(departments)),
    updateMandatShowCommercialName: (showCommercialName) => dispatch(actions.updateMandatShowCommercialName(showCommercialName))

})
export default connect(mapStateToProps, mapDispatchToProps)(ADMEdition)
