import React, { useEffect, useRef, useState } from 'react'
import './SignaturesDropdown.css';
import { NavLink, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../../../../store/actions/index';
import PersonIcon from '@material-ui/icons/Person';
import { useTranslation } from 'react-i18next';

function SignaturesDropdown({show, setShow, zIndex, userSignatures, setSelectedSignature}) {
    const divRef = useRef();
    const {t} = useTranslation();

    const clickOutSideHandler = e => {
        if(divRef.current.contains(e.target)) {
            return
        }
        setShow(false)
    }

    useEffect(() => {
        document.addEventListener("mousedown", clickOutSideHandler);

        return () => {
            document.removeEventListener("mousedown", clickOutSideHandler);
        }
    }, [])

    const selectSignatureClickHandler = (imageData) => {
        setSelectedSignature(imageData)
        setShow(false)
    }

    return (
        <div ref={divRef} className="SignaturesDropdown" style={{zIndex: zIndex, display: show ? "flex" : "none"}}>
            <h5 
                style={{
                    padding: "0.5rem",
                    margin: "0", 
                    fontSize: "0.8rem", 
                    fontWeight: "bold"
                }}>Signatures :</h5> 
            <div className="signatureDD_signaturesList_container">
               {
                    userSignatures && userSignatures?.length > 0 ? userSignatures?.map(signature => (
                        <div 
                            className="signatureList_item"
                            onClick={() => selectSignatureClickHandler(signature?.imageData)}
                        >
                            <img src={signature?.imageData} width="100" height="100" />
                        </div>
                    )) : <p>Aucune</p>
                } 
            </div>
            
        </div>
    )
}
const mapStateToProps = state => {
    return {
        isAuthenticated : state.auth.token !== null,
        login : state.auth.login
    }
} 

const mapDispatchToProps = dispatch => {
    return {
        onAuth : (email, password) => dispatch(actions.auth(email, password)),
        onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/')),
        logout: () => dispatch(actions.logout())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignaturesDropdown)
