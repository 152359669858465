import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function NoPayerMissionModal({show, handleClose, params, updateMissionHandler}) {
 
    const updateClickHandler = () => {         
        updateMissionHandler(params.mission, params.section, params.checked)
        handleClose();
    }

    const {t} = useTranslation();  
    return (
        <>
            <Modal show={show} onHide={handleClose} contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                <Modal.Title>{t('default:alert', "Alerte")}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="ADMCreationModal__body" style={{width: "650px"}}>
                    <p> 
                       {t("mandat:confirmNoPayerMission", "Attention vous avez choisi de créer cette attestation « Mandataire payeur ». En décochant cette case , vous devez ")}  
                       <div style = {{paddingLeft:"25px"}}> 
                        <ul> 
                            <li> { t("mandat:confirmNoPayerMissionLine1", "soit avoir ou créer au moins un Sous-Mandataire payeur")}</li>
                            <li> { t("mandat:confirmNoPayerMissionLine2", "soit changer le mandat en mettant « Mandataire payeur » à Non. ")}</li>
                        </ul> 
                        </div>  
                    </p>   
                </Modal.Body>
                <Modal.Footer> 
                <Button variant="secondary" onClick={handleClose}>
                    {t('default:cancel', "Annuler")}
                </Button>  
                <Button variant="primary" onClick={() => updateClickHandler()}>                     
                    {t('default:confirm', "Confirmer")}
                </Button> 
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default NoPayerMissionModal
