import React, { useEffect, useState } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CachedIcon from '@material-ui/icons/Cached';
import {formatDate, getTimeDate} from '../../../../../../../shared/utility';
import { useTranslation } from 'react-i18next';

function UserSignedOfflineReplacedEvent({isCreator, eventData, isLast}) {
    const [showContent, setShowContent] = useState(false)
    const {t} = useTranslation();

    useEffect(() => {
        if(isLast)
            setShowContent(true)
        else
            setShowContent(false)
    }, [isLast])

    return (
        <div className="timelineCard__container">
            <div className="timelineCard__hideicon" onClick={() => setShowContent(!showContent)}>
                {
                    showContent ? 
                        <ExpandMoreIcon />
                    :
                        <ChevronRightIcon />
                }
            </div>
            <div className="timelineCard__sideContainer">
                <div className="timelineCard__header">
                    <CachedIcon style={{width: "24px", height: "24px", borderRadius: "5px",backgroundColor:"#ffbc47", color:"#fff8d7" }}  />
                    <div className="timelineCard__header_label">
                        {t('timeline:signedOfflineReplaced', "Intégration du PDF de l'ADM par un administrateur Edipub")} 
                    </div>
                    <div className="timelineCard__header_time">
                        <span className="timelineCard__header_hour"> {getTimeDate(+eventData?.creationDate)}</span> 
                        <span className="timelineCard__header_date"> {formatDate(+eventData?.creationDate)}</span> 
                    </div>
                </div>
                <div className="timelineCard__content" style={{borderLeft: "2px solid rgb(0, 112, 210)",  display: showContent ? "block" : "none"}}>
                    <div className="timelineCard__content__info">
                        <p>{eventData?.data?.first_name + ' ' + eventData?.data?.last_name + ' ' + t('timeline:signedOfflineReplacedByUser', "(administrateur Edipub) a intégré un PDF post-signature, à la demande du mandataire")}</p>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default UserSignedOfflineReplacedEvent