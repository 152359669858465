import React, { useState } from 'react'
import './TableBody.css'
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import DetailModal from './DetailModal/DetailModal';

function TableBody(props) {
    const { t } = useTranslation();
    const { data, formatDate } = props;
    const userRole = localStorage.getItem('role');
    const history = useHistory();
    const [detailShow, setDetailShow] = useState(false);
    const [clickedDocUid, setClickedDocUid] = useState(null);
    const [clickedMediaUid, setClickedMediaUid] = useState(null);

    const detailClickHandler = (uid, mediaUid) => {
        setClickedDocUid(uid)
        setClickedMediaUid(mediaUid)
        setDetailShow(true)
    }


    return (
        <>
            {
                data?.map(row => (
                    <tr key={row.id} className={row.media1 == null ? 'tableBody__tr notPublished' : 'tableBody__tr'} style={{ cursor: userRole !== 'RG' && userRole !== 'RC' ? 'pointer' : 'default' }}  >
                        <td onClick={() => history.push('/mandat/' + row.uid)}>{row?.id}</td>
                        <td onClick={() => history.push('/mandat/' + row.uid)} >{formatDate(+row.creationDate)}</td>
                        <td onClick={() => history.push('/mandat/' + row.uid)}>{row.mandataire}</td>
                        <td onClick={() => history.push('/mandat/' + row.uid)}>{row.annonceur}</td>
                        {
                            Object.entries(row)?.filter(([key, value]) => ((key.length >= "media".length && key.startsWith('media'))))
                                .map(([field, value]) => (
                                    <td key={field} onClick={() => detailClickHandler(row.uid, field.substring("media".length))}>{value == null ? '0' : value}</td>
                                ))
                        }
                    </tr>
                ))
            }
            {
                detailShow ? (
                    <DetailModal
                        show={detailShow}
                        handleClose={() => setDetailShow(false)}
                        docUid={clickedDocUid}
                        mediaUid={clickedMediaUid}
                    />
                ) : null
            }
        </>
    )
}

const mapStateToProps = (state) => ({
    isAuth: state.auth.token !== null,
    token: state.auth.token,
    role: state.auth.role
})
const mapDispatchToProps = dispatch => ({
})
export default connect(mapStateToProps, mapDispatchToProps)(TableBody)
