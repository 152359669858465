import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';
import { useTranslation } from 'react-i18next';
import Axios from '../../../../axios-edipub';

function CreatorModal({handleClose, show, mandatUid, admCreator, updateAdmCreator, mandataireUid}) {
    const {t} = useTranslation();
    const [creator, setCreator] = useState(admCreator)
    const [users, setUsers] = useState([])

    const addClickHandler = () => {
        updateAdmCreator(mandatUid, creator)
        handleClose();
    }
    
    const selectChangeHandler = e => {
        const value = e.target.value;
        setCreator(value)
    }

    useEffect(() => {
        Axios.get("/entity/"+mandataireUid+"/possibleCreators").then(response => {
            setUsers(response.data);
        })
    }, [])

    return (
        <>
            <Modal show={show} onHide={handleClose} contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                <Modal.Title>
                    {t('mandat:creatorModal_title', "Modification du responsable du mandat")} 
                </Modal.Title>
                </Modal.Header>
                <Modal.Body className="ADMCreationModal__body" style={{width: "650px"}}>
                    <div className="ADMCreationModal__description__container">
                        <h5>{t('default:creator', "Créator")}</h5>
                        <select className="form-control" value={creator} onChange={e => selectChangeHandler(e)}>
                            <option value="">{t('mandat:selectCreator', "Sélectionnez un nouveau responsable")}</option>
                            {
                                users?.sort((userA, userB) => userA.name?.toLowerCase().localeCompare(userB.name?.toLowerCase())).map(user => <option value={user.id}>{user.name + " (" + user.email + ")"}</option>)
                            }
                        </select>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t('default:cancel', "Annuler")}
                </Button>
                <Button variant="primary" onClick={() => addClickHandler()} disabled={creator === "" || creator === admCreator}>
                    {t('default:update', "Modifier")}
                </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
const mapStateToProps = (state) => ({
    mandatUid: state.mandat.admData?.uid,
    admCreator : state?.mandat?.admData?.id_creator,
    mandataireUid : state?.mandat?.admData?.mandataire?.uid
})
const mapDispatchToProps = dispatch => ({
    updateAdmCreator: (mandatUid, admCreator) => dispatch(actions.updateAdmCreator(mandatUid, admCreator)),
})
export default connect(mapStateToProps, mapDispatchToProps) (CreatorModal)