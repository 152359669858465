import React, { useEffect, useState } from 'react'
import './MessageEvent.css'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MailIcon from '@material-ui/icons/Mail';
import {formatDate, getTimeDate} from '../../../../../../../shared/utility';
import { useTranslation } from 'react-i18next';

function MessageEvent({isCreator, eventData, isLast, eventType}) {
    const [showContent, setShowContent] = useState(false)
    const {t} = useTranslation();

    useEffect(() => {
        if(isLast)
            setShowContent(true)
        else
            setShowContent(false)
    }, [isLast])

    return (
        <div className="timelineCard__container">
            <div className="timelineCard__hideicon" onClick={() => setShowContent(!showContent)}>
                {
                    showContent ? 
                        <ExpandMoreIcon />
                    :
                        <ChevronRightIcon />
                }
            </div>
            <div className="timelineCard__sideContainer">
                <div className="timelineCard__header">
                    <MailIcon style={{backgroundColor: "rgb(141, 151, 168)", color: "#FFFFFF", borderRadius: "5px", padding: "2px"}}  />
                    {
                        eventType === "POST_SIGN_CHAT" ?
                            <div className="timelineCard__header_label">{t("mandat:postSignChatTab", "Remarques post signature")}</div>
                        :
                            <div className="timelineCard__header_label">{t("mandat:chatTab", "Demande de modification")}</div>
                    }
                    <div className="timelineCard__header_time">
                        <span className="timelineCard__header_hour"> {getTimeDate(+eventData?.creationDate)}</span> 
                        <span className="timelineCard__header_date"> {formatDate(+eventData?.creationDate)}</span> 
                    </div>
                </div>
                <div className="timelineCard__content" style={{borderLeft: "2px solid rgb(141, 151, 168)",  display: showContent ? "block" : "none"}}>
                    <div className="timelineCard__content__info">
                        <span className="messageEvent__userFrom" >
                            {eventData?.data?.first_name + ' ' + eventData?.data?.last_name} 
                        </span>
                    </div>
                    <div className="timelineCard__content__body">
                        <div className="messageEvent__content__header">
                            <div className="messageEvent__header__name">
                                <div className="messageEvent__header__nameLabel">
                                    {t("default:name", "Nom")} 
                                </div>
                                <div className="messageEvent__header__nameValue">{eventData?.data?.first_name + ' ' + eventData?.data?.last_name}</div>
                            </div>
                            <div className="messageEvent__header__company">
                                <div className="messageEvent__header__companyLabel">
                                    {t("default:society", "Société")} 
                                </div>
                                <div className="messageEvent__header__companyValue">{eventData?.data?.entities}</div>
                            </div>
                        </div>
                        <div className="messageEvent__content__message">
                            <div className="messageEvent__message__label">
                                {t("default:message", "Message")}
                            </div>
                            <div className="messageEvent__message__value">
                                {eventData?.message?.slice(0, 128)}
                                {eventData?.message?.length > 128 ? '...' : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default MessageEvent