import React, { useEffect, useRef, useState } from 'react'
import './NotificationsDropdown.css';
import CheckIcon from '@material-ui/icons/Check';
import CreateIcon from '@material-ui/icons/Create';
import DescriptionIcon from '@material-ui/icons/Description';
import MailIcon from '@material-ui/icons/Mail';
import ShareIcon from '@material-ui/icons/Share';
import PersonIcon from '@material-ui/icons/Person';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import TamponIcon from '../../../assets/images/tampon.png'

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import NotificationCard from '../NotificationCard/NotificationCard';
import Axios from '../../../axios-edipub';
import {  relativeDate, eventHeaderFromKey } from '../../../shared/utility';
import ResignSignatureIcon from '../../../assets/images/resignsignatureIcon.png'
import { useTranslation } from 'react-i18next'
import LoopRoundedIcon from '@material-ui/icons/LoopRounded';

function NotificationsDropdown({userRole, handleEventsFilter, setSortOrder, sortOrder, showNotifications, setShowNotifications, zIndex, userEvents, nbUserEvents, handleDeleteEvent}) {
    const divRef = useRef();
    const {t} = useTranslation()
    const clickOutSideHandler = e => {
        if(divRef.current.contains(e.target)) {
            return
        }
        setShowNotifications(false)
    }

    useEffect(() => {
        document.addEventListener("mousedown", clickOutSideHandler);
        return () => {
            document.removeEventListener("mousedown", clickOutSideHandler);
        }
    }, [])

    return (
        <div className="">
            <div ref={divRef} className="homeNotifications" style={{zIndex: zIndex, display: showNotifications ? "flex" : "none"}}>
                <div className="homeNotifications__header">
                    <div className="homeNotifications__headerHead">
                        <div className="homeNotifications__headerLabel">
                            <NotificationsIcon
                                className="homeNotifications__headerLabelIcon"
                                style={{
                                    fontSize: "2rem"
                                }}
                            />
                            <h4>Notifications</h4>
                        </div>
                        <div className="homeNotifications__headerActions">
                            <ArrowDropDownIcon 
                                className="homeNotifications__headerActionsIcon"
                                style={{
                                    fontSize: "1.5rem"
                                }} 
                            />
                        </div>
                    </div>
                    <div className="homeNotifications__headerUnviewed">
                        {nbUserEvents} {t("notification:non traitées", "non traitées") }
                    </div>
                </div>
                {   
                    nbUserEvents > 0 ? (
                        <div className="homeNotifications__typeHeader"
                             onClick={()=> {
                                 setSortOrder(!sortOrder)
                                 handleEventsFilter()
                             }}>
                            { t("notification:PLUS RECENTES", "PLUS RECENTES") }
                            {
                                !sortOrder ? (
                                    <ArrowDownwardIcon
                                        style={{
                                            fontSize: "1rem",
                                            color: "darkslategray",
                                            marginLeft: "10px"
                                        }} />
                                ):(
                                    <ArrowUpwardIcon
                                        style={{
                                            fontSize: "1rem",
                                            color: "darkslategray",
                                            marginLeft: "10px"
                                        }}
                                    />
                                )
                            }

                        </div>
                     ) : ""
                } 

                <div className="homeNotifications__lists">
                {
                    userEvents?.map(event => (
                        <NotificationCard 
                            key={event.uid}
                            icon={
                                  (event.type == "USER_CONTRIBUTOR_REMINDER") ? (
                                      <LoopRoundedIcon className="homeNotifications__elementIcon"/>
                                ):(event.type == "USER_SIGNATURE_REMINDER") ? (
                                   <LoopRoundedIcon className="homeNotifications__elementIcon"/>
                                ) :(event.type == "SIGNATORIES_CHANGED") ? (
                                    <LoopRoundedIcon className="homeNotifications__elementIcon"/>
                                 ) :(event.type == "STEP_CHANGED") ? (
                                    <CreateIcon className="homeNotifications__elementIcon"/>
                                ) : ( event.type == "STEP_SIGNATURE" || event.type == "USER_SIGNATURE" ) ? (
                                    <CheckIcon className="homeNotifications__elementIcon" style={{backgroundColor: "rgb(80, 204, 122)"}} />
                                ): (event.type == "USER_SIGNATURE_RESIGN") ? (
                                    <img src={ResignSignatureIcon} style={{width: "18px", height: "18px", color: "#FFFFFF", borderRadius: "5px", marginRight: "5px"}}  />
                                ): (event.type == "STEP_CREATION") ? (
                                    <DescriptionIcon className="homeNotifications__elementIcon" style={{backgroundColor: "rgb(0, 112, 210)"}}  />
                                ): ( event.type == "USER_CHAT_EVENT" || event.type === "POST_SIGN_CHAT" ) ? (
                                    <MailIcon className="homeNotifications__elementIcon" style={{backgroundColor: "rgb(33, 219, 69)"}}  />
                                ): ( event.type == "STEP_SHARED") ? (
                                    <ShareIcon className="homeNotifications__elementIcon" style={{backgroundColor: "rgb(129, 176, 255)"}}  />
                                ) : (event.type == "STEP_VALIDATION") ? ( 
                                    <CheckIcon className="homeNotifications__elementIcon" style={{backgroundColor: "rgb(80, 204, 122)"}} />
                                ) : (event.type == "CREATOR_CHANGED") ? (
                                    <PersonIcon className="homeNotifications__elementIcon" style={{backgroundColor: "rgb(80, 204, 122)"}} />
                                ) :(event.type == "USER_DELETED") ? (
                                    <img src={ResignSignatureIcon} style={{width: "1.4em", height: "1.3em", color: "#FFFFFF", borderRadius: "5px", marginRight: "4px"}}  />
                                    // <PersonIcon className="homeNotifications__elementIcon" style={{backgroundColor: "rgb(80, 204, 122)"}} />
                                ) :(event.type == "SUPPORT_TRANSFER") ? (
                                    <PriorityHighIcon className="homeNotifications__elementIcon" style={{backgroundColor: "rgb(200, 54, 12)"}} />
                                ) :(event.type == "USER_VALIDATION") ? (
                                    <img src={TamponIcon} style={{width: "1.5em", height: "1.5em", color: "#FFFFFF", borderRadius: "5px", marginRight: "4px"}}  />
                                ) :(event.type == "RECIPIENTS_CHANGED") ? (
                                    <button className="destination_button green_back"><i className="fas fa-eye"></i></button>
                                ) :(event.type == "DOCUMENT_CANCELLED") ? (
                                    <CheckIcon className="homeNotifications__elementIcon" style={{backgroundColor: "rgb(80, 204, 122)"}} />
                                ) :  "" 
                            }
                            label={ t("notification:"+eventHeaderFromKey(event.type).label, eventHeaderFromKey(event.type).label)   }
                            adm={event.description}
                            docUid={event.docUid}
                            docId={event.docId}
                            company={event.official_name}
                            message={
                                "USER_CONTRIBUTOR_REMINDER".includes(event.type)?"Relance approbation":
                                 "USER_SIGNATURE_REMINDER".includes(event.type)?  "Relance"  :
                                !"RECIPIENTS_CHANGED, CREATOR_CHANGED".includes(event.type) ? ( event.message 
                                            ? event.message 
                                            : t('notification:'+eventHeaderFromKey(event.type).message, 
                                                    eventHeaderFromKey(event.type).message))
                                            :
                                            (t('notification:'+eventHeaderFromKey(event.type).message, 
                                                    eventHeaderFromKey(event.type).message)
                                    )
                            }
                            eventId={event.e_userId} 
                            time={ relativeDate(+event.creationDate)}
                            deleteEvent={handleDeleteEvent}
                            creator={event.creator_fname+" "+event.creator_lname}
                            contributors={event?.contributors}
                            signataires={event?.signataires}
                            userRole={userRole}
                            >
                        </NotificationCard>
                    ))
                }

                </div>
            </div>
        </div>
        
    )
}


export default NotificationsDropdown
