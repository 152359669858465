import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap';
import {uuidv4} from '../../../../../../../../shared/utility'
import Axios from '../../../../../../../../axios-edipub';
import "./AddClauseModal.css";
import { useTranslation } from 'react-i18next';

function AddClauseModal({handleClose, show, addClause, clausesLength, agencyUid, clauses, lang}) {
    const [content, setContent] = useState("")
    const [availableClauses, setAvailableClauses] = useState([])
    const [canAdd, setCanAdd] = useState(false)
    const {t} = useTranslation();

    const addClickHandler = () => {
        let clauseToAdd = {
            uid: uuidv4(),
            label : content,
            orderIndex : clausesLength,
            mandatory : 0
        }
        addClause(clauseToAdd)
        handleClose()
    }

    const addExistingClickHandler = clause => {
        clause.orderIndex = clausesLength;
        clause.new = true;
        addClause(clause);
        handleClose();
    }

    useEffect(() => {
        if(agencyUid) {
            const clausesFilter = clauses.map(c => "'"+c.uid+"'").join(", ");
            let params = {};
            params.lang = lang;
            if(clausesFilter) 
                params.clauses = clausesFilter;
            Axios.get("entity/"+agencyUid+"/clauses", {'params' : params }).then(response => {
                setAvailableClauses(response.data);
            }) 
        }
    }, [])

    useEffect(() => {
        if (content) {
            const exists = clauses.find(c => {return c.label.replaceAll(" ", "").replaceAll("\r\n", "").toUpperCase() === content.replaceAll(" ", "").replaceAll("\r\n", "").toUpperCase()});
            setCanAdd(!exists);
        } else
            setCanAdd(false);
    }, [content])

    return (
        <>
            <Modal show={show} onHide={handleClose} contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                <Modal.Title>{t('mandat:addClause', "Ajouter une clause")} </Modal.Title>
                </Modal.Header>
                <Modal.Body className="ADMCreationModal__body">
                    {availableClauses?.length > 0 && <div className="ADMCreationModal__description__container" style={{marginBottom: "15px"}}>
                        <h5>{t("mandat:existedClauses", "Clauses pré-existantes")}</h5>
                        <table className="ADMCreationModal__existingTable">
                            <tbody>
                                {availableClauses.map(c => (<tr key={c.uid}>
                                    <td>{c.label}</td>
                                    <td>
                                        <button 
                                            className="btn btn-primary btn-sm" 
                                            onClick={e => addExistingClickHandler(c)}>
                                                {t("default:add","Ajouter")}
                                        </button>
                                    </td>
                                </tr>))}
                            </tbody>
                        </table>
                    </div>}
                    <div className="ADMCreationModal__description__container">
                        <h5>{t("default:content","Contenu")}</h5>
                        <textarea 
                            className="form-control" 
                            name="content"
                            rows="7"
                            maxLength="1024"
                            value={content || ''}
                            onChange={(e) => setContent(e.target.value)}
                        >
                        </textarea>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t('default:cancel', "Annuler")}
                </Button>
                <Button variant="primary" onClick={() => addClickHandler()} disabled={!canAdd}>
                    {t('default:add', "Ajouter")}
                </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddClauseModal
