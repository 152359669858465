import './CreateEntityComponent.css'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { getNotyfObject, uuidv4 } from '../../../shared/utility';
import { useTranslation } from 'react-i18next';
import Axios from '../../../axios-edipub';
import MCompanyForm from '../../../components/TokenAccess/CreateEntity/MCompanyForm/MCompanyForm';
import MUsersForm from '../../../components/TokenAccess/CreateEntity/MUsersForm/MUsersForm';
import MBrandsForm from '../../../components/TokenAccess/CreateEntity/MBrandsForm/MBrandsForm';

function CreateEntityComponent({ isAuth, uid, handleClose, parentRefreshContent }) {

    let { token } = useParams();
    const { t, i18n } = useTranslation();
    let notyf = getNotyfObject();
    const [errorMesssage, setErrorMessage] = useState(null);
    const [putLoading, setPutLoading] = useState(false);
    const TYPE_USERS = "utilisateurs";
    const TYPE_COMPANY = "entreprise";
    const TYPE_PRODUCTS = "products";
    const TYPE_END = "end";
    const [typeMasterData, setTypeMasterData] = useState(TYPE_COMPANY)
    const [manualEntityToken, setManualEntityToken] = useState()

    const getTokenData = () => {
        if (!isAuth) {
            Axios.get(`/manualEntityToken/${token}/checkNewTokenExists`).then(response => {
                let manualEntityTokenTemp = response.data
                if (!manualEntityTokenTemp.entity_address_hq_iso_country)
                    manualEntityTokenTemp.entity_address_hq_iso_country = 'FR'
                if (!manualEntityTokenTemp.entity_address_invoice_iso_country)
                    manualEntityTokenTemp.entity_address_invoice_iso_country = 'FR'
                if (!manualEntityTokenTemp.entity_address_mail_iso_country)
                    manualEntityTokenTemp.entity_address_mail_iso_country = 'FR'
                if (manualEntityTokenTemp.users.length == 0)
                    manualEntityTokenTemp.users = [{ "new": true, "uid": uuidv4(), "role": "AC", "function_label": "", "errors": [] }]
                if (manualEntityTokenTemp.lang === 'FR')
                    i18n.changeLanguage('fr');
                else if (manualEntityTokenTemp.lang === 'EN')
                    i18n.changeLanguage('en');
                setManualEntityToken(manualEntityTokenTemp)
            }).catch(error => {
                setErrorMessage(t("manualEntity:errorMessage"))
            })
        } else {
            Axios.get(`/manualEntityToken/${uid}/manualEntityByUid`).then(response => {
                setManualEntityToken(response.data)
            }).catch(error => {
                setErrorMessage(t("manualEntity:errorMessage"))
            })
        }
    }

    useEffect(() => {
        getTokenData()
    }, [token, uid])


    const setEntity = (entity) => {
        let manualEntityTokenTemp = { ...entity }
        setManualEntityToken(manualEntityTokenTemp);
    }

    const setUsers = (users) => {
        let manualEntityTokenTemp = {
            ...manualEntityToken,
            'users': [...users]
        }
        setManualEntityToken(manualEntityTokenTemp);
    }

    const setBrands = (brands) => {
        let brandsTemp = JSON.parse(JSON.stringify(brands));
        let manualEntityTokenTemp = {
            ...manualEntityToken,
            'brands': brandsTemp
        }
        setManualEntityToken(manualEntityTokenTemp);
    }

    const updateManualEntityToken = (e) => {
        e.preventDefault();
        setPutLoading(true)
        Axios.put('manualEntityToken/' + manualEntityToken.uid + '/manualEntityToken', manualEntityToken).then(response => {
            setPutLoading(false)
            setTypeMasterData(TYPE_END)
            notyf.success(t("default:updateEntity"))
        }).catch(response => {
            setPutLoading(false)
            notyf.error(t("mandat:generatedErrorMessage"))
        })
    }

    const createMasterData = (e) => {
        e.preventDefault();
        setPutLoading(true)
        Axios.put('manualEntityToken/' + manualEntityToken.uid + '/saveToMasterData', manualEntityToken).then(response => {
            setPutLoading(false)
            notyf.success(t("default:updateEntity"))
            if (isAuth) {
                handleClose();
                parentRefreshContent()
            }
        }).catch(response => {
            setPutLoading(false)
            notyf.error(t("mandat:generatedErrorMessage"))
        })
    }

    const moveNext = (e) => {
        switch (typeMasterData) {
            case TYPE_COMPANY:
                setTypeMasterData(TYPE_USERS)
                break;
            case TYPE_USERS:
                setTypeMasterData(TYPE_PRODUCTS)
                break;
            case TYPE_PRODUCTS:
                if (!isAuth) {
                    updateManualEntityToken(e);
                } else {
                    createMasterData(e);
                }
                break;
            default:
                break;
        }
    }

    const moveBack = () => {
        switch (typeMasterData) {
            case TYPE_USERS:
                setTypeMasterData(TYPE_COMPANY)
                break;
            case TYPE_PRODUCTS:
                setTypeMasterData(TYPE_USERS)
                break;
            default:
                break;
        }
    }

    return (
        <>
            {manualEntityToken &&
                <div>
                    <>
                        {typeMasterData === TYPE_COMPANY ?
                            (
                                <MCompanyForm
                                    entity={manualEntityToken}
                                    setEntity={setEntity}
                                    moveNext={moveNext}
                                    isAuth={isAuth}
                                />
                            ) : typeMasterData === TYPE_USERS ?
                                (
                                    <MUsersForm
                                        users={manualEntityToken.users}
                                        setUsers={setUsers}
                                        moveNext={moveNext}
                                        moveBack={moveBack}
                                        isAuth={isAuth}
                                    />

                                ) : typeMasterData === TYPE_PRODUCTS ?
                                    (
                                        <MBrandsForm
                                            brands={manualEntityToken.brands}
                                            setBrands={setBrands}
                                            moveNext={moveNext}
                                            moveBack={moveBack}
                                            loading={putLoading}
                                            isAuth={isAuth}
                                        />
                                    ) :
                                    typeMasterData === TYPE_END ?
                                        (
                                            <div className='savedToDbMessage'>
                                                {t("manualEntity:savedToDbMessage")}
                                            </div>
                                        ) : null
                        }
                    </>
                </div>
            }
            {errorMesssage &&
                <div >
                    <strong>{errorMesssage} </strong>
                </div>
            }
        </>
    )
}


export default CreateEntityComponent
