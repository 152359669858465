import React, { useEffect, useState } from 'react'
import './ADMMain.css';
import ADMView from './ADMView/ADMView';
import ADMEdition from './ADMEdition/ADMEdition';
import ADMSignature from './ADMSignature/ADMSignature';
import ADMContributeurs from './ADMSignature/ADMContributeurs';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';
import { useTranslation } from 'react-i18next';

function ADMMain({mandat, addressClick, tabActive, setTabActive, isCreator, role, admStatus}) { 
    const {t} = useTranslation();
    

    useEffect(() => {
        setTabActive(isCreator && (['DRAFT', 'TO_BE_APPROVED'].includes(admStatus)) ? "edit" : "mandat");
    }, [isCreator, admStatus])
    
    return ( 
        <div className= "mandatEdition">   
            <ul className="nav nav-tabs bg-light mandatEdition_navTabs">
                <li className="nav-item" onClick={() => setTabActive("mandat")}>
                    <a 
                        className={tabActive === "mandat" ? "nav-link active" : "nav-link " } 
                        data-toggle="tab" 
                        href="#Mandat"
                    >
                        {t('default:mandate', "Mandat")}
                    </a>
                </li>
                {
                    isCreator &&  (admStatus === "DRAFT" || admStatus === "TO_BE_APPROVED") ? (
                        <li className="nav-item" onClick={() => setTabActive("edit")}>
                            <a 
                                className={tabActive === "edit" ? "nav-link active" : "nav-link " } 
                                data-toggle="tab" 
                                href="#Edit"
                            >
                                {t('mandat:admEdition', "Editeur mandat")}
                            </a>
                        </li>
                    ) : null
                }
                {
                    isCreator && (admStatus === "TO_BE_SIGNED" || admStatus === "SIGNED"|| admStatus === "TO_BE_APPROVED" ) && mandat.hasContributors ? (
                        <li className="nav-item" onClick={() => setTabActive("contributeurs")}>
                            <a 
                                className={tabActive === "contributeurs" ? "nav-link active" : "nav-link " } 
                                data-toggle="tab" 
                                href="#contributeurs"
                            >
                                {t('default:contributors', "contributeurs")}
                            </a>
                        </li>
                    ) : null
                }
                {
                    isCreator && (admStatus === "TO_BE_SIGNED" || admStatus === "SIGNED") ? (
                        <li className="nav-item" onClick={() => setTabActive("signature")}>
                            <a 
                                className={tabActive === "signature" ? "nav-link active" : "nav-link " } 
                                data-toggle="tab" 
                                href="#Signature"
                            >
                                {t('default:signatories', "Signatures")}
                            </a>
                        </li>
                    ) : null
                }


                
            </ul>
            <div id="myTabContent" className="tab-content">
                <div className={tabActive === "mandat" ? "tab-pane fade show active" : "tab-pane fade" } id="Mandat">
                    <ADMView />
                </div>
                <div className={tabActive === "edit" ? "tab-pane fade show active" : "tab-pane fade" } id="Edit">
                    <ADMEdition 
                       
                        mandat={mandat}
                        addressClick={addressClick}
                        setTabActive = {setTabActive}
                    />
                </div>
                {
                    isCreator && (admStatus === "TO_BE_SIGNED" || admStatus === "SIGNED"|| admStatus === "TO_BE_APPROVED") && tabActive === "contributeurs" ? (
                        <div className={tabActive === "contributeurs" ? "tab-pane fade show active" : "tab-pane fade" } id="contributeurs">
                            <ADMContributeurs 
                                isCreator={isCreator}
                                mandatUid={mandat?.uid}
                                mandatid={mandat?.id}

                            />
                        </div>
                    ) : null
                }
                 {
                    isCreator && (admStatus === "TO_BE_SIGNED" || admStatus === "SIGNED" || admStatus === "TO_BE_APPROVED" ) && tabActive === "signature" ? (
                        <div className={tabActive === "signature" ? "tab-pane fade show active" : "tab-pane fade" } id="Signature">
                            <ADMSignature 
                                isCreator={isCreator}
                                mandatUid={mandat?.uid}
                                mandatid={mandat?.id}
                            />
                        </div>
                    ) : null
                }
                
            </div>

        </div>
        )      
    }
    
const mapStateToProps = (state) => ({
    tabActive: state.mandat.tabActive,
    admStatus: state.mandat.admData.status,
    role: state.auth.role,
    isCreator : state.auth?.userId === state.mandat?.admData?.creatorUid
})
const mapDispatchToProps = dispatch => ({
    setTabActive: (tabActive) => dispatch(actions.setTabActive(tabActive)),
})
export default connect(mapStateToProps, mapDispatchToProps) (ADMMain)

