import React, { useState } from 'react'
import './ADMTopCard.css';
import Axios from '../../../../axios-edipub';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';
// import ADMWorkFlow from '../ADMWorkFlow/ADMWorkFlow';
import _ from 'lodash';
import { Notyf } from 'notyf';
import { getNotyfObject, ucFirst } from '../../../../shared/utility';
import { useTranslation } from 'react-i18next';
import ConfirmStatusModal from './ConfirmStatusModal/ConfirmStatusModal';
import { Tooltip, withStyles } from '@material-ui/core';

const CTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 15,
    },
}))(Tooltip);

function ADMTopCard(props) {
    const {
        origine,
        status,
        role,
        admData,
        admUpdate,
        getMandatData,
        updateMandatStatus,
        updateMandatOffline,
        name,
        creationDate,
        isCreator,
        creatorName,
        advertiser,
        period,
        setTabSideActive
    } = props
    const { t } = useTranslation();
    let notyf = getNotyfObject();
    const [showDescModal, setShowDescModal] = useState(false)
    const [showPeriodModal, setShowPeriodModal] = useState(false)
    const [showADMWorkflow, setShowADMWorkflow] = useState(false)
    const [showCreatorModal, setShowCreatorModal] = useState(false)
    const [showResignConfirmModal, setShowResignConfirmModal] = useState(false)
    const [showAbondanConfirmModal, setShowAbondanConfirmModal] = useState(false)
    const [showConfirmStatusModal, setShowConfirmStatusModal] = useState(false)
    const [showOfflineConfirmModal, setShowOfflineConfirmModal] = useState(false)
    const [showTemplateConfirmModal, setShowTemplateConfirmModal] = useState(false)
    const [showChangePayerModal, setShowChangePayerModal] = useState(false)
    const [newStatus, setNewStatus] = useState(null)
    const [confirmMessage, setConfirmMessage] = useState(null)

    // const actorsClickHandler = () => {
    //     let mandatNotUpdated = _.isEqual(admUpdate, {
    //         addresses: {},
    //         usersToAdd: [],
    //         usersToUpdate: [],
    //         usersToDelete: [],
    //         subAgencyToAdd: [],
    //         subAgencyToDelete: [],
    //         admObjects: {},
    //         clauses: [],
    //         departmentsToAdd: [],
    //         departmentsToDelete: [],
    //         showCommercialNameToUpdate: []
    //     })
    //     if (mandatNotUpdated) {
    //         setShowADMWorkflow(!showADMWorkflow)
    //     } else {
    //         notyf.open({
    //             closeWith: ['click'],
    //             type: 'warning',
    //             background: 'orange',
    //             message: t("mandat:saveModificationsBeforeActors")
    //         });
    //     }
    // }

    const hasDesactivatedSignedUsers = () => {
        let desactivatedUsers = admData.annonceur.usersDoc.filter(u => u.status == "Desactivée").concat(admData.mandataire.usersDoc.filter(u => u.status == "Desactivée"))
        admData.sousMandataires.forEach(sousMandataire => {
            desactivatedUsers = desactivatedUsers.concat(sousMandataire.usersDoc.filter(u => u.status == "Desactivée"))
        });
        let tmp = desactivatedUsers.filter(u => u.is_signataire == "1")
        return tmp.length > 0
    }

    // const showToBeApprovedBtn = () => {
    //     if (isCreator && status === "TO_BE_SIGNED" && admData?.hasContributors && !hasDesactivatedSignedUsers()) {
    //         return true
    //     } else {
    //         return false
    //     }
    // }

    // const showToDraftBtn = () => {
    //     if (isCreator &&
    //         (status === "TO_BE_APPROVED" ||
    //             (status === "TO_BE_SIGNED" && (!admData?.hasContributors || (admData?.hasContributors && hasDesactivatedSignedUsers())))
    //         )) {
    //         return true
    //     } else {
    //         return false
    //     }
    // }

    // const showOfflineBtn = () => {
    //     if (isCreator && (status === "TO_BE_SIGNED") && admData?.mandataireUsersSigned == "1" && admData?.offline == "0") {
    //         return true
    //     } else {
    //         return false
    //     }
    // }

    // const showTemplateBtn = () => {
    //     if (isCreator && role === "MG" && (status === "DRAFT" || status === "TO_BE_APPROVED") && admData?.template === "0") {
    //         return true
    //     } else {
    //         return false
    //     }
    // }

    // const offlineAdm = () => {
    //     updateMandatOffline(admData?.uid, true)
    //     setTabSideActive("timeline")
    // }

    // const toDraftBtnClickHandler = () => {
    //     let confirmMsg = t("mandat:confirmDraftStatusMsg")
    //     setShowConfirmStatusModal(true)
    //     setConfirmMessage(confirmMsg)
    //     setNewStatus("DRAFT");
    // }

    // const toToBeApprovedBtnClickHandler = () => {
    //     let confirmMsg = t("mandat:confirmToBeApprovedStatusMsg")
    //     setShowConfirmStatusModal(true)
    //     setConfirmMessage(confirmMsg)
    //     setNewStatus("TO_BE_APPROVED");
    // }

    // const updateDocStatus = () => {
    //     Axios.put(`/document/${admData?.uid}/docStatus`, {
    //         status: newStatus
    //     }).then(response => {
    //         setNewStatus(null)
    //         getMandatData(admData?.uid)
    //     }).catch(err => {
    //         setNewStatus(null)
    //         if (err.response?.data?.error && (err.response?.data?.error === 'Expired token'))
    //             notyf.error('Une erreur s\'est produite ! Votre session a expiré. Veuillez vous déconnecter et vous reconnecter avant de renouveler l\'opération.')
    //         else
    //             notyf.error('Une erreur s\'est produite !')
    //     })
    // }
    // const confirmChangePayer = () => {
    //     const promise = new Promise((resolve, reject) => {
    //         Axios.put(`/document/${admData.uid}/payer`, showChangePayerModal)
    //             .then(response => {
    //                 setShowChangePayerModal(false)
    //                 getMandatData(admData.uid, true)
    //                 resolve(response);
    //             }).catch(err => {
    //                 setShowChangePayerModal(false)
    //                 if (err.response?.data?.error && (err.response?.data?.error === 'Expired token'))
    //                     notyf.error('Une erreur s\'est produite ! Votre session a expiré. Veuillez vous déconnecter et vous reconnecter avant de renouveler l\'opération.')
    //                 else
    //                     notyf.error('Une erreur s\'est produite !')
    //                 reject(err);
    //             })
    //     })
    //     return promise;
    // }

    // const confirmTemplate = () => {
    //     const promise = new Promise((resolve, reject) => {
    //         Axios.put(`/document/${admData.uid}/template`).
    //             then(response => {
    //                 getMandatData(admData.uid)
    //                 notyf.success('Template sauvegardé')
    //                 resolve(response);
    //             }).catch(err => {
    //                 if (err.response?.data?.error && (err.response?.data?.error === 'Expired token'))
    //                     notyf.error('Une erreur s\'est produite ! Votre session a expiré. Veuillez vous déconnecter et vous reconnecter avant de renouveler l\'opération.')
    //                 else
    //                     notyf.error('Une erreur s\'est produite !')
    //                 reject(err);
    //             })
    //     })
    //     return promise;
    // }

    return (
        <div className="MandatTopCard">
            <div className="MandatTopCard-div">
                <div className="MandatTopCard-attest">
                    <div className="attest-mandat">
                        <i className="fas fa-file-alt fa-3x" style={{ color: "rgb(77, 202, 118)", marginRight: "20px" }}></i>
                        <div className="">
                            <p>
                                {admData?.template === "1" ?
                                    ucFirst(t('template:template', 'Template').toLowerCase())
                                    : ucFirst(t('default:adm', "Attestation de mandat").toLowerCase())
                                }
                                <span style={{ color: "#0070D2", marginLeft: "0.25rem" }}>{name}</span> </p>
                            {admData?.duplicatedDocRef != null ?
                                <p>
                                    {admData?.duplicatedDocRefTemplate === "1" ?
                                        t('default:admTemplate', "Template du mandat")
                                        : t('default:originalAdm', "Mandat original")
                                    } : <span style={{ color: "#0070D2", marginLeft: "0.25rem" }}>{(admData?.duplicatedDocRefTemplate === '1' ? 'TM-' : 'AM-') + admData?.duplicatedDocRef}</span></p>
                                : ""
                            }
                            <div className="MandatTopCardTitleBold"><p style={{ fontWeight: "bold" }}></p></div>
                        </div>
                    </div>

                    <div className="workflow-buttons">
                        {/* {
                            showToDraftBtn() ?
                                <button
                                    type="button"
                                    className="btn btn-secondary btn-sm"
                                    style={{
                                        color: "#FFFFFF"
                                    }}
                                    onClick={() => toDraftBtnClickHandler()}
                                >
                                    {t('mandat:statusToDraft', "Passer en état 'Brouillon'")}
                                </button>
                                : null
                        } */}
                        {/* {
                            showToBeApprovedBtn() ?
                                <button
                                    type="button"
                                    className="btn btn-secondary btn-sm"
                                    style={{
                                        color: "#FFFFFF"
                                    }}
                                    onClick={() => toToBeApprovedBtnClickHandler()}
                                >
                                    {t('mandat:statusToToBeApproved', "Passer en état 'A approuver'")}
                                </button>
                                : null
                        } */}

                        {/* {
                            showConfirmStatusModal ? (
                                <ConfirmStatusModal
                                    show={showConfirmStatusModal}
                                    handleClose={() => setShowConfirmStatusModal(false)}
                                    validateFunc={() => updateDocStatus()}
                                    message={confirmMessage}
                                />
                            ) : null
                        } */}

                        {/* {
                            showOfflineBtn() ? (
                                <CTooltip title={t('mandat:startOfflineTooltip')} >
                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-sm"
                                        style={{
                                            color: "#FFFFFF"
                                        }}
                                        onClick={() => setShowOfflineConfirmModal(true)}
                                    >
                                        {t('mandat:startOffline', "Commencer la procédure 'offline'")}
                                    </button>
                                </CTooltip>
                            )
                                : null
                        } */}

                        {/* {
                            showOfflineConfirmModal ? (
                                <ConfirmOfflineModal
                                    show={showOfflineConfirmModal}
                                    handleClose={() => setShowOfflineConfirmModal(false)}
                                    offlineAdm={() => offlineAdm()
                                    }
                                />
                            ) : null
                        } */}

                        {/* {
                            showTemplateBtn() ? (
                                <CTooltip title={t('mandat:saveAsTemplateTooltip')} >
                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-sm"
                                        style={{
                                            color: "#FFFFFF"
                                        }}
                                        onClick={() => setShowTemplateConfirmModal(true)}
                                    >
                                        {t('mandat:saveAsTemplate', "Sauvegarder un modèle")}
                                    </button>
                                </CTooltip>
                            )
                                : null
                        } */}

                        {/* {
                            showTemplateConfirmModal ? (
                                <ConfirmStatusModal
                                    handleClose={() => setShowTemplateConfirmModal(false)}
                                    show={showTemplateConfirmModal}
                                    text={t("mandat:confirmTemplateModal")}
                                    okCallback={() => confirmTemplate()}
                                />
                            ) : null
                        } */}

                        {
                            (isCreator) && (["DRAFT", "TO_BE_APPROVED", "TO_BE_SIGNED"].includes(status)) && (admData?.template === "0") ?
                                <button
                                    type="button"
                                    className="btn btn-danger btn-sm"
                                    style={{
                                        color: "#FFFFFF"
                                    }}
                                    onClick={() => setShowAbondanConfirmModal(true)}
                                >
                                    {t('default:abandon', "Abandonner")}
                                </button>
                                : null
                        }

                        {
                            showAbondanConfirmModal ? (
                                <ConfirmStatusModal
                                    show={showAbondanConfirmModal}
                                    handleClose={() => setShowAbondanConfirmModal(false)}
                                    resignAdm={() => updateMandatStatus(admData?.uid, "ABANDONED")}
                                />
                            ) : null
                        }

                        {/* {
                            (isCreator || role === "MG") && (status === "SIGNED") ?
                                <button
                                    type="button"
                                    className="btn btn-danger btn-sm"
                                    style={{
                                        color: "#FFFFFF"
                                    }}
                                    onClick={() => setShowResignConfirmModal(true)}
                                >
                                    {t('default:CANCEL', "Résilier")}
                                </button>
                                : null
                        } */}

                        {/* {
                            showResignConfirmModal ? (
                                <ConfirmResignModal
                                    show={showResignConfirmModal}
                                    handleClose={() => setShowResignConfirmModal(false)}
                                    resignAdm={() => updateMandatStatus(admData?.uid, "CANCELLED")}
                                />
                            ) : null
                        } */}

                        {/* <button type="button" className="btn btn-light btn-sm" data-toggle="modal" data-target="#staticBackdrop">Acteurs</button> */}
                        {/* {
                            props.isCreator ? (
                                <button type="button" className="btn btn-light btn-sm" onClick={() => actorsClickHandler()} >Acteurs</button>
                            ) : null
                        } */}
                        {/* {
                            showADMWorkflow ? (
                                <ADMWorkFlow
                                    show={showADMWorkflow}
                                    handleClose={() => setShowADMWorkflow(false)}
                                    getMandatData={getMandatData}
                                    mandat={admData}
                                />
                            ) : null
                        } */}
                        {/* <div className="btn-group" role="group">
                            <button type="button" className="btn btn-light btn-sm" data-toggle="modal" data-target="#staticBackdrop">Workflow</button>
                            <div className="btn-group" role="group">
                                <button id="btnGroupDrop1" type="button" style={{padding: "5px 10px 5px 10px"}} className="btn btn-light btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                                <div className="dropdown-menu" aria-labelledby="btnGroupDrop1" >
                                    <a className="dropdown-item" href="#">Dropdown link</a>
                                </div>
                            </div>
                        </div> */}


                    </div>

                </div>

                <div className="Mandat-details">
                    <div className="description-spec">
                        <p className="ADMTopCard__item__label">
                            {t('default:creationDate', "Date de création")}
                        </p>
                        <strong >{creationDate}</strong>
                    </div>
                    <div className="description-spec">
                        <p className="ADMTopCard__item__label">
                            {t('default:description', "Description")}
                            {/* {
                                isCreator && status !== "CANCELLED" ? (
                                    <EditIcon
                                        className="ADMTopCard__editIcon"
                                        onClick={() => setShowDescModal(!showDescModal)}
                                    />
                                ) : null
                            } */}
                        </p>
                        <strong className="ADMTopCard__item__label_Description">{admData?.description}</strong>
                    </div>
                    <div className="description-spec">
                        <p className="ADMTopCard__item__label">
                            {t('default:creator', "Responsable")}
                        </p>
                        <strong >{admData?.creatorName}</strong>
                    </div>
                    <div className="annonceur-spec">
                        <p className="ADMTopCard__item__label">
                            {t('default:advertiser', "Annonceur")}
                        </p>
                        <strong>{admData?.annonceur?.official_name}</strong>
                    </div>
                    <div className="periode-spec">
                        <p className="ADMTopCard__item__label">
                            {t('default:period', "Période")}
                        </p>
                        <strong>{period}</strong>
                    </div>
                    <div className="description-spec">
                        <p className="ADMTopCard__item__label">
                            {t('default:Payer', "Mandataire payeur")}
                        </p>
                        <p> {+admData?.mandataire?.payer == 1 ? t("default:yes", "Oui") : t("default:no", "Non")}  </p>
                        {/* {isCreator && (status === "TO_BE_APPROVED" || status === "DRAFT") ? <select value={admData.mandataire.payer || ""} style={{ width: "50%", fontWeight: "bolder" }} onChange={(e) => setShowChangePayerModal(e.target.value)}>
                            {!admData.mandataire.payer && <option value=""></option>}
                            <option value={1}>{t("default:yes", "Oui")}</option>
                            <option value={0}>{t("default:no", "Non")}</option>
                        </select> : <strong className="ADMTopCard__item__label_Description">{admData?.mandataire?.payer === "1" ? "Oui" : (admData?.mandataire?.payer === "0" ? "Non" : "")}</strong>} */}

                    </div>
                </div>

            </div>
        </div>
    )
}
const mapStateToProps = (state) => ({
    status: state.mandat?.admData?.status,
    role: state.auth.role,
    isCreator: state.auth?.userId === state.mandat?.admData?.creatorUid,
    // creatorName: state.mandat?.admData?.creatorName,
    // admData: state.mandat.admData,
    // admUpdate: state.mandat.admUpdate
})
const mapDispatchToProps = dispatch => ({
    // getMandatData: (mandatUid, generatePdf = false) => dispatch(actions.getMandatData(mandatUid, generatePdf)),
    // updateMandatStatus: (mandatUid, status) => dispatch(actions.updateMandatStatus(mandatUid, status)),
    // updateMandatOffline: (mandatUid, offline) => dispatch(actions.updateMandatOffline(mandatUid, offline)),
    // setTabSideActive: (tabSideActive) => dispatch(actions.setTabSideActive(tabSideActive)),

})
export default connect(mapStateToProps, mapDispatchToProps)(ADMTopCard)

