import React, { useState } from 'react'
import { Notyf } from 'notyf';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function MandatNotFind({handleClose, show}) {
    const {t} = useTranslation();

 

    return (
        <>
            <Modal 
                show={show} 
                onHide={handleClose} 
                //contentClassName="modal_w_mc" 
            >
                <Modal.Header closeButton>
                <Modal.Title>{t('default:alert', "Alerte")} </Modal.Title>
                </Modal.Header>
                <Modal.Body className="ResignReasonModal__body" >
                    <div className="ResignReasonModal__motif__container">
                        <h5>{t('default:admNotFind', "Vous ne disposez pas des droits nécessaires pour consulter cette page, contactez votre gestionnaire si vous pensez devoir y avoir accès")} </h5>
                      
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t('default:cancel', "Annuler")}  
                </Button>
              
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default MandatNotFind
