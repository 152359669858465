import React, { useEffect, useState } from 'react'
import './DeleteUserModal.css'
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Axios from '../../../../axios-edipub';
import { Tooltip, withStyles } from '@material-ui/core';
import { getNotyfObject } from '../../../../shared/utility';
import Spinner from 'react-bootstrap/Spinner';
import DeleteUserModalConfirmation from './DeleteUserModalConfirmation/DeleteUserModalConfirmation';

const CTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 15,
    },
}))(Tooltip);

function DeleteUserModal({ handleClose, show, deleteUser, userUid }) {
    const { t } = useTranslation();
    const [agenciesCreatorsList, setAgenciesCreatorsList] = useState(null)
    const [agenciesCreator, setAgenciesCreator] = useState(null)
    const [agenciesValidatorsList, setAgenciesValidatorsList] = useState(null)
    const [agenciesValidator, setAgenciesValidator] = useState(null)
    const [agenciesSignatoriesList, setAgenciesSignatoriesList] = useState(null)
    const [agenciesSignatory, setAgenciesSignatory] = useState(null)
    const [agenciesRepresentativesList, setAgenciesRepresentativesList] = useState(null)
    const [agenciesRepresentative, setAgenciesRepresentative] = useState(null)
    const [errorCreator, setErrorCreator] = useState(null)
    const [errorSignatory, setErrorSignatory] = useState(null)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    //const [errorSignatory, setErrorSignatory] = useState(null)
    const [loading, setLoading] = useState(false);

    let notyf = getNotyfObject();


    const updateClickHandler = () => {
        let errorCreator = null;
        let warningValidator = null;
        let errorSignatory = null;

        if ((agenciesCreatorsList && agenciesCreatorsList?.length > 0) || (agenciesValidatorsList && agenciesValidatorsList?.length > 0) || (agenciesSignatoriesList && agenciesSignatoriesList?.length > 0) || (agenciesRepresentativesList && agenciesRepresentativesList?.length > 0)) {
            if (agenciesCreatorsList && agenciesCreatorsList?.length > 0) {
                let agenciesUid = agenciesCreatorsList.map(agency => agency.uid);
                let errorMsg = t('masterData:editCreatorError', "Veuillez sélectionner un nouveau responsable pour chaque agence")
                if (agenciesCreator) {
                    agenciesUid.map(agencyUid => {
                        if (!agenciesCreator[agencyUid])
                            errorCreator = errorMsg
                    })
                } else {
                    errorCreator = errorMsg
                }
                setErrorCreator(errorCreator)
            }

            // if (agenciesValidatorsList && agenciesValidatorsList?.length > 0) {
            //     let agenciesUid = agenciesValidatorsList.map(agency => agency.uid);
            //     let warningMsg = "Une approbation était attendue par cet utilisateur Pensez à choisir un nouvel approbateur dans le workflow d’approbation de ces ADM ou pas (si pas d’approbateur remplaçant)"
            //     if (agenciesValidator) {
            //         agenciesUid.map(agencyUid => {
            //             if (!agenciesValidator[agencyUid])
            //                 warningValidator = warningMsg
            //         })
            //     } else {
            //         warningValidator = warningMsg
            //     }
            //     setWarningValidator(warningValidator)
            // }

            if (agenciesSignatoriesList && agenciesSignatoriesList?.length > 0) {
                let agenciesUid = agenciesSignatoriesList.map(agency => agency.uid);
                let errorMsg = t('masterData:editSignatoryError', "Veuillez sélectionner un nouveau signataire pour chaque entité")
                if (agenciesSignatory) {
                    agenciesUid.map(agencyUid => {
                        if (!agenciesSignatory[agencyUid])
                            errorSignatory = errorMsg
                    })
                } else {
                    errorSignatory = errorMsg
                }
                setErrorSignatory(errorSignatory)
            }
            // if (agenciesRepresentativesList && agenciesRepresentativesList?.length > 0) {
            //     let agenciesUid = agenciesRepresentativesList.map(agency => agency.uid);
            //     let errorMsg = t('masterData:editSignatoryError', "Veuillez sélectionner un nouveau signataire pour chaque entité")
            //     if (agenciesRepresentative) {
            //         agenciesUid.map(agencyUid => {
            //             if (!agenciesRepresentative[agencyUid])
            //                 warningRepresentative = errorMsg
            //         })
            //     } else {
            //         warningRepresentative = errorMsg
            //     }
            //     setWarningRepresentative(warningRepresentative)
            // }

            if (!errorCreator && !errorSignatory) {
                deleteUser(userUid, {
                    agenciesCreator: agenciesCreator,
                    agenciesValidator: agenciesValidator,
                    agenciesSignatory: agenciesSignatory,
                    agenciesRepresentative: agenciesRepresentative
                })
                handleClose();
                /*
                setLoading(true)
                Axios.put(`/user/${userUid}/deleteUserAndEditDocs`, {
                    agenciesCreator: agenciesCreator,
                    agenciesValidator: agenciesValidator,
                    agenciesSignatory: agenciesSignatory,
                    agenciesRepresentative: agenciesRepresentative
                }).then(response => {
                    setLoading(false)
                    notyf.success(t("masterData:creatorsChanged", "Les createurs ont bien été modifiés"))
                    notyf.success(t("masterData:userDeleted", "L'utilisateur a bien été supprimé"))
                    setUser({
                        is_legal_representative: false,
                        is_signataire: false,
                        status: "Nouveau"
                    })
                    getUsersList()
                    handleClose();
                }).catch(response => {
                    setLoading(false)
                    notyf.error(t("mandat:generatedErrorMessage"));
                })*/
                // handleContinueUpdate()
            }
            // else if (!errorCreator && !errorSignatory && (warningValidator || warningRepresentative)) {
            //     setShowConfirmationModal(!showConfirmationModal)
            // }
        }
        //updateAdmCreator(mandatUid, creator)
    }
    // const handleContinueUpdate = () => {
    //     setLoading(true)
    //     Axios.put(`/user/${userUid}/deleteUser`, {
    //         agenciesCreator: agenciesCreator,
    //         agenciesValidator: agenciesValidator,
    //         agenciesSignatory: agenciesSignatory,
    //         agenciesRepresentative: agenciesRepresentative
    //     }).then(response => {
    //         setLoading(false)
    //         notyf.success(t("masterData:creatorsChanged", "Les createurs ont bien été modifiés"))
    //         notyf.success(t("masterData:userDeleted", "L'utilisateur a bien été supprimé"))
    //         setUser({
    //             is_legal_representative: false,
    //             is_signataire: false,
    //             status: "Nouveau"
    //         })
    //         getUsersList()
    //         handleClose();
    //     }).catch(response => {
    //         setLoading(false)
    //         notyf.error(t("mandat:generatedErrorMessage"));
    //     })
    // }

    useEffect(() => {
        //Axios.get("/user/"+userUid+"/agenciesPossibleCreators").then(response => {
        Axios.get("/user/" + userUid + "/agenciesPossibleSubstitutes").then(response => {
            setAgenciesCreatorsList(response?.data?.agenciesCreatorsList)
            setAgenciesValidatorsList(response?.data?.agenciesValidatorsList)
            setAgenciesSignatoriesList(response?.data?.agenciesSignatoriesList)
            setAgenciesRepresentativesList(response?.data?.agenciesRepresentativesList)
        }).catch(response => {
            notyf.error(t("mandat:generatedErrorMessage"));
        })
    }, [])

    const agencyCreatorChangeHandler = (agencyUid, creatorUid) => {
        if (agenciesCreator) {
            setAgenciesCreator({
                ...agenciesCreator,
                [agencyUid]: creatorUid
            })
        } else {
            setAgenciesCreator({
                [agencyUid]: creatorUid
            })
        }
    }

    const agencyValidatorChangeHandler = (agencyUid, validatorUid) => {
        if (agenciesValidator) {
            setAgenciesValidator({
                ...agenciesValidator,
                [agencyUid]: validatorUid
            })
        } else {
            setAgenciesValidator({
                [agencyUid]: validatorUid
            })
        }
    }

    const agenciesSignatoryChangeHandler = (agencyUid, signatoryUid) => {
        if (agenciesSignatory) {
            setAgenciesSignatory({
                ...agenciesSignatory,
                [agencyUid]: signatoryUid
            })
        } else {
            setAgenciesSignatory({
                [agencyUid]: signatoryUid
            })
        }
    }

    const agenciesRepresentativeChangeHandler = (agencyUid, representativeUidOrEmpty) => {
        if (agenciesRepresentative) {
            setAgenciesRepresentative({
                ...agenciesRepresentative,
                [agencyUid]: representativeUidOrEmpty
            })
        } else {
            setAgenciesRepresentative({
                [agencyUid]: representativeUidOrEmpty
            })
        }
    }


    // setAgenciesSignatory

    return (
        <>
            <Modal show={show} onHide={handleClose} contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                    <Modal.Title>

                        {/* {t('mandat:creatorModal_title', "Modification du responsable/approbateurs/signataires du mandat")}  */}
                        {t('mandat:creatorContributorSignatoryModalTitle', "Modification du responsable/approbateurs/signataires du mandat")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="ADMCreationModal__body" style={{ width: "650px" }}>

                    {
                        agenciesCreatorsList && agenciesCreatorsList?.length > 0 ? <>
                            <div className="alert alert-warning" role="alert">
                                <p>
                                    {t('masterData:editCreatorWarning1', "ATTENTION : cet utilisateur est responsable de mandats qui ne sont pas encore signés.")}<br />
                                    {t('masterData:editCreatorWarning2', "Vous devez définir un nouveau responsable pour chaque Mandataire.")}
                                </p>
                            </div>
                            <div className="editCreatorModal__body__container">
                                <div className="editCreatorModal__header__container">
                                    <h5 className="editCreatorModal__title__container">
                                        {/* {t('default:mandate', "Mandat")} */}
                                        {t('mandat:creatorModal_title', "Modification du responsable du mandat")}
                                    </h5>
                                    {/* <button className="btn btn-danger btn-sm">Résilier tout</button> */}
                                </div>
                                <div className="editCreatorModal_possibleCreators_container">
                                    {
                                        errorCreator && (
                                            <div className="alert alert-danger" role="alert">
                                                <p>
                                                    {errorCreator}
                                                </p>
                                            </div>)
                                    }
                                    {
                                        agenciesCreatorsList?.map(agency => (
                                            <div className="editCreatorModal_possibleCreators_item">
                                                <div className="possibleCreators_label_container">
                                                    <div className="possibleCreators_label_name">
                                                        {agency?.official_name}
                                                    </div>
                                                    <CTooltip title={agency?.suspendedDocs + " Document(s) en cours"} className="tableActions__icon">
                                                        <div className="possibleCreators_label_suspendedDocs">
                                                            {agency?.suspendedDocs}
                                                        </div>
                                                    </CTooltip>
                                                </div>
                                                <div className="possibleCreators_list_container">
                                                    <select
                                                        className="form-control "
                                                        value={(agenciesCreator && agenciesCreator[agency?.uid]) ? agenciesCreator[agency?.uid] : ""}
                                                        onChange={(e) => agencyCreatorChangeHandler(agency?.uid, e.target.value)}
                                                    >
                                                        <option value="">
                                                            {t('mandat:selectCreator', "Sélectionnez un nouveau responsable")}
                                                        </option>
                                                        {
                                                            agency?.possibleCreators?.map(user =>
                                                                <option value={user.uid}>
                                                                    {user.name + " (" + user.email + ")"}
                                                                </option>
                                                            )
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        ))
                                    }

                                </div>
                            </div> </> : ''
                    }

                    {
                        agenciesValidatorsList && agenciesValidatorsList?.length > 0 ? <>
                            <div className="alert alert-warning" role="alert">
                                <p>
                                    {t('masterData:editContributorWarning1', "ATTENTION : cet utilisateur est approbateur de mandats qui ne sont pas encore approuvés.")}<br />
                                    {t('masterData:editContributorWarning2', "Vous devez définir un nouvel approbateur pour chaque entité.")}
                                </p>
                            </div>
                            <div className="editCreatorModal__body__container">
                                <div className="editCreatorModal__header__container">
                                    <h5 className="editCreatorModal__title__container">
                                        {/* {t('default:mandate', "Mandat")} */}
                                        {t('mandat:contributorChange', "Modification de l' approbateur du mandat")}
                                    </h5>
                                    {/* <button className="btn btn-danger btn-sm">Résilier tout</button> */}
                                </div>
                                <div className="editCreatorModal_possibleCreators_container">
                                    {
                                        agenciesValidatorsList?.map(agency => (
                                            <div className="editCreatorModal_possibleCreators_item">
                                                <div className="possibleCreators_label_container">
                                                    <div className="possibleCreators_label_name">
                                                        {agency?.official_name}
                                                    </div>
                                                    <CTooltip title={agency?.suspendedDocs + " Document(s) en cours"} className="tableActions__icon">
                                                        <div className="possibleCreators_label_suspendedDocs">
                                                            {agency?.suspendedDocs}
                                                        </div>
                                                    </CTooltip>
                                                </div>
                                                <div className="possibleCreators_list_container">
                                                    <select
                                                        className="form-control "
                                                        value={(agenciesValidator && agenciesValidator[agency?.uid]) ? agenciesValidator[agency?.uid] : ""}
                                                        onChange={(e) => agencyValidatorChangeHandler(agency?.uid, e.target.value)}
                                                    >
                                                        <option value="">
                                                            {t('mandat:selectContributor', "Sélectionnez un nouvel approbateur")}
                                                        </option>
                                                        {
                                                            agency?.possibleValidators?.map(user =>
                                                                <option value={user.uid}>
                                                                    {user.name + " (" + user.email + ")"}
                                                                </option>
                                                            )
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        ))
                                    }

                                </div>
                            </div> </> : ''
                    }

                    {
                        agenciesSignatoriesList && agenciesSignatoriesList?.length > 0 ? <>
                            <div className="alert alert-warning" role="alert">
                                <p>
                                    {t('masterData:editSignatoryWarning1', "ATTENTION : cet utilisateur est signataire de mandats qui ne sont pas encore signés.")}<br />
                                    {t('masterData:editSignatoryWarning2', "Vous devez définir un nouveau signataire pour chaque entité.")}
                                </p>
                            </div>
                            <div className="editCreatorModal__body__container">
                                <div className="editCreatorModal__header__container">
                                    <h5 className="editCreatorModal__title__container">
                                        {t('mandat:signatoryChange', "Modification du signataire du mandat")}
                                    </h5>
                                    {/* <button className="btn btn-danger btn-sm">Résilier tout</button> */}
                                </div>
                                <div className="editCreatorModal_possibleCreators_container">
                                    {
                                        errorSignatory && (
                                            <div className="alert alert-danger" role="alert">
                                                <p>
                                                    {errorSignatory}
                                                </p>
                                            </div>)
                                    }
                                    {
                                        agenciesSignatoriesList?.map(agency => (
                                            <div className="editCreatorModal_possibleCreators_item">
                                                <div className="possibleCreators_label_container">
                                                    <div className="possibleCreators_label_name">
                                                        {agency?.official_name}
                                                    </div>
                                                    <CTooltip title={agency?.suspendedDocs + " Document(s) en cours"} className="tableActions__icon">
                                                        <div className="possibleCreators_label_suspendedDocs">
                                                            {agency?.suspendedDocs}
                                                        </div>
                                                    </CTooltip>
                                                </div>
                                                <div className="possibleCreators_list_container">
                                                    <select
                                                        className="form-control "
                                                        value={(agenciesSignatory && agenciesSignatory[agency?.uid]) ? agenciesSignatory[agency?.uid] : ""}
                                                        onChange={(e) => agenciesSignatoryChangeHandler(agency?.uid, e.target.value)}
                                                    >
                                                        <option value="">
                                                            {t('mandat:selectSignatory', "Sélectionnez un nouveau signataire")}
                                                        </option>
                                                        {
                                                            agency?.possibleSignatories?.map(user =>
                                                                <option value={user.uid}>
                                                                    {user.name + " (" + user.email + ")"}
                                                                </option>
                                                            )
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        ))
                                    }

                                </div>
                            </div> </> : ''
                    }

                    {
                        agenciesRepresentativesList && agenciesRepresentativesList?.length > 0 ? <>
                            <div className="alert alert-warning" role="alert">
                                <p>
                                    {t('masterData:editRepresentativeWarning1', "ATTENTION : cet utilisateur est représentant légal de mandats qui ne sont pas encore signés.")}<br />
                                    {t('masterData:editRepresentativeWarning2', "Vous devez si possible définir un nouveau représentant légal pour chaque entité.")}
                                </p>
                            </div>
                            <div className="editCreatorModal__body__container">
                                <div className="editCreatorModal__header__container">
                                    <h5 className="editCreatorModal__title__container">
                                        {t('mandat:representativeChange', "Modification du représentant légal du mandat")}
                                    </h5>
                                    {/* <button className="btn btn-danger btn-sm">Résilier tout</button> */}
                                </div>
                                <div className="editCreatorModal_possibleCreators_container">
                                    {
                                        agenciesRepresentativesList?.map(agency => (
                                            <div className="editCreatorModal_possibleCreators_item">
                                                <div className="possibleCreators_label_container">
                                                    <div className="possibleCreators_label_name">
                                                        {agency?.official_name}
                                                    </div>
                                                    <CTooltip title={agency?.suspendedDocs + " Document(s) en cours"} className="tableActions__icon">
                                                        <div className="possibleCreators_label_suspendedDocs">
                                                            {agency?.suspendedDocs}
                                                        </div>
                                                    </CTooltip>
                                                </div>
                                                <div className="possibleCreators_list_container">
                                                    <select
                                                        className="form-control "
                                                        value={(agenciesRepresentative && agenciesRepresentative[agency?.uid]) ? agenciesRepresentative[agency?.uid] : ""}
                                                        onChange={(e) => agenciesRepresentativeChangeHandler(agency?.uid, e.target.value)}
                                                    >
                                                        <option value="">
                                                            {t('mandat:selectRepresentative', "Sélectionnez un nouveau représentant légal")}
                                                        </option>
                                                        {
                                                            agency?.possibleRepresentatives?.map(user =>
                                                                <option value={user.uid}>
                                                                    {user.name + " (" + user.email + ")"}
                                                                </option>
                                                            )
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        ))
                                    }

                                </div>
                            </div> </> : ''
                    }

                </Modal.Body>
                <Modal.Footer>
                    {
                        loading ?
                            <Spinner animation="border" variant="secondary" />
                            :
                            <>
                                <Button variant="secondary" onClick={handleClose}>
                                    {t('default:cancel', "Annuler")}
                                </Button>
                                <Button
                                    variant="primary"
                                    onClick={() => updateClickHandler()}
                                    // TODO CORRIGER DISABLED
                                    // disabled={ !agenciesCreator || !agenciesCreatorsList ||!agenciesValidator || !agenciesValidatorsList||!agenciesSignatory || !agenciesSignatoriesList }
                                    //(agenciesCreatorsList && agenciesCreatorsList?.length > 0 ) || (agenciesValidatorsList && agenciesValidatorsList?.length > 0 ) || (agenciesSignatoriesList && agenciesSignatoriesList?.length > 0 )){

                                    // disabled={ (agenciesCreatorsList && agenciesCreatorsList?.length > 0  && (!agenciesCreator || agenciesCreator?.length != agenciesCreatorsList?.length)) ||
                                    //            (agenciesValidatorsList && agenciesValidatorsList?.length > 0 && (!agenciesValidator || agenciesValidator?.length != agenciesValidatorsList?.length)) ||
                                    //            (agenciesSignatoriesList && agenciesSignatoriesList?.length > 0 && (!agenciesSignatory || agenciesSignatory?.length != agenciesSignatoriesList?.length))  }

                                    disabled={(agenciesCreatorsList && agenciesCreatorsList?.length > 0 && !agenciesCreator) ||
                                        (agenciesSignatoriesList && agenciesSignatoriesList?.length > 0 && !agenciesSignatory)}

                                >
                                    {t('masterData:updateAndDelete', "Modifier et supprimer l'utilisateur")}
                                </Button>
                            </>
                    }
                </Modal.Footer>
            </Modal>

            {/* {
                showConfirmationModal &&
                <DeleteUserModalConfirmation
                    handleClose={() => setShowConfirmationModal(false)}
                    show={showConfirmationModal}
                    handleContinueConfirmation={handleContinueUpdate}
                    warningRepresentative={warningRepresentative}
                    warningValidator={warningValidator}
                />
            } */}
        </>
    )

    // const updateClickHandler = () => {
    //     let error = null;
    //     if(agenciesCreatorsList && agenciesCreatorsList?.length > 0 ) {
    //         let agenciesUid = agenciesCreatorsList.map(agency => agency.uid);
    //         let errorMsg = t('masterData:editCreatorError', "Veuillez sélectionner un nouveau responsable pour chaque agence")
    //         if(agenciesCreator){
    //             agenciesUid.map(agencyUid => {
    //                 if(!agenciesCreator[agencyUid])
    //                     error = errorMsg
    //             })
    //         } else {
    //             error = errorMsg
    //         }
    //         if(error)
    //             setErrorCreator(error)
    //         else {
    //             setLoading(true)
    //             Axios.put(`/user/${userUid}/edit_deleteUserAndEditDocs`, agenciesCreator).then(response => {
    //                 setLoading(false)
    //                 notyf.success(t("masterData:creatorsChanged", "Les createurs ont bien été modifié"))
    //                 notyf.success(t("masterData:userDeleted", "L'utilisateur a bien été supprimé"))
    //                 setUser({
    //                     is_legal_representative : false,
    //                     is_signataire : false,
    //                     status : "Nouveau"
    //                 })
    //                 getUsersList()
    //                 handleClose();
    //             }).catch(response => {
    //                 setLoading(false)
    //                 notyf.error(t("mandat:generatedErrorMessage"));
    //             }) 
    //         }
    //     }

    // }
}

export default DeleteUserModal