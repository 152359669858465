import React, { useCallback, useEffect, useState, useRef } from 'react'
import ListManagerAbstract from './ListManagerAbstract/ListManagerAbstract';
import { useTranslation } from 'react-i18next';
import Axios from '../../../axios-edipub'
import { getNotyfObject } from '../../../shared/utility'; 


function ListManagerGeo(props) { 
    let { 
        handleFormChanged, 
    } = props  
    
    const notyf = getNotyfObject();  
    const {t} = useTranslation(); 
    const [items , setItems] = useState([]);
    const [itemsToUpdate , setItemsToUpdate] = useState([]);
    const [itemsToDelete , setItemsToDelete] = useState([]);
    const [newItem, setNewItem] = useState({}); 
    const [updateLoading , setUpdateLoading] = useState(false);

    const getGeoList = useCallback((order = false) => { 
        Axios.get('geographicalarea', {
            params : { 
                sortOrder: (order) ? "DESC" : "ASC"
            }
        }).then(response => {
            setItems(response.data) 
        })
    }, [])
 
    const geoAddHandler = () => {
        Axios.post('/geographicalarea', newItem
        ).then (response => {
            getGeoList()
            notyf.success('Ajout sauvegardé') 
        }).catch(err => {
            notyf.error('Une erreur s\'est produite. ' + err?.response?.data?.error)
        })   
    }

    const geosUpdateHandler = ( ) => {
        setUpdateLoading(true)
        let data = { 
            'areasToUpdate' : itemsToUpdate,
            'areasToDelete' : itemsToDelete
        } 
        Axios.put(`/geographicalarea//editall`, data)
            .then(response => {
                setItemsToUpdate([])
                setItemsToDelete([])
                getGeoList()
                setUpdateLoading(false)
                handleFormChanged(false)  
                notyf.success('Modifications sauvegardées')
            }).catch(err => { 
                setUpdateLoading(false)   
                notyf.error('Une erreur s\'est produite !')
            }) 
    } 

    return (
        <ListManagerAbstract 
            title = {t('masterData:geographicalZones',"Zones géographiques")}    
            addNewTitle =  {t('masterData:addNewGeographicalZone', "Ajouter une nouvelle zone géographique")}          
            addNewAlreadyExistLabel = {t("masterData:existGeographicalAreaLabel",  "Cette zone géographique existe déja!")}
            fieldColumnNames= { {label: 'Francais', label_en: 'Anglais', code_geo: 'Code'}}            
            uniqueFields={["code_geo"]}
            mandatoryFields={["label","label_en","code_geo"]}
            fieldsMaxLen= {{code_geo:32}}
            items={items}
            setItems={setItems}
            itemsToUpdate={itemsToUpdate}
            setItemsToUpdate={setItemsToUpdate}
            itemsToDelete={itemsToDelete}
            setItemsToDelete={setItemsToDelete}
            newItem={newItem}
            setNewItem={setNewItem} 
            getItemList={getGeoList}
            itemAddHandler={geoAddHandler}
            itemsUpdateHandler={geosUpdateHandler}         
            handleFormChanged={handleFormChanged}
            updateLoading={updateLoading} 
        />
     ) 
}

export default ListManagerGeo