import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function PageChangeModal({handleClose, callback}) {
    const {t} = useTranslation();
    return (
        <>
            <Modal show={true} onHide={() => handleClose(false)} contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                <Modal.Title>{t('masterData:pageChange', "Changement de page")} </Modal.Title>
                </Modal.Header>
                <Modal.Body className="ADMCreationModal__body" style={{width: "650px"}}>
                    {t('masterData:pageChangeModal', "Voulez-vous vraiment quitter la page ? Toutes les modifications non sauvegardées seront perdues.")}
                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={() => {callback(); handleClose(true)}}>
                    {t('default:confirm', "Confirmer")}
                </Button>
                <Button variant="secondary" onClick={() => handleClose(false)}>
                    {t('default:cancel', "Annuler")}
                </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default PageChangeModal
