import React, { useEffect, useState } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {formatDate, getTimeDate} from '../../../../../../../shared/utility';
import { useTranslation } from 'react-i18next';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
function StatusRevertEvent({isCreator, eventData, isLast}) {
    const [showContent, setShowContent] = useState(false)
    const [previousStatus] = useState(eventData.message.split("|")[0])
    const [newStatus] = useState(eventData.message.split("|")[1])
    const {t} = useTranslation();
    useEffect(() => {
        if(isLast)
            setShowContent(true)
        else
            setShowContent(false)
    }, [isLast])

    return (
        <div className="timelineCard__container">
            <div className="timelineCard__hideicon" onClick={() => setShowContent(!showContent)}>
                {
                    showContent ? 
                        <ExpandMoreIcon />
                    :
                        <ChevronRightIcon />
                }
            </div>
            <div className="timelineCard__sideContainer">
                <div className="timelineCard__header">
                    <SettingsBackupRestoreIcon style={{width: "24px", height: "24px", borderRadius: "5px",backgroundColor:"#f3c839"}}  />
                    <div className="timelineCard__header_label">
                        {t('timeline:statusRevert', "Statut rétabli")} 
                    </div>
                    <div className="timelineCard__header_time">
                        <span className="timelineCard__header_hour"> {getTimeDate(+eventData?.creationDate)}</span> 
                        <span className="timelineCard__header_date"> {formatDate(+eventData?.creationDate)}</span> 
                    </div>
                </div>
                <div className="timelineCard__content" style={{borderLeft: "2px solid #f3c839",  display: showContent ? "block" : "none"}}>
                    <div className="timelineCard__content__info">
                    {
                        isCreator ? t('timeline:statusRevertSelfMessage', "Vous avez changé le signataire du mandat") 
                            : eventData?.data?.first_name + ' ' + eventData?.data?.last_name + t('timeline:statusRevertMessage',  ' a changé le signataire du mandat')
                    }
                     </div>
                    <div className="timelineCard__content__body">
                        <strong style={{color:"gray"}}>{t("timeline:previousStatus", "Statut précédent")} </strong>
                        <p style={{color:"#0070D2"}}>{t("default:"+previousStatus)}</p>
                        <br/>
                        <strong style={{color:"gray"}}>{t("timeline:newStatus", "Nouveau statut")} </strong>
                        <p style={{color:"#0070D2"}}>{t("default:"+newStatus)}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StatusRevertEvent
