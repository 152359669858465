import {updateObject} from '../../shared/utility';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
   
    admData: null,
    loading: false,
    error: null,
    updateLoading: false,
    updateError: null,
    timelineData: null,
    loadingTimeline: false,
    errorTimeline: null,
    tabActive : "mandat",
    tabSideActive : "timeline",
    admScrolledEnd : false,
    pdfPages : 0,
    masterDatasPending : 0,
    admUpdate: {
        addresses: {},
        usersToAdd: [],
        usersToUpdate: [],
        usersToDelete: [],
        subAgencyToAdd: [],
        subAgencyToDelete: [],
        admObjects : {},
        clauses: [],
        departmentsToAdd: [],
        departmentsToDelete: [],
        showCommercialNameToUpdate: []
        

    },
    recipientUsersToAdd : [],
    recipientUsersToDel : [],
    adm: null,
}


const resetAdmToInitialState = (state, action) => updateObject(state, {
    admData: null,
    loading: false,
    error: null,
    timelineData: null,
    loadingTimeline: false,
    errorTimeline: null,
    tabActive : "mandat",
    tabSideActive : "timeline",
    admScrolledEnd : false,
    pdfPages : 0,
    admUpdate: {
        addresses: {},
        usersToAdd: [],
        usersToUpdate: [],
        usersToDelete: [],
        subAgencyToAdd: [],
        subAgencyToDelete: [],
        admObjects : {},
        clauses: [],
        departmentsToAdd: [],
        departmentsToDelete: [],
        showCommercialNameToUpdate: []
    },
    recipientUsersToAdd : [],
    recipientUsersToDel : []
})

//pas utilisé car on utilise pas flag pending
// const countMasterDatasPending = (state, action) => updateObject(state, {
//     masterDatasPending : action.masterDatasPending
// })

const addRecipientUser = (state, action) => {
    let recipientUsersTemp = [...state?.recipientUsersToAdd];
    let recipientsToDelTemp = [...state?.recipientUsersToDel]
    let recipientDelIndex = recipientsToDelTemp.findIndex(uid => uid === action.userUid)

    if(recipientDelIndex >=0){
        recipientsToDelTemp.splice(recipientDelIndex, 1)
    }else {
        recipientUsersTemp = [...recipientUsersTemp, action.userUid];
    }

    return updateObject(state, {
        recipientUsersToAdd: recipientUsersTemp,
        recipientUsersToDel: recipientsToDelTemp
    })
}

const deleteRecipientUser = (state, action) => {
    let recipientsToAddTemp = [...state?.recipientUsersToAdd]
    let recipientsToDelTemp = [...state?.recipientUsersToDel]
    let recipientIndex = recipientsToAddTemp.findIndex(uid => uid === action.userUid)

    if(recipientIndex >= 0){
        recipientsToAddTemp.splice(recipientIndex, 1)
    }else {
        recipientsToDelTemp.push(action.userUid);
    }
    return updateObject(state, {
        recipientUsersToAdd: recipientsToAddTemp,
        recipientUsersToDel: recipientsToDelTemp
    })
}

const resetRecipientUsers = (state, action) => (updateObject(state, {
    recipientUsersToAdd : [],
    recipientUsersToDel : []
}))

const countUsersPending = (state, action) => updateObject(state, {
    usersPending : action.usersPending
})

const setAdmScrolledEnd = (state, action) => updateObject(state, {
    admScrolledEnd : action.scrolledEnd
})

const setAdmPdfPages = (state, action) => updateObject(state, {
    pdfPages : action.numPages
})
const setAdm = (state, action) => updateObject(state, {
    adm : action.adm
})

const setTabActive = (state , action) => updateObject(state, {
    tabActive : action.tabActive
})

const setTabSideActive = (state , action) => updateObject(state, {
    tabSideActive : action.tabSideActive
})

const mandatStart = (state, action) => updateObject(state, {
    error: null,
    loading: true
})

const mandatSuccess = (state, action) => updateObject(state, {
    admData: action.data,
    admUpdate : {
        ...state.admUpdate
    },
    error: null,
    loading: false
})

const mandatFail = (state, action) => updateObject(state, {
    admData: null,
    error: action.error,
    loading: false
})

const updateMandatStart = (state, action) => updateObject(state, {
    updateError: null,
    updateLoading: true
})

const updateMandatSuccess = (state, action) => updateObject(state, {
    updateError: null,
    updateLoading: false
})

const updateMandatFail = (state, action) => updateObject(state, {
    updateError: action.error,
    updateLoading: false
})

const updateMandatDescription = (state, action) => updateObject(state, {
    admData : {
        ...state.admData,
        description : action.description
    }
})

const updateMandatPeriod = (state, action) => updateObject(state, {
    admData : {
        ...state.admData,
        start_period : action.startPeriod,
        end_period : action.endPeriod
    }
})

const updateMandatCreator = (state, action) => updateObject(state, {
    admData : {
        ...state.admData,
        creatorName : action.creatorName,
        id_creator : action.id_creator,
        creatorUid : action.uid
    }
})

const mandatGetTimelineStart = (state, action) => updateObject(state, {
    errorTimeline: null,
    loadingTimeline: true
})

const mandatGetTimelineSuccess = (state, action) => updateObject(state, {
    timelineData: action.data,
    errorTimeline: null,
    loadingTimeline: false
})

const mandatGetTimelineFail = (state, action) => updateObject(state, {
    timelineData: null,
    errorTimeline: action.error,
    loadingTimeline: false
})

const updateAdmData = (state, action) => updateObject(state, {
    admData: action.admNewData
})

const updateAdmAddresses = (state, action) => {
    let addressesTemp = null;
    if (!state.admUpdate.addresses.hasOwnProperty(action.entityUid)) {
        addressesTemp = {
            ...state.admUpdate.addresses,
            [action.entityUid] : {
                [action.addressField] : action.addressValue
            }
        }
    } else 
        addressesTemp = {
            ...state.admUpdate.addresses,
            [action.entityUid] : {
                ...state.admUpdate.addresses[action.entityUid],
                [action.addressField] : action.addressValue
            }
        }
    
    return updateObject(state, {
        admUpdate : {
            ...state.admUpdate,
            addresses : addressesTemp
        }
    })
}

const addMandatuser = (state, action) => {
    let usersTemp = [
        ...state.admUpdate.usersToAdd,
        action.userToAdd
    ];

    return updateObject(state, {
        admUpdate : {
            ...state.admUpdate,
            usersToAdd : usersTemp
        }
    })
}

const addMandatUserToUpdate = (state, action) => {
    let usersTemp = [
        ...state.admUpdate.usersToUpdate,
        action.user
    ];

    return updateObject(state, {
        admUpdate : {
            ...state.admUpdate,
            usersToUpdate : usersTemp
        }
    })
}

const updateMandatUserToAdd = (state, action) => {
    let usersTemp = [...state.admUpdate.usersToAdd];
    usersTemp.filter(row => {
        return row.entityUid === action.entityUid && row.uid === action.uid
    })[0][action.field] = action.value;

    return updateObject(state, {
        admUpdate : {
            ...state.admUpdate,
            usersToAdd : usersTemp
        }
    })
}

const updateMandatUserToUpdate = (state, action) => {
    let usersTemp = [...state.admUpdate.usersToUpdate];
    usersTemp.filter(row => {
        return row.d2uUid === action.d2uUid
    })[0][action.field] = action.value;

    return updateObject(state, {
        admUpdate : {
            ...state.admUpdate,
            usersToUpdate : usersTemp
        }
    })
}

const deleteMandatUser = (state, action) => {
    let usersToDelTemp = [...state.admUpdate.usersToDelete, action.d2uUid]
    let usersToUpdTemp = [...state.admUpdate.usersToUpdate]
    let indexInToUpd = usersToUpdTemp.findIndex(user => user.d2uUid === action.d2uUid) 
    if (indexInToUpd >= 0 ) usersToUpdTemp.splice(indexInToUpd, 1) 

    return updateObject(state, {
        admUpdate : {
            ...state.admUpdate,
            usersToUpdate : usersToUpdTemp,
            usersToDelete : usersToDelTemp
        }
    })
}

const deleteMandatUserToAdd = (state, action) => {
    let usersToDelTemp = [...state.admUpdate.usersToAdd];
    let userIndex = usersToDelTemp.findIndex( user => {
        return user.entityUid === action.entityUid && user.uid === action.uid
    })

    if (userIndex >=0 ) usersToDelTemp.splice(userIndex, 1);

    return updateObject(state, {
        admUpdate : {
            ...state.admUpdate,
            usersToAdd : usersToDelTemp
        }
    })
}

const addMandatSubagency = (state, action) => {
    let subAgencyTemp = [...state.admUpdate.subAgencyToAdd, action.entityUid]

    return updateObject(state, {
        admUpdate : {
            ...state.admUpdate,
            subAgencyToAdd : subAgencyTemp
        }
    })
}

const deleteMandatSubAgency = (state, action) => {
    let subAgcToDelTemp = [...state.admUpdate.subAgencyToDelete, action.entityUid]
    let addressesTemp = {...state.admUpdate.addresses}
    let usersToAddTemp = [...state.admUpdate.usersToAdd]

    delete addressesTemp[action.entityUid]
    for (let i = 0; i < usersToAddTemp.length; i++) {
        let userIndex = usersToAddTemp.findIndex( user => user.entityUid === action.entityUid ) >= 0
        if (userIndex >= 0) usersToAddTemp.splice(userIndex, 1)
    }

    return updateObject(state, {
        admUpdate : {
            ...state.admUpdate,
            addresses : addressesTemp,
            usersToAdd : usersToAddTemp,
            subAgencyToDelete : subAgcToDelTemp
        }
    })
}

const deleteMandatSubAgencyToAdd = (state, action) => {
    let subAgcToAddTemp = [...state.admUpdate.subAgencyToAdd]
    let subAgencyIndex = subAgcToAddTemp.findIndex(agencyUid => agencyUid === action.entityUid)
    let addressesTemp = {...state.admUpdate.addresses}
    let usersToAddTemp = [...state.admUpdate.usersToAdd]

    subAgcToAddTemp.splice(subAgencyIndex, 1);
    delete addressesTemp[action.entityUid]
    for (let i = 0; i < usersToAddTemp.length; i++) {
        let userIndex = usersToAddTemp.findIndex( user => user.entityUid === action.entityUid )
        if (userIndex >= 0) usersToAddTemp.splice(userIndex, 1)
    }

    return updateObject(state, {
        admUpdate : {
            ...state.admUpdate,
            addresses : addressesTemp,
            usersToAdd : usersToAddTemp,
            subAgencyToAdd : subAgcToAddTemp
        }
    })
}

const resetMandatToUpdateData = (state, action) => updateObject(state, {
    admUpdate: {
        addresses: {},
        usersToAdd: [],
        usersToUpdate: [],
        usersToDelete: [],
        subAgencyToAdd: [],
        subAgencyToDelete: [],
        admObjects : {},
        clauses: [],
        departmentsToAdd: [],
        departmentsToDelete: [],
        showCommercialNameToUpdate: []
    }
})

const addMandatProduct = (state, action) => {
    let admObjectsT = {...state.admUpdate.admObjects}
    if(!state.admUpdate.admObjects.hasOwnProperty(action.admOUid)){
        admObjectsT = {
            ...state.admUpdate.admObjects,
            [action.admOUid] : {
                productsToAdd : [...action.products]
            }
        }
    } else {
        admObjectsT = {
            ...state.admUpdate.admObjects,
            [action.admOUid] : {
                ...state.admUpdate.admObjects[action.admOUid],
                productsToAdd : state.admUpdate.admObjects[action.admOUid].hasOwnProperty('productsToAdd') ? 
                                    [...state.admUpdate.admObjects[action.admOUid].productsToAdd, ...action.products] : 
                                    [...action.products]
            }
        }
    }
    return updateObject(state, {
        admUpdate: {
            ...state.admUpdate,
            admObjects : admObjectsT
        }
    })

}

const deleteMandatProduct = (state, action) => {
    let admObjectsT = {...state.admUpdate.admObjects}
    
    if(!state.admUpdate.admObjects.hasOwnProperty(action.admOUid)){
        admObjectsT = {
            ...state.admUpdate.admObjects,
            [action.admOUid] : {
                productsToDelete : [action.product.uid]
            }
        }
    } else if(action.product.hasOwnProperty('new')) {
        let productToAddTemp = [...state.admUpdate.admObjects[action.admOUid].productsToAdd]
        let productToAddIndex = productToAddTemp.findIndex(prod => prod.uid === action.product.uid)
        if(productToAddIndex >=0 )
            productToAddTemp.splice(productToAddIndex, 1)
        admObjectsT = {
            ...state.admUpdate.admObjects,
            [action.admOUid] : {
                ...state.admUpdate.admObjects[action.admOUid],
                productsToAdd : productToAddTemp
            }
        }
    } else {
        admObjectsT = {
            ...state.admUpdate.admObjects,
            [action.admOUid] : {
                ...state.admUpdate.admObjects[action.admOUid],
                productsToDelete : state.admUpdate.admObjects[action.admOUid].hasOwnProperty('productsToDelete') ?
                                    [...state.admUpdate.admObjects[action.admOUid].productsToDelete, action.product.uid] :
                                    [action.product.uid]
            }
        }
    }
    return updateObject(state, {
        admUpdate: {
            ...state.admUpdate,
            admObjects : admObjectsT
        }
    })
}

const addMandatMedia = (state, action) => {
    let admObjectsT = {...state.admUpdate.admObjects}
    if(!state.admUpdate.admObjects.hasOwnProperty(action.admOUid)){
        admObjectsT = {
            ...state.admUpdate.admObjects,
            [action.admOUid] : {
                mediasToAdd : [...action.medias]
            }
        }
    } else {
        admObjectsT = {
            ...state.admUpdate.admObjects,
            [action.admOUid] : {
                ...state.admUpdate.admObjects[action.admOUid],
                mediasToAdd : state.admUpdate.admObjects[action.admOUid].hasOwnProperty('mediasToAdd') ? 
                                    [...state.admUpdate.admObjects[action.admOUid].mediasToAdd, ...action.medias] : 
                                    [...action.medias]
            }
        }
    }
    return updateObject(state, {
        admUpdate: {
            ...state.admUpdate,
            admObjects : admObjectsT
        }
    })

}

const deleteMandatMedia = (state, action) => {
    let admObjectsT = {...state.admUpdate.admObjects}
    
    if(!state.admUpdate.admObjects.hasOwnProperty(action.admOUid)){
        admObjectsT = {
            ...state.admUpdate.admObjects,
            [action.admOUid] : {
                mediasToDelete : [action.media]
            }
        }
    } else if(action.media.hasOwnProperty('new')) {
        let mediasToAddTemp = [...state.admUpdate.admObjects[action.admOUid].mediasToAdd]
        let mediaToAddIndex = mediasToAddTemp.findIndex(row => row.uid === action.media.uid)
        if(mediaToAddIndex >=0 )
            mediasToAddTemp.splice(mediaToAddIndex, 1)
        admObjectsT = {
            ...state.admUpdate.admObjects,
            [action.admOUid] : {
                ...state.admUpdate.admObjects[action.admOUid],
                mediasToAdd : mediasToAddTemp
            }
        }
    } else {
        admObjectsT = {
            ...state.admUpdate.admObjects,
            [action.admOUid] : {
                ...state.admUpdate.admObjects[action.admOUid],
                mediasToDelete : state.admUpdate.admObjects[action.admOUid].hasOwnProperty('mediasToDelete') ?
                                    [...state.admUpdate.admObjects[action.admOUid].mediasToDelete, action.media] :
                                    [action.media] 
            }
        }
    }
    return updateObject(state, {
        admUpdate: {
            ...state.admUpdate,
            admObjects : admObjectsT
        }
    })
}

const addMandatRegie = (state, action) => {
    let admObjectsT = {...state.admUpdate.admObjects}
    if(!state.admUpdate.admObjects.hasOwnProperty(action.admOUid)){
        admObjectsT = {
            ...state.admUpdate.admObjects,
            [action.admOUid] : {
                regiesToAdd : [...action.regies]
            }
        }
    } else {
        admObjectsT = {
            ...state.admUpdate.admObjects,
            [action.admOUid] : {
                ...state.admUpdate.admObjects[action.admOUid],
                regiesToAdd : state.admUpdate.admObjects[action.admOUid].hasOwnProperty('regiesToAdd') ? 
                                    [...state.admUpdate.admObjects[action.admOUid].regiesToAdd, ...action.regies] : 
                                    [...action.regies]
            }
        }
    }
    return updateObject(state, {
        admUpdate: {
            ...state.admUpdate,
            admObjects : admObjectsT
        }
    })
}

const deleteMandatRegie = (state, action) => {
    let admObjectsT = {...state.admUpdate.admObjects}
    
    if(!state.admUpdate.admObjects.hasOwnProperty(action.admOUid)){
        admObjectsT = {
            ...state.admUpdate.admObjects,
            [action.admOUid] : {
                regiesToDelete : [action.regie.uid]
            }
        }
    } else if(action.regie.hasOwnProperty('new')) {
        let regiesToAddTemp = [...state.admUpdate.admObjects[action.admOUid].regiesToAdd]
        let regieToAddIndex = regiesToAddTemp.findIndex(row => row.uid === action.regie.uid)
        if(regieToAddIndex >=0 )
            regiesToAddTemp.splice(regieToAddIndex, 1)
        admObjectsT = {
            ...state.admUpdate.admObjects,
            [action.admOUid] : {
                ...state.admUpdate.admObjects[action.admOUid],
                regiesToAdd : regiesToAddTemp
            }
        }
    } else {
        admObjectsT = {
            ...state.admUpdate.admObjects,
            [action.admOUid] : {
                ...state.admUpdate.admObjects[action.admOUid],
                regiesToDelete : state.admUpdate.admObjects[action.admOUid].hasOwnProperty('regiesToDelete') ?
                                    [...state.admUpdate.admObjects[action.admOUid].regiesToDelete, action.regie.uid] :
                                    [action.regie.uid] 
            }
        }
    }
    return updateObject(state, {
        admUpdate: {
            ...state.admUpdate,
            admObjects : admObjectsT
        }
    })
}

const addMandatSupport = (state, action) => {
    let admObjectsT = {...state.admUpdate.admObjects}
    if(!state.admUpdate.admObjects.hasOwnProperty(action.admOUid)){
        admObjectsT = {
            ...state.admUpdate.admObjects,
            [action.admOUid] : {
                supportsToAdd : [...action.supports]
            }
        }
    } else {
        admObjectsT = {
            ...state.admUpdate.admObjects,
            [action.admOUid] : {
                ...state.admUpdate.admObjects[action.admOUid],
                supportsToAdd : state.admUpdate.admObjects[action.admOUid].hasOwnProperty('supportsToAdd') ? 
                                    [...state.admUpdate.admObjects[action.admOUid].supportsToAdd, ...action.supports] : 
                                    [...action.supports]
            }
        }
    }
    return updateObject(state, {
        admUpdate: {
            ...state.admUpdate,
            admObjects : admObjectsT
        }
    })
}

const deleteMandatSupport = (state, action) => {
    let admObjectsT = {...state.admUpdate.admObjects}
    
    if(!state.admUpdate.admObjects.hasOwnProperty(action.admOUid)){
        admObjectsT = {
            ...state.admUpdate.admObjects,
            [action.admOUid] : {
                supportsToDelete : [action.support]
            }
        }
    } else if(action.support.hasOwnProperty('new')) {
        let supportsToAddTemp = [...state.admUpdate.admObjects[action.admOUid].supportsToAdd]
        let supportToAddIndex = supportsToAddTemp.findIndex(row => row.uid === action.support.uid)
        if(supportToAddIndex >=0 )
            supportsToAddTemp.splice(supportToAddIndex, 1)
        admObjectsT = {
            ...state.admUpdate.admObjects,
            [action.admOUid] : {
                ...state.admUpdate.admObjects[action.admOUid],
                supportsToAdd : supportsToAddTemp
            }
        }
    } else {
        admObjectsT = {
            ...state.admUpdate.admObjects,
            [action.admOUid] : {
                ...state.admUpdate.admObjects[action.admOUid],
                supportsToDelete : state.admUpdate.admObjects[action.admOUid].hasOwnProperty('supportsToDelete') ?
                                    [...state.admUpdate.admObjects[action.admOUid].supportsToDelete, action.support] :
                                    [action.support] 
            }
        }
    }
    return updateObject(state, {
        admUpdate: {
            ...state.admUpdate,
            admObjects : admObjectsT
        }
    })
}

const addMandatMarketingMode = (state, action) => {
    let admObjectsT = {...state.admUpdate.admObjects}
    if(!state.admUpdate.admObjects.hasOwnProperty(action.admOUid)){
        admObjectsT = {
            ...state.admUpdate.admObjects,
            [action.admOUid] : {
                marketingModesToAdd : [...action.marketingModes]
            }
        }
    } else {
        admObjectsT = {
            ...state.admUpdate.admObjects,
            [action.admOUid] : {
                ...state.admUpdate.admObjects[action.admOUid],
                marketingModesToAdd : state.admUpdate.admObjects[action.admOUid].hasOwnProperty('marketingModesToAdd') ? 
                                    [...state.admUpdate.admObjects[action.admOUid].marketingModesToAdd, ...action.marketingModes] : 
                                    [...action.marketingModes]
            }
        }
    }
    return updateObject(state, {
        admUpdate: {
            ...state.admUpdate,
            admObjects : admObjectsT
        }
    })
}

const deleteMandatMarketingMode = (state, action) => {
    let admObjectsT = {...state.admUpdate.admObjects}
    
    if(!state.admUpdate.admObjects.hasOwnProperty(action.admOUid)){
        admObjectsT = {
            ...state.admUpdate.admObjects,
            [action.admOUid] : {
                marketingModesToDelete : [action.marketingMode.uid]
            }
        }
    } else if(action.marketingMode.hasOwnProperty('new')) {
        let marketingModesToAddTemp = [...state.admUpdate.admObjects[action.admOUid].marketingModesToAdd]
        let marketingModeToAddIndex = marketingModesToAddTemp.findIndex(row => row.uid === action.marketingMode.uid)
        if(marketingModeToAddIndex >=0 )
            marketingModesToAddTemp.splice(marketingModeToAddIndex, 1)
        admObjectsT = {
            ...state.admUpdate.admObjects,
            [action.admOUid] : {
                ...state.admUpdate.admObjects[action.admOUid],
                marketingModesToAdd : marketingModesToAddTemp
            }
        }
    } else {
        admObjectsT = {
            ...state.admUpdate.admObjects,
            [action.admOUid] : {
                ...state.admUpdate.admObjects[action.admOUid],
                marketingModesToDelete : state.admUpdate.admObjects[action.admOUid].hasOwnProperty('marketingModesToDelete') ?
                                    [...state.admUpdate.admObjects[action.admOUid].marketingModesToDelete, action.marketingMode.uid] :
                                    [action.marketingMode.uid] 
            }
        }
    }
    return updateObject(state, {
        admUpdate: {
            ...state.admUpdate,
            admObjects : admObjectsT
        }
    })
}

const addMandatGeographicalArea = (state, action) => {
    let admObjectsT = {...state.admUpdate.admObjects}
    if(!state.admUpdate.admObjects.hasOwnProperty(action.admOUid)){
        admObjectsT = {
            ...state.admUpdate.admObjects,
            [action.admOUid] : {
                geographicalAreasToAdd : [...action.geographicalAreas]
            }
        }
    } else {
        admObjectsT = {
            ...state.admUpdate.admObjects,
            [action.admOUid] : {
                ...state.admUpdate.admObjects[action.admOUid],
                geographicalAreasToAdd : state.admUpdate.admObjects[action.admOUid].hasOwnProperty('geographicalAreasToAdd') ? 
                                    [...state.admUpdate.admObjects[action.admOUid].geographicalAreasToAdd, ...action.geographicalAreas] : 
                                    [...action.geographicalAreas]
            }
        }
    }
    return updateObject(state, {
        admUpdate: {
            ...state.admUpdate,
            admObjects : admObjectsT
        }
    })
}

const deleteMandatGeographicalArea = (state, action) => {
    let admObjectsT = {...state.admUpdate.admObjects}
    
    if(!state.admUpdate.admObjects.hasOwnProperty(action.admOUid)){
        admObjectsT = {
            ...state.admUpdate.admObjects,
            [action.admOUid] : {
                geographicalAreasToDelete : [action.geographicalArea.uid]
            }
        }
    } else if(action.geographicalArea.hasOwnProperty('new')) {
        let geographicalAreasToAddTemp = [...state.admUpdate.admObjects[action.admOUid].geographicalAreasToAdd]
        let geographicalAreasToAddIndex = geographicalAreasToAddTemp.findIndex(row => row.uid === action.geographicalArea.uid)
        if(geographicalAreasToAddIndex >=0 )
            geographicalAreasToAddTemp.splice(geographicalAreasToAddIndex, 1)
        admObjectsT = {
            ...state.admUpdate.admObjects,
            [action.admOUid] : {
                ...state.admUpdate.admObjects[action.admOUid],
                geographicalAreasToAdd : geographicalAreasToAddTemp
            }
        }
    } else {
        admObjectsT = {
            ...state.admUpdate.admObjects,
            [action.admOUid] : {
                ...state.admUpdate.admObjects[action.admOUid],
                geographicalAreasToDelete : state.admUpdate.admObjects[action.admOUid].hasOwnProperty('geographicalAreasToDelete') ?
                                    [...state.admUpdate.admObjects[action.admOUid].geographicalAreasToDelete, action.geographicalArea.uid] :
                                    [action.geographicalArea.uid] 
            }
        }
    }
    return updateObject(state, {
        admUpdate: {
            ...state.admUpdate,
            admObjects : admObjectsT
        }
    })
}

const updateMandatObjects = (state, action) => {
    let admObjectsT = {...state.admUpdate.admObjects}
    if(!state.admUpdate.admObjects.hasOwnProperty(action.admOUid)){
        admObjectsT = {
            ...admObjectsT,
            [action.admOUid] : {
                objects : {
                    [action.object.field] : action.object.value
                }
            }
        }
    } else {
        admObjectsT = {
            ...admObjectsT,
            [action.admOUid] : {
                ...admObjectsT[action.admOUid],
                objects : admObjectsT[action.admOUid].hasOwnProperty('objects') ? 
                            {
                                ...admObjectsT[action.admOUid].objects,
                                [action.object.field] : action.object.value
                            } :
                            {
                                [action.object.field] : action.object.value
                            }
            }
        }
    }
    
    
    return updateObject(state, {
        admUpdate: {
            ...state.admUpdate,
            admObjects : admObjectsT
        }
    })
}

const updateMandatAdmObjectsAgency = (state, action) => {
    let admObjectsT = {...state.admUpdate.admObjects}
    let mandataireUid = state.admData?.mandataire?.uid
    if(!state.admUpdate.admObjects.hasOwnProperty(action.admOUid)){
        admObjectsT[action.admOUid] = {
            agencyUid : action.agencyUid
        }
    } else {
        if(mandataireUid === action.agencyUid)
            delete admObjectsT[action.admOUid].new
        admObjectsT[action.admOUid] = {
            ...admObjectsT[action.admOUid],
            agencyUid : action.agencyUid
        }
    }
    return updateObject(state, {
        admUpdate: {
            ...state.admUpdate,
            admObjects : admObjectsT
        }
    })

}

const addMandatAdmObjects = (state, action) => {
    let admObjectsT = {
        ...state.admUpdate.admObjects,
        [action.admOUid] : {
            new: true,
            isFirstSubAgency : state?.admData?.sousMandataires.length === 0,
            agencyUid : action.agencyUid,
            allProducts: 1,
            allMedias: 1,
            allAddeps: 1,
            allSupports: 1,
            allMarketingmodes: 1,
            objects: {
                ...action.purposesObject
            }    
        }
    }
    return updateObject(state, {
        admUpdate: {
            ...state.admUpdate,
            admObjects : admObjectsT
        }
    })
}

const updateMandatAdmObject = (state, action) => {
    let admObjectsT = {...state.admUpdate.admObjects}
    let field = action.field;
    let value = action.value;

    if(!state.admUpdate.admObjects.hasOwnProperty(action.admOUid)){
        admObjectsT = {
            ...state.admUpdate.admObjects,
            [action.admOUid] : {
                [field] : value
            }
        }
    } else {
        admObjectsT = {
            ...state.admUpdate.admObjects,
            [action.admOUid] : {
                ...state.admUpdate.admObjects[action.admOUid],
                [field] : value
            }
        }
    }
    return updateObject(state, {
        admUpdate: {
            ...state.admUpdate,
            admObjects : admObjectsT
        }
    })
}

const deleteMandatAdmObjects = (state, action) => {
    let admObjectsT = {...state.admUpdate.admObjects}
    let admOUid = Object.keys(admObjectsT).find(key => {
        return admObjectsT[key].agencyUid === action.entityUid
        }
    )
    // let admOUid = admObjectsT.findIndex(obj => obj.agencyUid === action.agencyUid)
    // if(admOUid) admObjectsT.splice(admOUid, 1)
    delete admObjectsT[admOUid]
    return updateObject(state, {
        admUpdate: {
            ...state.admUpdate,
            admObjects : admObjectsT
        }
    })
}

const updateMandatClauses = (state, action) => {
    return updateObject(state, {
        admUpdate: {
            ...state.admUpdate,
            clauses: action.clausesToUpdate
        }
    })
}

const deleteMandatClause = (state, action) => {
    return updateObject(state, {
        admUpdate: {
            ...state.admUpdate,
            clausesToDelete: state.admUpdate.hasOwnProperty('clausesToDelete') ? 
                                [...state.admUpdate.clausesToDelete, action.clauseToDelete] :
                                [action.clauseToDelete]
        }
    })
}

const updateMandatDepartments = (state, action) => {
    const departmentsToAdd = action.departments.filter(d => +d.selected && !d.documentDepartment);
    const departmentsToDelete = action.departments.filter(d => !+d.selected && d.documentDepartment);
    return updateObject(state, {
        admUpdate: {
            ...state.admUpdate,
            departmentsToAdd: departmentsToAdd,
            departmentsToDelete: departmentsToDelete
        }
    })
}

const updateMandatShowCommercialName = (state, action) => {
    return updateObject(state, {
        admUpdate: {
            ...state.admUpdate,
            showCommercialNameToUpdate:[action.showCommercialName]             
        }
    }) 
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SET_TABACTIVE: return setTabActive(state, action)
        case actionTypes.SET_TAB_SIDEACTIVE: return setTabSideActive(state, action)
        case actionTypes.MANDAT_START: return mandatStart(state, action)
        case actionTypes.MANDAT_SUCCESS: return mandatSuccess(state, action)
        case actionTypes.MANDAT_FAIL: return mandatFail(state, action)
        case actionTypes.UPDATEMANDAT_START: return updateMandatStart(state, action)
        case actionTypes.UPDATEMANDAT_SUCCESS: return updateMandatSuccess(state, action)
        case actionTypes.UPDATEMANDAT_FAIL: return updateMandatFail(state, action)
        case actionTypes.MANDAT_GET_TIMELINE_START: return mandatGetTimelineStart(state, action)
        case actionTypes.MANDAT_GET_TIMELINE_SUCCESS: return mandatGetTimelineSuccess(state, action)
        case actionTypes.MANDAT_GET_TIMELINE_FAIL: return mandatGetTimelineFail(state, action)
        case actionTypes.UPDATE_MANDAT_DATA: return updateAdmData(state, action)
        case actionTypes.UPDATE_MANDAT_ADDRESSES: return updateAdmAddresses(state, action)
        case actionTypes.ADD_MANDAT_USER: return addMandatuser(state, action)
        case actionTypes.ADD_MANDAT_USER_TO_UPDATE: return addMandatUserToUpdate(state, action)
        case actionTypes.UPDATE_MANDAT_USER_TO_ADD: return updateMandatUserToAdd(state, action)
        case actionTypes.UPDATE_MANDAT_USER_TO_UPDATE: return updateMandatUserToUpdate(state, action)
        case actionTypes.DELETE_MANDAT_USER: return deleteMandatUser(state, action)
        case actionTypes.DELETE_MANDAT_USER_TO_ADD: return deleteMandatUserToAdd(state, action)
        case actionTypes.ADD_MANDAT_SUBAGENCY: return addMandatSubagency(state, action)
        
        case actionTypes.DELETE_MANDAT_SUBAGENCY : return deleteMandatSubAgency(state, action)
        case actionTypes.DELETE_MANDAT_SUBAGENCY_TO_ADD : return deleteMandatSubAgencyToAdd(state, action)
        case actionTypes.RESET_MANDAT_TO_UPDATE_DATA : return resetMandatToUpdateData(state, action)
        case actionTypes.ADD_MANDAT_PRODUCT : return addMandatProduct(state, action)
        case actionTypes.DELETE_MANDAT_PRODUCT : return deleteMandatProduct(state, action)
        case actionTypes.ADD_MANDAT_MEDIA : return addMandatMedia(state, action)
        case actionTypes.DELETE_MANDAT_MEDIA : return deleteMandatMedia(state, action)
        case actionTypes.ADD_MANDAT_REGIE : return addMandatRegie(state, action)
        case actionTypes.DELETE_MANDAT_REGIE : return deleteMandatRegie(state, action)
        case actionTypes.ADD_MANDAT_SUPPORT : return addMandatSupport(state, action)
        case actionTypes.DELETE_MANDAT_SUPPORT : return deleteMandatSupport(state, action)
        case actionTypes.ADD_MANDAT_MARKETINGMODE : return addMandatMarketingMode(state, action)
        case actionTypes.DELETE_MANDAT_MARKETINGMODE : return deleteMandatMarketingMode(state, action)
        case actionTypes.ADD_MANDAT_GEOGRAPHICALAREA : return addMandatGeographicalArea(state, action)
        case actionTypes.DELETE_MANDAT_GEOGRAPHICALAREA : return deleteMandatGeographicalArea(state, action)
        case actionTypes.UPDATE_MANDAT_OBJECTS : return updateMandatObjects(state, action)
        case actionTypes.UPDATE_MANDAT_ADMOBJECTS_AGENCY : return updateMandatAdmObjectsAgency(state, action)
        case actionTypes.ADD_MANDAT_ADMOBJECTS : return addMandatAdmObjects(state, action)
        case actionTypes.UPDATE_MANDAT_ADMOBJECTS : return updateMandatAdmObject(state, action)
        case actionTypes.DELETE_MANDAT_ADMOBJECTS : return deleteMandatAdmObjects(state, action)
        case actionTypes.UPDATE_MANDAT_CLAUSES : return updateMandatClauses(state, action)
        case actionTypes.DELETE_MANDAT_CLAUSE : return deleteMandatClause(state, action)
        case actionTypes.UPDATE_MANDAT_DESCRIPTION : return updateMandatDescription(state, action)
        case actionTypes.UPDATE_MANDAT_PERIOD : return updateMandatPeriod(state, action)
        case actionTypes.UPDATE_MANDAT_CREATOR : return updateMandatCreator(state, action)
        case actionTypes.SET_ADM_SCROLLEDEND : return setAdmScrolledEnd(state, action)
        case actionTypes.SET_ADM_PDFPAGES : return setAdmPdfPages(state, action)
        case actionTypes.RESET_ADM_TO_INITIAL_STATE : return resetAdmToInitialState(state, action)
        //case actionTypes.GET_COUNT_USERS_PENDING : return countUsersPending(state, action)

        //case actionTypes.GET_COUNT_MASTERDATAS_PENDING : return countMasterDatasPending(state, action)  //pas utilisé car on utilise pas flag pending
        case actionTypes.ADD_RECIPIENT_USER : return addRecipientUser(state, action)
        case actionTypes.DELETE_RECIPIENT_USER : return deleteRecipientUser(state, action)
        case actionTypes.RESET_RECIPIENT_USERS : return resetRecipientUsers(state, action)
        case actionTypes.UPDATE_MANDAT_DEPARTMENTS : return updateMandatDepartments(state, action)
        case actionTypes.UPDATE_MANDAT_SHOW_COMMERCIAL_NAME : return updateMandatShowCommercialName(state, action)
        case actionTypes.SET_ADM : return setAdm(state, action)

        //case actionTypes.UPDATEADMPAYER_SUCCESS: return updateAdmPayer(state, action)
     
    
       
        default:
            return state
    }
}
export default reducer