import React, { useCallback, useEffect, useState } from 'react'
import './GeographicalAreaModal.css';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import Axios from '../../../../../../../../axios-edipub'
import { Button, Modal } from 'react-bootstrap';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import CheckIcon from '@material-ui/icons/Check';
import { Notyf } from 'notyf';
import { getNotyfObject } from '../../../../../../../../shared/utility';
import { useTranslation } from 'react-i18next';

function GeographicalAreaModal({agencyUid, handleClose, show, addGeographicalAreas,lang}) {
    const [entityGeographicalAreas , setEntityGeographicalAreas] = useState([]);
    const [selectedGeographicalAreas, setSelectedGeographicalAreas] = useState([]);
    const [geographicalAreasToAdd, setGeographicalAreasToAdd] = useState(null);
    const [filtredGeographicalAreas, setFiltredGeographicalAreas] = useState([]);
    const notyf = getNotyfObject();
    const {t} = useTranslation();

    const getGeographicalAreasList = useCallback(() => {
        Axios.get('geographicalarea').then(response => {
            setEntityGeographicalAreas(response.data)
            setFiltredGeographicalAreas(response.data)
        })
    }, [])

    // const addMediaToList = useCallback((e) => {
    //     e.preventDefault();
    //     if(mediaToAdd && mediaToAdd.trim() !== ""){
    //         Axios.post('media', {
    //             label : mediaToAdd
    //         }).then(response => {
    //             getMediasList()
    //             setMediaToAdd('');
    //         })
    //     }
    // }, [mediaToAdd])
    

    useEffect(() => {
        getGeographicalAreasList()
        return () => {
            setSelectedGeographicalAreas([]);
        }
    }, [agencyUid])

    const geographicalAreasFilterHandler = (searchQuery) => {
        let arrayFiltred = filtredGeographicalAreas;
        if (searchQuery == null || searchQuery.trim() === '' ) {
            setFiltredGeographicalAreas(entityGeographicalAreas)
        } else {
            arrayFiltred = entityGeographicalAreas.filter(row => {
                if (row.label.toLowerCase().includes(searchQuery.trim().toLowerCase()))
                    return true
                else return false
            })
            setFiltredGeographicalAreas(arrayFiltred)
        }    
    }

    const SelectGeographicalArea = (geographicalArea) => {
        let geographicalAreaIndex = selectedGeographicalAreas.findIndex(row => row.uid === geographicalArea.uid);
        let selectedTemp = [...selectedGeographicalAreas];
        if(geographicalAreaIndex >= 0)
            selectedTemp.splice(geographicalAreaIndex,1)
        else 
            selectedTemp = [...selectedGeographicalAreas, geographicalArea];
        setSelectedGeographicalAreas(selectedTemp);
    }

    const selectAllGeographicalAreas = (e) => {
        let selectedTemp = []
        if (e.target.checked) {
            selectedTemp = filtredGeographicalAreas.map(geographicalArea => ({uid : geographicalArea.uid, label: geographicalArea.label,label_en: geographicalArea.label_en, new: true}))
        }
        setSelectedGeographicalAreas(selectedTemp)
    }

    const addClickHandler = () => {
        addGeographicalAreas(selectedGeographicalAreas)
        handleClose()
    }
    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>{t('mandat:geoModalTitle', "Ajouter des zones géographiques")} </Modal.Title>
                </Modal.Header>
                <Modal.Body className="productsModal__body" >
                    <div className="productsModal__header">
                        <div className="productsModal__searchInput input-group input-group-sm">
                            <input 
                                type="text" 
                                className="form-control" 
                                placeholder={t('default:search', "Rechercher")}  
                                aria-label="Recipient's username" 
                                aria-describedby="basic-addon2" 
                                style={{height: "100%"}}
                                onChange={(e) => geographicalAreasFilterHandler(e.target.value)} 
                            />
                            <div 
                                className="input-group-append" 
                                style={{height: "100%"}}    
                            >
                                <span className="input-group-text" id="basic-addon2"><SearchIcon/> </span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <p className="productsModal_totalItems">{selectedGeographicalAreas?.length} {t('mandat:selectedZones', "zone(s) sélectionnée(s)")} </p>
                            <div className="form-check modals_selectAll_container">
                                <input className="form-check-input" type="checkbox" value="" id="selectAllMedias" onChange={(e) => selectAllGeographicalAreas(e)} />
                                <label className="form-check-label" for="selectAllMedias" >
                                    {t('mandat:selectAll', "Selectionner tout")}
                                </label>
                            </div>
                        </div>
                        
                    </div>
                    <div className="productsModal_items">
                        {
                            filtredGeographicalAreas?.length > 0 ? filtredGeographicalAreas.map(geographicalArea => (
                                <div className="productsModal__item">
                                    <div className="productsModal__itemIcon" onClick={() => SelectGeographicalArea({uid : geographicalArea.uid, label : geographicalArea.label,label_en : geographicalArea.label_en, new : true})}>
                                        {
                                            selectedGeographicalAreas.some(row => row.uid === geographicalArea.uid) ? (
                                                <CheckIcon style={{backgroundColor: "#4BCA81", color: "white", borderRadius: "5px"}} />
                                            ) : <AddIcon style={{color: "blue",border: "1px solid rgb(112, 110, 107, 0.3)", borderRadius: "5px" }} />
                                        }
                                        
                                    </div>
                                    <div className="productsModal__itemName">
                                        {lang==="FR" ? geographicalArea.label : geographicalArea.label_en}
                                    </div>
                                </div>
                            )) : <div>{t('mandat:noZones', 'Aucune zone géographique à afficher')} </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t('default:cancel', "Annuler")} 
                </Button>
                <Button variant="primary" onClick={() => addClickHandler()}>
                    {t('default:add', "Ajouter")} 
                </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default GeographicalAreaModal
