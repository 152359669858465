import React, { useEffect } from 'react'
import { useState } from 'react';
import iconEntity from '../../../assets/images/icon-entity.png'
import EntityGroupModal from './EntityGroupModal/EntityGroupModal'
import GroupIcon from '@material-ui/icons/Group';
import CloseIcon from '@material-ui/icons/Close';
import { checkValidity } from '../../../shared/utility';
import ResetWarningModal from './ResetWarningModal/ResetWarningModal';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { useTranslation } from 'react-i18next';
import Axios from '../../../axios-edipub';
import Spinner from 'react-bootstrap/Spinner';
import ConfirmDeleteLastEntityModal from './ConfirmDeleteLastEntityModal/ConfirmDeleteLastEntityModal';
import CustomConfirmModal from '../../ADMContext/ADMProgressLine/CustomConfirmModal/CustomConfirmModal';

function GroupForm({ group, setGroup, addGroup, updateGroup, deleteGroup, role, handleFormChanged, loading }) {
    const [showEntityModal, setShowEntityModal] = useState(false);
    const [showResetWarningModal, setShowResetWarningModal] = useState(false);
    const [categoryTemp, setCategoryTemp] = useState("Annonceur");
    const [errors, setErrors] = useState(null);
    const [connectedUserEntities, setConnectedUserEntities] = useState([]);
    const [editab, setEditab] = useState(false);
    const [intersect, setIntersect] = useState(null);
    const [showDeleteLastEntityModal, setShowDeleteLastEntityModal] = useState(false);
    const [entityToDel, setEntityToDel] = useState(null);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false)
    const userRole = localStorage.getItem('role');
    const userId = localStorage.getItem('userId');

    const { t } = useTranslation();

    useEffect(() => {

        setErrors(null);
        setEntityToDel(null)
    }, [group])


    useEffect(() => {

        setGroup({
            ...group,
            category: categoryTemp
        })

    }, [])



    const toint = (A) => {

        var t1 = A.map(function (numbe) {
            return { commercial_name: numbe.commercial_name }
        })
        return t1;
    }


    const inter = (a, b) => {
        var yy = false;
        a.forEach(function (item) {
            b.forEach(function (itemb) {
                if (item.commercial_name == itemb.commercial_name) {
                    yy = true;
                }
            });
        }); return yy;
    }


    const intersection = (a, b) => {
        var aa = toint(a);
        var bb = toint(b);
        var res = inter(aa, bb);
        return res;
    }



    useEffect(() => {
        if (["MG", "MR"].includes(role)) {
            Axios.get(`user/${userId}/user`).then(response => {
                let userEntities = response?.data?.user?.entities ? response?.data?.user?.entities : [];
                setConnectedUserEntities(userEntities);
            })
        }
    }, [role])

    const userEntitiesIncludesEntity = (entityUid) => {
        if (connectedUserEntities && (connectedUserEntities?.length > 0)) {
            return connectedUserEntities.some(entity => entity?.uid === entityUid)
        } else {
            return false
        }
    }

    const disableGroupInput = () => {
        switch (role) {
            case "AE":
            case "MR":
            case "MG":
                return false;
                break;
            case "MC":
                return true;
                break;
            default:
                return true;
                break;
        }
    }

    const disableCategoryGroupSelect = () => {
        switch (role) {
            case "AE":
                return false;
                break;
            case "MC":
                return true;
                break;
            case "MG":
            case "MR":
                if (group?.id)
                    return true;
                else
                    return false;
                break;
            default:
                return true;
                break;
        }
    }

    const showAddEntitiesBtn = () => {
        switch (role) {
            case "AE":
            case "MG":
            case "MR":
                return true;
                break;
            case "MC":
                return false;
                break;
            default:
                return false;
                break;
        }
    }

    const showDeleteEntityIcon = (entityUid) => {
        switch (role) {
            case "AE":
                return true;
                break;
            case "MC":
                return false;
                break;
            case "MG":
            case "MR":
                if (group?.id) {
                    if (group?.category === "Agence") {
                        return userEntitiesIncludesEntity(entityUid)
                    } else {
                        return true;
                    }
                } else {
                    return true
                }
                break;
            default:
                return false;
                break;
        }
    }

    const showAddGroupBtn = () => {
        return !group?.id && "AE, MG, MR".includes(role);
    }

    const showEditGroupBtn = () => {
        return group?.id && "AE, MG, MR".includes(role);
    }

    const inputGroupChangeHandler = (e) => {
        let field = e.target.name;
        let value = e.target.value
        setGroup({
            ...group,
            [field]: value
        })
        handleFormChanged(true);
    }

    const inputGroupChangeHandlerByNameValue = (field, value) => {
        setGroup({
            ...group,
            [field]: value
        })
        handleFormChanged(true);
    }

    const categoryChangeHandler = (value) => {
        if (group?.entities?.length > 0) {
            if (group?.category !== value) {
                setShowResetWarningModal(true);
                setCategoryTemp(value);
            }
        } else {
            setGroup({
                ...group,
                category: value
            })
        }

    }

    const categoryUpdateHandler = () => {
        setGroup({
            ...group,
            category: categoryTemp,
            entities: [],
            entitiesToAdd: [],
            entitiesToDelete: [...group?.entities]
        })
        handleFormChanged(true);
    }



    const addEntitiesHandler = (addedEntities) => {
        if (group && group.hasOwnProperty('entities')) {
            let groupEntitiesTemp = group?.entities?.length > 0 ? [...group.entities, ...addedEntities] : [...addedEntities];
            setGroup({
                ...group,
                entities: groupEntitiesTemp,
                entitiesToAdd: (group?.entitiesToAdd && group?.entitiesToAdd.length > 0) ? [...group.entitiesToAdd, ...addedEntities] : [...addedEntities]
            });
        } else {
            setGroup({
                ...group,
                entities: [...addedEntities],
                entitiesToAdd: (group?.entitiesToAdd && group?.entitiesToAdd.length > 0) ? [...group.entitiesToAdd, ...addedEntities] : [...addedEntities]
            })
        }
        handleFormChanged(true);
    }

    const isLastEntityMGInGroup = (entityUid) => {
        if (connectedUserEntities && (connectedUserEntities?.length > 0) && group?.entities && group?.entities?.length > 0) {
            for (let i = 0; i < connectedUserEntities.length; i++) {
                const agencyUid = connectedUserEntities[i]?.uid;
                if (agencyUid !== entityUid && group?.entities?.some(e => e?.uid === agencyUid))
                    return false
            }
            return true
        } else {
            return false
        }
    }

    const deleteEntityClickHandler = (entity) => {
        if (["MG", "MR"].includes(role) && group?.category === "Agence" && isLastEntityMGInGroup(entity?.uid)) {
            setEntityToDel(entity)
            setShowDeleteLastEntityModal(true);
        } else
            deleteEntityHandler(entity)
    }

    const deleteEntityHandler = (entity) => {
        let groupEntitiesTemp = [...group.entities];
        let entityIndex = groupEntitiesTemp.findIndex(row => row.uid === entity.uid);
        groupEntitiesTemp.splice(entityIndex, 1)
        if (entity.hasOwnProperty('new')) {
            let groupEntitiesToAddTemp = [...group.entitiesToAdd];
            let entityIndex = groupEntitiesToAddTemp.findIndex(row => row.uid === entity.uid);
            groupEntitiesToAddTemp.splice(entityIndex, 1)
            setGroup({
                ...group,
                entities: groupEntitiesTemp,
                entitiesToAdd: groupEntitiesToAddTemp
            })
        } else {
            setGroup({
                ...group,
                entities: groupEntitiesTemp,
                entitiesToDelete: (group?.entitiesToDelete && group?.entitiesToDelete.length > 0) ? [...group.entitiesToDelete, entity.uid] : [entity.uid]
            })
        }
        handleFormChanged(true);
    }

    const checkGroupFormValidity = (group) => {
        let errors = []
        if (!group?.hasOwnProperty('label') || !checkValidity(group?.label, { required: true })) {
            errors.push(t("masterData:emptyInputName"));
        }
        // if(!group.hasOwnProperty('representative') || !checkValidity(group?.representative, {required: true})){
        //     errors.push("Le champ 'Représentant du groupe' est vide ") ;
        // }
        if (!group?.hasOwnProperty('entities') || group?.entities?.length < 2) {
            errors.push(t("masterData:twoSocietyAtLeast"));
        }
        return errors;
    }

    const addGroupclickHandler = (e) => {
        e.preventDefault();
        let errors = checkGroupFormValidity(group);
        if (errors && errors.length > 0) {
            setErrors(errors)
        } else {
            setErrors(null)
            addGroup(e)
        }
    }
    const updateGroupclickHandler = (e) => {
        e.preventDefault();
        let errors = checkGroupFormValidity(group);
        if (errors && errors.length > 0) {
            setErrors(errors)
        } else {
            setErrors(null)
            updateGroup(e)
        }
    }

    const deleteGroupClickHandler = e => {
        e.preventDefault();
        setShowConfirmDeleteModal(true);
    }


    return (
        <>

            <div className="rightSide_header_container">
                <div className="header_infos_container">
                    <GroupIcon
                        className="rightSide_header_logo"
                        style={{ height: "48px", width: "48px", backgroundColor: "green", color: "white", borderRadius: "0.25rem" }}
                        fontSize="large" />
                </div>
            </div>

            {
                errors && errors.length > 0 ? (
                    <div className="alert alert-danger mt-2" role="alert">
                        <ul>
                            {
                                errors.map(error => (<li key={error}>{error}</li>))
                            }
                        </ul>
                    </div>
                ) : (
                    <div className="alert alert-light" role="alert">
                        {t('masterData:groupInfos', " Avant de poursuivre, assurez-vous d'avoir créé les entreprises à intégrer au groupe ")}
                    </div>
                )
            }
            <div className="rightSide_form_container">
                <form>
                    <div className="form_twoElements_container">
                        <div className="form_element_container">
                            <label htmlFor="groupName">{t('masterData:groupName', "Nom du groupe")}</label>
                            <input
                                type="text"
                                className="form-control"
                                id="groupName"
                                minLength="1"
                                autoComplete="off"
                                name="label"
                                value={group?.label || ''}
                                onChange={(e) => inputGroupChangeHandler(e)}
                                disabled={disableGroupInput()}
                            // disabled={("MC".includes(role)||("RC, RG".includes(role) && !group?.id===true)) ? true : false}
                            />
                        </div>

                        <div className="form_element_container">
                            <label >{t('masterData:agencyType', "Type des entreprises")}  </label>
                            <select
                                className="form-control"
                                style={{ height: "calc(1.5em + .75rem + 2px)" }}
                                required
                                id="category"
                                name="category"
                                value={group?.category || categoryTemp}
                                onChange={(e) => categoryChangeHandler(e.target.value)}
                                disabled={disableCategoryGroupSelect()}
                            // disabled={("MC, MR, MG".includes(role)||("RC, RG".includes(role) && !group?.id)) ? true : false}
                            >
                                {("AE, AG, RG".includes(role)) ?
                                    <>
                                        {(!"RG".includes(role)) && <option value="Annonceur">{t('default:advertiser', "Annonceur")} </option>}
                                        {("AE".includes(role)) && <option value="Agence">{t('default:agency', "Agence")}</option>}
                                        {("AE,RG".includes(role)) && <option value="Regie">{t('default:regie', "Régie")}</option>}
                                    </> :
                                    <option value={group?.category}>{group?.category}</option>
                                }

                            </select>
                            {
                                showResetWarningModal ? (
                                    <ResetWarningModal
                                        show={showResetWarningModal}
                                        handleClose={() => setShowResetWarningModal(false)}
                                        updateCategory={categoryUpdateHandler}
                                        cancelUpdate={() => setCategoryTemp(null)}
                                    />
                                ) : null
                            }
                        </div>
                    </div>


                    {/* les 2 cards */}
                    <div className="form_twoElements_container">
                        <div className="form_element_container" style={{ alignItems: "normal" }}>
                            <label>
                                {t('default:society', "Société")}
                                {
                                    showAddEntitiesBtn() ?
                                        <i
                                            className="fas fa-plus addEntities_icon"
                                            onClick={() => setShowEntityModal(true)}
                                        >
                                        </i>
                                        : null
                                }
                                {/* <Button className="btn btn-light btn-sm text-primary" onClick={() => setShowEntityModal(true)}>+ Ajouter des régies </Button> */}
                                {showEntityModal ? (
                                    <EntityGroupModal
                                        handleClose={() => setShowEntityModal(false)}
                                        show={showEntityModal}
                                        entitiesCategory={group?.category}
                                        addEntities={addEntitiesHandler}
                                        groupEntities={group?.entities || []}
                                    />
                                ) : null}
                            </label>
                            <div className="form_pillsElements_container" >
                                {
                                    group?.entities?.length > 0 ? group?.entities.map(ent => (
                                        <div className="form_pillElement_container" key={ent.uid}>
                                            <div className="pillElement_infos">
                                                <img className="pillElement_icon" src={iconEntity} ></img>
                                                <div className="pillElement_label">{ent.commercial_name}</div>
                                            </div>
                                            {
                                                showDeleteEntityIcon(ent?.uid) ?
                                                    <CloseIcon
                                                        style={{ color: "red", cursor: "pointer" }}
                                                        fontSize="small"
                                                        onClick={() => deleteEntityClickHandler(ent)}
                                                    />
                                                    : null
                                            }
                                        </div>
                                    )) : null
                                }
                                {
                                    showDeleteLastEntityModal &&
                                    <ConfirmDeleteLastEntityModal
                                        show={showDeleteLastEntityModal}
                                        handleClose={() => setShowDeleteLastEntityModal(false)}
                                        entityToDel={entityToDel}
                                        deleteEntity={deleteEntityHandler}
                                    />
                                }

                            </div>
                        </div>
                        <div className="form_element_container">
                            <label >{t('masterData:groupRepresentant', "Représentant du groupe ")} </label>
                            <select
                                className="form-control"
                                style={{ height: "calc(1.5em + .75rem + 2px)" }}
                                required
                                id="representative"
                                name="representative"
                                value={group?.representative || ''}
                                onChange={(e) => inputGroupChangeHandler(e)}
                                aria-label="Default select example"
                                disabled={disableGroupInput()}>
                                {
                                    group?.entities?.length > 0 ? group?.entities.map(ent => (
                                        <option key={ent.uid} value={ent.uid}>{ent.commercial_name}</option>
                                    )) : null
                                }

                            </select>
                            <div className="entityUser_roles" >
                                <div style={{ display: "flex" }}>
                                    <input
                                        style={{ margin: "0px 8px 0px 5px" }}
                                        className="form-check-input"
                                        type="checkbox"
                                        name="hide_representative"
                                        value=""
                                        id="hide_representative"
                                        checked={(group?.hide_representative && group?.hide_representative == "1") ? true : false}
                                        onChange={(e) => inputGroupChangeHandlerByNameValue(e.target.name, e.target.checked)}
                                    />
                                    <label className="form-check-label" htmlFor="hide_representative">
                                        {t('masterData:groupHideRepresentative', "Masquer le représentant dans le volet Facturation")}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="rightSide_form_submit_container">

                        {
                            loading ?
                                <div className="rightSide_form_submit_container">
                                    <Spinner animation="border" variant="primary" />
                                </div>
                                : showEditGroupBtn() ?
                                    <input
                                        type="submit"
                                        value={t('default:save', "Enregistrer")}
                                        className="btn btn-primary"
                                        onClick={(e) => updateGroupclickHandler(e)}></input>
                                    : showAddGroupBtn() ?
                                        <input
                                            type="submit"
                                            value={t('default:add', "Ajouter")}
                                            className="btn btn-primary"
                                            onClick={(e) => addGroupclickHandler(e)}></input>
                                        : null
                        }
                        {group?.canDelete === "1" && role === "AE" && <input
                            type="submit"
                            value={t('default:delete', "Supprimer")}
                            className="btn btn-danger rightSide_form_submitBtn"
                            onClick={(e) => deleteGroupClickHandler(e)}
                        />}
                    </div>

                </form>
                {
                    showConfirmDeleteModal &&
                    <CustomConfirmModal
                        show={showConfirmDeleteModal}
                        handleClose={() => setShowConfirmDeleteModal(false)}
                        text={t('masterData:confirmDeleteGroup', "Voulez-vous vraiment supprimer ce groupe ?")}
                        okCallback={deleteGroup}
                        loading={loading}
                    />
                }
            </div>


        </>
    )
}
const mapStateToProps = (state) => ({
    role: state.auth.role,
    authUserId: state.auth.userId
})
const mapDispatchToProps = dispatch => ({
})
export default connect(mapStateToProps, mapDispatchToProps)(GroupForm)
