import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function RedirectEntityModal({handleClose, callback}) {
    const {t} = useTranslation();
    return (
        <>
            <Modal show={true} onHide={() => handleClose(false)} contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                <Modal.Title>{t('masterData:pageChange', "Changement de page")} </Modal.Title>
                </Modal.Header>
                <Modal.Body className="ADMCreationModal__body" style={{width: "650px"}}>
                    {t('masterData:redirectEntityModal', "Vous allez être redirigé vers la page de l'entreprise déjà existante pour votre recherche. Voulez-vous vraiment quitter la page ? Toutes les modifications non sauvegardées seront perdues. Une fois redirigé sur l'entreprise existante, si vous souhaitez interroger les APIs pour cette entreprise il vous faudra de nouveau cliquer sur le bouton 'Interroger SIREN / Societe.com'.")}
                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={() => {callback(); handleClose(true)}}>
                    {t('default:confirm', "Confirmer")}
                </Button>
                <Button variant="secondary" onClick={() => handleClose(false)}>
                    {t('default:cancel', "Annuler")}
                </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default RedirectEntityModal
