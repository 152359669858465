import React, { useEffect, useState } from 'react' 
import './CreateEntity.css' 
import CreateEntityComponent from '../../../components/TokenAccess/CreateEntity/CreateEntityComponent.js'; 

function CreateEntity() { 
    return (
        <>
        <div className="mandat_container"> 
            <div className= "MandatBody">   
                <div className="MandatEdition w-100"> 
                    <CreateEntityComponent 
                        isAuth = {false} 
                        > 
                    </CreateEntityComponent>
                </div>
            </div>
        </div>
        </>
    )
} 

export default CreateEntity
