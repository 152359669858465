import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner'

function UpdateSignatoryModal({handleClose, show, signatory, updateSignatory, editAdvertiserContact, setIsUnknwonSignatory, setAdvertiserContact,selectUnknwonSignatory,advertiserContact}) {
    const {t} = useTranslation();

    const confirmClickHandler = () => { 
        if(selectUnknwonSignatory==true){
            editAdvertiserContact(true, advertiserContact);
        }else{
            if(signatory?.userId)      
                updateSignatory(signatory?.docId, signatory?.userId, signatory?.entityId, 1)
            else{
                setAdvertiserContact(signatory?.advertiserContact)
                editAdvertiserContact(true, signatory?.advertiserContact)
                // setIsUnknwonSignatory(true)
            }
        }
        handleClose();
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                <Modal.Title>{t('default:alert', "Alerte")}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="ADMCreationModal__body" style={{width: "650px"}}>
                    <p>
                        {t("mandat:confirmUpdateSignatory", `Un signataire existe déjà pour ${signatory?.entityName}. Voulez vous confirmer le choix du nouveau signataire ?`, {entityName: signatory?.entityName})}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t('default:cancel', "Annuler")} 
                </Button>
                    <Button variant="primary" onClick={() => confirmClickHandler()}>                     
                        {t('default:confirm', "Confirmer")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default UpdateSignatoryModal
