import React, { useCallback, useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux';
import ListManagerAbstract from './ListManagerAbstract/ListManagerAbstract';
import { useTranslation } from 'react-i18next';
import Axios from '../../../axios-edipub'
import { getNotyfObject } from '../../../shared/utility'; 


function ListManagerClause({handleFormChanged, entitiesList}) {

    const notyf = getNotyfObject();  
    const {t} = useTranslation(); 
    const [items , setItems] = useState([]);
    const [itemsToUpdate , setItemsToUpdate] = useState([]);
    const [itemsToDelete , setItemsToDelete] = useState([]);
    const [newItem, setNewItem] = useState({}); 
    const [updateLoading , setUpdateLoading] = useState(false);       
    const [entity, setEntity] = useState((typeof entitiesList !== 'undefined' && entitiesList !== null && entitiesList.length > 0 )   ?  entitiesList[0].uid:null )
 
    useEffect(() => {
       if (entitiesList!=null && entitiesList.length > 0) {
            entityChangeHandler(entitiesList[0].uid)
        }
    }, [entitiesList])

    const entityChangeHandler = (uid) => {
        setEntity(uid)
        getClauseList(uid)
        setItemsToUpdate([])
        setItemsToDelete([])
        setNewItem([])
    }
   
    const getClauseList = useCallback((entityp = entity) => {
        if (entityp){            
            let params = {'entity': entityp};   
            Axios.get('clause', {'params' : params }).then(response => {
                setItems(response.data) 
            })
        }
    }, [entity])
 
    const clauseAddHandler = () => {
        let tmpNewItem = {...newItem}
        tmpNewItem['agency_uid'] = entity
      
        Axios.post('/clause', tmpNewItem
        ).then (response => {
            getClauseList()
            notyf.success('Ajout sauvegardé') 
        }).catch(err => {
            notyf.error('Une erreur s\'est produite. ' + err?.response?.data?.error)
        })   
    }

    const clausesUpdateHandler = ( ) => {
        setUpdateLoading(true)
        let data = { 
            'clausesToUpdate' : itemsToUpdate,
            'clausesToDelete' : itemsToDelete,
            'entity' : entity
        }  
        Axios.put(`/clause//editall`, data)
            .then(response => {
                setItemsToUpdate([])
                setItemsToDelete([])
                getClauseList()
                setUpdateLoading(false)
                handleFormChanged(false)  
                notyf.success('Modifications sauvegardées')
            }).catch(err => { 
                setUpdateLoading(false)   
                if(err.response?.data?.error && (err.response?.data?.error === 'Expired token'))
                    notyf.error('Une erreur s\'est produite ! Votre session a expiré. Veuillez vous déconnecter et vous reconnecter avant de renouveler l\'opération.')
                else 
                    notyf.error('Une erreur s\'est produite !')
            }) 
    } 

    return (
        <ListManagerAbstract 
            title = {t('masterData:clauses',"Clauses")}    
            addNewTitle =  {t('masterData:addNewClause', "Ajouter une nouvelle clause" )}      
            addNewAlreadyExistLabel = {t("masterData:existClauseLabel", "Cette clause existe déja!")}  
            fieldColumnNames= {{label:'Francais', label_en:'Anglais'}}
            textFields= {['label','label_en']}
            fieldsMaxLen= {{label:1024, label_en:1024}}
            positionField= {'orderIndex'}
            items={items}
            setItems={setItems}
            itemsToUpdate={itemsToUpdate}
            setItemsToUpdate={setItemsToUpdate}
            itemsToDelete={itemsToDelete}
            setItemsToDelete={setItemsToDelete}
            newItem={newItem}
            setNewItem={setNewItem} 
            getItemList={() => getClauseList(entity)}
            itemAddHandler={clauseAddHandler}
            itemsUpdateHandler={clausesUpdateHandler}
            entitiesList={entitiesList}  
            entity={entity}  
            entityChangeHandler={entityChangeHandler}
            handleFormChanged={handleFormChanged}
            updateLoading={updateLoading} 
        />
     ) 
}

export default ListManagerClause