import React from 'react'
import './NotificationCard.css';

import CancelIcon from '@material-ui/icons/Cancel';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

function NotificationCard(props) {
    const {icon,label, adm, company, time, children} = props;

    return (
        <div className="homeNotifications__element">
            <div className="homeNotifications__elementHeader">
                <div className="homeNotifications__elementLabel">
                    {icon}
                    <h6>{label} </h6>
                </div>
                <CancelIcon
                    style={{
                        fontSize: "1.2rem",
                        cursor: "pointer",
                        color: "#b6b6b6d1"
                    }}
                />
            </div>
            <div className="homeNotifications__elementInfo">
                <h6>ADM: <span style={{color: "#007bff"}}>{adm} </span></h6>
                <h6>{company} </h6>
            </div>
            <div className="homeNotifications__elementBody">
                {children}
            </div>
            <div className="homeNotifications__elementFooter">
                    <AccessTimeIcon
                        style={{
                            fontSize: "0.9rem",
                            color: "green",
                            marginRight: "5px"
                        }}
                    />
                    <h6>{time} </h6>
            </div>
        </div>
    )
}

export default NotificationCard
