import { Update } from '@material-ui/icons';
import React, { useCallback, useEffect, useState } from 'react';
import './ADMWorkFlow.css';
import Axios from '../../../axios-edipub';
import { connect } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CTooltip, getNotyfObject } from '../../../shared/utility';
import UpdateSignatoryModal from './UpdateSignatoryModal/UpdateSignatoryModal';
import CantUpdateValidatorModal from './CantUpdateValidatorModal/CantUpdateValidatorModal';

function ADMWorkFlow(props) {
  const { t } = useTranslation();
  let {
    getMandatData,
    mandat,
    handleClose,
    show,
    role,
    connectedUserId
  } = props;
  const [users, setUsers] = useState(null)
  const [filter, setFilter] = useState("")
  const [isADMCreator, setIsADMCreator] = useState(false)
  const [selectedRegieEntities, setSelectedRegieEntities] = useState([]);
  const [showUpdateSignatoryModal, setShowUpdateSignatoryModal] = useState(false);
  const [signatoryToUpdate, setSignatoryToUpdate] = useState(null);
  const [isUnknwonSignatory, setIsUnknwonSignatory] = useState(mandat?.advertiserContact !== null);
  const [advertiserContact, setAdvertiserContact] = useState(mandat?.advertiserContact);
  const [selectUnknwonSignatory, setSelectUnknwonSignatory] = useState(false);
  const [showCantUpdateValidatorModal, setShowCantUpdateValidatorModal] = useState(false);
  let notyf = getNotyfObject();
  var tBody = null;

  useEffect(() => {
    usersRefresh()
    return () => {
      if (mandat.status !== "SIGNED") {
        getMandatData(mandat?.uid, true)
      }
    }
  }, [])

  const usersRefresh = () => {
    Axios.get('/user/' + mandat.uid + '/concernedUsers',
      {
        params: {
          //"filter": filter  
        }
      }
    ).then(response => {
      let data = response?.data?.users;
      if (connectedUserId == response?.data?.admCreatorUid)
        setIsADMCreator(true)
      else setIsADMCreator(false)

      let regie = response?.data?.users_regie;
      const merge = (regie != null && regie.length > 0) ? data.concat(regie) : data

      setUsers(merge)
      let temp = []
      regie.filter(r => r.category == "Regie").map(userRegie => {
        temp.push({
          userId: userRegie.user_id,
          entityId: (userRegie.selectedEntity != null && userRegie?.selectedEntity != "")
            ? userRegie?.selectedEntity
            : +regieEntityNameAndId(regiesEntitiesStringToTable(userRegie.official_name)[0])[1],
          entityName: regieEntityNameAndId(regiesEntitiesStringToTable(userRegie.official_name)[0])[0],
          documentId: userRegie.document_id
        })
      })
      setSelectedRegieEntities(temp)

    }).catch(response => {
      notyf.error(t("mandat:generatedErrorMessage"))
    })
  }

  const getFiltredUsers = useCallback((searchQuery = null) => {
    let filterQuery = searchQuery ? searchQuery : filter;
    filterQuery = filterQuery.trim();
    let usersTemp = users ? [...users] : [];
    let filtredUsersTemp = [...usersTemp]
    if (usersTemp && filterQuery) {
      filtredUsersTemp = usersTemp.filter(user => (
        (user?.first_name?.toUpperCase()?.includes(filterQuery.toUpperCase())) ||
        (user?.last_name?.toUpperCase()?.includes(filterQuery.toUpperCase())) ||
        (user?.email?.toUpperCase()?.includes(filterQuery.toUpperCase())) ||
        (user?.official_name?.toUpperCase()?.includes(filterQuery.toUpperCase())) ||
        (user?.category?.toUpperCase()?.includes(filterQuery.toUpperCase()))
      ))
    }
    return filtredUsersTemp
  }, [users, filter])

  const editAdvertiserContact = (isChecked, advertiserContact) => {
    Axios.put('/document/' + mandat?.uid + '/advertiserContact',
      {
        "isUnknwonSignatory": isChecked,
        "advertiserUserId": advertiserContact,
        "advertiserUid": mandat?.annonceur?.uid
      }
    ).then(response => {
      if (isChecked) {
        setAdvertiserContact(advertiserContact)
        setIsUnknwonSignatory(true)
        let contactBelongsToMandataire = mandat?.mandataire?.users?.find((u) => u.id === advertiserContact)
        toggleDestinataire(mandat?.id, advertiserContact, (contactBelongsToMandataire ? mandat.mandataire.id : mandat?.annonceur?.id), 1)
      } else {
        setAdvertiserContact(null)
        setIsUnknwonSignatory(false)
      }
      usersRefresh()
    }).catch(response => {
      notyf.error(t("mandat:generatedErrorMessage"))
    })
  }

  const unknownSignCheckChangeHandler = (e) => {
    let checked = e.target.checked;
    if (checked) {
      setIsUnknwonSignatory(true)
    } else {
      setIsUnknwonSignatory(false)
    }
  }

  const advertiserContactChangeHandler = (userId) => {
    if (userId != "") {

      if (isUniqueSignatory(null, mandat?.annonceur?.id)) {
        //on considere comme unique le signataire meme si il y avait avant un autre signataire inconnu (null) car pas d'incidence dans les tables en bdd
        setAdvertiserContact(userId);
        setSelectUnknwonSignatory(true);
        editAdvertiserContact(true, userId);
      } else {
        let signatory = {
          docId: mandat?.id,
          entityId: mandat?.annonceur?.id,
          advertiserContact: userId,
          entityName: mandat?.annonceur?.official_name,
          userId: null
        }
        setShowUpdateSignatoryModal(false)
        setSignatoryToUpdate(signatory);
        setShowUpdateSignatoryModal(true)
      }
    }

  }

  const toggleContributeur = (document_id, user_id, entity_id, is_true) => {
    if (!is_true && users.some(row => row.validatedDoc === "1")) {
      setShowCantUpdateValidatorModal(true)
    } else {
      Axios.put('/user/' + document_id + '/editContributeur',
        {
          "user_id": user_id,
          "entity_id": entity_id,
          "is_true": is_true
        }).then(response => {
          usersRefresh()
        }).catch(response => {
          notyf.error(t("mandat:generatedErrorMessage"))
        })
    }
  }

  const toggleDestinataire = (document_id, user_id, entity_id, is_true) => {
    Axios.put('/user/' + document_id + '/editDestinataire',
      {
        "user_id": user_id,
        "entity_id": entity_id,
        "is_true": is_true
      }
    ).then(response => {
      usersRefresh()
    }).catch(response => {
      notyf.error(t("mandat:generatedErrorMessage"))
    })
  }

  const toggleDestinataireRegie = (document_id, user_id, is_true) => {
    Axios.put('/user/' + document_id + '/editDestinataireRegie',
      {
        "user_id": user_id,
        "is_true": is_true,
        "selectedRegieEntities": selectedRegieEntities
      }
    ).then(response => {
      usersRefresh()
    }).catch(response => {
      notyf.error(t("mandat:generatedErrorMessage"))
    })
  }

  const updateSignatory = (document_id, user_id, entity_id, is_true) => {
    Axios.put('/user/' + document_id + '/editSignataire',
      {
        "user_id": user_id,
        "entity_id": entity_id,
        "is_true": is_true
      }).then(response => {
        setAdvertiserContact(response?.data?.advertiserContact);
        setIsUnknwonSignatory(response?.data?.advertiserContact !== null)
        setSignatoryToUpdate(null)
        usersRefresh()
      }).catch(response => {
        notyf.error(t("mandat:generatedErrorMessage"))
      })
  }

  const isUniqueSignatory = (userId, entityId) => {
    const otherSignatories = users?.filter(u => +u.is_signataire === 1 && +u.user_id !== +userId && +u.entity_id === +entityId)
    return otherSignatories.length === 0
  }

  const isAlreayInSignatureWorkflowEdition = () => {
    return props.mandat.status === "TO_BE_SIGNED" && "MR, MG".includes(role)
  }
  const isSignedADMWorkflowEdition = () => {
    return props.mandat.status === "TO_BE_SIGNED" && "MR, MG".includes(role)
  }

  const toggleSignataire = (document_id, user_id, entity_id, is_true) => {
    if (!is_true) {
      if (isUniqueSignatory(user_id, entity_id)) {
        notyf.error('Un signataire doit étre désigné pour chaque acteur')
      } else {
        updateSignatory(document_id, user_id, entity_id, is_true)
      }
    } else {
      if (isUniqueSignatory(user_id, entity_id)) {
        updateSignatory(document_id, user_id, entity_id, is_true)
      } else {
        let signatory = {
          docId: document_id,
          entityId: entity_id,
          entityName: users?.find(u => (+u.user_id === +user_id && +u.entity_id === +entity_id))?.official_name,
          userId: user_id
        }
        setSignatoryToUpdate(signatory);
        setSelectUnknwonSignatory(false);
        setShowUpdateSignatoryModal(true)
      }
    }
  }


  const editSignatureOrderHandler = (doc2UserUid, signOrder) => {
    if (signOrder >= 1)
      Axios.put(`/document2user/${doc2UserUid}/signatureOrder`, { signOrder })
        .then(response => {
          usersRefresh();
        }).catch(response => {
          notyf.error(t("mandat:generatedErrorMessage"))
        })
  }

  const searchChangeHandler = (e) => {
    let inputTape = e.target.value;
    setFilter(inputTape)
  }

  const regiesEntitiesStringToTable = (stringRegiesIds) => {
    return stringRegiesIds.split(',');
  }

  const regieEntityNameAndId = (stringRegieRef) => {
    return stringRegieRef.split(';');
  }

  const toggleSelectedDestinataireRegie = (e, reloadUsers) => {
    const [user_id, document_id] = e.target.name.split('|');
    const selectedEntity = e?.target?.value;
    let tempSelectedRegieEntities = selectedRegieEntities
    tempSelectedRegieEntities.map(regieEntity => {
      if (regieEntity.userId == user_id && regieEntity.documentId == document_id)
        regieEntity.entityId = +selectedEntity
    })
    setSelectedRegieEntities(tempSelectedRegieEntities)
    Axios.put('/user/' + document_id + '/editSelectedDestinataireRegie',
      {
        "user_id": user_id,
        "selectedEntity": selectedEntity
      }).then(response => {
        if (reloadUsers == 1)
          usersRefresh()
      })

  }

  const getRegieEntityName = (user) => {
    let regieName = "";
    selectedRegieEntities.map(userRegieEntity => {
      if (userRegieEntity.userId == user.user_id) {
        if (user.is_recipient)
          regieName = user.selectedEntityOfficialName
        else
          regieName = userRegieEntity.entityName
      }
    })
    return regieName;
  }

  const contribDisabled = (user) => {
    // return user?.contributor_imposed === '1' && (user?.user_id === user.entity_imposed_contributor) && role !== "MG"
    return user?.contributor_imposed === '1' && (user?.user_id === user.entity_imposed_contributor)
  }
  const signDisabled = (user) => {
    // return (user?.signatory_imposed === '1' && role !== "MG") || (user?.status === "Desactivée" && user.is_signataire != 1)
    return (user?.signatory_imposed === '1') || (user?.status === "Desactivée" && user.is_signataire != 1)
  }
  const destinataireDisabled = (user) => {
    return user?.status === "Desactivée" && user.is_recipient != 1
  }


  tBody = getFiltredUsers()?.map(user => (

    <tr className="roleHeaders">
      <td scope="col" className="role"></td>
      <td scope="row" className="role" >

        {
          user.is_contributor != 1 ? (
            (user.category != "Regie" && user?.user_id !== null) ? (
              'DRAFT, TO_BE_APPROVED'.includes(mandat.status) ? (
                <button className="workflow_button hover_regie" onClick={() => toggleContributeur(user.document_id, user.user_id, user.entity_id, 1)}>
                  <i className="fas fa-plus"></i>
                </button>
              ) : (
                <button className="workflow_button" disabled={true} style={{ backgroundColor: "#e5e2de" }}>
                  <i className="fas fa-thumbs-up disabled" style={{ color: "gray" }}></i>
                </button>
              )
            ) : (
              <button className="workflow_button" disabled style={{ backgroundColor: "#e5e2de" }}>
                <i className="fas fa-thumbs-up disabled" style={{ color: "gray" }} ></i>
              </button>
            )
          ) : (
            user.category != "Regie" && 'DRAFT, TO_BE_APPROVED'.includes(mandat.status) ? (
              <button className="workflow_button regie_active" onClick={() => toggleContributeur(user.document_id, user.user_id, user.entity_id, 0)} disabled={(!'DRAFT, TO_BE_APPROVED'.includes(mandat.status) || contribDisabled(user)) ? true : false} style={(contribDisabled(user) ? { cursor: "default" } : {})}>
                <i className="fas fa-thumbs-up "></i></button>
            ) : (
              <button className="workflow_button regie_active" disabled style={{ backgroundColor: "#e5e2de" }}
              // onClick={() => handleContributorRegieUser(user.document_id, user.user_id, user.entity_id, user.is_recipient, 0)} 
              // disabled={!'DRAFT, TO_BE_APPROVED'.includes(mandat.status) ? true : false}
              >
                <i className="fas fas fa-thumbs-up disabled" style={{ color: "gray" }}></i></button>
            )
          )
        }
      </td>
      <td className="role">
        {user.category != "Regie" && mandat.status !== "SIGNED" ? (
          user.is_signataire != 1 ? (
            <button className="workflow_button hover_annonceur" disabled={signDisabled(user)} onClick={() => toggleSignataire(user.document_id, user.user_id, user.entity_id, 1)} style={(signDisabled(user) ? { backgroundColor: "#e5e2de" } : {})} >
              <i className={(signDisabled(user) ? 'fas fas fa-award disabled ' : 'fas fa-plus ')} style={(signDisabled(user) ? { color: "gray" } : {})}></i>
            </button>
          ) : (
            <button className="workflow_button annonceur_active" onClick={() => toggleSignataire(user.document_id, user.user_id, user.entity_id, 0)} disabled={signDisabled(user)} style={(signDisabled(user) ? { cursor: "default" } : {})} >
              <i className="fas fa-award "></i></button>
          )
        ) : (
          <button className="workflow_button" disabled style={{ backgroundColor: "#e5e2de" }}>
            <i className="fas fa-award disabled" style={{ color: "gray" }}></i>
          </button>
        )}

      </td>
      <td className="role">
        {user.is_recipient != 1 ? (
          user?.user_id !== null ? (
            user.category != "Regie" ? (
              <button className={(destinataireDisabled(user) ? "workflow_button " : "workflow_button hover_agence")} disabled={destinataireDisabled(user)} onClick={() => toggleDestinataire(user.document_id, user.user_id, user.entity_id, 1)} >
                <i className="fas fa-plus" style={(destinataireDisabled(user) ? { color: "gray" } : {})} ></i>
              </button>
            ) : (
              <button className={(destinataireDisabled(user) ? "workflow_button " : "workflow_button hover_agence")} disabled={destinataireDisabled(user)} onClick={() => toggleDestinataireRegie(user.document_id, user.user_id, 1)} >
                <i className="fas fa-plus" style={(destinataireDisabled(user) ? { color: "gray" } : {})}></i>
              </button>
            )
          ) : (
            <button className="workflow_button" disabled style={{ backgroundColor: "#e5e2de" }}>
              <i className="fas fa-plus disabled" style={{ color: "gray" }}></i>
            </button>
          )
        ) : (
          user.category != "Regie" ? (
            <button className="workflow_button agence_active" onClick={() => toggleDestinataire(user.document_id, user.user_id, user.entity_id, 0)} ><i className="far fa-eye "></i></button>
          ) : (
            <button className="workflow_button agence_active" onClick={() => toggleDestinataireRegie(user.document_id, user.user_id, 0)} ><i className="far fa-eye "></i></button>
          )
        )}
      </td>
      <td scope="col" className="role"></td>
      <td className="name_header">
        {
          user?.user_id === null ?
            (<strong>{t('mandat:unknown')}</strong>)
            :
            (<span>{`${user?.first_name} ${user?.last_name}`}</span>)
        }
      </td>
      {
        user.category != "Regie" ?
          <td className="company_header">{user.official_name} </td>
          :
          <td className="company_header">
            <select
              name={user.user_id + "|" + user.document_id}
              onChange={(e) => toggleSelectedDestinataireRegie(e, user.is_recipient)}
              className="form-control-sm"
              style={{ border: "0.5px solid lightgray", color: "#666666", padding: "0px", margin: "-6px", fontSize: "12px", maxWidth: "300px" }}>
              {
                regiesEntitiesStringToTable(user.official_name).map(
                  regie => (
                    <option
                      value={regieEntityNameAndId(regie)[1]}
                      key={regieEntityNameAndId(regie)[1]}
                      selected={user?.selectedEntity != null && regieEntityNameAndId(regie)[1] == user?.selectedEntity}>
                      {regieEntityNameAndId(regie)[0]}
                    </option>

                  )
                )
              }
            </select>
          </td>
      }
      <td className="mail_header nowrap" title={user?.user_id === null ? null : user.email}>
        {
          user?.user_id === null ?
            (<strong>{t('mandat:unknown')}</strong>)
            :
            (<span>{user.email}</span>)
        }
      </td>
      {(user.category == "Annonceur") ? (<td className="type_header annonceur_background">{t('default:advertiser', "ANNONCEUR").toUpperCase()} </td>)
        : (user.category == "Regie") ? (<td className="type_header regie_background">{t('default:regie', "REGIE").toUpperCase()}</td>)
          : (user.category == "Agence") ? (<td className="type_header agence_background">{t('default:agency', "AGENCE").toUpperCase()} </td>)
            : (<td>No Type</td>)
      }
      <td></td>
    </tr>
  ))


  return (

    <Modal
      show={show}
      onHide={handleClose}
      //contentClassName="modal_w_mc" 
      dialogClassName="modalSize"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {t('mandat:workflowBtn', "Processus d'approbation")}
        </Modal.Title>
      </Modal.Header>
      {
        isADMCreator ?
          <Modal.Body>
            <div className="actors-List">
              <h6>{t('mandat:actorList', "Liste des acteurs")}</h6>
            </div>

            <div className="body-container">

              <div className="search_bloc">
                <div className="input-group md-form form-sm form-2 pl-0 search">
                  <input
                    className="form-control my-0 py-1 red-border"
                    type="text"
                    placeholder={t('default:search', "Rechercher")}
                    aria-label="Search"
                    onChange={(e) => searchChangeHandler(e)} />
                  <div className="input-group-append">
                    <span className="input-group-text red lighten-3" id="basic-text1">
                      <i className="fas fa-search text-grey" aria-hidden="true"></i></span>
                  </div>
                </div>
                <div className="workflow_advertiserContact_container">
                  <div className="form-check advertiserContact_checkBox_container">
                    <input
                      style={{ margin: "0px" }}
                      className="form-check-input"
                      type="checkbox"
                      id="advertiserContact"
                      checked={isUnknwonSignatory}
                      onChange={(e) => unknownSignCheckChangeHandler(e)}
                      disabled={!'DRAFT, TO_BE_APPROVED'.includes(mandat.status) && !isAlreayInSignatureWorkflowEdition()}
                    />
                    <label className="form-check-label" htmlFor="advertiserContact">
                      {t('mandat:unknownAdvertiserSignatory')}
                    </label>
                  </div>
                  {
                    isUnknwonSignatory &&
                    <div>
                      <label>{t('mandat:selectForwardingContact', "Sélectionnez votre contact relais")}</label>
                      <select
                        className="form-control"
                        value={advertiserContact}
                        onChange={(e) => advertiserContactChangeHandler(e.target.value)}
                      >
                        <option value="" >{t('mandat:selectAdvertiserContact', "-- Sélectionnez un contact relais --")}</option>
                        <optgroup label="Annonceur">
                          {
                            mandat?.annonceur?.users?.filter((u, index) =>
                              mandat?.annonceur?.users.map(u2 => u2.id).indexOf(u.id) === index // supprime les duplications
                            ).sort((a, b) => {
                              if ((a.first_name + " " + a.last_name).toUpperCase() > (b.first_name + " " + b.last_name).toUpperCase())
                                return 1;
                              if ((a.first_name + " " + a.last_name).toUpperCase() < (b.first_name + " " + b.last_name).toUpperCase())
                                return -1;
                              return 0;
                            }).map(user => <option value={user.id}>{user?.first_name + " " + user?.last_name + " (" + user.email + ")"}</option>)
                          }
                        </optgroup>
                        <optgroup label="Mandataire">
                          {
                            mandat?.mandataire?.users?.filter((u, index) =>
                              mandat?.mandataire?.users.map(u2 => u2.id).indexOf(u.id) === index // supprime les duplications
                            ).sort((a, b) => {
                              if ((a.first_name + " " + a.last_name).toUpperCase() > (b.first_name + " " + b.last_name).toUpperCase())
                                return 1;
                              if ((a.first_name + " " + a.last_name).toUpperCase() < (b.first_name + " " + b.last_name).toUpperCase())
                                return -1;
                              return 0;
                            }).map(user => <option value={user.id}>{user?.first_name + " " + user?.last_name + " (" + user.email + ")"}</option>)
                          }
                        </optgroup>
                      </select>
                    </div>
                  }
                </div>
              </div>

              <div className="modal-body body-modal">
                {/* <table className="workflow-table">
                    <thead>
                      <tr className="roleHeaders">
                        <td scope="col" style={{width:'31px'}}></td>
                        <td scope="col" className="hrole">
                          <button className="workflow_button blue_back" disabled>
                            <i className="fas fa-thumbs-up"></i>
                          </button>
                        </td>
                        <td scope="col" className="hrole">
                          <button className="workflow_button red_back" disabled>
                            <i className="fas fa-award "></i>
                          </button>
                        </td>
                        <td scope="col" className="hrole">
                          <button className="workflow_button green_back" disabled>
                            <i className="fas fa-eye"></i>
                          </button>
                        </td>
                        <td scope="col" className="role" style={{width: "31px"}}></td>
                        <td scope="col" className="column-name">{t('default:name', "NOM").toUpperCase()}</td>
                        <td scope="col" className="column-company">{t('default:society', "SOCIETE").toUpperCase()}</td>
                        <td scope="col" className="column-mail">@{t('default:email', "Mail").toUpperCase()}</td>
                        <td scope="col" className="column-actor">{t('mandat:actorType', "TYPE ACTEUR").toUpperCase()}</td>
                        <td scope="col" className="column-freespace"></td>
                      </tr>
                    </thead>
                  </table> */}
                <div className="scroller-Table">
                  <table className="table">
                    <thead>
                      <tr className="roleHeaders">
                        <td scope="col" style={{ width: '31px' }}></td>
                        <td scope="col" className="hrole">
                          <button className="workflow_button blue_back" disabled>
                            <i className="fas fa-thumbs-up"></i>
                          </button>
                        </td>
                        <td scope="col" className="hrole">
                          <button className="workflow_button red_back" disabled>
                            <i className="fas fa-award "></i>
                          </button>
                        </td>
                        <td scope="col" className="hrole">
                          <button className="workflow_button green_back" disabled>
                            <i className="fas fa-eye"></i>
                          </button>
                        </td>
                        <td scope="col" className="role" style={{ width: "31px" }}></td>
                        <td scope="col" className="column-name">{t('default:name', "NOM").toUpperCase()}</td>
                        <td scope="col" className="column-company">{t('default:society', "SOCIETE").toUpperCase()}</td>
                        <td scope="col" className="column-mail">@{t('default:email', "Mail").toUpperCase()}</td>
                        <td scope="col" className="column-actor">{t('mandat:actorType', "TYPE ACTEUR").toUpperCase()}</td>
                        <td scope="col" className="column-freespace"></td>
                      </tr>
                    </thead>
                    <tbody>
                      {tBody}
                    </tbody>
                  </table>
                </div>
              </div>
              {
                showUpdateSignatoryModal &&
                <UpdateSignatoryModal
                  show={showUpdateSignatoryModal}
                  handleClose={() => setShowUpdateSignatoryModal(false)}
                  signatory={signatoryToUpdate}
                  updateSignatory={updateSignatory}
                  editAdvertiserContact={editAdvertiserContact}
                  setIsUnknwonSignatory={setIsUnknwonSignatory}
                  setAdvertiserContact={setAdvertiserContact}
                  advertiserContact={advertiserContact}
                  selectUnknwonSignatory={selectUnknwonSignatory}
                />
              }
              {
                showCantUpdateValidatorModal &&
                <CantUpdateValidatorModal
                  show={showCantUpdateValidatorModal}
                  handleClose={() => setShowCantUpdateValidatorModal(false)}
                />
              }

            </div>
          </Modal.Body> : null
      }
      <div className="footer">
        <div className="footerConainer">
          <div className={((props.mandat.status == "TO_BE_APPROVED" && !isADMCreator) ? "roleContainerContributor" : "roleContainer")}>
            <h6><i className="fas fa-thumbs-up annonceur_header"></i>{t('mandat:contributors_auditors', "Contributeurs/Vérificateurs")} </h6>
            <div className="actors_block_list">
              <table className="table table-sm" style={{ fontsize: 'small' }}>
                <thead>
                  <tr className="table-active">
                    <td>{t('default:name', "Nom")}</td>
                    <td>{t('default:society', "Société")}</td>
                    {
                      props.mandat.status !== "SIGNED" && props.mandat.status !== "TO_BE_SIGNED" ? (
                        <td></td>
                      ) : null
                    }
                  </tr>
                </thead>
                <tbody >
                  {users?.filter(u => u.is_contributor == 1).map(user => {
                    let mgs = "";
                    if (user.category == "Agence") {
                      let entity = null;
                      if (props.mandat.mandataire.id == user.entity_id)
                        entity = props.mandat.mandataire;
                      else
                        entity = props.mandat.sousMandataires.find(sm => sm.id == user.entity_id);

                      if (entity)
                        mgs = entity?.users.filter(u => u.role == "MG")?.map(u => u.email)?.join(", ");
                    }

                    return (
                      <CTooltip title={(isADMCreator) && (props.mandat.status == "DRAFT" || props.mandat.status == "TO_BE_APPROVED") && contribDisabled(user) ? "Il existe des choix imposés pour ce (sous) mandataire, d'approbateur(s) ou de signataire(s), seul un gestionnaire de votre société peut effectuer ce changement pour vous (" + mgs + "), ou l'administrateur MyMandat : sloison@edipub.org" : ""}>
                        <tr style={(isADMCreator) && (props.mandat.status == "DRAFT" || props.mandat.status == "TO_BE_APPROVED") && contribDisabled(user) ? { background: "#eee" } : null}>
                          <td className="nowrap">{user.first_name}&nbsp; {user.last_name}</td>
                          <td>{user.official_name}</td>
                          {
                            !(props.mandat.status == "TO_BE_APPROVED" && !isADMCreator) &&
                              props.mandat.status !== "SIGNED" && props.mandat.status !== "TO_BE_SIGNED" && !contribDisabled(user) ? (
                              <td style={{ width: "60px" }}>
                                <button type="button" className="close" aria-label="Close" onClick={() => toggleContributeur(user.document_id, user.user_id, user.entity_id, 0)} disabled={!'DRAFT, TO_BE_APPROVED'.includes(mandat.status) ? true : false}>
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </td>
                            ) : null
                          }
                        </tr>
                      </CTooltip>
                    )
                  })
                  }
                </tbody>
              </table>
            </div>
          </div >
          <div className={((props.mandat.status == "TO_BE_APPROVED" && !isADMCreator) ? "roleContainerContributor" : "roleContainer")}>
            <div className=" roleContainer_header">
              <h6 ><i className="fas fa-award regie_header"></i>{t('default:signatories', "Signataires")} </h6>
            </div>

            <div className="actors_block_list">
              <table className="table table-sm" style={{ fontsize: 'small' }}>
                <thead>
                  <tr className="table-active">
                    <td>{t('default:name', "Nom")}</td>
                    <td>{t('default:society', "Société")}</td>
                    <td style={{ width: "60px" }}>{t('default:order', "Ordre")}</td>
                    {
                      props.mandat.status !== "SIGNED" && (props.mandat.status !== "TO_BE_SIGNED" || isAlreayInSignatureWorkflowEdition()) && !(props.mandat.status === "TO_BE_APPROVED" && !isADMCreator) ? (
                        <td style={{ width: "30px" }}></td>
                      ) : null
                    }
                  </tr>
                </thead>
                <tbody>
                  {users?.filter(u => u.is_signataire == 1).map(user => {
                    let mgs = "";
                    if (user.category == "Agence") {
                      let entity = null;
                      if (props.mandat.mandataire.id == user.entity_id)
                        entity = props.mandat.mandataire;
                      else
                        entity = props.mandat.sousMandataires.find(sm => sm.id == user.entity_id);

                      if (entity)
                        mgs = entity?.users.filter(u => u.role == "MG")?.map(u => u.email)?.join(", ");
                    }

                    return (
                      <CTooltip title={(isADMCreator) && (props.mandat.status == "DRAFT" || props.mandat.status == "TO_BE_APPROVED") &&
                        signDisabled(user) && role !== "MG" ? "Il existe des choix imposés pour ce (sous) mandataire, d'approbateur(s) ou de signataire(s), seul un gestionnaire de votre société peut effectuer ce changement pour vous (" + mgs + "), ou l'administrateur MyMandat : sloison@edipub.org" : ""}>
                        <tr style={(isADMCreator) && (props.mandat.status == "DRAFT" || props.mandat.status == "TO_BE_APPROVED") &&
                          signDisabled(user) && role !== "MG" ? { background: "#eee" } : null}>
                          <td className="nowrap" style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                            {
                              user?.user_id === null ?
                                (<strong>{t('mandat:unknown')}</strong>)
                                :
                                (<span>{`${user?.first_name} ${user?.last_name}`}</span>)
                            }
                          </td>
                          <td style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{user.official_name}</td>
                          <td style={{ width: "60px" }} >
                            <CTooltip title={(isADMCreator) && (props.mandat.status == "DRAFT" || props.mandat.status == "TO_BE_APPROVED") &&
                              props.mandat?.mandataire?.workflow_advertiser_sign_order !== null ? "L'ordre de signature ne peut pas être changé car il est prédéfini pour l'agence, seul un gestionnaire de votre société peut effectuer ce changement pour vous (" + mgs + "), ou l'administrateur MyMandat : sloison@edipub.org" : ""}>
                              <input
                                type="number"
                                className="form-control form-control-sm"
                                min={1}
                                value={+user?.signOrder}
                                style={(isADMCreator) && (props.mandat.status == "DRAFT" || props.mandat.status == "TO_BE_APPROVED") &&
                                  props.mandat?.mandataire?.workflow_advertiser_sign_order !== null ? { background: "#eee" } : null}
                                onChange={(e) => editSignatureOrderHandler(user?.docUserId, e.target.value)}
                                readOnly={
                                  props.mandat.status === "SIGNED"
                                  || (props.mandat.status === "TO_BE_SIGNED" && !isAlreayInSignatureWorkflowEdition())
                                  || (props.mandat?.mandataire?.workflow_advertiser_sign_order !== null)
                                  || (props.mandat.status == "TO_BE_APPROVED" && !isADMCreator)
                                }
                              >
                              </input>
                            </CTooltip>
                          </td>
                          {
                            props.mandat.status !== "SIGNED" && (props.mandat.status !== "TO_BE_SIGNED" || isAlreayInSignatureWorkflowEdition()) && !(props.mandat.status === "TO_BE_APPROVED" && !isADMCreator) ? (
                              <>
                                <td style={{ width: "30px" }}>
                                  {
                                    !signDisabled(user) &&
                                    <button type="button" className="close" aria-label="Close" onClick={() => toggleSignataire(user.document_id, user.user_id, user.entity_id, 0)} disabled={!'DRAFT, TO_BE_APPROVED'.includes(mandat.status) && !isAlreayInSignatureWorkflowEdition() ? true : false}>
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  }
                                </td>
                              </>
                            ) : null
                          }
                        </tr>
                      </CTooltip>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>

          {
            (props.mandat.status !== "TO_BE_APPROVED" || (isADMCreator)) &&
            <div className="roleContainer">
              <h6><i className="far fa-eye dest_header"></i>{t('default:recpients', "Destinataires")} </h6>
              <div className="actors_block_list">
                <table className="table table-sm" style={{ fontsize: 'small' }}>
                  <thead>
                    <tr className="table-active">
                      <td>{t('default:name', "Nom")}</td>
                      <td>{t('default:society', "Société")}</td>
                      {
                        props.mandat.status !== "SIGNED" && props.mandat.status !== "TO_BE_SIGNED" ? (
                          <td></td>
                        ) : null
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {users?.filter(u => u.is_recipient == 1).map(user => (
                      <tr>
                        <td className="nowrap">{user.first_name}&nbsp; {user.last_name}</td>

                        <td>
                          {
                            user.category != "Regie"
                              ? user.official_name
                              : getRegieEntityName(user)
                          }
                        </td>

                        {
                          props.mandat.status !== "SIGNED" && props.mandat.status !== "TO_BE_SIGNED" ? (
                            <td>
                              {
                                user.category != "Regie" ? (
                                  <button type="button" className="close" aria-label="Close" onClick={() =>
                                    toggleDestinataire(user.document_id, user.user_id, user.entity_id, 0)} disabled={!'DRAFT, TO_BE_APPROVED'.includes(mandat.status) ? true : false}>
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                ) : (
                                  <button type="button" className="close" aria-label="Close" onClick={() =>
                                    toggleDestinataireRegie(user.document_id, user.user_id, 0)} disabled={!'DRAFT, TO_BE_APPROVED'.includes(mandat.status) ? true : false}>
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                )
                              }
                            </td>
                          ) : null
                        }
                      </tr>
                    ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
          }
        </div>
      </div>

    </Modal>

  )
}

const mapStateToProps = (state) => ({
  role: state.auth.role,
  connectedUserId: state.auth.userId

})
const mapDispatchToProps = dispatch => ({
})
export default connect(mapStateToProps, mapDispatchToProps)(ADMWorkFlow)


