import * as actionTypes from './actionTypes';
import Axios from '../../axios-edipub';
import { Notyf } from 'notyf';
import { getNotyfObject } from '../../shared/utility';

const notyf = getNotyfObject();

export const resetAdmToInitialState = () => ({
    type: actionTypes.RESET_ADM_TO_INITIAL_STATE
})

export const setAdmScrolledEnd = (scrolledEnd) => ({
    type: actionTypes.SET_ADM_SCROLLEDEND,
    scrolledEnd
})

export const setAdmPdfPages = (numPages) => ({
    type: actionTypes.SET_ADM_PDFPAGES,
    numPages
})
export const setAdm = (adm) => ({
    type: actionTypes.SET_ADM,
    adm
})

export const setTabActive = (tabActive) => ({
    type: actionTypes.SET_TABACTIVE,
    tabActive
})

export const setTabSideActive = (tabSideActive) => ({
    type: actionTypes.SET_TAB_SIDEACTIVE,
    tabSideActive
})

export const mandatStart = () => ({
    type: actionTypes.MANDAT_START
})

export const mandatSuccess = (data) => ({
    type: actionTypes.MANDAT_SUCCESS,
    data: data,
});

export const mandatFail = (error) => ({
    type: actionTypes.MANDAT_FAIL,
    error: error
})

export const mandatGetTimelineStart = () => ({
    type: actionTypes.MANDAT_GET_TIMELINE_START
})

export const mandatGetTimelineSuccess = (data) => ({
    type: actionTypes.MANDAT_GET_TIMELINE_SUCCESS,
    data: data,
});

export const mandatGetTimelineFail = (error) => ({
    type: actionTypes.MANDAT_GET_TIMELINE_FAIL,
    error: error
})

export const mandatPostEventStart = () => ({
    type: actionTypes.MANDAT_POST_EVENT_START
})

export const mandatPostEventFail = (error) => ({
    type: actionTypes.MANDAT_POST_EVENT_FAIL,
    error: error
})

export const updateAdmData = (admNewData) => ({
    type: actionTypes.UPDATE_MANDAT_DATA,
    admNewData: admNewData
})

export const updateAdmAddresses = (entityUid, addressField, addressValue) => ({
    type: actionTypes.UPDATE_MANDAT_ADDRESSES,
    entityUid,
    addressField,
    addressValue
})

export const addMandatuser = (user) => ({
    type: actionTypes.ADD_MANDAT_USER,
    userToAdd: user
})

export const addMandatUserToUpdate = (user) => ({
    type: actionTypes.ADD_MANDAT_USER_TO_UPDATE,
    user
})

export const updateMandatUserToAdd = (entityUid, uid, field, value) => ({
    type: actionTypes.UPDATE_MANDAT_USER_TO_ADD,
    entityUid,
    uid,
    field,
    value
})

export const updateMandatUserToUpdate = (d2uUid, field, value) => ({
    type: actionTypes.UPDATE_MANDAT_USER_TO_UPDATE,
    d2uUid,
    field,
    value
})

export const deleteMandatUser = (d2uUid) => ({
    type: actionTypes.DELETE_MANDAT_USER,
    d2uUid
})

export const deleteMandatUserToAdd = (entityUid, uid) => ({
    type: actionTypes.DELETE_MANDAT_USER_TO_ADD,
    entityUid,
    uid
})

export const addMandatSubagency = (entityUid) => ({
    type: actionTypes.ADD_MANDAT_SUBAGENCY,
    entityUid
})

export const deleteMandatSubAgency = (entityUid) => ({
    type: actionTypes.DELETE_MANDAT_SUBAGENCY,
    entityUid
})

export const deleteMandatSubAgencyToAdd = (entityUid) => ({
    type: actionTypes.DELETE_MANDAT_SUBAGENCY_TO_ADD,
    entityUid
})

export const resetMandatToUpdateData = () => ({
    type: actionTypes.RESET_MANDAT_TO_UPDATE_DATA
})

export const addMandatProduct = (admOUid, products) => ({
    type: actionTypes.ADD_MANDAT_PRODUCT,
    admOUid,
    products
})

export const deleteMandatProduct = (admOUid, product) => ({
    type: actionTypes.DELETE_MANDAT_PRODUCT,
    admOUid,
    product
})

export const addMandatMedia = (admOUid, medias) => ({
    type: actionTypes.ADD_MANDAT_MEDIA,
    admOUid,
    medias
})

export const deleteMandatMedia = (admOUid, media) => ({
    type: actionTypes.DELETE_MANDAT_MEDIA,
    admOUid,
    media
})

export const addMandatRegie = (admOUid, regies) => ({
    type: actionTypes.ADD_MANDAT_REGIE,
    admOUid,
    regies
})

export const deleteMandatRegie = (admOUid, regie) => ({
    type: actionTypes.DELETE_MANDAT_REGIE,
    admOUid,
    regie
})

export const addMandatSupport = (admOUid, supports) => ({
    type: actionTypes.ADD_MANDAT_SUPPORT,
    admOUid,
    supports
})

export const deleteMandatSupport = (admOUid, support) => ({
    type: actionTypes.DELETE_MANDAT_SUPPORT,
    admOUid,
    support
})

export const addMandatMarketingMode = (admOUid, marketingModes) => ({
    type: actionTypes.ADD_MANDAT_MARKETINGMODE,
    admOUid,
    marketingModes
})

export const deleteMandatMarketingMode = (admOUid, marketingMode) => ({
    type: actionTypes.DELETE_MANDAT_MARKETINGMODE,
    admOUid,
    marketingMode
})

export const addMandatGeographicalArea = (admOUid, geographicalAreas) => ({
    type: actionTypes.ADD_MANDAT_GEOGRAPHICALAREA,
    admOUid,
    geographicalAreas
})

export const deleteMandatGeographicalArea = (admOUid, geographicalArea) => ({
    type: actionTypes.DELETE_MANDAT_GEOGRAPHICALAREA,
    admOUid,
    geographicalArea
})

export const updateMandatObjects = (admOUid, object) => ({
    type: actionTypes.UPDATE_MANDAT_OBJECTS,
    admOUid,
    object
})

export const updateMandatAdmObject = (admOUid, field, value) => ({
    type: actionTypes.UPDATE_MANDAT_ADMOBJECTS,
    admOUid,
    field,
    value
})

export const updateMandatAdmObjectsAgency = (admOUid, agencyUid) => ({
    type: actionTypes.UPDATE_MANDAT_ADMOBJECTS_AGENCY,
    admOUid,
    agencyUid
})

export const addMandatAdmObjects = (admOUid, agencyUid, purposesObject) => ({
    type: actionTypes.ADD_MANDAT_ADMOBJECTS,
    admOUid,
    agencyUid,
    purposesObject
})

export const deleteMandatAdmObjects = (entityUid) => ({
    type: actionTypes.DELETE_MANDAT_ADMOBJECTS,
    entityUid
})

export const updateMandatClauses = (clausesToUpdate) => ({
    type: actionTypes.UPDATE_MANDAT_CLAUSES,
    clausesToUpdate
})

export const deleteMandatClause = (clauseToDelete) => ({
    type: actionTypes.DELETE_MANDAT_CLAUSE,
    clauseToDelete
})

export const updateMandatDepartments = (departments) => ({
    type: actionTypes.UPDATE_MANDAT_DEPARTMENTS,
    departments
})

export const updateMandatShowCommercialName = (showCommercialName) => ({
    type: actionTypes.UPDATE_MANDAT_SHOW_COMMERCIAL_NAME,
    showCommercialName
})

export const updateMandatDescription = (description) => ({
    type: actionTypes.UPDATE_MANDAT_DESCRIPTION,
    description
})

export const updateMandatPeriod = (startPeriod, endPeriod) => ({
    type: actionTypes.UPDATE_MANDAT_PERIOD,
    startPeriod,
    endPeriod
})

export const updateMandatCreator = creator => ({
    type: actionTypes.UPDATE_MANDAT_CREATOR,
    creatorName: creator.name,
    id_creator: creator.id,
    uid: creator.uid
})

// //pas utilisé car on utilise pas flag pending
// export const countMasterDatasPending = masterDatasPending => ({
//     type : actionTypes.GET_COUNT_MASTERDATAS_PENDING,
//     masterDatasPending
// })

export const addRecipientUser = userUid => ({
    type: actionTypes.ADD_RECIPIENT_USER,
    userUid
})

export const deleteRecipientUser = userUid => ({
    type: actionTypes.DELETE_RECIPIENT_USER,
    userUid
})

export const resetRepicientUsers = () => ({
    type: actionTypes.RESET_RECIPIENT_USERS
})

export const getMandatData = (mandatUid, generatePdf = false, refreshMasterDataAndPdfIfPossible = false) => {
    return dispatch => {
        dispatch(mandatStart());
        Axios.get(`/document/${mandatUid}/document`, {
            params: {
                generatePdf,
                refreshMasterDataAndPdfIfPossible
            }
        })
            .then(response => {
                dispatch(mandatSuccess(response.data))
            }).catch(err => {
                dispatch(mandatFail(err.response?.data?.error))
            })
    }
}

//pas utilisé car on utilise pas flag pending
// export const getCountMasterDatasPending = () => {
//     return dispatch => {
//         Axios.get('/user//countMasterDatasPending')
//         .then(response => {
//             dispatch(countMasterDatasPending(response?.data?.pendingMasterDatas))
//         }).catch(err => {
//             if(err.response?.data?.error && (err.response?.data?.error === 'Expired token'))
//                 notyf.error('Une erreur s\'est produite ! Votre session a expiré. Veuillez vous déconnecter et vous reconnecter avant de renouveler l\'opération.')
//             else 
//                 notyf.error('Une erreur s\'est produite !')
//         })
//     }
// }

export const updateMandatStart = () => ({
    type: actionTypes.UPDATEMANDAT_START
})

export const updateMandatSuccess = () => ({
    type: actionTypes.UPDATEMANDAT_SUCCESS
});

export const updateMandatFail = (error) => ({
    type: actionTypes.UPDATEMANDAT_FAIL,
    error: error
})

export const updateMandat = (mandatUid, toUpdateData, callback) => {
    return dispatch => {
        dispatch(updateMandatStart())
        Axios.put(`/document/${mandatUid}/document`, { toUpdateData })
            .then(response => {
                dispatch(updateMandatSuccess())
                dispatch(resetMandatToUpdateData())
                dispatch(getMandatData(mandatUid))
                if (callback)
                    callback();
                else {
                    if (response?.data?.duplicatedInformation) {
                        notyf.open({
                            closeWith: ['click'],
                            type: 'warning',
                            background: 'orange',
                            message: "Une attestation de mandat avec le même annonceur ou groupe d’annonceur, la même agence existe déjà pour toute ou partie de la période indiquée"
                        });
                        notyf.success('Modifications sauvegardées')
                    }
                }
            }).catch(err => {
                dispatch(updateMandatFail(err.response?.data?.error))
                if (err.response?.data?.error && (err.response?.data?.error === 'Expired token'))
                    notyf.error('Une erreur s\'est produite ! Votre session a expiré. Veuillez vous déconnecter et vous reconnecter avant de renouveler l\'opération.')
                else
                    notyf.error('Une erreur s\'est produite !')
            })
    }
}

export const updateAdmDescription = (mandatUid, description) => {
    return dispatch => {
        Axios.put(`/document/${mandatUid}/description`, {
            description
        }).then(response => {
            dispatch(updateMandatDescription(description))
            notyf.success("Description modifiée")
        }).catch(response => notyf.error('Une erreur s\'est produite !'))
    }
}

export const updateAdmPeriod = (mandatUid, startPeriod, endPeriod) => {
    return dispatch => {
        Axios.put(`/document/${mandatUid}/period`, {
            startPeriod,
            endPeriod
        }).then(response => {
            if (response?.data?.duplicatedInformation) {
                notyf.open({
                    closeWith: ['click'],
                    type: 'warning',
                    background: 'orange',
                    message: "Une attestation de mandat avec le même annonceur ou groupe d’annonceur, la même agence existe déjà pour toute ou partie de la période indiquée"
                });
            }
            notyf.success('Modifications sauvegardées')
            //dispatch(updateMandatPeriod(startPeriod, endPeriod))
            //notyf.success("Période modifiée")
            dispatch(getMandatData(mandatUid, true))
        }).catch(response => notyf.error('Une erreur s\'est produite !'))
    }
}

export const updateAdmCreator = (mandatUid, creatorId) => {
    return dispatch => {
        Axios.put(`/document/${mandatUid}/creator`, creatorId).then(response => {
            dispatch(updateMandatCreator(response.data))
            notyf.success("Responsable modifié")
        }).catch(err => {
            if (err.response?.data?.error && (err.response?.data?.error === 'Expired token'))
                notyf.error('Une erreur s\'est produite ! Votre session a expiré. Veuillez vous déconnecter et vous reconnecter avant de renouveler l\'opération.')
            else
                notyf.error('Une erreur s\'est produite !')
        })
    }
}

export const updateMandatStatus = (mandatUid, status) => {
    return dispatch => {
        Axios.put(`/document/${mandatUid}/documentStatus`, {
            status
        }).then(response => {
            // createMandatTimelineEvent (mandatUid, {
            //     documentUid : mandatUid,
            //     type : "STEP_SHARED",
            //     message: null
            // })
            dispatch(getMandatData(mandatUid))
            notyf.success('Statut modifié')
        }).catch(err => {
            if (err.response?.data?.error && (err.response?.data?.error === 'Expired token'))
                notyf.error('Une erreur s\'est produite ! Votre session a expiré. Veuillez vous déconnecter et vous reconnecter avant de renouveler l\'opération.')
            else
                notyf.error('Une erreur s\'est produite !')
        })
    }
}

export const updateMandatOffline = (mandatUid, offline) => {
    return dispatch => {
        Axios.put(`/document/${mandatUid}/offline`, {
            offline
        }).then(response => {
            dispatch(getMandatData(mandatUid))
            if (offline)
                notyf.success('Procédure commencée')
        }).catch(err => {
            if (err.response?.data?.error && (err.response?.data?.error === 'Expired token'))
                notyf.error('Une erreur s\'est produite ! Votre session a expiré. Veuillez vous déconnecter et vous reconnecter avant de renouveler l\'opération.')
            else
                notyf.error('Une erreur s\'est produite !')
        })

    }
}

export const getMandatTimelineEvents = (mandatUid, type = null) => {
    return dispatch => {
        dispatch(mandatGetTimelineStart());
        Axios.get('/documentevent', {
            params: {
                documentUid: mandatUid,
                type: type ? type : null
            }
        }).then(response => {
            dispatch(mandatGetTimelineSuccess(response.data?.events))
        }).catch(err => {
            dispatch(mandatGetTimelineFail(err.response?.data?.error))
        })
    }
}

export const createMandatTimelineEvent = (mandatUid, eventData) => {
    return dispatch => {
        dispatch(mandatPostEventStart());
        Axios.post('/documentevent//documentEvent', {
            ...eventData
        }).then(response => {
            getMandatTimelineEvents(mandatUid)
        }).catch(err => {
            dispatch(mandatPostEventFail(err.response?.data?.error))
        })
    }
}



export const updateAdmPayer = (target, payer) => ({
    type: actionTypes.UPDATEADMPAYER_SUCCESS,
    target,
    payer
})




