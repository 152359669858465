import React from 'react'
import './TooltipInfo.css'
import InfoIcon from '@material-ui/icons/Info';
import DropDown from '../DropDown/DropDown'
import { useTranslation } from 'react-i18next';


function TooltipInfo({content, dropdownZindex}) {
    return (
        <div className="askedBy_container">
            <DropDown 
                icon={<InfoIcon style={{width: "15px", marginLeft: "4px"}} className='infoIcon' />}
                zIndex={dropdownZindex}
            >
                <div className="askedBy_dropDown_container">
                    {content?.split("\n").map((c, index) => <p key={index}>{c}</p>)}
                </div>
                
            </DropDown>
        </div>
    )
}

export default TooltipInfo
