 import React, { useCallback, useRef, useState } from 'react'
import './TableActions.css';
import Axios from '../../../axios-edipub';
import FilterListIcon from '@material-ui/icons/FilterList';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import ArchiveIcon from '@material-ui/icons/Archive';
import ReplayIcon from '@material-ui/icons/Replay';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import { Tooltip, withStyles } from '@material-ui/core';
import { CSVLink } from "react-csv";

const CTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 15,
    },
}))(Tooltip);

 
function TableActions(props) {
    const {filterState, setShowFilters, resetData, onlyUnpublished, setOnlyUnpublished, exportAllCsv, csvBtn, csvData, columns} = props;

    function handleFilters (){
        setShowFilters(!filterState)
    } 
    
    function handleUnpublished () {
        setOnlyUnpublished(!onlyUnpublished)
    }

    const csvHeaders = columns?.map(c => ({label: c.title, key: c.field}));
    csvHeaders.splice(4, 0, {label: "Régies publiées", key: "regies"})

    return (
        <div className="tableActions">
            <div className="tableActions__icons">
                <div className="tableActions__action" style={{border: "none"}}>
                    <CTooltip title="Reinitialiser les filtres" className="tableActions__icon">
                        <ReplayIcon onClick={() => resetData()} fontSize="small"  />
                    </CTooltip>
                </div>
                <div className="tableActions__action">
                    <CTooltip title="Filtres" className="tableActions__icon"  style={{color: filterState ? 'rgb(38, 146, 235)' : ''}}>
                        <FilterListIcon onClick={() => handleFilters()} fontSize="small"  />
                    </CTooltip>
                </div>
                <div className="tableActions__action">
                    <CTooltip title="Voir uniquement les mandats non publiés" className="tableActions__icon"  style={{color: onlyUnpublished ? 'rgb(38, 146, 235)' : ''}}>
                        <VisibilityOffIcon onClick={() => handleUnpublished()} fontSize="small"  />
                    </CTooltip>
                </div> 
                {<div className="tableActions__action" style={{display:'flex' }} onClick={() => exportAllCsv()}>
                    <CTooltip title="Exporter le résultat de la recherche en CSV" className="tableActions__icon" >
                        <ImportExportIcon  fontSize="small"/>
                    </CTooltip>
                </div>}
                {csvData ? <CSVLink
                    ref={csvBtn}
                    data={csvData}
                    filename={"rapportPublication.csv"}
                    headers={csvHeaders}
                    separator={";"}>
                </CSVLink> : null}
            </div>
        </div>
    )
}

export default TableActions
