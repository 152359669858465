import React, { useEffect, useRef, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@material-ui/icons/Search';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import AddIcon from '@material-ui/icons/Add';
import frLocale from "date-fns/locale/fr";
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
  } from '@material-ui/pickers';

import { getNotyfObject } from '../../../../shared/utility';
import { Notyf } from 'notyf';



function DocDuplicateModal({setSearchQuery, setSearchQueryGroup, show, handleClose, duplicateDocument, advertiserType, advertisers, selectedAdvertiser, setSelectedAdvertiser,
    groups, selectedGroup, setSelectedGroup, selectedStartDate, selectedEndDate, setSelectedStartDate, setSelectedEndDate}) {

    const {t} = useTranslation();
    const notyf = getNotyfObject();
    const [startDateError, setStartDateError] = useState(false);
    const [endDateError, setEndDateError] = useState(false);
    const divRef = useRef();

    useEffect(() => {
        if (setSearchQuery)
            setSearchQuery("")
        if (setSearchQueryGroup)
            setSearchQueryGroup("")
    }, [])

    const handleStartDateChange = (date) => {
        if (date === null){
            setEndDateError(true)
        } else if (date.toString() === "Invalid Date"){
            setStartDateError(true)
        } else if(date.getFullYear <= 1900 || date.getFullYear > 2100){
            setStartDateError(true)
        } else {
            setStartDateError(false)
        }
        setSelectedStartDate(date);
    };

    const handleEndDateChange = (date) => {
        if (date === null){
            setEndDateError(true)
        } else if (date.toString() === "Invalid Date"){
            setEndDateError(true)
        } else if(date < selectedStartDate || date.getFullYear > 2100 ){
            setEndDateError(true)
        } else {
            setEndDateError(false)
        }
        setSelectedEndDate(date);
    };

    const generateMaxEndDate = () => {
        let startDate = new Date(selectedStartDate);
        return new Date( startDate.setFullYear(startDate.getFullYear() , 12, 0 ))
    }

    let advertisersList = null;

    (advertisers && advertisers.length > 0) ? 
        advertisersList = (
        <table className="table">
            <thead className="thead-light">
                <tr>
                <th scope="col"></th>
                <th scope="col">{t('default:name', "NOM")} </th>
                <th scope="col">{t('default:siret', "SIRET")}</th>
                <th scope="col">{t('default:addressSS', "Adresse SS")}</th>
                </tr>
            </thead>
            <tbody>
                {selectedAdvertiser && advertisers.filter(row => row.uid === selectedAdvertiser).map(row => (
                    <tr>
                        <td>
                            <div className="productsModal__itemIcon" onClick={() => {
                                    setSelectedAdvertiser(row.uid)
                                    divRef.current.scrollTop = 0
                                 }}>
                                <ThumbUpIcon style={{color: "green"}} />
                            </div>
                        </td>
                        <td>{row.commercial_name} </td>
                        <td>{row.siret} </td>
                        <td>{row.address_line} {row.post_code}, {row.label_primary} </td>
                    </tr>
                ))}
                {advertisers.filter(row => row.uid !== selectedAdvertiser).map(row => (
                    <tr>
                        <td>
                            <div className="productsModal__itemIcon" onClick={() => {
                                    setSelectedAdvertiser(row.uid)
                                    divRef.current.scrollTop = 0
                                }}>
                                <AddIcon style={{color: "blue"}} />
                            </div>
                        </td>
                        <td>{row.commercial_name} </td>
                        <td>{row.siret} </td>
                        <td>{row.address_line} {row.post_code}, {row.label_primary} </td>
                    </tr>
                ))}
                

            </tbody>
        </table>
    ) : advertisersList = (<h5>{t('home:noAdvertiser', "Aucun Annonceur à afficher")} </h5>);
        
    const groupsList = (groups && groups.length > 0) ? 
    (<table className="table">
       <thead className="thead-light">
           <tr>
           <th scope="col"></th>
           <th scope="col">{t('default:name', "NOM")} </th>
           </tr>
       </thead>
       <tbody>
           {selectedGroup && groups.filter(row => row.uid === selectedGroup).map(row => (
               <tr>
                   <td>
                       <div className="productsModal__itemIcon" onClick={() => {
                                setSelectedGroup(row.uid)
                                divRef.current.scrollTop = 0
                            }}>
                            <ThumbUpIcon style={{color: "green"}} />  
                       </div>
                   </td>
                   <td>{row.label} </td>
               </tr>
           ))}
           {groups.filter(row => row.uid !== selectedGroup).map(row => (
               <tr>
                   <td>
                       <div className="productsModal__itemIcon" onClick={() => {
                                setSelectedGroup(row.uid)
                                divRef.current.scrollTop = 0
                            }}>
                            <AddIcon style={{color: "blue"}} />                           
                       </div>
                   </td>
                   <td>{row.label} </td>
               </tr>
           ))} 
       </tbody>
   </table>)
   : <h5>{t('home:noGroup', "Aucun groupe à afficher")} </h5>;


    return (
        <>
            <Modal show={show} onHide={handleClose} contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                <Modal.Title>{t('default:duplicateModalHeader', 'Duplication of mandate')} </Modal.Title>
                </Modal.Header>
                <Modal.Body className="ADMCreationModal__body" style={{width: "650px"}}>
                    {advertiserType === "advertiser" &&
                        <div className="advertiser-container">
                            <div className="productsModal__header" style={{padding: "0"}}>
                                <h5>{t('default:duplicateModalText', "Veuillez choisir un Annonceur")}</h5> 
                                <div className="input-group input-group-sm" style={{height: "30px"}}>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder={t('home:modalCreation_searchPlaceholder', "Rechercher Annonceurs (Dénomination sociale, SIRET)" )}
                                        name="searchQuery" 
                                        style={{height: "100%"}}
                                        onChange={(e) => setSearchQuery(e.target?.value)} />
                                    <div 
                                        className="input-group-append" 
                                        style={{height: "100%"}}
                                    >
                                        <span className="input-group-text" id="basic-addon2"><SearchIcon/> </span>
                                    </div>
                                </div>
                            </div>
                            <div ref={divRef} className="productsModal_items bg-white">
                                {advertisersList}
                            </div>
                        </div>
                    }
                    {advertiserType === "group" && 
                        <div className="advertiser-container">
                            <div className="productsModal__header " style={{padding: "0"}}>
                                <h5>{t('default:duplicateModalTextGroup', "Veuillez choisir un groupe d'Annonceur")}</h5>
                                <div className="input-group input-group-sm" style={{height: "30px"}}>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder={t('home:modalCreation_searchGroupPlaceholder', "Chercher des groupes d'Annonceurs (Nom)" )}
                                        name="searchQuery" 
                                        style={{height: "100%"}}
                                        onChange={(e) => setSearchQueryGroup(e.target.value)} />
                                    <div 
                                        className="input-group-append" 
                                        style={{height: "100%"}}
                                    >
                                        <span className="input-group-text" id="basic-addon2"><SearchIcon/> </span>
                                    </div>
                                </div> 
                            </div> 
                            <div ref={divRef} className="productsModal_items bg-white">
                                {groupsList}
                            </div>
                        </div>
                    }
                       
                    <div className="ADMCreationModal__selectionPeriod__container mt-4"> 
                        <h5> {t('home:modalDupplication_periodTitle', "Période de validité")}  </h5> 
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                            <Grid container justify="space-around">
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label={t('default:startAdmDate', "Date début ADM")}
                                    format="dd/MM/yyyy"
                                    value={selectedStartDate}
                                    onChange={handleStartDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    invalidDateMessage={t('default:invalidDateMessage', "Format de date invalide")}
                                    maxDateMessage={t('default:maxDateMessage', "La date ne doit pas être postérieure à la date maximale")}
                                    minDateMessage={t('default:minDateMessage', "La date ne doit pas être antérieure à la date minimale")}
                                    error={startDateError}
                                />
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label={t('default:endAdmDate', "Date fin ADM")}
                                    format="dd/MM/yyyy"
                                    value={selectedEndDate}
                                    onChange={handleEndDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    minDate={selectedStartDate}
                                    maxDate={generateMaxEndDate()}
                                    invalidDateMessage={t('default:invalidDateMessage', "Format de date invalide")}
                                    maxDateMessage={t('default:maxDateMessage', "La date ne doit pas être postérieure à la date maximale")}
                                    minDateMessage={t('default:minDateMessage', "La date ne doit pas être antérieure à la date minimale")}
                                    error={endDateError} 
                                /> 
                            </Grid>
                        </MuiPickersUtilsProvider> 
                    </div>  

                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t('default:cancel', "Annuler")}
                </Button>
                <Button variant="primary" onClick={() => duplicateDocument()} disabled={ ((!selectedAdvertiser || selectedAdvertiser=="") && !selectedGroup) ? true : false } >
                    {t('default:duplicate', "Dupliquer")}
                </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default DocDuplicateModal
