import React, { useState } from 'react'
import { connect } from 'react-redux';
import './Auth.css';
import * as actions from '../../store/actions';
import Signin from '../../components/Signin/Signin'
import Spinner from '../../components/UI/Spinner/Spinner';

const Auth = props => {
    const {error} = props;
    const [authForm, setAuthForm] = useState({
        email: '',
        password: '' 
    })

    const inputChangeHandler = (e) => {
        setAuthForm({...authForm,[e.target.name]: e.target.value})
    }

    const submitHandler = e => {
        e.preventDefault();
        props.onAuth(authForm.email, authForm.password)
    }

 
    return (
        <div>
            {props.loading ? 
                <Spinner /> : 
                <Signin
                    changed={(e) => inputChangeHandler(e)}
                    submited={(e) => submitHandler(e)}
                    signinError={error}
                />}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        isAuthenticated : state.auth.token !== null,
        authRedirectPath: state.auth.authRedirectPath
    }
} 

const mapDispatchToProps = dispatch => {
    return {
        onAuth : (email, password) => dispatch(actions.auth(email, password)),
        onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/'))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
