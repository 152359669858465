import React, { useCallback, useEffect, useState } from 'react'
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import Axios from '../../../../axios-edipub'
import { Button, Modal } from 'react-bootstrap';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { Notyf } from 'notyf';
import { getNotyfObject } from '../../../../shared/utility';
import { useTranslation } from 'react-i18next';

function EntityGroupModal({handleClose, show, entitiesCategory, addEntities, groupEntities}) {
    let notyf = getNotyfObject();
    const [entities , setEntities] = useState([]);
    const [selectedEntities, setSelectedEntities] = useState([]);
    const [searchQuery, setSearchQuery] = useState(null)
    const {t} = useTranslation();
    const getEntitiesList = useCallback(() => {
        let filter = {
            category: entitiesCategory || "annonceur",
            searchQuery: searchQuery
        }
        Axios.get('/entity', {
            params: filter
        })
        .then(res => {
            setEntities(res.data.entities)
        })
    }, [searchQuery])

    useEffect(() => {
        getEntitiesList()
    }, [searchQuery])
    

    const SelectEntity = (entity) => {
        let entityGroupIndex = groupEntities.findIndex(row => row.uid === entity.uid);
        if(entityGroupIndex >= 0){
            notyf.error(t("masterData:alreadyAssociatedEmail"))
        }else {
            let entityIndex = selectedEntities.findIndex(row => row.uid === entity.uid);
            let selectedTemp = [...selectedEntities];
            if(entityIndex >= 0)
                selectedTemp.splice(entityIndex,1)
            else 
                selectedTemp = [...selectedEntities, entity];
            setSelectedEntities(selectedTemp);
        }
        
    }
    
    const selectAllEntities = (e) => {
        let selectedTemp = []
        if (e.target.checked) {
            selectedTemp = selectedEntities.map(entity => ({uid : entity.uid, commercial_name: entity.commercial_name, new: true}))
        }
        setSelectedEntities(selectedTemp)
    }

    const addClickHandler = () => {
        addEntities(selectedEntities)
        handleClose()
    }
    return (
        <>
            <Modal show={show} onHide={handleClose} contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                <Modal.Title>{t('masterData:entityGroupModal', "Associer des entreprises au groupe")}  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="productsModal__body" style={{minWidth: "40rem"}}>
                    <div className="productsModal__header">
                        <div class="productsModal__searchInput input-group input-group-sm">
                            <input 
                                type="text" 
                                class="form-control" 
                                placeholder={t('masterData:findBySiret',"Rechercher par SIRET, Nom")}  
                                aria-label="Recipient's username" 
                                aria-describedby="basic-addon2" 
                                style={{height: "100%"}}
                                onChange={(e) => setSearchQuery(e.target.value)} 
                                value={searchQuery || ''}
                            />
                            <div class="input-group-append" style={{height: "100%"}}>
                                <span class="input-group-text" id="basic-addon2"><SearchIcon/> </span>
                            </div>
                        </div>
                        {/* <div className="d-flex justify-content-between align-items-center">
                            <p className="productsModal_totalItems">{selectedEntities?.length} entreprise(s) séléctionnée(s)</p>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="selectAllRegies" onChange={(e) => selectAllEntities(e)} />
                                <label class="form-check-label" for="selectAllRegies" >
                                    Selectionner tout
                                </label>
                            </div>
                        </div> */}
                        
                    </div>
                    <div className="productsModal_items bg-white">
                        <table class="table">
                            <thead class="thead-light">
                                <tr>
                                <th scope="col"></th>
                                <th scope="col">{t('default:name', "Nom")}</th>
                                <th scope="col">{t('default:siret', "SIRET")} </th>
                                <th scope="col">{t('default:headOffice', "Siège social")} </th>
                                </tr>
                            </thead>
                            <tbody>
                                
                                {
                                    entities?.length > 0 ? entities.map(entity => (
                                        <tr>
                                            <td>
                                                <div className="productsModal__itemIcon" onClick={() => SelectEntity({uid : entity.uid, commercial_name : entity.commercial_name, new : true})}>
                                                    {
                                                        selectedEntities.some(row => row.uid === entity.uid) ? (
                                                            <ThumbUpIcon style={{color: "green"}} />
                                                        ) : <AddIcon style={{color: "blue"}} />
                                                    }
                                                </div>
                                            </td>
                                            <td title={entity.commercial_name} style={{whiteSpace: "break-spaces", overflow:"hidden", textOverflow:"ellipsis", maxWidth:"200px"}}>{entity.commercial_name} </td>
                                            <td>{entity.siret} </td>
                                            <td title={entity.address_line} style={{whiteSpace: "break-spaces", overflow:"hidden", textOverflow:"ellipsis", maxWidth:"200px"}}>{entity.address_line} {entity.post_code} </td>
                                        </tr>
                                    )) : <div>{t('mandat:noAgencies', "Aucune entreprise à afficher")}  </div>
                                }
                            </tbody>
                        </table>
                    </div>
                    
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t("default:cancel")}
                </Button>
                <Button variant="primary" onClick={() => addClickHandler()}>
                    {t("masterData:associate")}
                </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default EntityGroupModal
