import React from 'react'
import './ADMBody.css';
import ADMMain from './ADMMain/ADMMain';
import ADMSide from './ADMSide/ADMSide';

function ADMBody({mandat, addressClick, isCreator, showSignatureTab,lang}) { 

    return ( 
        <div className= "MandatBody">   
            <div className="MandatEdition">
            <ADMMain 
                    lang={lang}
                    mandat={mandat}
                    addressClick={addressClick}
                    isCreator={isCreator}
                    />
            </div>

            {   mandat?.template === "0" && 
                <div className="TimeLine">
                    <ADMSide 
                        isCreator={isCreator}
                        mandat={mandat}
                        showSignature={showSignatureTab}
                    />
                </div>
            }
            
        </div>
        )      
    }
    
export default ADMBody
