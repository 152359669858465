import React, { useState } from 'react'
import frLocale from "date-fns/locale/fr";
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Notyf } from 'notyf';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';
import { getNotyfObject } from '../../../../shared/utility';
import { useTranslation } from 'react-i18next';

function PeriodModal({handleClose, show, mandatUid,startPeriod,endPeriod, updateAdmPeriod}) {
    const {t} = useTranslation();
    const notyf = getNotyfObject();
    const [selectedStartDate, setSelectedStartDate] = useState(new Date(+startPeriod));
    const [selectedEndDate, setSelectedEndDate] = useState(new Date(+endPeriod));
    const [startDateError, setStartDateError] = useState(false);
    const [endDateError, setEndDateError] = useState(false);

    const handleStartDateChange = (date) => {
        if (date.toString() === "Invalid Date"){
            setStartDateError(true)
        } else 
        if(date.getFullYear <= 1900 || date.getFullYear > 2100){
            setStartDateError(true)
        }else {
            setStartDateError(false)
        }
        setSelectedStartDate(date);
    };

    const handleEndDateChange = (date) => {
        if (date.toString() === "Invalid Date"){
            setEndDateError(true)
        } else 
        if(date < selectedStartDate || date.getFullYear > 2100 ){
            setEndDateError(true)
        }else {
            setEndDateError(false)
        }
        setSelectedEndDate(date);
    };

    const generateMaxEndDate = () => {
        let startDate = new Date(selectedStartDate);
        return new Date( startDate.setFullYear(startDate.getFullYear() , 12, 0 ))
    }

    const addClickHandler = () => {
        updateAdmPeriod(mandatUid, selectedStartDate.getTime(), selectedEndDate.getTime())
        handleClose();
    }
    return (
        <>
            <Modal show={show} onHide={handleClose} contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                <Modal.Title>
                    {t('mandat:periodModal_title', "Modifier la période du mandat")} </Modal.Title>
                </Modal.Header>
                <Modal.Body className="ADMCreationModal__body" style={{width: "650px"}}>
                    <div className="ADMCreationModal__selectionPeriod__container mt-4">
                        <h5>
                            {t('mandat:periodModal_body', "Définir la période de validité")}
                        </h5>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                            <Grid container justify="space-around">
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label={t('default:startAdmDate', "Date début ADM")}
                                    format="dd/MM/yyyy"
                                    value={selectedStartDate}
                                    onChange={handleStartDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    invalidDateMessage={t('default:invalidDateMessage', "Format de date invalide")}
                                    maxDateMessage={t('default:maxDateMessage', "La date ne doit pas être postérieure à la date maximale")}
                                    minDateMessage={t('default:minDateMessage', "La date ne doit pas être antérieure à la date minimale")}
                                />
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label={t('default:endAdmDate', "Date fin ADM")}
                                    format="dd/MM/yyyy"
                                    value={selectedEndDate}
                                    onChange={handleEndDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    minDate={selectedStartDate}
                                    maxDate={generateMaxEndDate()}
                                    invalidDateMessage={t('default:invalidDateMessage', "Format de date invalide")}
                                    maxDateMessage={t('default:maxDateMessage', "La date ne doit pas être postérieure à la date maximale")}
                                    minDateMessage={t('default:minDateMessage', "La date ne doit pas être antérieure à la date minimale")}
                                />
                                
                            </Grid>
                        </MuiPickersUtilsProvider>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t('default:cancel', "Annuler")} 
                </Button>
                <Button variant="primary" disabled={(startDateError || endDateError)} onClick={() => addClickHandler()}>
                    {t('default:update', "Modifier")} 
                </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
const mapStateToProps = (state) => ({
    mandatUid: state.mandat.admData?.uid,
    startPeriod: state.mandat.admData?.start_period,
    endPeriod: state.mandat.admData?.end_period
})
const mapDispatchToProps = dispatch => ({
    updateAdmPeriod: (mandatUid ,startPeriod,endPeriod ) => dispatch(actions.updateAdmPeriod(mandatUid ,startPeriod,endPeriod )),
})
export default connect(mapStateToProps, mapDispatchToProps) (PeriodModal)
