import React, { useContext, useEffect, useState } from 'react'
import '../SignatureTab.css';
import Axios from '../../../../../../axios-edipub'
import DoneIcon from '@material-ui/icons/Done';
import { Notyf } from 'notyf';
import { Link } from 'react-router-dom';
import ResignReasonModal from '../ResignReasonModal/ResignReasonModal';
import { checkValidity, getNotyfObject } from '../../../../../../shared/utility';
import SignatureCanvas from '../SignatureCanvas/SignatureCanvas';
import Spinner from 'react-bootstrap/Spinner'
import { useTranslation } from 'react-i18next';
import ViewListIcon from '@material-ui/icons/ViewList';
import SignaturesDropdown from '../SignaturesDropdown/SignaturesDropdown';
import { b64toBlob, validateEmail } from "../../../../../../shared/utility";
import { connect } from 'react-redux';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import fr from 'react-phone-input-2/lang/fr.json'
import SecretResignReasonModal from '../SecretResignReasonModal/SecretResignReasonModal';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import SendIcon from '@material-ui/icons/Send';


function SecretSignatureTab({ setUnknownSignatorySign, accessToken, setSignedSuccess, user, admScrolledEnd, setTabSideActive, mandatUid, mandatId, getMandatData, tokenSignature, generateAdmPdf, status, admData, signatureSuccess, setPdfLoading }) {
    const notyf = getNotyfObject();
    const [randSmsCode, setRandSmsCode] = useState(Math.floor(100000 + Math.random() * 900000));
    const [confirmIdentity, setConfirmIdentity] = useState(false);////// to false
    const [docApproved, setDocApproved] = useState(false);
    const [smsCode, setSmsCode] = useState(null);
    const [matchedSmsCode, setMatchedSmsCode] = useState(false);/// to false
    const [userSignatory, setUserSignatory] = useState(null)
    const [userSignatoryErrors, setUserSignatoryErrors] = useState([]);
    const [errors, setErrors] = useState(null)
    const [showReasonModal, setShowReasonModal] = useState(false);
    const [signatureDataB64, setSignatureDataB64] = useState(null);
    const [sendSmsLoading, setSendSmsLoading] = useState(false);
    const [signADMLoading, setSignADMLoading] = useState(false);
    const [signdoc, setSigndoc] = useState(null);
    const [isMyTurnToSign, setIsMyTurnToSign] = useState(false);
    const [selectedSignature, setSelectedSignature] = useState(null)
    const { t } = useTranslation();
    const [ipinfo, setIpinfo] = useState(null);
    const [admScrolledEnd1, setAdmScrolledEnd1] = useState(null);
    const [pdf1, setPdf1] = useState(null);
    const contentType = "application/pdf";
    const [sendEmail, setSendEmail] = useState(false)
    const [userEmailToken, setUserEmailToken] = useState("")
    const [showEmailToken, setShowEmailToken] = useState(false)

    const sendSmsCode = (messageText) => {
        Axios.get('/document//sendSmsToSign', {
            params: {
                // phoneNumber : user?.telephone_number,
                phoneNumber: userSignatory?.telephone_number,
                smsMessage: messageText
            }
        }).then(response => {
            notyf.success(t("mandat:sendMessageText"))
            setSendSmsLoading(false)
            setConfirmIdentity(true)
        }).catch(response => {
            setSendSmsLoading(false)
            notyf.error(t("mandat:failedSendingMessageText"));
        })
        // axios.get("https://www.ovh.com/cgi-bin/sms/http2sms.cgi", {
        //     params : {
        //         noStop : 1,
        //         contentType : "text/json",
        //         account : "sms-ct5560-1",
        //         login : "avasseur",
        //         password : "mer1440b",
        //         from : "ICD",
        //         to : user?.telephone_number,
        //         message : messageText
        //     }
        // }).then(response => {
        //     let status = response.data.status;
        //     if(status === 100){
        //         notyf.success("Un sms vient de vous être envoyé")
        //     }else if(status === 200) {
        //         notyf.error("Échec d'envoi du code ")
        //     }
        // })
    }

    const checkSignOrder = () => {
        Axios.get(`/document/${mandatUid}/checkSecretSignOrder`)
            .then(response => {
                if (response?.data?.hasOwnProperty("canSign") && response?.data?.canSign) {
                    setIsMyTurnToSign(true)
                }
            }).catch(response => {
                notyf.error("mandat:receivingSignatureError")

            })
    }

    useEffect(() => {
        Axios.get(`/document/${mandatUid}/hasSecretSigned`)
            .then(response => {
                let hasSigned = response?.data?.hasSigned;
                setSigndoc(hasSigned);
                if (!hasSigned)
                    checkSignOrder();
            })
            .catch(response => {
                notyf.error("mandat:receivingSignatureError")
            })

    }, [])

    const telNumChangeHandler = (phone) => {
        setUserSignatoryErrors([])
        setUserSignatory(userSignatory => ({ ...userSignatory, telephone_number: phone }))
        // setTelNum(phone)
    }

    const checkUserFormValidity = () => {
        let errors = [];
        if (userSignatory === null || !userSignatory.hasOwnProperty('last_name') || !checkValidity(userSignatory?.last_name, { required: true })) {
            errors.push(t("masterData:error_userLastName"));
        }
        if (userSignatory === null || !userSignatory.hasOwnProperty('first_name') || !checkValidity(userSignatory?.first_name, { required: true })) {
            errors.push(t("masterData:error_userFirstName"));
        }
        if (userSignatory === null || !userSignatory.hasOwnProperty('function') || !checkValidity(userSignatory?.function, { required: true })) {
            errors.push(t("masterData:error_function"));
        }
        if (userSignatory === null || !userSignatory.hasOwnProperty('telephone_number') || !checkValidity(userSignatory?.telephone_number, { required: true, isPhone: true })) {
            errors.push(t("mandat:error_telNum", "Veuillez saisir un numéro de portable valide"));
        }
        return errors;
    }

    const checkIdentityClickHandler = (checked) => {
        if (!confirmIdentity && checked) {
            let errors = checkUserFormValidity();
            if (errors && errors.length > 0) {
                setUserSignatoryErrors(errors)
            } else {
                setUserSignatoryErrors([])
                let messageText = t("mandat:confirmationCodeMessageText") + randSmsCode
                setSendSmsLoading(true);
                sendSmsCode(messageText)
            }
        }
    }

    const smsCodeChangeHandler = (e) => {
        let code = e.target.value;
        if (code.length < 7) {
            setSmsCode(code)
            if (code.length == 6 && +code == randSmsCode) {
                setMatchedSmsCode(true)
                setConfirmIdentity(true)
            }
        }
    }

    const addpreuve = () => {
        let post = {
            ...ipinfo,
            randSmsCode,
            uidDoc: admData?.uid,
            description: admData?.description,
            phoneNumber: userSignatory?.telephone_number
        }
        Axios.post('/document//createpreuve', { ...post })
            .then(response => {
                generateAdmPdf(admData?.uid)
            })
            .catch(response => {
                generateAdmPdf(admData?.uid)
                notyf.error("mandat:preuve not generated ")
            })
    }

    const saveSignature = () => {
        Axios.post('/signature2user', {
            signatureDataB64
        }).then(response => {
            notyf.success(t("mandat:savedSignature", "Signature enregistrée"))
            setSignatureDataB64(null)
        }).catch(err => {
            //notyf.error(t("mandat:savedSignature", "Signature enregistrée"))
        })
    }

    const signMandat = (mandatUid) => {
        if (setPdfLoading)
            setPdfLoading(true)
        Axios.put(`/document/${mandatUid}/secretSignDocument`, {
            ...ipinfo,
            user: userSignatory,
            signatureDataB64,
            randSmsCode,
            uidDoc: admData?.uid,
            description: admData?.description,
            phoneNumber: userSignatory?.telephone_number,
        }).then(response => {
            if (response?.data?.hasOwnProperty("canSign") && !response?.data?.canSign) {
                notyf.error(t("mandat:cantSignAdmOrder", "Ce n'est pas encore votre tour pour signer "))
            } else {
                signatureSuccess(response)
                // addpreuve();
                // pour info ,on success de addpreuve on appelle generatePdf  qui setSignedSuccess(true)
            }
        }).catch(err => {
            setSignADMLoading(false)
            console.error(err)
            notyf.error(t("mandat:generatedErrorMessage"))
        }).finally(() => {
            if (setPdfLoading)
                setPdfLoading(false)
        })
    }

    const secretResignSignMandat = (user, reasonMessage) => {
        Axios.put(`/document/${mandatUid}/secretResignSignDocument`, {
            user,
            reasonMessage
        }).then(response => {
            //setSignedSuccess(true)
            setUnknownSignatorySign(false)
            //getMandatData(mandatUid)
        }).catch(err => {
            if (err.response?.data?.error && (err.response?.data?.error === 'Expired token'))
                notyf.error('Une erreur s\'est produite ! Votre session a expiré. Veuillez vous déconnecter et vous reconnecter avant de renouveler l\'opération.')
        })
    }

    const checkValidityForm = () => {
        let errors = []
        if (!confirmIdentity) {
            errors.push(t("mandat:confirmIdentityError"));
        }
        if (!admScrolledEnd) {
            errors.push(t("mandat:readDocumentMessage"));
        }
        if (!docApproved) {
            errors.push(t("mandat:approuveDocMessage"));
        }
        if (!matchedSmsCode) {
            errors.push(t("mandat:checkCodeMessage"));
        }
        if (!signatureDataB64) {
            errors.push(t("mandat:noSignDetectedMessage"));
        }
        return errors;
    }

    const signClickHandler = () => {
        let errors = checkValidityForm();
        if (errors && errors.length > 0) {
            setErrors(errors)
        } else {
            setErrors(null)
            setSignADMLoading(true)
            signMandat(mandatUid)
        }
    }

    const resignSignClickHandler = (user, reasonMessage) => {
        setErrors(null)
        secretResignSignMandat(user, reasonMessage)
    }

    const inputUserChangeHandler = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        setUserSignatoryErrors([])
        setUserSignatory({ ...userSignatory, [name]: value });
    }

    const sendEmailHandle = (email) => {
        if (validateEmail(email) != null) {
            if (!sendEmail && !matchedSmsCode) {
                Axios.get('/signature2user/' + email + '/authenticateEmailCode', { params: { 'code': randSmsCode } }).then(response => {
                    notyf.success(t("mandat:savedSignature", "Le code d'authentification vous a été envoyé par email à l'adresse " + email))
                    setSendEmail(true)
                }).catch(err => {
                    notyf.error(t("mandat:savedSignature", "Email KO"))
                })
            } else if (!matchedSmsCode)
                notyf.open(
                    {
                        closeWith: ['click'],
                        type: 'warning',
                        background: 'orange',
                        message: t("mandat:savedSignature", "Un mail vous a été envoyé à l'adresse " + email + ".<br/><br/> Veuillez réessayer dans une minute !!")
                    }
                )

        } else
            notyf.error(t("mandat:savedSignature", "Veuillez saisir un email valide !!"))
    }

    const toggleValueForEmailTimer = () => {
        if (sendEmail) {
            setSendEmail(false)
        }
    };
    useEffect(() => {
        const intervalId = setInterval(toggleValueForEmailTimer, 60000);
        return () => clearInterval(intervalId);
    }, [sendEmail])


    const emailInputChangeHandler = (value) => {
        setUserEmailToken(value)
        setUserSignatory({ ...userSignatory, email: value });
    }

    return (
        <>
            {!signdoc ?
                !isMyTurnToSign ?
                    <div class="alert alert-warning" role="alert">
                        {t('mandat:cantSignAdmOrder', "Ce n'est pas encore votre tour pour signer")}
                    </div>
                    :
                    <div className="signatureTab__container">

                        <div className="signatureTab__header">
                            <h5 className="signatureTab__header__title">
                                {t('mandat:signatureTab', "Signature de l’attestation de mandat")}
                            </h5>
                            <div className="signatureTab__header__resignButton__container">
                                <Link to={{ pathname: "/" }}
                                    className="btn btn-outline-danger btn-sm signatureTab__header__resignButton">
                                    {t('default:cancel', "Annuler")}
                                </Link>
                                <button
                                    className="btn btn-danger btn-sm signatureTab__header__resignButton"
                                    onClick={() => setShowReasonModal(true)}
                                >
                                    {t('default:deny', "Refuser")}
                                </button>
                                {
                                    showReasonModal ?
                                        <SecretResignReasonModal
                                            show={showReasonModal}
                                            handleClose={() => setShowReasonModal(false)}
                                            secretResignSignature={resignSignClickHandler}
                                        />
                                        : null
                                }
                            </div>
                            {
                                errors && errors.length > 0 ? (
                                    <div class="alert alert-danger mt-2" role="alert">
                                        <ul>
                                            {
                                                errors.map(error => (<li>{error}</li>))
                                            }
                                        </ul>
                                    </div>
                                ) : (
                                    <div class="alert alert-light" role="alert">
                                        {t('mandat:signatureAlert', "Avant de poursuivre, assurez vous d'avoir bien verifier et respecter toutes les étapes")}
                                    </div>
                                )
                            }
                        </div>
                        <div className="signatureTab__steps">
                            <div className="signatureTab__step">
                                <div className="singatureTab__step_header">
                                    {
                                        confirmIdentity ?
                                            <div className="signatureTab__stepDoneIcon"><DoneIcon /> </div>
                                            : sendSmsLoading ?
                                                <Spinner animation="border" variant="primary" />
                                                :
                                                <>
                                                    <div className="signatureTab__stepNumber">
                                                        <span>1</span>
                                                    </div>
                                                </>
                                    }
                                    <div className="signatureTab__stepCheckbox__container">
                                        <input
                                            type="checkbox"
                                            className="step__checkbox__input"
                                            id="identityConfirmCheckbox"
                                            checked={confirmIdentity}
                                            disabled={confirmIdentity}
                                            onChange={(e) => checkIdentityClickHandler(e.target.checked)}
                                        />
                                        <label
                                            className="step__checkbox__label"
                                            for="identityConfirmCheckbox">
                                            {t('mandat:confirmIdentity', "Je confirme mon identité")}
                                        </label>
                                    </div>

                                </div>
                                <div className="signatureTab__stepContent">
                                    <div className="stepContent__identityContent">
                                        <fieldset>
                                            <input
                                                type="text"
                                                className="form-control form-control-sm mb-2"
                                                placeholder="NOM"
                                                name="last_name"
                                                value={userSignatory?.last_name || ''}
                                                onChange={(e) => inputUserChangeHandler(e)}
                                                disabled={confirmIdentity}
                                            />
                                            <input
                                                type="text"
                                                className="form-control form-control-sm mb-2"
                                                placeholder="Prénom"
                                                name="first_name"
                                                value={userSignatory?.first_name || ''}
                                                onChange={(e) => inputUserChangeHandler(e)}
                                                disabled={confirmIdentity}
                                            />
                                            <input
                                                type="text"
                                                className="form-control form-control-sm mb-2"
                                                placeholder="Fonction"
                                                name="function"
                                                value={userSignatory?.function || ''}
                                                onChange={(e) => inputUserChangeHandler(e)}
                                                disabled={confirmIdentity}
                                            />
                                        </fieldset>
                                        <PhoneInput
                                            disabled={confirmIdentity}
                                            localization={fr}
                                            country={'fr'}
                                            inputClass={"form-control w-100"}
                                            dropdownStyle={{ width: "auto" }}
                                            preferredCountries={['fr', 'us']}
                                            enableSearch={false}
                                            inputProps={{ name: "telephone_number" }}
                                            placeholder={"33 0 00 00 00 00"}
                                            value={userSignatory?.telephone_number?.substr(2) || ''}
                                            onChange={phone => telNumChangeHandler("00" + phone)}
                                        />
                                        {
                                            !matchedSmsCode && userSignatoryErrors && userSignatoryErrors?.length > 0 ?
                                                <ul className="mt-3">
                                                    {
                                                        userSignatoryErrors.map(error => (
                                                            <li>
                                                                <span style={{ color: "red" }}>{error}</span>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="signatureTab__step">
                                <div className="singatureTab__step_header">
                                    {
                                        admScrolledEnd ?
                                            <div className="signatureTab__stepDoneIcon"><DoneIcon /> </div>
                                            :
                                            <div className="signatureTab__stepNumber"><span>2</span></div>
                                    }
                                    <div className="step__header__title">
                                        {t('mandat:pdfLectureM', "Scroller le document jusqu’en bas, pour justifier de votre lecture ")}
                                    </div>
                                </div>
                            </div>

                            <div className="signatureTab__step">
                                <div className="singatureTab__step_header">
                                    {
                                        docApproved ?
                                            <div className="signatureTab__stepDoneIcon"><DoneIcon /> </div>
                                            :
                                            <div className="signatureTab__stepNumber"><span>3</span></div>
                                    }
                                    <div className="signatureTab__stepCheckbox__container">
                                        <input
                                            type="checkbox"
                                            className="step__checkbox__input"
                                            id="docConfirmCheckbox"
                                            checked={docApproved}
                                            onChange={(e) => setDocApproved(e.target.checked)}
                                        />
                                        <label
                                            className="step__checkbox__label"
                                            for="docConfirmCheckbox">
                                            {t('mandat:approvePdfContent', "J’ai pris connaissance du document et j’approuve le contenu")}
                                        </label>
                                    </div>

                                </div>
                            </div>

                            <div className="signatureTab__step">
                                <div className="singatureTab__step_header">
                                    {
                                        matchedSmsCode ?
                                            <div className="signatureTab__stepDoneIcon"><DoneIcon /> </div>
                                            :
                                            <div className="signatureTab__stepNumber"><span>4</span></div>
                                    }
                                    <div className="step__header__title">
                                        {t('mandat:enterSmsCode', "Entrez le code reçu par SMS/Email")}
                                    </div>

                                </div>
                                <div className="signatureTab__stepContent">
                                    <div className="stepContent__identityContent">
                                        <input
                                            type="text"
                                            className="form-control form-control-sm "
                                            placeholder={t('mandat:receivedCode', "Code reçu")}
                                            value={smsCode || ''}
                                            disabled={matchedSmsCode}
                                            onChange={(e) => smsCodeChangeHandler(e)} />
                                    </div>
                                    <div className="stepContent__identityContent">
                                        <input
                                            style={{ marginTop: "5px" }}
                                            type='checkbox'
                                            className="step__checkbox__input"
                                            id="emailCheckbox"
                                            checked={showEmailToken}
                                            onChange={(e) => setShowEmailToken(e.target.checked)}
                                            disabled={matchedSmsCode}
                                        />
                                        <label
                                            className="step__checkbox__label"
                                            style={{ fontSize: "13px", color: "#2d2c2c !important", fontWeight: "100" }}
                                            for="label">
                                            {t('mandat:receiveEmailCode', "Recevoir le code par email")}
                                            <AlternateEmailIcon style={{ color: "rgb(89 147 179)", fontSize: "17px" }} />
                                        </label>

                                    </div>
                                    {
                                        showEmailToken ?
                                            <div className="stepContent__identityContent" style={{ width: "auto" }}>
                                                {
                                                    <div className="input-group">
                                                        <input
                                                            value={userEmailToken}
                                                            type="text"
                                                            className="form-control" placeholder="Adresse Email"
                                                            aria-describedby="button-addon2"
                                                            onChange={(e) => emailInputChangeHandler(e?.target.value)}
                                                            disabled={sendEmail || matchedSmsCode}
                                                        />
                                                        <button className={"signatureTab__specBtn" + " btn btn-primary"}
                                                            type="button"
                                                            id="button-addon2"
                                                            style={{
                                                                background: (sendEmail || matchedSmsCode) ? "gray" : "",
                                                                cursor: (sendEmail || matchedSmsCode) ? "auto" : "pointer"
                                                            }}
                                                            disabled={sendEmail || matchedSmsCode}
                                                            onClick={(e) => sendEmailHandle(userEmailToken)}
                                                        >
                                                            <SendIcon />
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                            : null
                                    }


                                </div>
                            </div>

                            <div className="signatureTab__step">
                                <div className="singatureTab__step_header">
                                    {
                                        signatureDataB64 ?
                                            <div className="signatureTab__stepDoneIcon"><DoneIcon /> </div>
                                            :
                                            <div className="signatureTab__stepNumber"><span>5</span></div>
                                    }
                                    <div className="step__header__title">
                                        {t('mandat:handSign', "Dessiner ou déposer votre signature dans le carré ci-dessous")}
                                    </div>
                                </div>
                                <div className="signatureTab__canvasSignContent">
                                    <SignatureCanvas
                                        setSignatureDataB64={setSignatureDataB64}
                                        signatureDataB64={signatureDataB64}
                                        saveSignature={saveSignature}
                                        selectedSignature={selectedSignature}
                                        setSelectedSignature={setSelectedSignature}
                                        accessToken={accessToken}
                                    />
                                </div>
                            </div>

                        </div>
                        <div className="signatureTab__signButton__container">
                            {
                                signADMLoading ?
                                    <Spinner animation="border" variant="success" />
                                    :
                                    <button className="btn btn-success signatureTab__signButton" onClick={() => signClickHandler()}>
                                        {t('default:sign', "Signer")}
                                    </button>
                            }
                        </div>
                    </div>
                :
                <div className="signatureTab__step">
                    <div
                        className="singatureTab__step_header"
                        style={{
                            justifyContent: "center",
                            margin: "35px",
                            fontSize: 80,
                            marginTop: "150px"
                        }}
                    >

                        <div className="signatureTab__stepDoneIcon" style={{ justifyContent: "center", padding: "30px" }} >
                            <DoneIcon style={{ fontSize: "1.9rem" }} />
                        </div>
                    </div>
                    <div >
                        {//t('mandat:pdfLecture', "Lisez attentivement l’attestation de mandat jusqu’à la fin du document")
                        }
                        <h2 style={{ textAlign: "center" }}> Vous venez de signer l'attestation de mandat.<br></br><br></br></h2>


                    </div>
                </div>
            }

        </>
    )
}




const mapStateToProps = (state) => ({
    status: state.mandat?.admData?.status,
})
const mapDispatchToProps = dispatch => ({

})
export default connect(mapStateToProps, mapDispatchToProps)(SecretSignatureTab)