import React, { useCallback, useEffect, useState } from 'react'
import iconEntity from '../../../assets/images/icon-entity.png'
import logoHand from '../../../assets/images/logo-hand.png'
import AutorenewIcon from '@material-ui/icons/Autorenew';

import { useParams } from 'react-router-dom';

import IdentificationNumModal from './Modals/IdentificationNumModal/IdentificationNumModal'
import AddressModal from './Modals/AddressModal/AddressModal'
import { Notyf } from 'notyf';
import CloseIcon from '@material-ui/icons/Close';
import Axios from '../../../axios-edipub';
import AddBoxIcon from '@material-ui/icons/AddBox';
import BusinessIcon from '@material-ui/icons/Business';
import ApartmentIcon from '@material-ui/icons/Apartment';
import { Tooltip, withStyles } from '@material-ui/core';
import { checkValidity, getNotyfObject } from '../../../shared/utility'
import countries from '../../../assets/countries.json'
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next';
import BandAddressModal from './Modals/BandAddressModal/BandAddressModal'
import Spinner from 'react-bootstrap/Spinner';
import ConfirmDeleteMediaModal from './Modals/ConfirmDeleteMediaModal/ConfirmDeleteMediaModal'
import ConfirmDeleteSupportModal from './Modals/ConfirmDeleteSupportModal/ConfirmDeleteSupportModal'
import NotyfAdminDelSupportModal from './Modals/NotyfAdminDelSupportModal/NotyfAdminDelSupportModal'
import FileCopyIcon from '@material-ui/icons/FileCopy';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';

import FlipToFrontOutlinedIcon from '@material-ui/icons/FlipToFrontOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { uuidv4 } from '../../../shared/utility'
import CustomConfirmModal from '../../ADMContext/ADMProgressLine/CustomConfirmModal/CustomConfirmModal'
import ManualEntityCreationModal from './Modals/ManualEntityCreationModal/ManualEntityCreationModal'
import TooltipInfo from '../../UI/TooltipInfo/TooltipInfo'
import Select from "react-select";
// const addressInputChangeHandler = (e) => {
//     let field = e.target.name;
//     let value = e.target.value;
//     let addressTemp = {
//         ...addressLoc, 
//         [field] : value
//     }
//     setAddressLoc(addressTemp)
// }

const CTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 15,
    },
}))(Tooltip);

function CompanyForm({ validEntityData, setValidEntityData, loading, entity, setEntity, addEntity, validateEntity, disableEntity, updateEntity, cancelEntity, deleteEntity, role, authUserId, handleFormChanged, formChanged, updateEntityWithApi }) {
    const { t } = useTranslation();
    let notyf = getNotyfObject()
    const [idNumModalShow, setIdNumModalShow] = useState(false)
    const [ssAdrModalShow, setSsAdrModalShow] = useState(false);
    const [invAdrModalShow, setInvAdrModalShow] = useState(false);
    const [sendInvAdrModalShow, setSendInvAdrModalShow] = useState(false);
    const [compAdrModalShow, setCompAdrModalShow] = useState(false);
    const [productInputShow, setProductInputShow] = useState(false);
    const [departmentInputShow, setDepartmentInputShow] = useState(false);
    const [mediaInputShow, setMediaInputShow] = useState(false);
    const [supportInputShow, setSupportInputShow] = useState(false);
    const [selectedIdentNumber, setSelectedIdentNumber] = useState(null);
    const [brandLabelToAdd, setBrandLabelToAdd] = useState(null)
    const [departmentLabelToAdd, setDepartmentLabelToAdd] = useState(null)
    const [mediaLabelToAdd, setMediaLabelToAdd] = useState(null)
    const [addressComp, setAddressComp] = useState(null)
    const [errors, setErrors] = useState(null);
    const [authUser, setAuthUser] = useState(null);

    const [selectedMedia, setSelectedMedia] = useState(null);
    const [supportToAdd, setSupportToAdd] = useState(null);
    const [addClauseShow, setAddClauseShow] = useState(false);
    const [showBrandAddressModal, setShowBrandAddressModal] = useState(false)
    const [showBrandAddressMailModal, setShowBrandAddressMailModal] = useState(false)
    const [selectedBrandForAddress, setSelectedBrandForAddress] = useState(null)
    const [showConfirmDeleteMediaModal, setShowConfirmDeleteMediaModal] = useState(false)
    const [mediaToDelete, setMediaToDelete] = useState(null);
    const [showConfirmDeleteSupportModal, setShowConfirmDeleteSupportModal] = useState(false)
    const [supportToDelete, setSupportToDelete] = useState(null);
    const [showNotifAdminDelSupModal, setShowNotifAdminDelSupModal] = useState(false)
    const [mediaArray, setMediaArray] = useState([]);
    const [copiedAdress, setCopiedAdress] = useState();
    //const [entityExist, setEntityExist] = useState(false);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false)
    const [showRequireControlModal, setShowRequireControlModal] = useState(false)
    const [agencyManagers, setAgencyManagers] = useState([]);
    const [showManualEntityCreationModal, setShowManualEntityCreationModal] = useState(false)
    //const [showConfirmCreatingCompany, setShowConfirmCreatingCompany] = useState(false)
    //const [entityToAdd, setEntityToAdd] = useState(null);

    // useEffect(() => {
    //     setErrors(null)

    //     if (selectedMedia) {
    //         const newSelectedMedia = entity?.medias?.find(m => m.label === selectedMedia.label);
    //         if (!newSelectedMedia?.new)
    //             setSelectedMedia(newSelectedMedia);
    //     }
    // }, [entity])












    useEffect(() => {
        setErrors(null)
        if (selectedMedia) {
            const newSelectedMedia = entity?.medias?.find(m => m.label === selectedMedia.label);
            if (!newSelectedMedia?.new)
                setSelectedMedia(newSelectedMedia);
        }
        if (entity?.uid && (role == "AE" || (checkEntity2AuthUser(entity) && (role === "AG" || role === "MG" || role === "MR" || role === "RG"))
            || ((role === "MG" || role === "MR") && entity?.category === "Annonceur"))) {
            let errs = checkEntityFormValidity(entity)
            if (errs.length > 0) {
                if (role == "AE") {
                    setErrors(errs)
                    setValidEntityData(false)
                } else {
                    let specificErrs = []
                    for (let i = 0; i < errs.length; i++) {
                        if (errs[i] === t("default:emptyBillingAddress") || errs[i] === t("default:emptySendInvAddress")) {
                            specificErrs.push(errs[i])
                        }
                    }
                    errs = (specificErrs.length > 0) ? specificErrs : [t("default:saveIncompleteEntityData")];
                    setErrors(errs)
                    setValidEntityData(false)
                }
            } else
                setValidEntityData(true)

        }

    }, [entity])

    useEffect(() => {
        getAuthUserDetails();
        getMedia();
    }, [])



    useEffect(() => {
        setSelectedMedia(null);
        if (entity?.id && role === "AE" && entity?.category.toLowerCase() === "annonceur" && agencyManagers.length === 0)
            getAgencyManagers();
    }, [entity?.id])

    const getMedia = () => {
        Axios.get("/document//getMedia"
        ).then(response => {
            if (response?.data && response?.data) {
                const result = Object.values(response?.data.media);
                setMediaArray(result);

            }
        }).catch(response => {

        })
    }
    const getAgencyManagers = () => {
        Axios.get("/user//agencyManagers").then(response => {
            if (response?.data) {
                setAgencyManagers(response.data.map(u => ({ value: u.id, label: u.first_name + " " + u.last_name + " (" + u.email + ")" })));
            }
        }).catch(response => {

        })
    }
    const entityInputChangeHandler = (field, value) => {
        let entityTemp = {
            ...entity,
            [field]: value
        }
        setEntity(entityTemp)
        //setEntityExist(false)
        handleFormChanged(true);
    }




    const addIdNumIconClick = () => {
        setSelectedIdentNumber(null);
        setIdNumModalShow(true);
    }

    const selectIdentNumHandler = (identNum, index) => {
        let identNumTemp = { ...identNum, index }
        setSelectedIdentNumber(identNumTemp);
        setIdNumModalShow(true);
    }

    const addIdentNumHandler = (identNum) => {
        let entityTemp = { ...entity };
        if (entityTemp.identNumbers && entityTemp.identNumbers.length > 0) {
            let idNumIndex = entityTemp.identNumbers.findIndex(idnum => idnum.type === identNum.type);
            if (idNumIndex >= 0) {
                notyf.error(t("masterData:identificationNumberAlreadyExist"));
            } else {
                entityTemp = {
                    ...entityTemp,
                    identNumbers: [...entityTemp.identNumbers, identNum],
                    identNumToAdd: entityTemp.hasOwnProperty('identNumToAdd') ? [...entityTemp.identNumToAdd, identNum] : [identNum]
                }
            }
        } else {
            entityTemp = {
                ...entityTemp,
                identNumbers: [identNum],
                identNumToAdd: entityTemp.hasOwnProperty('identNumToAdd') ? [...entityTemp.identNumToAdd, identNum] : [identNum]
            }
        }
        setEntity(entityTemp)
        setIdNumModalShow(false);
        handleFormChanged(true);
    }

    const updateIdentNumHandler = (identNum) => {
        let entityTemp = { ...entity };
        let idNumTypeIndex = entityTemp.identNumbers.findIndex(idnum => idnum.type === identNum.type);
        if (idNumTypeIndex >= 0 && idNumTypeIndex !== identNum.index) {
            notyf.error(t("masterData:identificationNumberAlreadyExist"));
        } else {
            if (identNum.hasOwnProperty('new')) {
                entityTemp.identNumbers[identNum.index] = identNum
                let idNumIndex = entityTemp.identNumToAdd.findIndex(idnum => idnum.type === identNum.type);
                entityTemp.identNumToAdd[idNumIndex] = identNum
            } else {
                entityTemp.identNumbers[identNum.index] = identNum;
                if (entityTemp.hasOwnProperty('identNumToUpdate') && entityTemp.identNumToUpdate.length > 0) {
                    let idNumIndex = entityTemp.identNumToUpdate.findIndex(idnum => idnum.uid === identNum.uid);
                    if (idNumIndex > 0)
                        entityTemp.identNumToUpdate[idNumIndex] = identNum;
                    else
                        entityTemp.identNumToUpdate.push(identNum);
                } else {
                    entityTemp = {
                        ...entityTemp,
                        identNumToUpdate: [identNum]
                    }
                }
            }
            setEntity(entityTemp);
        }
        setIdNumModalShow(false);
        handleFormChanged(true);
    }

    const deleteIdentNumHandler = (identNum, index) => {
        let entityTemp = { ...entity };
        entityTemp.identNumbers.splice(index, 1)
        if (identNum.hasOwnProperty('new')) {
            let idNumIndex = entityTemp.identNumToAdd.findIndex(idnum => idnum.type === identNum.type);
            entityTemp.identNumToAdd.splice(idNumIndex, 1)
        } else {
            entityTemp = {
                ...entityTemp,
                identNumToDelete: entityTemp.hasOwnProperty('identNumToDelete') ? [...entityTemp.identNumToDelete, identNum.uid] : [identNum.uid]
            }
        }
        setEntity(entityTemp)
        handleFormChanged(true);
    }

    const addBrandHandler = () => {
        if (brandLabelToAdd && brandLabelToAdd.trim() !== "") {
            let existLabel = false;
            if (entity?.brands && entity?.brands.length > 0) {
                existLabel = entity?.brands.findIndex(brand => brand.label.toUpperCase() === brandLabelToAdd.toUpperCase()) >= 0;
            }
            if (existLabel) {
                notyf.error(t("masterData:existBrandLabel", "ce produit existe déja!"))
            } else {
                if (entity?.uid) {
                    Axios.get('/brand//checkBrandForAdd', {
                        params: {
                            advertiserUid: entity?.uid,
                            label: brandLabelToAdd
                        }
                    }).then(response => {
                        let canAdd = response?.data?.canAdd
                        if (response?.data?.hasOwnProperty("overwrite") && response?.data?.overwrite) {
                            notyf.open({
                                type: "warning",
                                message: t("masterData:overwritingLabel", "ATTENTION : Ce produit est déjà créé par des agences, les données liées à ce produit seront écrasés")
                            })
                        }
                        if (canAdd) {
                            let brandTemp = {
                                new: true,
                                label: brandLabelToAdd
                            }
                            let brandsTemp = (entity?.brands && entity?.brands.length > 0) ? [...entity.brands, brandTemp] : [brandTemp];
                            setEntity({
                                ...entity,
                                brands: brandsTemp,
                                brandsToAdd: (entity?.brandsToAdd && entity?.brandsToAdd.length > 0) ? [...entity.brandsToAdd, brandTemp] : [brandTemp]
                            })
                        } else {
                            notyf.error(t("masterData:existBrandLabel", "ce produit existe déja!"))
                        }
                    }).catch(response => {
                        notyf.error(t("default:errorMessage"));
                    })
                } else {
                    let brandTemp = {
                        new: true,
                        label: brandLabelToAdd
                    }
                    let brandsTemp = (entity?.brands && entity?.brands.length > 0) ? [...entity.brands, brandTemp] : [brandTemp];
                    setEntity({
                        ...entity,
                        brands: brandsTemp,
                        brandsToAdd: (entity?.brandsToAdd && entity?.brandsToAdd.length > 0) ? [...entity.brandsToAdd, brandTemp] : [brandTemp]
                    })
                }
            }

            setBrandLabelToAdd(null);
            handleFormChanged(true);
        }

    }

    const deleteBrandHandler = (brand, index) => {
        let brandsTemp = [...entity.brands];
        brandsTemp.splice(index, 1);
        if (brand.hasOwnProperty('new')) {
            let brandsToAddT = [...entity?.brandsToAdd];
            let brandIndex = brandsToAddT.findIndex(item => item.label === brand.label);
            brandsToAddT.splice(brandIndex, 1);
            setEntity({
                ...entity,
                brands: brandsTemp,
                brandsToAdd: brandsToAddT
            })
        } else {
            setEntity({
                ...entity,
                brands: brandsTemp,
                brandsToDelete: (entity?.brandsToDelete && entity?.brandsToDelete.length > 0) ? [...entity.brandsToDelete, brand.uid] : [brand.uid]
            })
        }
        handleFormChanged(true);
    }

    const addBrandAddressHandler = (address, brand, addressType) => {
        let brandsTemp = [...entity?.brands];
        let brandIndex = brandsTemp.findIndex(item => item.label === brand.label);
        // let brandTemp = {
        //     ...brand,
        //     address
        // }
        let brandTemp = {
            ...brand
        }
        brandTemp[addressType] = address
        brandsTemp[brandIndex] = brandTemp;
        if (brand.hasOwnProperty('new')) {
            let brandsToAddTemp = [...entity.brandsToAdd]
            let brandToAddIndex = brandsToAddTemp.findIndex(item => item.label === brand.label);
            brandsToAddTemp[brandToAddIndex] = brandTemp;
            setEntity({
                ...entity,
                brands: brandsTemp,
                brandsToAdd: [...brandsToAddTemp]
            })
        } else {
            if (entity.hasOwnProperty('brandsToUpdate')) {
                let brandsToUpdateTemp = [...entity.brandsToUpdate]
                let brandToUpdateIndex = brandsToUpdateTemp.findIndex(item => item.label === brand.label);
                if (brandToUpdateIndex >= 0)
                    brandsToUpdateTemp[brandToUpdateIndex] = brandTemp;
                else
                    brandsToUpdateTemp.push(brandTemp)
                setEntity({
                    ...entity,
                    brands: brandsTemp,
                    brandsToUpdate: [...brandsToUpdateTemp]
                })
            } else {
                setEntity({
                    ...entity,
                    brands: brandsTemp,
                    brandsToUpdate: [brandTemp]
                })
            }
        }

    }





    const showBrandAdressModalHandler = (brand) => {
        setSelectedBrandForAddress(brand)
        setShowBrandAddressModal(true);
    }

    const showBrandAdressMailModalHandler = (brand) => {
        setSelectedBrandForAddress(brand)
        setShowBrandAddressMailModal(true);
    }

    const addMediaHandler = () => {
        if (mediaLabelToAdd && mediaLabelToAdd.trim() !== "" && !entity.medias?.find(m => m.label === mediaLabelToAdd)) {
            const mediaTemp = {
                new: true,
                label: mediaLabelToAdd
            }
            const mediasTemp = (entity?.medias && entity?.medias.length > 0) ? [...entity.medias, mediaTemp] : [mediaTemp];
            setEntity({
                ...entity,
                medias: mediasTemp,
                mediasToAdd: (entity?.mediasToAdd && entity?.mediasToAdd.length > 0) ? [...entity.mediasToAdd, mediaTemp] : [mediaTemp]
            })
            setMediaLabelToAdd(null);
            setMediaInputShow(false);
            handleFormChanged(true);
        }
    }

    const checkDeleteMedia = (media, index) => {
        Axios.get(`/entity/${media.adDepartmentUid}/checkDeleteMedia`).then(response => {
            const existSupportsInDocs = response?.data?.existSupportsInDocs
            if (existSupportsInDocs) {
                setMediaToDelete({
                    ...media,
                    mediaIndex: index
                })
                setShowConfirmDeleteMediaModal(true)
            } else {
                deleteMediaHandler(media, index)
            }
        }).catch(response => {
            notyf.error(t("mandat:generatedErrorMessage"))
        })
    }

    const deleteMediaClickHandler = (media, index) => {
        if (!media.hasOwnProperty('new')) {
            checkDeleteMedia(media, index);
        } else {
            deleteMediaHandler(media, index)
        }
    }

    const deleteMediaHandler = (media, index) => {
        const mediasTemp = [...entity.medias];
        mediasTemp.splice(index, 1);
        if (media.hasOwnProperty('new')) {
            const mediasToAddT = [...entity?.mediasToAdd];
            const brandIndex = mediasToAddT.findIndex(item => item.label === media.label);
            mediasToAddT.splice(brandIndex, 1);
            setEntity({
                ...entity,
                medias: mediasTemp,
                mediasToAdd: mediasToAddT
            })
        } else {
            setEntity({
                ...entity,
                medias: mediasTemp,
                mediasToDelete: (entity?.mediasToDelete && entity?.mediasToDelete.length > 0) ? [...entity.mediasToDelete, media.uid] : [media.uid]
            })
        }
        handleFormChanged(true);
    }

    const selectMedia = media => {
        if (media === selectedMedia)
            setSelectedMedia(null);
        else
            setSelectedMedia(media);
    }

    const supportToAddChangeHandler = (e) => {
        const field = e.target.name;
        const value = e.target.value;
        setSupportToAdd({
            ...supportToAdd,
            [field]: value
        })
    }

    //check if an other support with the same code exist in the current supports medias
    const checkExistSupport = (supportToAdd) => {
        let exist = false;
        if (entity?.medias && entity?.medias.length > 0) {
            let mediasTemp = [...entity.medias];
            let mediaWithSupportToAdd = mediasTemp.filter(media => {
                if (media?.supports && media.supports.length > 0) {
                    exist = media?.supports.find(sup => sup.supportCode === supportToAdd.code) ? true : false
                    if (exist) {
                        return true
                    }
                }
                if (media?.supportsToAdd && media.supportsToAdd.length > 0) {
                    exist = media?.supportsToAdd.find(sup => sup.supportCode === supportToAdd.code) ? true : false
                    if (exist) {
                        return true
                    }
                }
            })
            if (mediaWithSupportToAdd.length > 0) {
                return [true, mediaWithSupportToAdd[0].label]
            } else {
                return [false, null]
            }
        } else {
            return [false, null]
        }
    }

    const addSupport = (supportToAdd) => {
        let supportTemp = {
            new: true,
            supportCode: supportToAdd.code,
            supportLabel: supportToAdd.label
        }
        let mediasTemp = [...entity.medias];
        let mediaIndex = mediasTemp.findIndex(m => m.label === selectedMedia.label)
        let mediaTemp = { ...mediasTemp[mediaIndex] }
        mediaTemp = {
            ...mediaTemp,
            supports: (mediaTemp.supports && mediaTemp.supports.length > 0) ? [...mediaTemp.supports, supportTemp] : [supportTemp],
            // supportsToAdd : (mediaTemp.supportsToAdd && mediaTemp.supportsToAdd.length > 0) ? [...mediaTemp.supportsToAdd, supportTemp] : [supportTemp]
        }
        mediasTemp[mediaIndex] = mediaTemp
        setSelectedMedia(mediaTemp);
        setEntity({
            ...entity,
            medias: mediasTemp
        })
        setSupportToAdd(null);
        setSupportInputShow(false);
        handleFormChanged(true);

    }

    const notifyAdminDelSupport = () => {
        Axios.put('entity//notifyAdminDelSup', supportToAdd).then(response => {
            if (response?.data?.success) {
                notyf.success(t("masterData:notifyAdminSuccess", "L'administrateur a bien été notifié "));
                setSupportToAdd(null)
            } else {
                notyf.error(t("mandat:generatedErrorMessage"))
            }
        }).catch(response => {
            notyf.error(t("mandat:generatedErrorMessage"))
        })
    }

    const checkAddSupport = (supportToAdd) => {
        Axios.get(`/entity//checkAddSupport`, {
            params: {
                supportCode: supportToAdd.code
            }
        }).then(response => {
            const existSupport = response?.data?.existSupport
            if (existSupport) {
                const entityName = response?.data?.entityName;
                if (role === "AE")
                    notyf.error(t("masterData:existSupportRegie", "Ce support est déjà attribué à la régie ") + " " + entityName);
                else if (role === "RG") {
                    setSupportToAdd(supportToAdd => ({
                        ...supportToAdd,
                        entityName
                    }))
                    setShowNotifAdminDelSupModal(true)
                }
            } else {
                addSupport(supportToAdd)
            }
        }).catch(response => {
            notyf.error(t("mandat:generatedErrorMessage"))
        })
    }

    const addSupportHandler = () => {
        if (supportToAdd && supportToAdd?.code && supportToAdd?.code.trim() !== "" && supportToAdd?.label && supportToAdd?.label.trim() !== "") {
            let [existSupport, mediaOfSupport] = checkExistSupport(supportToAdd);
            if (existSupport) {
                notyf.error(t("masterData:existSupportMedia", "Un support avec le même code est déjà attribué pour le média ") + " " + mediaOfSupport);
            } else {
                checkAddSupport(supportToAdd);
            }
        } else {
            notyf.error(t("masterData:emptySupportToAdd", "Vous devez saisir un code et un libellé"))
        }

    }

    const checkDeleteSupport = (support) => {
        Axios.get(`/entity/${support.uid}/checkDeleteSupport`).then(response => {
            const existSupportInDocs = response?.data?.existSupportInDocs
            if (existSupportInDocs) {
                setSupportToDelete({
                    ...support
                })
                setShowConfirmDeleteSupportModal(true)
            } else {
                deleteSupportHandler(support)
            }
        }).catch(response => {
            notyf.error(t("mandat:generatedErrorMessage"))
        })
    }

    const deleteSupportClickHandler = (support) => {
        if (!support.hasOwnProperty('new')) {
            checkDeleteSupport(support);
        } else {
            deleteSupportHandler(support)
        }
    }

    const deleteSupportHandler = (support) => {
        let mediasTemp = [...entity.medias];
        let mediaIndex = mediasTemp.findIndex(m => m.label === selectedMedia.label)
        let mediaTemp = { ...mediasTemp[mediaIndex] }
        let supportIndex = mediaTemp.supports.findIndex(s => support.supportCode && s.supportCode === support.supportCode || s.supportLabel === support.supportLabel)
        let mediaSupports = [...mediaTemp.supports]
        mediaSupports.splice(supportIndex, 1);
        if (!support.hasOwnProperty("new")) {
            mediaTemp = {
                ...mediaTemp,
                supports: mediaSupports,
                supportsToDelete: (mediaTemp.supportsToDelete && mediaTemp.supportsToDelete.length > 0) ? [...mediaTemp.supportsToDelete, support.uid] : [support.uid]
            }
        } else {
            mediaTemp = {
                ...mediaTemp,
                supports: mediaSupports
            }
        }
        mediasTemp[mediaIndex] = mediaTemp
        setSelectedMedia(mediaTemp);
        setEntity({
            ...entity,
            medias: mediasTemp
        })
        handleFormChanged(true);
    }

    const addDepartmentHandler = () => {
        if (departmentLabelToAdd && departmentLabelToAdd.trim() !== "") {
            let existLabel = false;
            if (entity?.departments && entity?.departments.length > 0) {
                existLabel = entity?.departments.findIndex(department => department.label.toUpperCase() === departmentLabelToAdd.toUpperCase()) >= 0;
            }
            if (existLabel) {
                notyf.error(t("masterData:departmentAlreadyExists", "Ce département existe déjà !"))
            } else {
                if (entity?.uid) {
                    let departmentTemp = {
                        new: true,
                        label: departmentLabelToAdd
                    }
                    let departmentsTemp = (entity?.departments && entity?.departments.length > 0) ? [...entity.departments, departmentTemp] : [departmentTemp];
                    setEntity({
                        ...entity,
                        departments: departmentsTemp,
                        departmentsToAdd: (entity?.departmentsToAdd && entity?.departmentsToAdd.length > 0) ? [...entity.departmentsToAdd, departmentTemp] : [departmentTemp]
                    })
                } else {
                    let departmentTemp = {
                        new: true,
                        label: departmentLabelToAdd
                    }
                    let departmentsTemp = (entity?.departments && entity?.departments.length > 0) ? [...entity.departments, departmentTemp] : [departmentTemp];
                    setEntity({
                        ...entity,
                        departments: departmentsTemp,
                        departmentsToAdd: (entity?.departmentsToAdd && entity?.departmentsToAdd.length > 0) ? [...entity.departmentsToAdd, departmentTemp] : [departmentTemp]
                    })
                }
            }

            setDepartmentLabelToAdd(null);
            setDepartmentInputShow(false);
            handleFormChanged(true);
        }

    }

    const deleteDepartmentHandler = (department, index) => {
        let departmentsTemp = [...entity.departments];
        departmentsTemp.splice(index, 1);
        if (department.hasOwnProperty('new')) {
            let departmentsToAddT = [...entity?.departmentsToAdd];
            let departmentIndex = departmentsToAddT.findIndex(item => item.label === department.label);
            departmentsToAddT.splice(departmentIndex, 1);
            setEntity({
                ...entity,
                departments: departmentsTemp,
                departmentsToAdd: departmentsToAddT
            })
        } else {
            setEntity({
                ...entity,
                departments: departmentsTemp,
                departmentsToDelete: (entity?.departmentsToDelete && entity?.departmentsToDelete.length > 0) ? [...entity.departmentsToDelete, department.uid] : [department.uid]
            })
        }
        handleFormChanged(true);
    }

    const addressClickHandler = (address, index) => {
        let addressTemp = { ...address, index };
        setAddressComp(addressTemp);
        setCompAdrModalShow(true)
    }

    const addAddressCompHandler = (addressToAdd) => {
        setEntity({
            ...entity,
            compAddresses: (entity?.compAddresses && entity?.compAddresses.length > 0) ? [...entity.compAddresses, addressToAdd] : [addressToAdd],
            compAddressesToAdd: (entity?.compAddressesToAdd && entity?.compAddressesToAdd.length > 0) ? [...entity.compAddressesToAdd, addressToAdd] : [addressToAdd]
        })
        handleFormChanged(true);
    }

    const updateAddressCompHandler = (addressToUpdate) => {
        let addressesCompTemp = [...entity?.compAddresses];
        addressesCompTemp[addressToUpdate.index] = addressToUpdate;
        if (addressToUpdate.hasOwnProperty('new')) {
            let compAddressesToAddT = [...entity?.compAddressesToAdd]
            let addressIndex = compAddressesToAddT.findIndex(item => item.uidTemp === addressToUpdate.uidTemp);
            compAddressesToAddT[addressIndex] = addressToUpdate;
            setEntity({
                ...entity,
                compAddresses: addressesCompTemp,
                compAddressesToAdd: compAddressesToAddT
            })
        } else {
            setEntity({
                ...entity,
                compAddresses: addressesCompTemp,
                compAddressesToUpdate: (entity?.compAddressesToUpdate && entity?.compAddressesToUpdate.length > 0) ? [...entity.compAddressesToUpdate, addressToUpdate] : [addressToUpdate]
            })
        }
        handleFormChanged(true);

    }

    const deleteAddressHandler = (addressType, address) => {
        if (!address.hasOwnProperty('new')) {
            setEntity({
                ...entity,
                [addressType]: null,
                addressesToDelete: (entity?.addressesToDelete && entity?.addressesToDelete.length > 0) ? [...entity.addressesToDelete, address.uid] : [address.uid]
            })
        } else {
            setEntity({
                ...entity,
                [addressType]: null
            })
        }
        handleFormChanged(true);
    }
    const copierAddressHandler = (addressType, address) => {

        if (copiedAdress) {
            let addressTemp = {
                new: true,
                uidTemp: uuidv4(),
                iso_country: address?.iso_country || addressType === "address_mail" ? address.iso_country : "FR",
                ...address
            }

            if (!address.hasOwnProperty('new')) {
                setEntity({
                    ...entity,
                    [addressType]: addressTemp,
                })
            } else {
                setEntity({
                    ...entity,
                    [addressType]: addressTemp,
                })
            }
            handleFormChanged(true);
        }
    }

    const deleteAddressCompHandler = (addressToDelete, index) => {
        let addressesCompTemp = [...entity?.compAddresses];
        addressesCompTemp.splice(index, 1);
        if (addressToDelete.hasOwnProperty('new')) {
            let compAddressesToAddT = [...entity?.compAddressesToAdd]
            let addressIndex = compAddressesToAddT.findIndex(item => item.uid === addressToDelete.uid);
            compAddressesToAddT.splice(addressIndex, 1)
            setEntity({
                ...entity,
                compAddresses: addressesCompTemp,
                compAddressesToAdd: compAddressesToAddT
            })
        } else {
            setEntity({
                ...entity,
                compAddresses: addressesCompTemp,
                compAddressesToDelete: (entity?.compAddressesToDelete && entity?.compAddressesToDelete.length > 0) ? [...entity.compAddressesToDelete, addressToDelete.uid] : [addressToDelete.uid]
            })
        }
        handleFormChanged(true);
    }

    const updateClausesHandler = (clausesToUpdate, clausesToDelete) => {
        setEntity({
            ...entity,
            clauses: clausesToUpdate,
            clausesToDelete: clausesToDelete ? clausesToDelete : entity.clausesToDelete
        });
        handleFormChanged(true);
    }

    const addClauseHandler = (clauseToAdd) => {
        const newClauses = entity.clauses ? [...entity.clauses, clauseToAdd] : [clauseToAdd];
        updateClausesHandler(newClauses);
    }

    const deleteClauseHandler = (clauseToDelete) => {
        const clausesTemp = [...entity.clauses];
        clausesTemp.splice(clauseToDelete.orderIndex, 1)
        const clausesToUpdate = clausesTemp.map((clause, index) => {
            let clauseTemp = { ...clause, orderIndex: index }
            return clauseTemp
        })
        const clausesToDelete = clauseToDelete.agency_id ? (entity.clausesToDelete ? [...entity.clausesToDelete, clauseToDelete] : [clauseToDelete]) : null;
        updateClausesHandler(clausesToUpdate, clausesToDelete)
    }

    const checkIsoCodeIsUE = (isoCode) => {
        if (isoCode) {
            let index = countries.findIndex(country => country.alpha2 === isoCode)
            return countries[index].isUE
        }
        return false
    }

    const checkEntityFormValidity = (entity) => {
        let errors = []
        if (!entity.hasOwnProperty('category') || !checkValidity(entity?.category, { required: true })) {
            errors.pusht(t("default:emptyCategorie"));
        }
        if (!entity.hasOwnProperty('official_name') || !checkValidity(entity?.official_name, { required: true })) {
            errors.push(t("default:emptyCompanyNameField"));
        }
        if (!entity.hasOwnProperty('commercial_name') || !checkValidity(entity?.commercial_name, { required: true })) {
            errors.push(t("default:emptyCommercialLabelField"));
        }
        // if(!entity.hasOwnProperty('siret') || !checkValidity(entity?.siret, {required: true})){
        //     errors.push("Le champ 'SIRET/identifiant' est vide ") ;
        // }
        // if(!checkIsoCodeIsUE(entity?.address_hq?.iso_country) && (!entity.hasOwnProperty('vat_number') || !checkValidity(entity?.vat_number, {required: true}))){
        //     errors.push("Le champ 'Numéro de TVA intracommunautaire' est vide ") ;
        // }
        if (!entity.hasOwnProperty('address_hq') || entity?.address_hq === null) {
            errors.push(t("default:emptyRegisteredAddress"));
        }
        if (entity?.category === 'Annonceur' && (!entity.hasOwnProperty('address_invoice') || entity?.address_invoice === null)) {
            errors.push(t("default:emptyBillingAddress"));
        }
        if ((entity?.category === 'Annonceur' || entity?.category === 'Agence') && (!entity.hasOwnProperty('address_mail') || entity?.address_mail === null)) {
            errors.push(t("default:emptySendInvAddress"));
        }
        if ((!entity.hasOwnProperty('forme_juridique') || entity?.forme_juridique === "" || entity?.forme_juridique === null) && entity.category !== "Regie") {
            errors.push(t("default:emptyLegalForm"));
        }
        if (entity.hasOwnProperty('address_hq') && checkIsoCodeIsUE(entity?.address_hq?.iso_country)) {
            // TVA pas necessaire pour les regies
            if (entity.hasOwnProperty('category') && entity.category !== 'Regie') {
                if (!entity.hasOwnProperty('identNumbers') || !entity.identNumbers || entity?.identNumbers.findIndex(ident => ident.type === "TVA") < 0) {
                    errors.push(t("default:EmptyVTANumber"));
                }
            }
            if (entity?.address_hq?.iso_country === "FR") {
                if (!entity.hasOwnProperty('identNumbers')
                    || !entity.identNumbers
                    || (entity?.identNumbers.findIndex(ident => ident.type === "SIRET") < 0 && entity?.identNumbers.findIndex(ident => ident.type === "SIREN") < 0)
                ) {
                    errors.push(t("default:EmptySIRETNumber"));
                }
                // else if(entity?.identNumbers.length < 3){
                //     errors.push("Un Numéro d'identification autre que SIRET,SIREN ou TVA doit être renseigné ") ;
                // }
            }
            // else {
            //     if(!entity.hasOwnProperty('identNumbers') || !entity.identNumbers || entity?.identNumbers.length < 2){
            //         errors.push("Un numéro d'identification autre que TVA doit être renseigné ") ;
            //     }
            // }
        } else {
            if (!entity.hasOwnProperty('identNumbers') || !entity.identNumbers || entity?.identNumbers.length == 0) {
                errors.push(t("default:emptyIdentificationNumber"));
            }
        }
        if (entity.public_service == "1") {
            if (!entity.hasOwnProperty('mandating_siret') || entity?.mandating_siret === null || !checkValidity(entity?.mandating_siret, { required: true })) {
                errors.push(t("default:emptyMandatingSiret"));
            }
        }
        if (entity?.contributor_imposed == "1" && (entity?.contributor === null || !checkValidity(entity?.contributor, { required: true }))) {
            errors.push(t("default:emptyContributor"));
        }
        if (entity?.signatory_imposed == "1" && (entity?.signatory === null || !checkValidity(entity?.signatory, { required: true }))) {
            errors.push(t("default:emptySignatory"));
        }
        if (entity?.legal_representative_imposed == "1" && (entity?.legal_representative === null || !checkValidity(entity?.legal_representative, { required: true }))) {
            errors.push(t("default:emptyLegalReprentative"));
        }
        if (entity?.workflow_actors_sign_order_mandatory == "1" && (
            (entity?.workflow_advertiser_sign_order === null || !checkValidity(entity?.workflow_advertiser_sign_order, { required: true })) ||
            (entity?.workflow_agency_sign_order === null || !checkValidity(entity?.workflow_agency_sign_order, { required: true })) ||
            (entity?.workflow_subagency_sign_order === null || !checkValidity(entity?.workflow_subagency_sign_order, { required: true })) ||
            (entity?.workflow_two_actors_advertiser_sign_order === null || !checkValidity(entity?.workflow_two_actors_advertiser_sign_order, { required: true })) ||
            (entity?.workflow_two_actors_agency_sign_order === null || !checkValidity(entity?.workflow_two_actors_agency_sign_order, { required: true }))
        )) {
            errors.push(t("default:emptyWorkflowActorsSignOrder"));
        }
        if (entity?.workflow_actors_sign_order_mandatory == "1" && (
            (entity?.workflow_advertiser_sign_order !== null && checkValidity(entity?.workflow_advertiser_sign_order, { required: true })) ||
            (entity?.workflow_agency_sign_order !== null && checkValidity(entity?.workflow_agency_sign_order, { required: true })) ||
            (entity?.workflow_subagency_sign_order !== null && checkValidity(entity?.workflow_subagency_sign_order, { required: true })) ||
            (entity?.workflow_two_actors_advertiser_sign_order !== null && checkValidity(entity?.workflow_two_actors_advertiser_sign_order, { required: true }))
                (entity?.workflow_two_actors_agency_sign_order !== null && checkValidity(entity?.workflow_two_actors_agency_sign_order, { required: true }))
        ) && (
                entity.workflow_advertiser_sign_order == entity.workflow_agency_sign_order &&
                entity.workflow_advertiser_sign_order == entity.workflow_subagency_sign_order &&
                entity.workflow_two_actors_advertiser_sign_order == entity.workflow_two_actors_agency_sign_order
            )
        ) {
            errors.push(t("default:invalidWorkflowActorsSignOrder", "Un ordre de signature des acteurs ne peut etre imposé que si il est different pour au moins deux d'entre eux"));
        }
        return errors;
    }

    const sendManualEntityClickHandler = (e) => {
        e.preventDefault();
        setShowManualEntityCreationModal(true);
    }

    const sendManualEntityEnrichClickHandler = (e) => {
        e.preventDefault();
        setShowManualEntityCreationModal(true);
    }

    const createManualEntity = (advertiserEmail, lang) => {
        return new Promise((resolve, reject) => {
            Axios.post("manualEntityToken", {
                advertiserEmail,
                lang,
                entityUid: (entity?.uid ? entity?.uid : null)
            }).then(response => {
                notyf.success(t('masterData:sendManualEntityNewMailSuccess', "mail de formulaire envoyé à votre contact"));
                resolve(response);
            }).catch(err => {
                if (err?.response?.status == '409')
                    notyf.error(t('masterData:sendManualEntityNewMailErrorConflict', "Erreur de l'envoi du mail de formulaire a votre contact. Aucune nouvelle demande n'est adressée à ce contact car il a déjà été contacté."));
                else
                    notyf.error(t('masterData:sendManualEntityNewMailError', "Erreur de l'envoi du mail de formulaire a votre contact."));
                reject(err);
            })
        })
    }

    const addEntityclickHandler = (e) => {
        e.preventDefault();

        let errors = checkEntityFormValidity(entity);
        if (errors && errors.length > 0) {
            setErrors(errors)
        } else {
            addEntity(e)
        }/*else{
            setErrors(null)
           
           // if("MG, MR".includes(role)){
                //setEntityToAdd(e);
                //setShowConfirmCreatingCompany(true);
                //}else{
            //}
            //}
            Axios.get("entity//checkReason", 
                {params: { entityReason : entity?.official_name}}
                ).then( response => {
                    if(!response?.data.entityExist){    
                        addEntity(e)
                    }else{
                        setEntityExist(true)
                    }
                });
        }*/
        handleFormChanged(false);
    }


    const validateEntityclickHandler = (e, userUid) => {
        e.preventDefault();
        let errors = checkEntityFormValidity(entity);
        if (errors && errors.length > 0) {
            setErrors(errors)
        } else {
            setErrors(null)
            validateEntity(e)
            //props.reloadUserList()
            handleFormChanged(false);
        }
    }

    const disableEntityclickHandler = (e) => {
        e.preventDefault();
        // let errors = checkEntityFormValidity(entity);
        // if(errors && errors.length > 0){
        //     setErrors(errors)
        // }else{
        //     setErrors(null)
        //     disableEntity(e)
        //     //props.reloadUserList()
        //     handleFormChanged(false);
        // }
        disableEntity(e)
        handleFormChanged(false);
    }

    const resetEntityFormHandler = () => {
        setErrors(null)
        setEntity({
            category: "Annonceur"
        })
        handleFormChanged(false);
    }

    const updateEntityclickHandler = (e) => {
        e.preventDefault();
        let errors = checkEntityFormValidity(entity);
        if (errors && errors.length > 0) {
            setErrors(errors)
        } else {
            setErrors(null)
            updateEntity(e)
        }
        handleFormChanged(false);
    }

    const cancelEntityclickHandler = (e) => {
        e.preventDefault();
        setErrors(null)
        cancelEntity(e)
        handleFormChanged(false);
    }

    const updateEntityWithApiClickHandler = (e) => {
        e.preventDefault();
        updateEntityWithApi(e)
        handleFormChanged(false);
    }

    const checkEntity2AuthUser = (entity) => {
        if ("MR, MG".includes(role) && entity?.category.toLowerCase() === "annonceur")
            return entity?.canManageAdvertiser;
        else {
            let found = false;
            if (authUser && authUser.entities) {
                authUser.entities.forEach(element => {
                    if (element.uid == entity.uid) {
                        found = true;
                    }
                });
            }
            return found;
        }
    }


    const capitalizeFirstLetter = (string) => {
        if (string != null) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        } else
            return ''
    }

    const getAuthUserDetails = (userUid) => {
        Axios.get("user/" + authUserId + "/user").then(response => {
            setAuthUser(response?.data?.user);
        }).catch(response => {
            notyf.error(t("default:errorMessage"));
        })
    }

    const verificationCopiedElement = (element) => {
        if (copiedAdress == element) {
            return true
        } else
            return false

    }

    const deleteEntityClickHandler = e => {
        e.preventDefault();
        setShowConfirmDeleteModal(true);
    }

    const publicServiceClickHandler = (value) => {

        let entityTemp = null
        if (value) {
            if (entity.serviceCodes) {
                let scsTemp = JSON.parse(JSON.stringify(entity.serviceCodes))
                for (let i = 0; i < scsTemp?.length; i++) {
                    if ((scsTemp[i].service_code === null || scsTemp[i].service_code.length === 0) && scsTemp[i].service_code_default_value !== null)
                        scsTemp[i].service_code = scsTemp[i].service_code_default_value
                }
                entityTemp = {
                    ...entity,
                    ["public_service"]: value,
                    ["serviceCodes"]: scsTemp
                }
                setEntity(entityTemp)
                //setEntityExist(false)
                handleFormChanged(true);
            } else {
                entityTemp = {
                    ...entity,
                    ["public_service"]: value,
                }
                let scsTemp = null;
                Axios.get("entity/" + (entity.uid ? entity.uid : '') + "/serviceCodes",
                    { params: {} }
                ).then(response => {
                    scsTemp = response.data.serviceCodes;
                    entityTemp = {
                        ...entityTemp,
                        ["serviceCodes"]: scsTemp
                    }
                    setEntity(entityTemp)
                    //setEntityExist(false)
                    handleFormChanged(true);
                });
            }
        } else {
            entityTemp = {
                ...entity,
                ["public_service"]: value,
                ["serviceCodes"]: null
            }
            setEntity(entityTemp)
            //setEntityExist(false)
            handleFormChanged(true);
        }
    }

    const serviceCodeHandler = (index, value) => {
        let serviceCodeTemp = JSON.parse(JSON.stringify(entity.serviceCodes))
        serviceCodeTemp[index].service_code = value
        setEntity({
            ...entity,
            serviceCodes: serviceCodeTemp
        })
    }

    const checkBoxChangeHandler = (e) => {
        let field = e.target.name;
        let checkedValue = e.target.checked ? "1" : "0";
        setEntity({
            ...entity,
            [field]: checkedValue
        })
        handleFormChanged(true);
    }

    const sendControlRequestEmail = () => {
        return new Promise((resolve, reject) => {
            Axios.post("entity/" + entity?.uid + "/sendControlRequestEmail").then(response => {
                notyf.success(t('masterData:controlRequestSuccess', "Email de demande d'autorisation de modification envoyé à un administrateur"));
                resolve(response);
            }).catch(err => {
                notyf.error(t('masterData:controlRequestError', "Erreur de l'envoi d'mail de demande d'autorisation de modification"));
                reject(err);
            })
        })
    }

    const workflowActorsSignOrderMandatoryChangeHandler = (e) => {
        let checked = e.target.checked;
        let entityTemp = {
            ...entity,
            workflow_actors_sign_order_mandatory: (checked ? '1' : '0'),
            workflow_advertiser_sign_order: (checked ? '1' : null),
            workflow_agency_sign_order: (checked ? '1' : null),
            workflow_subagency_sign_order: (checked ? '1' : null),
            workflow_two_actors_advertiser_sign_order: (checked ? '1' : null),
            workflow_two_actors_agency_sign_order: (checked ? '1' : null)
        }
        setEntity(entityTemp)
        handleFormChanged(true);
        /*
        if(checked){ 
            //entityInputChangeHandler("workflow_actors_sign_order_mandatory", "1")  
            entityTemp = {
                ...entity, 
                workflow_actors_sign_order_mandatory : '1',
                workflow_advertiser_sign_order : 1,
                workflow_agency_sign_order : 1,
                workflow_subagency_sign_order : 1
            } 
        } else{
            entityTemp = {
                ...entity, 
                workflow_actors_sign_order_mandatory : '0',
                workflow_advertiser_sign_order : null,
                workflow_agency_sign_order : null,
                workflow_subagency_sign_order : null
            }
            setEntity(entityTemp)
            handleFormChanged(true);
        }  */
    }

    const withNumericBoundariesChangeHandler = (e, min, max) => {
        let value = e.target.value
        if (!value)
            value = min;
        else if (parseInt(value) > parseInt(max))
            value = max
        else if (parseInt(value) < parseInt(min))
            value = min
        entityInputChangeHandler(e.target.name, value)
    }

    return (
        <>
            <form>
                {
                    !loading && !entity?.id && (role === "MG" || role === "MR") && entity?.category === "Annonceur" && (
                        !('id' in entity) && !('email' in entity) && !('address_line' in entity) && !('city_cedex' in entity) && (!('corporate_name' in entity) || entity.corporate_name == '')
                        && !('distribution_service' in entity) && !('iso_country' in entity) && !('post_code' in entity) && !('address_invoice' in entity) && !('address_mail' in entity)
                        && !('capital_social' in entity) && !('code_ape' in entity) && (!('commercial_name' in entity) || entity.commercial_name == '') && !('engagement_number' in entity) && (!('forme_juridique' in entity) || entity.forme_juridique == '')
                        && !('identNumbers' in entity) && !('mandating_siret' in entity) && (!('official_name' in entity) || entity.official_name == '') && !('public_service' in entity) && !('rcs' in entity) &&
                        !('serviceCodes' in entity)
                    ) &&
                    <>
                        <div className="header_infos_container">
                            <input
                                type="submit"
                                value={"Envoyer un formulaire vierge à l’Annonceur pour création"}
                                className="btn btn-primary mt-3 rightSide_form_submitBtn"
                                onClick={(e) => sendManualEntityClickHandler(e)}
                            />
                        </div>
                        <p style={{ borderBottom: '1px solid rgba(0,0,0,.1)', padding: "5px 0", marginBottom: "20px" }}></p>
                    </>
                }
                <div className="rightSide_header_container">
                    <div className="header_infos_container">
                        <img className="rightSide_header_logo" src={iconEntity}></img>
                        <div className="header_labels_container">
                            <strong>{entity ? capitalizeFirstLetter(entity?.official_name) : t("default:company")} </strong>
                            <small>{entity ? capitalizeFirstLetter(entity?.commercial_name) : ''} </small>
                            {
                                entity && (entity?.status == "CREATION_REQ") ? (
                                    <div className="rightSide_header_pendingValidation">
                                        <div >
                                            <i style={{ marginRight: "5px" }} className="fa fa-spinner"></i>
                                            {t('masterData:validationPending', "En attente de validation")}
                                        </div>
                                    </div>
                                ) : null
                            }
                        </div>
                    </div>
                    {
                        //  showConfirmCreatingCompany &&
                        // <ConfirmCreatingCompanyModel
                        //     handleClose={() => setShowConfirmCreatingCompany(false)} 
                        //     show={showConfirmCreatingCompany} 
                        //     AddEntity={addEntity} 
                        //     entity={entityToAdd}
                        // />
                    }
                    <div className="header_select_container">
                        {
                            !entity?.id ? (
                                <select className="form-control" required id="category" name="category" value={entity?.category || ''} onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)} disabled={("MC".includes(role)) ? true : false}>
                                    <option value="Annonceur">{t('default:advertiser', "Annonceur")} </option>
                                    {
                                        (role === "AE") ? (
                                            <>
                                                <option value="Agence">{t('default:agency', "Agence")} </option>
                                                <option value="Regie">{t('default:regie', "Régie")}</option>
                                            </>
                                        ) : null
                                    }
                                </select>
                            ) :
                                <input
                                    type="text"
                                    className="form-control"
                                    id="category"
                                    required
                                    name="category"
                                    value={entity?.category || ''}
                                    onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)}
                                    autoComplete="off"
                                    disabled />
                        }
                    </div>
                </div>
                {
                    errors && errors.length > 0 ? (
                        <div className="alert alert-danger mt-2" role="alert">
                            <ul style={{ marginBottom: "0px" }}>
                                {
                                    errors.map(error => (<li key={error}>{error}</li>))
                                }
                            </ul>
                        </div>
                    ) : null
                }
                <div className="rightSide_form_container">
                    <div className="form_twoElements_container" style={{ marginTop: "1rem" }}>
                        <div className="form_element_container">
                            <span className="form_required_asterisk">*</span>
                            {t("masterData:requiredFields", "Champs obligatoires")}
                        </div>
                        {["MG", "MR"].includes(role) && entity?.id && entity?.category.toLowerCase() === "annonceur" && !entity?.canManageAdvertiser && <div className="form_element_container">
                            <input
                                type="button"
                                value={t('masterData:requireControl', "Demander l'autorisation de modification de l'annonceur")}
                                className="btn btn-primary"
                                onClick={() => setShowRequireControlModal(true)}
                            />
                        </div>}
                    </div>
                    <div className="form_twoElements_container">
                        <div className="form_element_container">
                            <div style={{ display: "flex", flex: "90%" }}>
                                <label htmlFor="socialReason">{t('default:companyName', "Dénomination sociale")} <span className="form_required_asterisk">*</span></label>
                            </div>
                            <input
                                type="text"
                                className="form-control"
                                id="socialReason"
                                required
                                name="official_name"
                                value={entity?.official_name || ''}
                                onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)}
                                autoComplete="off"
                                disabled={("MC".includes(role) || entity?.status == 'ACTIVE' && role !== "AE" && !((role === "MG" || role === "MR") && (entity?.category.toLowerCase() === "agence" || entity?.category.toLowerCase() === "annonceur" && entity?.canManageAdvertiser)) || ("RC, RG".includes(role) && !entity?.official_name === true)) ? true : false} />
                        </div>
                        <div className="form_element_container">
                            <label htmlFor="commercialName">{t('masterData:commercialName', "Nom commercial")} <span className="form_required_asterisk">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                id="commercialName"
                                required
                                name="commercial_name"
                                value={entity?.commercial_name || ''}
                                onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)}
                                autoComplete="off"
                                disabled={("MC".includes(role) || entity?.status == 'ACTIVE' && role !== "AE" && !((role === "MG" || role === "MR") && (entity?.category.toLowerCase() === "agence" || entity?.category.toLowerCase() === "annonceur" && entity?.canManageAdvertiser)) || ("RC, RG".includes(role) && !entity?.official_name === true)) ? true : false} />
                            {
                                //                                disabled={ ( "MC".includes(role) ||("MR".includes(role)&& !entity?.category==="Annonceur") ||("RC, RG".includes(role) && !entity?.official_name===true)) ? true : false}

                            }
                        </div>
                    </div>
                    {/*
                        entityExist ? 
                            <span style={{color: "red"}}>{t("default:existingSocialReason", "Cette dénomination sociale existe déjà sur une autre entité")}</span>
                            :null*/
                    }
                    {
                        entity?.category && entity?.category.toLowerCase() === "annonceur" ? (
                            <div className="form_twoElements_container">
                                <div className="form_element_container">
                                    <input
                                        style={{ margin: "23px 8px 0px 2px" }}
                                        className="form-check-input"
                                        type="checkbox"
                                        name="public_service"
                                        value=""
                                        id="publicService"
                                        checked={(entity?.public_service && entity?.public_service == "1") ? true : false}
                                        //onChange={(e) => entityInputChangeHandler(e.target.name, e.target.checked)}  
                                        onChange={(e) => publicServiceClickHandler(e.target.checked)}
                                        disabled={(
                                            entity?.status == 'ACTIVE'
                                            && role !== "AE"
                                            && !(
                                                (role === "MG" || role === "MR")
                                                && (entity?.category.toLowerCase() === "agence"
                                                    || entity?.category.toLowerCase() === "annonceur"
                                                    && entity?.canManageAdvertiser)
                                            )
                                        ) ? true : false}
                                    />
                                    <label className="form-check-label" htmlFor="publicService">
                                        {t('masterData:publicService', "Annonceur service public")}
                                    </label>
                                </div>
                                {entity?.id && role === "AE" && <div className="form_element_container">
                                    <label htmlFor="creator">Responsable</label>
                                    <Select
                                        classNames={{ control: () => "form-control" }}
                                        styles={{ control: (baseStyles) => ({ ...baseStyles, backgroundColor: "white", borderRadius: "0.25rem", transition: "border-color .15s", padding: "0 0 0 6px" }) }}
                                        id="creator"
                                        required
                                        name="creator"
                                        options={agencyManagers}
                                        value={agencyManagers.find(m => m.value === entity?.creator) || ''}
                                        placeholder="-- Sélectionnez un responsable de l'annonceur --"
                                        onChange={(e) => { entityInputChangeHandler("creator", e.value) }}
                                    />
                                </div>}
                            </div>
                        ) : null

                    }
                    {
                        entity?.public_service == "1" ? (
                            <div className="form_twoElements_container">
                                <div className="form_element_container">
                                    <div style={{ display: "flex", flex: "90%" }}>
                                        <label htmlFor="mandatingSiret">{t('masterData:mandatingSiret', "SIRET de la structure mandante réservée au marché de régies publicitaire ")} <span className="form_required_asterisk">*</span></label>
                                    </div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="mandatingSiret"
                                        required
                                        name="mandating_siret"
                                        value={entity?.mandating_siret || ''}
                                        onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)}
                                        autoComplete="off"
                                        disabled={("MC".includes(role) || entity?.status == 'ACTIVE' && role !== "AE" && !((role === "MG" || role === "MR") && (entity?.category.toLowerCase() === "agence" || entity?.category.toLowerCase() === "annonceur" && entity?.canManageAdvertiser)) || ("RC, RG".includes(role) && !entity?.mandating_siret === true)) ? true : false} />
                                </div>
                                {<div className="form_element_container">
                                    <label htmlFor="engagementNumber">{t('masterData:engagementNumber', "N° d’engagement juridique")}</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="engagementNumber"
                                        required
                                        name="engagement_number"
                                        value={entity?.engagement_number || ''}
                                        onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)}
                                        autoComplete="off"
                                        disabled={("MC".includes(role) || entity?.status == 'ACTIVE' && role !== "AE" && !((role === "MG" || role === "MR") && (entity?.category.toLowerCase() === "agence" || entity?.category.toLowerCase() === "annonceur" && entity?.canManageAdvertiser)) || ("RC, RG".includes(role) && !entity?.engagement_number === true)) ? true : false} />

                                </div>}
                            </div>

                        ) : null

                    }

                    <div className="form_twoElements_container">


                        {/* <div className="form_twoVerElements_container">
                        
                            <div className="form_elementVer_container">
                                <label htmlFor="SIRET">SIRET ou autre identifiant</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="Siret" 
                                    name="siret" 
                                    required  
                                    value={entity?.siret || ''} 
                                    onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)} 
                                    autoComplete="off" />
                            </div>
                            <div className="form_elementVer_container">
                                <label htmlFor="numIntra">Numéro de TVA intracommunautaire</label>
                                <input 
                                    type="text"  
                                    className="form-control" 
                                    id="tvaIntra" 
                                    required 
                                    name="vat_number" 
                                    value={entity?.vat_number || ''} 
                                    onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)} 
                                    autoComplete="off" />
                            </div>
                        </div> */}

                        <div className="form_element_container">
                            <div style={{ display: "flex" }}>
                                <label style={{ margin: "5px", flex: "90%", display: "flex", alignItems: "baseline" }}>
                                    {t('masterData:identNumbers', "Numéros d'identification")} <span className="form_required_asterisk" style={{ marginRight: '0', marginLeft: "4px" }}>*</span>
                                    <TooltipInfo
                                        content={t('masterData:identificationNumberInfo', "Numéros d'identification obligatoires :\n- France : N° de TVA intracommunautaire et SIRET ou SIREN\n- Union européenne : N° de TVA intracommunautaire\n- Non Union européenne : AUT")}
                                        dropdownZindex={100}
                                    />
                                    {
                                        (!entity?.id && (role === "AE" || role === "MG" || role === "MR")) ||
                                            (entity?.id &&
                                                (role === "AE" ||
                                                    (checkEntity2AuthUser(entity) && (role === "AG" || role === "MG" || role === "RG" || role === "MR")
                                                    ))) ? (
                                            <i className="fas fa-plus addEntities_icon" onClick={() => addIdNumIconClick()} ></i>
                                        ) : null
                                    }

                                    {

                                        idNumModalShow ? (
                                            !"MC".includes(role) ? (
                                                <IdentificationNumModal
                                                    handleClose={() => setIdNumModalShow(false)}
                                                    show={idNumModalShow}
                                                    identNum={selectedIdentNumber}
                                                    addIdentNum={addIdentNumHandler}
                                                    updateIdentNum={updateIdentNumHandler}
                                                />) : ""
                                        ) : null
                                    }
                                </label>

                                {/* ("MG, MR".includes(role)) && !entity?.id &&<InputInfo  infoMessage={t('masterData:editCompagnyInfo', "ce champ n'est pas modifiable après la création")} /> */}
                            </div>

                            <div className="form_company_pillsElements_container">
                                {
                                    entity?.identNumbers && entity.identNumbers.length > 0 ? entity.identNumbers.map((idNum, index) =>
                                        <div
                                            className="form_pillElement_container"
                                            key={idNum.id}
                                        >
                                            <div className="pillElement_infos" style={{ cursor: "pointer" }} onClick={() => (!entity?.id && (role === "AE" || role === "MG" || role === "MR")) || (entity?.id && (role === "AE" || (checkEntity2AuthUser(entity) && (role === "AG" || role === "MG" || role === "MR" || role === "RG")))) && selectIdentNumHandler(idNum, index)}>
                                                <img className="pillElement_icon" src={iconEntity} style={{ marginRight: '4px' }}></img>
                                                <div className="pillElement_label">{idNum.type} : {idNum.number}</div>
                                            </div>
                                            {
                                                (!entity?.id && (role === "AE" || role === "MG" || role === "MR")) || (entity?.id && (role === "AE" || (checkEntity2AuthUser(entity) && (role === "AG" || role === "MG" || role === "MR" || role === "RG")))) ? (
                                                    <CloseIcon style={{ color: "red", cursor: "pointer" }} fontSize="small" onClick={() => deleteIdentNumHandler(idNum, index)} />
                                                ) : null
                                            }
                                        </div>
                                    ) : null
                                }


                            </div>
                        </div>

                        <div className="form_element_container">
                            <div style={{ display: "flex" }}>

                                <label style={{ margin: "5px", flex: "90%" }}>
                                    {t('mandat:headquarterAddress', "Adresse du siège social")} <span className="form_required_asterisk">*</span>
                                    {
                                        (!entity?.id && (role === "AE" || role === "MG" || role === "MR")) || (entity?.id && (role === "AE" || (checkEntity2AuthUser(entity) && (role === "AG" || role === "MG" || role === "MR" || role === "RG")))) ? (
                                            <i className="fas fa-plus addEntities_icon" onClick={() => setSsAdrModalShow(true)} ></i>
                                        ) : null
                                    }
                                    {
                                        ssAdrModalShow ? (
                                            <AddressModal
                                                handleClose={() => setSsAdrModalShow(false)}
                                                show={ssAdrModalShow}
                                                address={entity?.address_hq}
                                                addressType="address_hq"
                                                entityInputChangeHandler={entityInputChangeHandler}
                                                entityCategory={entity?.category}
                                            />
                                        ) : null
                                    }
                                </label>
                                {/* ("MG, MR".includes(role))&& !entity?.id &&  <InputInfo  infoMessage={t('masterData:editCompagnyInfo', "ce champ n'est pas modifiable après la création")} /> */}
                            </div>

                            <div className="form_company_address_container">
                                {
                                    entity?.address_hq ? (
                                        <>
                                            {
                                                (!entity?.id && (role === "AE" || role === "MG" || role === "MR")) || (entity?.id && (role === "AE" || (checkEntity2AuthUser(entity) && (role === "AG" || role === "MG" || role === "MR" || role === "RG")))) ?
                                                    (

                                                        <div className="company_address_closeIcon" >
                                                            <CTooltip title={t(`masterData:${verificationCopiedElement(entity?.address_hq) ? "copiedAddress" : "copyAddress"}`)}>
                                                                {verificationCopiedElement(entity?.address_hq) ?
                                                                    <FileCopyIcon style={{ cursor: "pointer", color: "#767675", width: "16px" }} fontSize="small" onClick={() => setCopiedAdress(entity?.address_hq)} />
                                                                    :
                                                                    <FileCopyOutlinedIcon style={{ cursor: "pointer", color: "#767675", width: "16px" }} fontSize="small" onClick={() => setCopiedAdress(entity?.address_hq)} />
                                                                }
                                                            </CTooltip>
                                                            <CloseIcon style={{ color: "red", cursor: "pointer" }} fontSize="small" onClick={() => deleteAddressHandler("address_hq", entity?.address_hq)} />



                                                        </div>



                                                    ) : null
                                            }
                                            <div className="company_address_value">
                                                {entity?.address_hq?.corporate_name ? (<p>{entity?.address_hq?.corporate_name}</p>) : null}
                                                {entity?.address_hq?.label_primary ? (<p>{entity?.address_hq?.label_primary}</p>) : null}
                                                {entity?.address_hq?.label_secondary ? (<p>{entity?.address_hq?.label_secondary}</p>) : null}
                                                {entity?.address_hq?.address_line ? (<p>{entity?.address_hq?.address_line}</p>) : null}
                                                {entity?.address_hq?.distribution_service ? (<p>{entity?.address_hq?.distribution_service}</p>) : null}
                                                {entity?.address_hq?.post_code || entity?.address_hq?.city_cedex ?
                                                    (<p>
                                                        {entity?.address_hq?.post_code ? (entity?.address_hq?.post_code) : null} {entity?.address_hq?.city_cedex ? (entity?.address_hq?.city_cedex) : null}
                                                    </p>) : null
                                                }
                                                {entity?.address_hq?.email ? (<p>{entity?.address_hq?.email}</p>) : null}
                                            </div>
                                        </>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>


                </div>

                {/* les 2 cards */}
                {(entity.category != 'Regie') ? (
                    <div className="form_twoElements_container">

                        <div className="form_element_container">

                            <div style={{ display: "flex" }}>

                                <label style={{ margin: "5px", flex: "90%" }}>
                                    {t('mandat:billingAddress', "Adresse de facturation")}
                                    {(entity?.category === "Annonceur") && <span className="form_required_asterisk">*</span>
                                    }
                                    {
                                        (!entity?.id && (role === "AE" || role === "MG" || role === "MR")) || (entity?.id && (role === "AE" || (checkEntity2AuthUser(entity) && (role === "AG" || role === "MG" || role === "MR" || role === "RG")))) ? (
                                            <i className="fas fa-plus addEntities_icon" onClick={() => setInvAdrModalShow(true)} ></i>
                                        ) : null
                                    }
                                    {
                                        invAdrModalShow ? (
                                            <AddressModal
                                                handleClose={() => setInvAdrModalShow(false)}
                                                show={invAdrModalShow}
                                                address={entity?.address_invoice}
                                                addressType="address_invoice"
                                                entityInputChangeHandler={entityInputChangeHandler}
                                                entityCategory={entity?.category}
                                            />
                                        ) : null
                                    }
                                </label>

                                {/* ("MG, MR".includes(role)) && !entity?.id &&<InputInfo  infoMessage={t('masterData:editCompagnyInfo', "ce champ n'est pas modifiable après la création")} /> */}
                            </div>
                            <div className="form_company_address_container">
                                {
                                    entity?.address_invoice ? (
                                        <>
                                            {
                                                (!entity?.id && (role === "AE" || role === "MG" || role === "MR")) || (entity?.id && (role === "AE" || (checkEntity2AuthUser(entity) && (role === "AG" || role === "MG" || role === "MR" || role === "RG")))) ? (
                                                    <div className="company_address_closeIcon" >
                                                        <CTooltip title={t(`masterData:${verificationCopiedElement(entity?.address_invoice) ? "copiedAddress" : "copyAddress"}`)}>
                                                            {
                                                                verificationCopiedElement(entity?.address_invoice) ?
                                                                    <FileCopyIcon style={{ cursor: "pointer", color: "#767675", width: "16px" }} fontSize="small" onClick={() => setCopiedAdress(entity?.address_invoice)} />
                                                                    : <FileCopyOutlinedIcon style={{ cursor: "pointer", color: "#767675", width: "16px" }} fontSize="small" onClick={() => setCopiedAdress(entity?.address_invoice)} />

                                                            }

                                                        </CTooltip>

                                                        <CTooltip title={t("masterData:pasteAddress")} >
                                                            < FlipToFrontOutlinedIcon style={{ cursor: "pointer", color: "#2d2f30", width: "16px" }} fontSize="small" onClick={() => copierAddressHandler("address_invoice", copiedAdress)} />
                                                        </CTooltip>
                                                        <CloseIcon style={{ color: "red", cursor: "pointer" }} fontSize="small" onClick={() => deleteAddressHandler("address_invoice", entity?.address_invoice)} />
                                                    </div>
                                                ) : null
                                            }
                                            <div className="company_address_value">
                                                {entity?.address_invoice?.corporate_name ? (<p>{entity?.address_invoice?.corporate_name}</p>) : null}
                                                {entity?.address_invoice?.label_primary ? (<p>{entity?.address_invoice?.label_primary}</p>) : null}
                                                {entity?.address_invoice?.label_secondary ? (<p>{entity?.address_invoice?.label_secondary}</p>) : null}
                                                {entity?.address_invoice?.address_line ? (<p>{entity?.address_invoice?.address_line}</p>) : null}
                                                {entity?.address_invoice?.distribution_service ? (<p>{entity?.address_invoice?.distribution_service}</p>) : null}
                                                {entity?.address_invoice?.post_code || entity?.address_invoice?.city_cedex ?
                                                    (<p>
                                                        {entity?.address_invoice?.post_code ? (entity?.address_invoice?.post_code) : null} {entity?.address_invoice?.city_cedex ? (entity?.address_invoice?.city_cedex) : null}
                                                    </p>) : null
                                                }
                                                {entity?.address_invoice?.email ? (<p>{entity?.address_invoice?.email}</p>) : null}
                                            </div>
                                        </>
                                    ) : <div className="company_address_closeIcon" >
                                        <CTooltip title={t("masterData:pasteAddress")} >
                                            <FlipToFrontOutlinedIcon style={{ cursor: "pointer", color: "#2d2f30", width: "16px" }} fontSize="small" onClick={() => copierAddressHandler("address_invoice", copiedAdress)} />
                                        </CTooltip>
                                    </div>
                                }
                            </div>


                        </div>

                        <div className="form_element_container">
                            <div style={{ display: "flex" }}>

                                <label style={{ margin: "5px", flex: "90%" }}>

                                    {t('mandat:sendInvAddress', "Adresse d'envoi des factures")}
                                    {(entity?.category === "Annonceur" || entity?.category === 'Agence') && <span className="form_required_asterisk">*</span>
                                    }
                                    {
                                        (!entity?.id && (role === "AE" || role === "MG" || role === "MR")) || (entity?.id && (role === "AE" || (checkEntity2AuthUser(entity) && (role === "AG" || role === "MG" || role === "MR" || role === "RG")))) ? (
                                            <i className="fas fa-plus addEntities_icon" onClick={() => setSendInvAdrModalShow(true)} ></i>
                                        ) : null
                                    }
                                    {
                                        sendInvAdrModalShow ? (
                                            <AddressModal
                                                handleClose={() => setSendInvAdrModalShow(false)}
                                                show={sendInvAdrModalShow}
                                                address={entity?.address_mail}
                                                addressType="address_mail"
                                                entityInputChangeHandler={entityInputChangeHandler}
                                                entityCategory={entity?.category}
                                            />
                                        ) : null
                                    }
                                </label>

                                {/* (("MG, MR".includes(role))&& !entity?.id) &&<InputInfo  infoMessage={t('masterData:editCompagnyInfo', "ce champ n'est pas modifiable après la création")} /> */}
                            </div>
                            <div className="form_company_address_container">
                                {
                                    entity?.address_mail ? (
                                        <>
                                            {
                                                (!entity?.id && (role === "AE" || role === "MG" || role === "MR")) || (entity?.id && (role === "AE" || (checkEntity2AuthUser(entity) && (role === "AG" || role === "MG" || role === "MR" || role === "RG")))) ? (
                                                    <div className="company_address_closeIcon" >
                                                        <CTooltip title={t(`masterData:${verificationCopiedElement(entity?.address_mail) ? "copiedAddress" : "copyAddress"}`)}>
                                                            {
                                                                verificationCopiedElement(entity?.address_mail) ?
                                                                    <FileCopyIcon style={{ cursor: "pointer", color: "#767675", width: "16px" }} fontSize="small" onClick={() => setCopiedAdress(entity?.address_mail)} />
                                                                    : <FileCopyOutlinedIcon style={{ cursor: "pointer", color: "#767675", width: "16px" }} fontSize="small" onClick={() => setCopiedAdress(entity?.address_mail)} />
                                                            }
                                                        </CTooltip>

                                                        <CTooltip title={t("masterData:pasteAddress")} >
                                                            <FlipToFrontOutlinedIcon style={{ cursor: "pointer", color: "#2d2f30", width: "16px" }} fontSize="small" onClick={() => copierAddressHandler("address_mail", copiedAdress)} />
                                                        </CTooltip>
                                                        <CloseIcon style={{ color: "red", cursor: "pointer" }} fontSize="small" onClick={() => deleteAddressHandler("address_mail", entity?.address_mail)} />

                                                    </div>
                                                ) : null
                                            }
                                            <div className="company_address_value">
                                                {entity?.address_mail?.corporate_name ? (<p>{entity?.address_mail?.corporate_name}</p>) : null}
                                                {entity?.address_mail?.label_primary ? (<p>{entity?.address_mail?.label_primary}</p>) : null}
                                                {entity?.address_mail?.label_secondary ? (<p>{entity?.address_mail?.label_secondary}</p>) : null}
                                                {entity?.address_mail?.address_line ? (<p>{entity?.address_mail?.address_line}</p>) : null}
                                                {entity?.address_mail?.distribution_service ? (<p>{entity?.address_mail?.distribution_service}</p>) : null}
                                                {entity?.address_mail?.post_code || entity?.address_mail?.city_cedex ?
                                                    (<p>
                                                        {entity?.address_mail?.post_code ? (entity?.address_mail?.post_code) : null} {entity?.address_mail?.city_cedex ? (entity?.address_mail?.city_cedex) : null}
                                                    </p>) : null
                                                }
                                                {entity?.address_mail?.email ? (<p>{entity?.address_mail?.email}</p>) : null}
                                            </div>
                                        </>
                                    ) : <div className="company_address_closeIcon" >
                                        <CTooltip title={t("masterData:pasteAddress")} >
                                            <FlipToFrontOutlinedIcon style={{ cursor: "pointer", color: "#2d2f30", width: "16px" }} fontSize="small" onClick={() => copierAddressHandler("address_mail", copiedAdress)} />
                                        </CTooltip>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>)
                    : null}

                {(entity.category != 'Regie') &&
                    <div className="form_twoElements_container">
                        <div className="form_element_container">
                            <div style={{ display: "flex" }}>

                                <label style={{ margin: "5px", flex: "90%", display: "flex", alignItems: "baseline" }}>
                                    {t('masterData:legalForm', "Forme juridique")}
                                    <span className="form_required_asterisk" style={{ marginRight: '0', marginLeft: "4px" }}>*</span>
                                    <TooltipInfo
                                        content={t('masterData:legalFormInfo', "Table de référence INSEE Niveau II https://www.insee.fr/fr/information/2028129")}
                                        dropdownZindex={100}
                                    />

                                </label>
                                {/* ("MG, MR".includes(role)) &&!entity?.id&&<InputInfo  infoMessage={t('masterData:editCompagnyInfo', "ce champ n'est pas modifiable après la création")} /> */}
                            </div>
                            <select className="form-control"
                                id="forme_juridique"
                                name="forme_juridique"
                                value={entity?.forme_juridique || ''}
                                onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)}
                                autoComplete="off"
                                disabled={("MC".includes(role) || ((role === "MG" || role === "MR") && !checkEntity2AuthUser(entity) && entity?.id) || ("RC, RG".includes(role) && !entity?.official_name === true)) ? true : false}>

                                {/* <option value=""></option>
                            <option value="EARL">EARL - Exploitation agricole à responsabilité limitée</option>
                            <option value="EI">EI - Entreprise individuelle</option>
                            <option value="EIRL">EIRL - Entreprise individuelle à responsabilité limitée</option>
                            <option value="EPA">EPA - Établissement public administratif</option>
                            <option value="EPIC">EPIC - Établissement public industriel et commercial</option>
                            <option value="EURL">EURL - Entreprise unipersonnelle à responsabilité limitée</option>
                            <option value="GAEC">GAEC - Groupement agricole d'exploitation en commun</option>
                            <option value="GEIE">GEIE - Groupement européen d’intérêt économique</option>
                            <option value="GIE">GIE - Groupement d’intérêt économique</option>
                            <option value="SARL">SARL - Société à responsabilité limitée</option>
                            <option value="SA">SA - Société anonyme</option>
                            <option value="SAS">SAS - Société par actions simplifiée</option>
                            <option value="SASU">SASU - Société par actions simplifiée unipersonnelle</option>
                            <option value="SC">SC - Société civile</option>
                            <option value="SCA">SCA - Société en commandite par actions</option>
                            <option value="SCI">SCI - Société civile immobilière</option>
                            <option value="SCIC">SCIC - Société coopérative d’intérêt collectif</option>
                            <option value="SCM">SCM - Société civile de moyens</option>
                            <option value="SCOP">SCOP - Société coopérative ouvrière de production</option>
                            <option value="SCP">SCP - Société civile professionnelle</option>
                            <option value="SCS">SCS - Société en commandite simple</option>
                            <option value="SEL">SEL - Société d’exercice libéral</option>
                            <option value="SELAFA">SELAFA - Société d’exercice libéral à forme anonyme</option>
                            <option value="SELARL">SELARL - Société d’exercice libéral à responsabilité limitée</option>
                            <option value="SELAS">SELAS - Société d’exercice libéral par actions simplifiée</option>
                            <option value="SELCA">SELCA - Société d’exercice libéral en commandite par actions</option>
                            <option value="SEM">SEM - Société d’économie mixte</option>
                            <option value="SEML">SEML - Société d’économie mixte locale</option>
                            <option value="SEP">SEP - Société en participation</option>
                            <option value="SICA">SICA - Société d’intérêt collectif agricole</option>
                            <option value="SNC">SNC - Société en nom collectif</option>
                            <option value="A1901">A1901 - Association loi de 1901</option>
                            <option value="SAI">SAI - Société anonyme avec conseil d'administration</option>
                            <option value="SCEIR">Société commerciale étrangère immatriculée au RCS</option>
                        <option value="AUT">{t("masterData:other")}</option> */}


                                <option value=""></option>
                                <option value="_00">Organisme de placement collectif en valeurs mobilières sans personnalité morale (00)</option>
                                <option value="_10">Entrepreneur individuel (10)</option>
                                <option value="_21">Indivision (21)</option>
                                <option value="_22">Société créée de fait (22)</option>
                                <option value="_23">Société en participation (23)</option>
                                <option value="_24">Fiducie (24)</option>
                                <option value="_27">Paroisse hors zone concordataire (27)</option>
                                <option value="_28">Assujetti unique à la TVA (28)</option>
                                <option value="_29">Autre groupement de droit privé non doté de la personnalité morale (29)</option>
                                <option value="_31">Personne morale de droit étranger, immatriculée au RCS (registre du commerce et des sociétés) (31)</option>
                                <option value="_32">Personne morale de droit étranger, non immatriculée au RCS (32)</option>
                                <option value="_41">Etablissement public ou régie à caractère industriel ou commercial (41)</option>
                                <option value="_51">Société coopérative commerciale particulière (51)</option>
                                <option value="_52">Société en nom collectif (52)</option>
                                <option value="_53">Société en commandite (53)</option>
                                <option value="_54">Société à responsabilité limitée (SARL) (54)</option>
                                <option value="_55">Société anonyme à conseil d'administration (55)</option>
                                <option value="_56">Société anonyme à directoire (56)</option>
                                <option value="_57">Société par actions simplifiée (57)</option>
                                <option value="_58">Société européenne (58)</option>
                                <option value="_61">Caisse d'épargne et de prévoyance (61)</option>
                                <option value="_62">Groupement d'intérêt économique (62)</option>
                                <option value="_63">Société coopérative agricole (63)</option>
                                <option value="_64">Société d'assurance mutuelle (64)</option>
                                <option value="_65">Société civile (65)</option>
                                <option value="_69">Autre personne morale de droit privé inscrite au registre du commerce et des sociétés (69)</option>
                                <option value="_71">Administration de l'état (71)</option>
                                <option value="_72">Collectivité territoriale (72)</option>
                                <option value="_73">Etablissement public administratif (73)</option>
                                <option value="_74">Autre personne morale de droit public administratif (74)</option>
                                <option value="_81">Organisme gérant un régime de protection sociale à adhésion obligatoire (81)</option>
                                <option value="_82">Organisme mutualiste (82)</option>
                                <option value="_83">Comité d'entreprise (83)</option>
                                <option value="_84">Organisme professionnel (84)</option>
                                <option value="_85">Organisme de retraite à adhésion non obligatoire (85)</option>
                                <option value="_91">Syndicat de propriétaires (91)</option>
                                <option value="_92">Association loi 1901 ou assimilé (92)</option>
                                <option value="_93">Fondation (93)</option>
                                <option value="_99">{t("masterData:other")} (99)</option>
                            </select>

                        </div>
                        {
                            entity?.address_hq?.iso_country == "FR" ?
                                <div className="form_element_container">
                                    <div style={{ display: "flex" }}>

                                        <label style={{ margin: "5px", flex: "90%" }}>{t("masterData:APECode")}</label>
                                        {/* ("MG, MR".includes(role))&&!entity?.id&&<InputInfo  infoMessage={t('masterData:editCompagnyInfo', "ce champ n'est pas modifiable après la création")} /> */}
                                    </div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="code_ape"
                                        name="code_ape"
                                        value={entity?.code_ape || ''}
                                        onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)}
                                        autoComplete="off"
                                        disabled={("MC".includes(role) || ((role === "MG" || role === "MR") && !checkEntity2AuthUser(entity) && entity?.id)) ? true : false} />
                                </div>
                                : ""
                        }

                    </div>
                }
                {(entity.category != 'Regie') &&
                    <div className="form_twoElements_container">
                        {
                            entity?.address_hq?.iso_country == "FR" ?
                                <Fragment >
                                    <div className="form_element_container">
                                        <div style={{ display: "flex" }}>
                                            <label style={{ margin: "5px", flex: "90%" }}>{t('masterData:shareCapital', "Capital social")} </label>
                                            {/* ("MG, MR".includes(role))&&!entity?.id && <InputInfo  infoMessage={t('masterData:editCompagnyInfo', "ce champ n'est pas modifiable après la création")} /> */}
                                        </div>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="capital_social"
                                            name="capital_social"
                                            value={entity?.capital_social || ''}
                                            onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)}
                                            disabled={("MC".includes(role) || ((role === "MG" || role === "MR") && !checkEntity2AuthUser(entity) && entity?.id)) ? true : false} />
                                    </div>
                                    <div className="form_element_container">
                                        <div style={{ display: "flex" }}>
                                            <label style={{ margin: "5px", flex: "90%" }}>RCS</label>
                                            {/* ("MG, MR".includes(role))&& !entity?.id &&<InputInfo  infoMessage={t('masterData:editCompagnyInfo', "ce champ n'est pas modifiable après la création")} /> */}
                                        </div>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="rcs"
                                            name="rcs"
                                            value={entity?.rcs || ''}
                                            onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)}
                                            autoComplete="off"
                                            disabled={("MC".includes(role) || ((role === "MG" || role === "MR") && !checkEntity2AuthUser(entity) && entity?.id)) ? true : false} />
                                    </div>
                                </Fragment>
                                : ""
                        }
                    </div>
                }
                {entity?.category.toLowerCase() === 'agence' && <div className="form_twoElements_container">
                    <div className="form_element_container">
                        <div className="form_element_product_header">
                            <label>
                                {t('masterData:departments', "Départements")}
                                <span className="form_required_asterisk" style={{ marginRight: '0', marginLeft: "4px", fontSize: "12px" }}>
                                    {t('masterData:departmentsMandatory', "(Si au moins un département est renseigné, il sera obligatoire de renseigner un département dans les ADM)")}
                                </span>
                                {
                                    !"MC".includes(role) && ((entity?.uid && entity?.canManageAdvertiser) || (!entity.uid && "MG, MR".includes(role))) ? (
                                        <i className="fas fa-plus addEntities_icon" onClick={() => setDepartmentInputShow(!departmentInputShow)} ></i>

                                    ) : (
                                        ""
                                    )
                                }

                            </label>

                            {
                                departmentInputShow ? (
                                    <div className="form_element_addProduct_container">
                                        <input
                                            className="form-control"
                                            type="text"
                                            autoComplete="off"
                                            name="label"
                                            placeholder="Ajouter département"
                                            value={departmentLabelToAdd || ''}
                                            onChange={(e) => setDepartmentLabelToAdd(e.target.value)}
                                            disabled={!(!"MC".includes(role) && ((entity?.uid && entity?.canManageAdvertiser) || (!entity.uid && "MG, MR".includes(role)))) ? true : false} />
                                        <AddBoxIcon
                                            className="form_element_addProduct_icon"
                                            fontSize="large"
                                            onClick={() => addDepartmentHandler()} />
                                    </div>
                                )
                                    : null
                            }
                        </div>

                        <div className="form_company_pillsElements_container">
                            {entity?.departments && entity.departments.length > 0 ? entity.departments.map((department, index) =>
                                <div className="form_pillElement_container" key={department.uid}>
                                    <div className="pillElement_infos">
                                        <BusinessCenterIcon className="pillElement_departmentIcon" />
                                        <div style={{ fontSize: '12px' }}>{department.label}</div>
                                    </div>
                                    <div className="form_company_brandActions_container">
                                        {!"MC".includes(role) && ((entity?.uid && entity?.canManageAdvertiser) || (!entity.uid && "MG, MR".includes(role))) &&
                                            <CloseIcon
                                                style={{ color: "red", cursor: "pointer" }}
                                                fontSize="small"
                                                onClick={() => deleteDepartmentHandler(department, index)}
                                            />
                                        }

                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>}

                {
                    // entity.uid ?
                    ((entity?.category && entity?.category.toLowerCase() === "annonceur" && "AG, MC, MR, MG".includes(role))) ? (
                        <>
                            {role !== "AG" &&
                                <p
                                    style={{
                                        color: 'rgb(112, 110, 107)',
                                        borderBottom: '1px solid rgba(0,0,0,.1)',
                                        padding: "5px 0", marginBottom: "20px", fontSize: "0.7rem"
                                    }}
                                >
                                    {t('masterData:agencyReservedData', "DONNÉES RÉSERVÉES À L'AGENCE")}
                                </p>}

                            <div className="form_twoElements_container">
                                <div className="form_element_container">
                                    <div className="form_element_product_header">
                                        <label>
                                            {t('masterData:productsMarks', "Produits/marques")}
                                            {
                                                !"MC".includes(role) && ((entity?.uid && entity?.canManageAdvertiser) || (!entity.uid && "MG, MR".includes(role))) ? (
                                                    <i className="fas fa-plus addEntities_icon" onClick={() => setProductInputShow(!productInputShow)} ></i>

                                                ) : (
                                                    ""
                                                )
                                            }
                                        </label>

                                        {
                                            productInputShow ? (
                                                <div className="form_element_addProduct_container">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        autoComplete="off"
                                                        name="label"
                                                        placeholder="Ajouter marque"
                                                        value={brandLabelToAdd || ''}
                                                        onChange={(e) => setBrandLabelToAdd(e.target.value)}
                                                        disabled={("MC".includes(role)) ? true : false} />
                                                    <AddBoxIcon
                                                        className="form_element_addProduct_icon"
                                                        //style={{color: "black", cursor: "pointer", height: "40px", width: "40px", marginLeft: "-5px"}} 
                                                        fontSize="large"
                                                        onClick={() => addBrandHandler()} />
                                                </div>
                                            )
                                                : null
                                        }
                                    </div>

                                    <div className="form_company_pillsElements_container">
                                        {entity?.brands && entity.brands.length > 0 ? entity.brands.map((brand, index) =>
                                            <div className="form_pillElement_container" key={brand.uid}>
                                                <div className="pillElement_infos">
                                                    <img className="pillElement_icon" src={logoHand}></img>
                                                    <div style={{ fontSize: '12px' }}>{brand.label}</div>
                                                </div>
                                                <div className="form_company_brandActions_container">
                                                    <CTooltip title={t('masterData:addAddressInvoice', "Ajouter une adresse de facturation")}>
                                                        <BusinessIcon
                                                            style={{
                                                                color: "lightblue",
                                                                cursor: "pointer"
                                                            }}
                                                            fontSize="small"
                                                            onClick={() => showBrandAdressModalHandler(brand)}
                                                        />
                                                    </CTooltip>
                                                    <CTooltip title={t('masterData:addAddressMail', "Ajouter une adresse d'envoi des factures")}>
                                                        <ApartmentIcon
                                                            style={{
                                                                color: "lightblue",
                                                                cursor: "pointer"
                                                            }}
                                                            fontSize="small"
                                                            onClick={() => showBrandAdressMailModalHandler(brand)}
                                                        />
                                                    </CTooltip>
                                                    {
                                                        (role === "AG" || !parseInt(brand.isGlobal)) ? (
                                                            <CloseIcon
                                                                style={{ color: "red", cursor: "pointer" }}
                                                                fontSize="small"
                                                                onClick={() => deleteBrandHandler(brand, index)}
                                                            />
                                                        ) : null
                                                    }

                                                </div>
                                            </div>
                                        ) : null}
                                        {
                                            //BandAddressModal({handleClose, show, address, addBrandAddressHandler, brand, readOnly, addressType
                                            showBrandAddressModal ? (
                                                <BandAddressModal
                                                    handleClose={() => setShowBrandAddressModal(false)}
                                                    show={showBrandAddressModal}
                                                    //address = {selectedBrandForAddress?.address}
                                                    brand={selectedBrandForAddress}
                                                    addBrandAddressHandler={addBrandAddressHandler}
                                                    readOnly={role !== "AG" && +selectedBrandForAddress?.isGlobal}
                                                    title={t('masterData:addAddressInvoice', "Ajouter une adresse de facturation")}
                                                    addressType="address"
                                                />
                                            ) : null
                                        }
                                        {
                                            showBrandAddressMailModal ? (
                                                <BandAddressModal
                                                    handleClose={() => setShowBrandAddressMailModal(false)}
                                                    show={showBrandAddressMailModal}
                                                    //address = {selectedBrandForAddress?.address}
                                                    brand={selectedBrandForAddress}
                                                    addBrandAddressHandler={addBrandAddressHandler}
                                                    readOnly={role !== "AG" && +selectedBrandForAddress?.isGlobal}
                                                    title={t('masterData:addAddressMail', "Ajouter une adresse d'envoi des factures")}
                                                    addressType="address_mail"
                                                />
                                            ) : null
                                        }
                                    </div>
                                </div>
                                {
                                    /*role !== "AG" && <div className="form_element_container">
                                    <label> 
                                        {t('masterData:complementaryAddress', "Adresses complémentaires")}
                                        {
                                            (!entity?.id && (role === "AE" || role === "MG" || role === "MR")) || (entity?.id && (role === "AE" || (checkEntity2AuthUser(entity) && (role === "AG" || role ===  "MG" || role === "MR" || role ===  "RG")))) ? (
                                                <i className="fas fa-plus addEntities_icon" onClick={() => {setAddressComp(null); setCompAdrModalShow(true)}} ></i>
                                            ) : null
                                        }
                                        {
                                            compAdrModalShow ? (
                                                <AddressModal
                                                    handleClose = {() => setCompAdrModalShow(false)}
                                                    show = {compAdrModalShow}
                                                    address = {addressComp}
                                                    addCompAddress = {addAddressCompHandler}
                                                    updateCompAddress = {updateAddressCompHandler}
                                                    addressType = "compAddress"
                                                    entityCategory= {entity?.category}
                                                />
                                            ) : null
                                        }
                                    </label>

                                    <div className="form_company_pillsElements_container" >
                                        {entity?.compAddresses && entity.compAddresses.length > 0 ? entity.compAddresses.map((address, index) => (
                                            <div className="form_addressC_pillElmnt_container" key={address.uid} >
                                                <div className="company_address_value" onClick={() => addressClickHandler(address, index)}>
                                                    <p>{address.address_line} </p>
                                                    <p >{address.post_code + ' ' + address.city_cedex}</p>
                                                </div>
                                                <CloseIcon style={{color: "red", cursor: "pointer"}} fontSize="small" onClick={() => deleteAddressCompHandler(address, index)} />
                                            </div>
                                        )) : null}
                                    </div>
                                </div>
                                */}
                            </div>

                            {
                                entity?.public_service && entity?.public_service == "1" && entity?.serviceCodes && entity.serviceCodes.length > 0 &&
                                <div style={{ display: "flex", flex: "90%" }}>
                                    <label  >{t('masterData:serviceCode', "Liste des codes service des agences dans la structure de l'annonceur")} </label>
                                </div>
                            }
                            {
                                entity?.public_service && entity?.public_service == "1" && entity?.serviceCodes && entity.serviceCodes.length > 0 &&
                                entity.serviceCodes.map((serviceCode, index) => (
                                    <div className="form_twoElements_container" key={index}>
                                        <div className="form_element_container">
                                            <label htmlFor="serviceCode">{serviceCode.agency_name}</label>
                                        </div>
                                        <div className="form_element_container">
                                            <input
                                                style={{ boxSizing: "border-box", paddingLeft: (((serviceCode?.service_code && serviceCode?.service_code.length > 0) || !serviceCode?.service_code_default_value) ? "12px" : "2.2rem") }}
                                                type="text"
                                                className="form-control"
                                                id="serviceCode"
                                                name={"service_code_" + serviceCode.uid}
                                                value={serviceCode?.service_code || ''}
                                                onChange={(e) => serviceCodeHandler(index, e.target.value)}
                                                autoComplete="off"
                                                disabled={("MC".includes(role) || entity?.status == 'ACTIVE' && role !== "AE" && !((role === "MG" || role === "MR") && (entity?.category.toLowerCase() === "agence" || entity?.category.toLowerCase() === "annonceur" && entity?.canManageAdvertiser)) || ("RC, RG".includes(role) && !entity?.mandating_siret === true)) ? true : false} />
                                            {
                                                !serviceCode?.service_code && serviceCode?.service_code_default_value &&
                                                <CTooltip title={t("masterData:reinitServiceCode")}>
                                                    <div className="company_codeservice_autorenewIcon" onClick={() => serviceCodeHandler(index, serviceCode.service_code_default_value)}>
                                                        <AutorenewIcon size="small" style={{ color: "#909090", borderRadius: "7px", padding: "2px", display: "absolute" }} />
                                                    </div>
                                                </CTooltip>
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </>
                    ) : null
                }

                {
                    ((entity?.category && entity?.category.toLowerCase() === "regie")) ? (
                        <>
                            <div className="form_twoElements_container">
                                <div className="form_element_container">
                                    <div className="form_element_product_header">
                                        <label>
                                            {t('default:medias', "Médias")}
                                            {
                                                "AE, RG".includes(role) ? (
                                                    <i className="fas fa-plus addEntities_icon" onClick={() => setMediaInputShow(!mediaInputShow)} ></i>
                                                ) : null
                                            }

                                        </label>
                                        {
                                            mediaInputShow ? (
                                                <div className="form_element_addProduct_container">
                                                    {!"MG, MC, MR".includes(role) ? (
                                                        <Fragment>
                                                            <select className="form-control"
                                                                name="label"
                                                                style={{ padding: "6px", height: "27px" }}
                                                                placeholder="Ajouter média"
                                                                value={mediaLabelToAdd || ''}
                                                                onChange={(e) => setMediaLabelToAdd(e.target.value)}
                                                                disabled={("MG, MC, MR".includes(role)) ? true : false}
                                                            >
                                                                <option value="">{t('masterData:selectMedia', "Sélectionnez un média")}</option>
                                                                {
                                                                    mediaArray?.map((x) =>
                                                                        <option value={x.label} key={x.id}>{x.label}</option>)
                                                                }


                                                            </select>

                                                            <AddBoxIcon
                                                                className="form_element_addProduct_icon"
                                                                //style={{color: "black", cursor: "pointer", height: "40px", width: "40px", marginLeft: "-5px"}} 
                                                                fontSize="large"
                                                                onClick={() => addMediaHandler()} />
                                                        </Fragment>


                                                    ) : null
                                                    }
                                                </div>
                                            ) : null
                                        }
                                    </div>
                                    <div className="form_company_pillsElements_container">
                                        {entity?.medias && entity.medias.length > 0 ? entity.medias.map((media, index) =>
                                            <div className={"form_pillElement_container " + (selectedMedia?.label === media?.label ? "form_pillElement_container_selected" : "")} style={{ cursor: "pointer" }} onClick={() => selectMedia(media)} key={media.id}>
                                                <div className="pillElement_infos">
                                                    <img className="pillElement_icon" src={logoHand}></img>
                                                    <div style={{ fontSize: '12px' }} className="pillElement_label">{media.label}</div>
                                                </div>
                                                {!"MG, MC, MR".includes(role) ? (
                                                    <CloseIcon style={{ color: "red", cursor: "pointer" }} fontSize="small" onClick={() => deleteMediaClickHandler(media, index)} />
                                                ) : ("")
                                                }
                                            </div>
                                        ) : null}
                                        {
                                            showConfirmDeleteMediaModal &&
                                            <ConfirmDeleteMediaModal
                                                handleClose={() => setShowConfirmDeleteMediaModal(false)}
                                                show={showConfirmDeleteMediaModal}
                                                deleteMedia={() => deleteMediaHandler(mediaToDelete, mediaToDelete.mediaIndex)}
                                            />
                                        }
                                    </div>
                                </div>
                                {selectedMedia &&
                                    <div className="form_element_container">
                                        <div className="form_element_product_header">
                                            <label>
                                                Supports
                                                {
                                                    (!entity?.id && (role === "AE" || role === "RG")) || (entity?.id && (role === "AE" || (checkEntity2AuthUser(entity) && (role === "AG" || role === "MG" || role === "MR" || role === "RG")))) ? (
                                                        <i className="fas fa-plus addEntities_icon" onClick={() => { setSupportInputShow(!supportInputShow) }} ></i>
                                                    ) : null
                                                }
                                            </label>
                                            {
                                                supportInputShow &&
                                                <div className="form_element_addSupport_container">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        autoComplete="off"
                                                        name="code"
                                                        placeholder="Code"
                                                        value={supportToAdd?.code || ''}
                                                        onChange={(e) => supportToAddChangeHandler(e)} />
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        autoComplete="off"
                                                        name="label"
                                                        placeholder="Libellé"
                                                        value={supportToAdd?.label || ''}
                                                        onChange={(e) => supportToAddChangeHandler(e)} />
                                                    <AddBoxIcon
                                                        className="form_element_addProduct_icon"
                                                        //style={{color: "black", cursor: "pointer", height: "40px", width: "40px", marginLeft: "-5px"}} 
                                                        fontSize="large"
                                                        onClick={() => addSupportHandler()} />
                                                </div>
                                            }
                                            {
                                                showNotifAdminDelSupModal &&
                                                <NotyfAdminDelSupportModal
                                                    handleClose={() => setShowNotifAdminDelSupModal(false)}
                                                    show={showNotifAdminDelSupModal}
                                                    notifyAdmin={() => notifyAdminDelSupport()}
                                                />
                                            }
                                        </div>
                                        <div className="form_company_pillsElements_container" >
                                            {
                                                (selectedMedia?.supports && selectedMedia.supports?.length > 0) ?
                                                    selectedMedia?.supports?.map((sup, index) => (

                                                        <div className="form_pillElement_container" >

                                                            <div className="pillElement_infos">
                                                                <img className="pillElement_icon" src={logoHand}></img>
                                                                <div style={{ fontSize: '12px' }} className="pillElement_label">{sup.supportLabel + (sup.supportCode ? " (" + sup.supportCode + ")" : "")}</div>
                                                            </div>
                                                            {"AE, RG".includes(role) ? (
                                                                <CloseIcon style={{ color: "red", cursor: "pointer" }} fontSize="small" onClick={() => deleteSupportClickHandler(sup)} />
                                                            ) : null}
                                                        </div>
                                                    ))
                                                    : null
                                            }
                                            {
                                                showConfirmDeleteSupportModal &&
                                                <ConfirmDeleteSupportModal
                                                    handleClose={() => setShowConfirmDeleteSupportModal(false)}
                                                    show={showConfirmDeleteSupportModal}
                                                    deleteSupport={() => deleteSupportHandler(supportToDelete)}
                                                />
                                            }
                                        </div>

                                    </div>
                                }
                            </div>
                        </>
                    ) : null
                }
                {
                    ((entity?.category && entity?.category.toLowerCase() === "agence")) && (
                        <>

                            {/* Referent publication */}
                            <div className="form_twoElements_container">
                                <div className="form_element_container">
                                    <div style={{ display: "flex", flex: "90%" }}>
                                        <label htmlFor="socialReason">{t('masterData:publicationRepresentative', "Référent MyMandat / Référent publication")}</label>
                                    </div>
                                    <select className="form-control"
                                        id="publication_representative"
                                        name="publication_representative"
                                        value={entity?.publication_representative || ''}
                                        onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)}
                                        autoComplete="off">
                                        <option value=""></option>
                                        {entity?.users?.map(user => (
                                            <option value={user.id}>{user.first_name + " " + user.last_name}</option>))
                                        }
                                        <option value=""></option>
                                    </select>
                                </div>
                            </div>

                            <div style={{ width: "100%", height: "20px", borderTop: "1px solid #DDDBDA", marginTop: "20px", marginBottom: "20px", display: "flex" }}>
                                <span style={{ padding: "0 10px", color: "#706E6B", marginTop: "5px" }}>
                                    {t('masterData:workflowImposed', "Workflow d'approbation – choix imposés")}
                                </span>
                            </div>

                            {/* Vérificateur imposé*/}
                            <div className="form_twoElements_container">
                                <div className="form_element_container">
                                    <div style={{ display: "flex" }}>
                                        <input
                                            style={{ margin: "6px 8px 0px 2px", paddingLeft: "30px" }}
                                            className="form-check-input"
                                            type="checkbox"
                                            name="contributor_imposed"
                                            value=""
                                            id="contributor_imposed"
                                            checked={(entity?.contributor_imposed && entity?.contributor_imposed == "1") ? true : false}
                                            onChange={(e) => checkBoxChangeHandler(e)}
                                            disabled={!(entity?.users?.length > 0)}
                                        />
                                        <label className="form-check-label" htmlFor="contributor_imposed">
                                            {t('masterData:contributorImposed', "Vérificateur imposé")}
                                        </label>
                                    </div>
                                </div>
                                {
                                    (entity?.contributor_imposed && entity?.contributor_imposed == "1") &&
                                    <div className="form_element_container">
                                        <select className="form-control"
                                            id="contributor"
                                            name="contributor"
                                            value={entity?.contributor || ''}
                                            // value={(entity?.userContributeur ? entity?.userContributeur.uid ? find(u=>u.contributor === "1")?.uid || ''}
                                            onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)}

                                            autoComplete="off">
                                            <option value=""></option>
                                            {entity?.users?.map(user => (
                                                <option value={user.id}>{user.first_name + " " + user.last_name}</option>))
                                            }
                                            <option value=""></option>
                                        </select>
                                    </div>
                                }
                            </div>

                            {/* signatory imposé */}
                            <div className="form_twoElements_container">
                                <div className="form_element_container">
                                    <div style={{ display: "flex" }}>
                                        <input
                                            style={{ margin: "0px 8px 7px 2px", paddingLeft: "30px" }}
                                            className="form-check-input"
                                            type="checkbox"
                                            name="signatory_imposed"
                                            value=""
                                            id="signatory_imposed"
                                            checked={(entity?.signatory_imposed && entity?.signatory_imposed == "1") ? true : false}
                                            onChange={(e) => checkBoxChangeHandler(e)}
                                            disabled={!(entity?.users?.length > 0)}
                                        />
                                        <label className="form-check-label" htmlFor="signatory_imposed">
                                            {t('masterData:signatoryImposed', "Signataire imposé")}
                                        </label>
                                        <TooltipInfo
                                            content={t('masterData:signatorylistTooltip', "Choisissez le signataire imposé, parmi la liste des utilisateurs identifiés Signataires dans les Master Data utilisateurs … ")}
                                            dropdownZindex={100}
                                        />
                                    </div>
                                </div>
                                {
                                    (entity?.signatory_imposed && entity?.signatory_imposed == "1") &&
                                    <div className="form_element_container">

                                        <select className="form-control"
                                            id="signatory"
                                            name="signatory"
                                            value={entity?.signatory || ''}
                                            onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)}
                                            autoComplete="off">
                                            <option value=""></option>
                                            {
                                                entity?.users?.filter(u => u.is_signataire === "1").map(user => (
                                                    <option value={user.id}>{user.first_name + " " + user.last_name}</option>))
                                            }
                                        </select>
                                    </div>
                                }
                            </div>

                            {/* Représentant légal imposé  */}
                            <div className="form_twoElements_container">
                                <div className="form_element_container">
                                    <div style={{ display: "flex" }}>
                                        <input
                                            style={{ margin: "0px 8px 7px 2px", paddingLeft: "30px" }}
                                            className="form-check-input"
                                            type="checkbox"
                                            name="legal_representative_imposed"
                                            value=""
                                            id="legal_representative_imposed"
                                            checked={(entity?.legal_representative_imposed && entity?.legal_representative_imposed == "1") ? true : false}
                                            onChange={(e) => checkBoxChangeHandler(e)}
                                            disabled={!(entity?.users?.length > 0)}
                                        />
                                        <label className="form-check-label" htmlFor="legal_representative_imposed">
                                            {t('masterData:legalRepresentativeImposed', "Représentant légal imposé")}
                                        </label>
                                        <TooltipInfo
                                            content={t('masterData:legalRepresentativelistTooltip', "Choisissez le représentant légal imposé, parmi la liste des utilisateurs identifiés représentants légaux dans les Master Data utilisateurs … ")}
                                            dropdownZindex={100}
                                        />
                                    </div>
                                </div>
                                {
                                    (entity?.legal_representative_imposed && entity?.legal_representative_imposed == "1") &&
                                    <div className="form_element_container">
                                        <select className="form-control"
                                            id="legal_representative"
                                            name="legal_representative"
                                            value={entity?.legal_representative || ''}
                                            onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)}
                                            autoComplete="off">
                                            <option value=""></option>
                                            {entity?.users?.filter(u => u.is_legal_representative === "1").map(user => (
                                                <option value={user.id}>{user.first_name + " " + user.last_name}</option>))
                                            }
                                        </select>
                                    </div>
                                }
                            </div>

                            {/* ordre de singature des acteurs imposé  */}
                            <div className="form_twoElements_container">
                                <div className="form_element_container">
                                    <div style={{ display: "flex" }}>
                                        <input
                                            style={{ margin: "0px 8px 7px 2px", paddingLeft: "30px" }}
                                            className="form-check-input"
                                            type="checkbox"
                                            name="workflow_actors_sign_order_mandatory"
                                            value=""
                                            id="workflow_actors_sign_order_mandatory"
                                            checked={entity?.workflow_actors_sign_order_mandatory === "1"}
                                            onChange={(e) => workflowActorsSignOrderMandatoryChangeHandler(e)}
                                        />
                                        <label className="form-check-label" htmlFor="workflow_actors_sign_order_mandatory">
                                            {t('masterData:workflowActorsSignOrderImposed', "Ordre des signatures des acteurs du workflow imposé")}
                                        </label>
                                    </div>
                                </div>

                            </div>
                            {

                                entity?.workflow_actors_sign_order_mandatory == "1" &&
                                <>
                                    <div className="form_twoElements_container">
                                        <div className="form_element_container">
                                            <label className="form-check-label" htmlFor="workflow_actors_sign_order_mandatory" style={{ paddingLeft: "40px" }}>
                                                {t('masterData:forMandatesWithSubAgent', "Pour les mandats avec Sous-Mandataire : ")}
                                            </label>
                                        </div>
                                        {/* <div className="form_element_container">  
                            </div>   */}
                                    </div>
                                    <div className="form_twoElements_container">
                                        <div className="form_element_container">
                                        </div>
                                        <div className="form_element_container">
                                            <div style={{ display: "flex", alignItems: 'center' }}>
                                                <label style={{ margin: "0px 10px", width: "100%" }} className="form-check-label" htmlFor="workflow_advertiser_sign_order">
                                                    {t('default:advertiser', "Annonceur")}
                                                </label>
                                                <input
                                                    type="number"
                                                    min={1}
                                                    max={3}
                                                    className="form-control"
                                                    style={{ width: "60px" }}
                                                    id="workflow_advertiser_sign_order"
                                                    name="workflow_advertiser_sign_order"
                                                    value={entity?.workflow_advertiser_sign_order || ''}
                                                    onChange={(e) => withNumericBoundariesChangeHandler(e, '1', '3')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form_twoElements_container">
                                        <div className="form_element_container">
                                        </div>
                                        <div className="form_element_container">
                                            <div style={{ display: "flex", alignItems: 'center' }}>
                                                <label style={{ margin: "0px 10px", width: "100%" }} className="form-check-label" htmlFor="workflow_agency_sign_order">
                                                    {t('default:agency', "Agence")}
                                                </label>
                                                <input
                                                    type="number"
                                                    min={1}
                                                    max={3}
                                                    className="form-control"
                                                    style={{ width: "60px" }}
                                                    id="workflow_agency_sign_order"
                                                    name="workflow_agency_sign_order"
                                                    value={entity?.workflow_agency_sign_order || ''}
                                                    onChange={(e) => withNumericBoundariesChangeHandler(e, '1', '3')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form_twoElements_container">
                                        <div className="form_element_container">
                                        </div>
                                        <div className="form_element_container">
                                            <div style={{ display: "flex", alignItems: 'center' }}>
                                                <label style={{ margin: "0px 10px", width: "100%" }} className="form-check-label" htmlFor="workflow_subagency_sign_order">
                                                    {t('default:subAgencies', "Sous-Mandataire(s)")}
                                                </label>
                                                <input
                                                    type="number"
                                                    min={1}
                                                    max={3}
                                                    className="form-control"
                                                    style={{ width: "60px" }}
                                                    id="workflow_subagency_sign_order"
                                                    name="workflow_subagency_sign_order"
                                                    value={entity?.workflow_subagency_sign_order || ''}
                                                    onChange={(e) => withNumericBoundariesChangeHandler(e, '1', '3')}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {/* cas workflow two actors */}
                                    <div className="form_twoElements_container">
                                        <div className="form_element_container">
                                            <label className="form-check-label" htmlFor="workflow_actors_sign_order_mandatory" style={{ paddingLeft: "40px" }}>
                                                {t('masterData:forMandatesWithoutSubAgent', "Pour les mandats sans Sous-Mandataire : ")}
                                            </label>
                                        </div>
                                        {/* <div className="form_element_container"> 
                            </div>  */}
                                    </div>
                                    <div className="form_twoElements_container">
                                        <div className="form_element_container">
                                        </div>
                                        <div className="form_element_container">
                                            <div style={{ display: "flex", alignItems: 'center' }}>
                                                <label style={{ margin: "0px 10px", width: "100%" }} className="form-check-label" htmlFor="workflow_two_actors_advertiser_sign_order">
                                                    {t('default:advertiser', "Annonceur")}
                                                </label>
                                                <input
                                                    type="number"
                                                    min={1}
                                                    max={2}
                                                    className="form-control"
                                                    style={{ width: "60px" }}
                                                    id="workflow_two_actors_advertiser_sign_order"
                                                    name="workflow_two_actors_advertiser_sign_order"
                                                    value={entity?.workflow_two_actors_advertiser_sign_order || ''}
                                                    onChange={(e) => withNumericBoundariesChangeHandler(e, '1', '2')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form_twoElements_container">
                                        <div className="form_element_container">
                                        </div>
                                        <div className="form_element_container">
                                            <div style={{ display: "flex", alignItems: 'center' }}>
                                                <label style={{ margin: "0px 10px", width: "100%" }} className="form-check-label" htmlFor="workflow_two_actors_agency_sign_order">
                                                    {t('default:agency', "Agence")}
                                                </label>
                                                <input
                                                    type="number"
                                                    min={1}
                                                    max={2}
                                                    className="form-control"
                                                    style={{ width: "60px" }}
                                                    id="workflow_two_actors_agency_sign_order"
                                                    name="workflow_two_actors_agency_sign_order"
                                                    value={entity?.workflow_two_actors_agency_sign_order || ''}
                                                    onChange={(e) => withNumericBoundariesChangeHandler(e, '1', '2')}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </>

                            }


                        </>
                    )
                }
                {
                    /* entity?.category === "Agence" && checkEntity2AuthUser(entity) && (
                         <div className="form_elementVer_container">
                          
                             <div className="form_element_product_header">
                            
                                 <label style={{fontSize:"0.9rem",marginTop:"15px"}}>{t('default:clausesAgency', "CLAUSES")}</label>
                                 {
                                     (role === "MG" || role === "MR") && <i style={{fontSize:"0.9rem",marginTop:"15px"}} className="fas fa-plus addEntities_icon" onClick={() => {setAddClauseShow(!addClauseShow)}} ></i>
                                 }
                                 
                             </div>
                             {<p 
                                 style={{
                                     color:'rgb(112, 110, 107)', 
                                     borderBottom: '1px solid rgba(0,0,0,.1)', 
                                     padding: "5px 0", marginBottom: "20px" , fontSize: "0.9rem"}}
                                 >        
                             </p>}
                             <div className="form_company_pillsElements_container">
                                 {entity?.clauses && entity.clauses.length > 0 && 
                                     <MandatClauses clauses={entity.clauses}
                                         updateClauses={updateClausesHandler}
                                         deleteClause={deleteClauseHandler}
                                         canEdit={"MG, MR".includes(role)}/>} 
                             </div>
                             {
                                 addClauseShow &&
                                     <AddClauseModal 
                                         show={addClauseShow}
                                         handleClose={() => setAddClauseShow(false)}
                                         clausesLength={entity.clauses?.length}
                                         clauses={entity.clauses}
                                         addClause={addClauseHandler}
                                     />
                             }
                         </div>
                     )*/
                }

                <div className="rightSide_form_submit_container">
                    {
                        loading ?
                            <Spinner animation="border" variant="primary" />
                            :
                            (entity?.id && entity?.status == "CREATION_REQ" && role == "AE") ? (
                                <>
                                    <input type="submit" onClick={(e) => validateEntityclickHandler(e)} value="Valider" className="btn btn-primary rightSide_form_submitBtn"></input>
                                    <input type="submit" onClick={(e) => disableEntityclickHandler(e)} value="Refuser" className="btn btn-primary rightSide_form_submitBtn"></input>
                                </>
                            ) : (

                                (!"MC, AC, RC".includes(role)) ? (
                                    !entity?.id && (!"RG, AG".includes(role)) ? (
                                        <>
                                            {
                                                ((role === "MG" || role === "MR" || role === "AE") && entity?.category === "Annonceur"
                                                    && (
                                                        (entity?.identNumbers && entity.identNumbers.some(row => (['SIRET', 'SIREN', 'TVA'].includes(row.type))))
                                                        || (entity?.official_name || entity?.commercial_name)
                                                    )
                                                ) &&
                                                (
                                                    <input
                                                        type="submit"
                                                        value={t('masterData:askSiren', "Interroger SIREN / Societe.com")}
                                                        className="btn btn-primary mt-3 rightSide_form_submitBtn"
                                                        onClick={(e) => updateEntityWithApiClickHandler(e)}
                                                    />
                                                )
                                            }
                                            <input
                                                type="submit"
                                                value={t('default:cancel', "Annuler")}
                                                className="btn btn-primary mt-3 rightSide_form_submitBtn"
                                                disabled={!formChanged}
                                                onClick={(e) => resetEntityFormHandler(e)}
                                            />

                                            <input
                                                type="submit"
                                                value={t('default:add', "Ajouter")}
                                                className="btn btn-primary mt-3 rightSide_form_submitBtn"
                                                onClick={(e) => addEntityclickHandler(e)} />
                                        </>
                                    ) : (
                                        (role === "AE" ||
                                            (checkEntity2AuthUser(entity)
                                                && (role === "AG" || role === "MG" || role === "MR" || role === "RG"))
                                        ) ? (
                                            <>
                                                {
                                                    ((role === "MG" || role === "MR" || role === "AE") && entity?.category === "Annonceur"
                                                        && entity?.identNumbers && entity.identNumbers.some(row => (['SIRET', 'SIREN', 'TVA'].includes(row.type)))) &&
                                                    (
                                                        <input
                                                            type="submit"
                                                            value={t('masterData:askSiren', "Interroger SIREN / Societe.com")}
                                                            className="btn btn-primary mt-3 rightSide_form_submitBtn"
                                                            onClick={(e) => updateEntityWithApiClickHandler(e)}
                                                        />
                                                    )
                                                }
                                                {
                                                    // WORK IN PROGRESS   renseigner MD par Annonceur                                  
                                                    ((role === "MG" || role === "MR") && entity?.category === "Annonceur") && (
                                                        <input
                                                            type="submit"
                                                            value={"Faire Compléter / valider par l’Annonceur"}
                                                            className="btn btn-primary mt-3 rightSide_form_submitBtn"
                                                            onClick={(e) => sendManualEntityEnrichClickHandler(e)}
                                                        />
                                                    )
                                                }

                                                <input
                                                    type="submit"
                                                    value={t('default:cancel', "Annuler")}
                                                    className="btn btn-primary mt-3 rightSide_form_submitBtn"
                                                    disabled={!formChanged}
                                                    onClick={(e) => cancelEntityclickHandler(e)}
                                                />
                                                <input
                                                    type="submit"
                                                    value={t('default:save', "Enregistrer")}
                                                    className="btn btn-primary mt-3 rightSide_form_submitBtn"
                                                    disabled={!validEntityData && role != "AE"}
                                                    onClick={(e) => updateEntityclickHandler(e)}
                                                />
                                            </>
                                        ) : null
                                    )
                                ) : ""
                            )
                    }
                    {entity?.canDelete === "1" && role === "AE" && <input
                        type="submit"
                        value={t('default:delete', "Supprimer")}
                        className="btn btn-danger mt-3 rightSide_form_submitBtn"
                        onClick={(e) => deleteEntityClickHandler(e)}
                    />}
                </div>
            </form>
            {
                showConfirmDeleteModal &&
                <CustomConfirmModal
                    show={showConfirmDeleteModal}
                    handleClose={() => setShowConfirmDeleteModal(false)}
                    text={t('masterData:confirmDeleteEntity', "Voulez-vous vraiment supprimer cette entité ?")}
                    okCallback={deleteEntity}
                    loading={loading}
                />
            }
            {
                showRequireControlModal &&
                <CustomConfirmModal
                    show={showRequireControlModal}
                    handleClose={() => setShowRequireControlModal(false)}
                    text={t('masterData:controlRequestModal', "Je, Mr/Mme [currentUser], en tant qu’utilisateur référencé dans MyMandat rattaché à l’ / aux agence(s) [agencies] certifie sur l’honneur être habilité(e) à gérer les données de l’entreprise [officialName] - [vatNumber] et à mettre à jour les attestations de mandat, l’agence / les agences sus – citée(s) agissant, pour son compte et en son nom au titre de Mandataire media.")
                        .replace("[currentUser]", authUser?.first_name + " " + authUser?.last_name).replace("[agencies]", authUser?.entities?.map(e => e.commercial_name)?.join(" / ")).replace("[officialName]", entity?.official_name).replace(" - [vatNumber]", entity?.identNumbers?.find(i => i.type === "TVA") ? " - " + entity?.identNumbers?.find(i => i.type === "TVA").number : "")}
                    okCallback={sendControlRequestEmail}
                />
            }
            {
                showManualEntityCreationModal &&
                <ManualEntityCreationModal
                    show={showManualEntityCreationModal}
                    handleClose={() => setShowManualEntityCreationModal(false)}
                    createManualEntity={createManualEntity}
                    entity={entity}
                />
            }

        </>
    )
}

// const mapStateToProps = (state) => ({ 
//     role: state.auth.role,
//     authUserId: state.auth.userId
// })
// const mapDispatchToProps = dispatch => ({
// })  
// export default connect(mapStateToProps, mapDispatchToProps)(CompanyForm)
export default CompanyForm
