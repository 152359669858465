import React, { useState, useEffect, useCallback } from 'react'
import './ModificationsChat.css';
import ScrollToBottom from 'react-scroll-to-bottom';
import Axios from "../../../../../axios-edipub";
import * as actions from '../../../../../store/actions/index';
import { connect } from 'react-redux';
import { isToday, isYesterday } from "../../../../../shared/utility";
import Spinner from 'react-bootstrap/Spinner'
import FileUploaderBtn from "./FileUploaderBtn/FileUploaderBtn";
import { Notyf } from "notyf";

import { useTranslation } from 'react-i18next';

function ModificationsChat({ documentObj, timelineData, getMandatTimelineEvents, createMandatTimelineEvent, admStatus, type }) {
    const [message, setMessage] = useState("");
    const [messages, setMessages] = useState([]);
    const [messagesLoading, setMessagesLoading] = useState(false)
    const userUid = localStorage.getItem("userId");
    const { t } = useTranslation();
    const [file, setFile] = useState("");
    const [documentation, setDocumentation] = useState({});
    const notyf = new Notyf();

    const loadMessages = () => {
        setMessagesLoading(true)
        if (type === "USER_CHAT_EVENT") {
            Axios.get("document/" + documentObj.uid + "/events?typeList=USER_CHAT_EVENT,USER_CHAT_DOCUMENT").then(response => {
                setMessagesLoading(false)
                setMessages(response?.data.events)
            }).catch(response => {
                setMessagesLoading(false)
            })
        } else {
            Axios.get("document/" + documentObj.uid + "/events?typeList=POST_SIGN_CHAT,POST_SIGN_DOCUMENT").then(response => {
                setMessagesLoading(false)
                setMessages(response?.data.events)
            }).catch(response => {
                setMessagesLoading(false)
            })
        }
    }

    const formatDate = useCallback((date) => {
        if (date !== null && date.valueOf() > 0) {
            const newDate = new Date(date);
            const day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate();
            const month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1);
            const hours = newDate.getHours() < 10 ? '0' + newDate.getHours() : newDate.getHours();
            const minutes = newDate.getMinutes() < 10 ? '0' + newDate.getMinutes() : newDate.getMinutes();
            const seconds = newDate.getSeconds() < 10 ? '0' + newDate.getSeconds() : newDate.getSeconds();
            const time = " " + t("default:at", "à") + hours + ":" + minutes + ":" + seconds;
            return (isToday(newDate) ? t("default:today", "Aujourd'hui") : (isYesterday(newDate) ? t("default:yesterday", "Hier") : day + '/' + month + '/' + newDate.getFullYear())) + time;
        } else return null;
    }, [])

    useEffect(() => {
        if (documentObj) {
            loadMessages();
            const interval = setInterval(() => {
                loadMessages();
            }, 60000);
            return () => clearInterval(interval);
        }
    }, [documentObj])

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    }

    const postMessage = () => {
        if (message)
            Axios.post("document/" + documentObj.uid + "/chatMessage", { message: message, type: type }
            ).then(response => {
                loadMessages();
                setMessage("");
            }).catch(err => {
                if (err.response?.data?.error && (err.response?.data?.error === 'Expired token'))
                    notyf.error('Une erreur s\'est produite ! Votre session a expiré. Veuillez vous déconnecter et vous reconnecter avant de renouveler l\'opération.')
                else
                    notyf.error(t("default:errorMessage"));
            })
    }

    const postChatDocument = () => {
        if (file) {
            const formData = new FormData();
            formData.append("file", documentation.formData);
            formData.append("type", type == 'USER_CHAT_EVENT' ? 'USER_CHAT_DOCUMENT' : 'POST_SIGN_DOCUMENT');
            Axios.post("document/" + documentObj.uid + "/chatDocument", formData, { headers: { "Content-Type": "multipart/form-data" } }
            ).then(response => {
                loadMessages();
                setMessage("");
                setFile("");
            }).catch(err => {
                if (err.response?.data?.error && (err.response?.data?.error === 'Expired token'))
                    notyf.error('Une erreur s\'est produite ! Votre session a expiré. Veuillez vous déconnecter et vous reconnecter avant de renouveler l\'opération.')
                else
                    notyf.error(t("default:errorMessage"));
            })
        }
    }

    const handleFileChange = (e) => {
        if (e?.target?.files[0]?.type == "application/pdf") {
            setFile(e.target.files[0].name);
            let tmpDoc = {
                formData: e?.target.files[0],
                fileName: e?.target.files[0].name,
                fileType: e?.target.files[0].type,
            };
            setDocumentation(tmpDoc);
        } else {
            notyf.error(t("mandat:invalidDocFormat"));
        }
    };

    const getDocumentPdfFile = (uid) => {
        Axios.get('/document/' + uid + '/chatDocumentPdfFile', {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
            .then(response => {
                if (response.status === 200) {
                    let blob = new Blob([response.data], { type: 'application/pdf' })
                    let filename = ""
                    const disposition = response.request.getResponseHeader('Content-Disposition')
                    if (disposition && disposition.indexOf('attachment') !== -1) {
                        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        var matches = filenameRegex.exec(disposition);
                        if (matches !== null && matches[1]) {
                            filename = matches[1].replace(/['"]/g, '');
                        }
                    }
                    const downloadUrl = URL.createObjectURL(blob)
                    let a = document.createElement("a");
                    a.href = downloadUrl;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                } else {
                    notyf.error(t("mandat:docNotFound"));
                }
            })
            .catch(err => {
                notyf.error(t("default:errorMessage"));
            })
    }


    return (
        <div className="modificationsChat__container">
            {
                ((admStatus === "DRAFT" || admStatus === "TO_BE_APPROVED" || admStatus === "TO_BE_SIGNED") && type === "USER_CHAT_EVENT") ||
                    (admStatus === "SIGNED" && type === "POST_SIGN_CHAT") ? (
                    <>
                        <div className="modificationsChat__sendMsg">
                            <textarea
                                type="text"
                                maxlength="4095"
                                rows="6"
                                placeholder={t('mandat:messagePlaceHolder', "Tapez votre message")}
                                value={message}
                                onChange={handleMessageChange} />
                            <button
                                className="btn btn-secondary btn-sm"
                                type="submit"
                                onClick={() => postMessage()}>
                                {t('default:send', "Envoyer")}
                            </button>
                        </div>
                        <div className="modificationsChat__sendMsg">
                            <input type="text" value={file} disabled={true} />
                            {file ?
                                <button
                                    className="btn btn-secondary btn-sm"
                                    type="submit"
                                    disabled={!file}
                                    onClick={() => postChatDocument()}>
                                    {t('default:send', "Envoyer")}
                                </button>
                                : <FileUploaderBtn
                                    btnLabel="Ajouter un PDF"
                                    handleChange={handleFileChange}
                                    name="filename"
                                />
                            }
                        </div>
                    </>
                ) : null
            }
            {
                messagesLoading ? (
                    <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                        <Spinner animation="border" variant="primary" />
                    </div>
                ) : messages.length === 0 ? (
                    <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                        <p>{t('mandat:noMessages', "Aucun message à afficher")} </p>
                    </div>
                ) : (
                    <ScrollToBottom className="modificationsChat__messages">
                        {
                            messages?.map(m => (<div key={m.id} className={`modificationsChat__msgContainer ${userUid === m.userUid ? 'myMessage' : ''}`}>
                                {userUid !== m.userUid && <div className="modificationsChat__msgAvatar">
                                    {m.first_name.charAt(0).toUpperCase() + m.last_name.charAt(0).toUpperCase()}
                                </div>}
                                <div className="modificationsChat__msgValue">
                                    <p className="msgBorder">
                                        {
                                            m.type === 'USER_CHAT_DOCUMENT' || m.type === 'POST_SIGN_DOCUMENT' ?
                                                <div onClick={(e) => getDocumentPdfFile(m.uid)} >
                                                    <span style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}>
                                                        {m.message}
                                                    </span>
                                                </div>
                                                : m.message
                                        }
                                    </p>
                                    <h6>{m.first_name + " " + m.last_name + " - " + formatDate(parseInt(m.creationDate))}</h6>
                                </div>
                            </div>))
                        }
                    </ScrollToBottom>
                )
            }
        </div>
    )
}

const mapStateToProps = (state) => ({
    admStatus: state.mandat.admData?.status,
})
const mapDispatchToProps = dispatch => ({
    getMandatTimelineEvents: (mandatUid, type) => dispatch(actions.getMandatTimelineEvents(mandatUid, type)),
    createMandatTimelineEvent: (mandatUid, eventData) => dispatch(actions.createMandatTimelineEvent(mandatUid, eventData))
})
export default connect(mapStateToProps, mapDispatchToProps)(ModificationsChat)
