import React from 'react'
import { connect } from 'react-redux';
import { getNotyfObject } from '../../../../../shared/utility'; 
import Axios from '../../../../../axios-edipub'
import { useTranslation } from 'react-i18next';
import PdfViewer from './PdfViewer/PdfViewer';
import './ADMView.css'
import * as actions from '../../../../../store/actions/index';
import { Tooltip, withStyles } from '@material-ui/core';
const CTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 15,
    },
}))(Tooltip);

function ADMView({pdfFile, setAdmScrolledEnd, pdfFileName, setAdmPdfPages, admData, updateMandatData, userUid, userRole}) {
    const {t} = useTranslation();
    const notyf = getNotyfObject();  

    const forceGenerateAdmPdf = () => {
        let mandatTemp = { ...admData}
        Axios.get('/document/'+admData.uid+'/admPdf').then(response => {
            mandatTemp = {
                ...mandatTemp,
                pdfFile : response.data.content
            }
            updateMandatData(mandatTemp)
            notyf.success(t("mandat:generatedSucccessMessage"))
        }).catch(response => notyf.error(t("mandat:generatedErrorMessage")))
    }

    return (
        <div className="ADMView__pdfViewer_Container">
            {["RG", "RC"].includes(userRole) && <div style={{ paddingTop:"5px",paddingLeft:"20px"}}  ><span> 
                 {t('default:creator', "Responsable") + " " + admData?.creatorName + " (" + admData?.creatorEmail +")"}
            </span></div>}
            {(userUid === admData?.creatorUid || userRole === "MG") && !admData?.hasSignature && admData?.template === "0" && 
            	<CTooltip title={admData?.offline == '1' ? "Impossible de rafraîchir le PDF car en signature 'offline'." :  ""} >
	                <button 
	                    type="button"
	                    disabled={admData?.offline == '1'  }
                        style={{margin: "20px 0 -30px 15px"}}
	                    className="btn btn-primary btn-sm" 
	                    onClick={() => forceGenerateAdmPdf() } >
	                        {t('mandat:generateAdm', "Générer MyMandat")}
	                </button>
                </CTooltip>
           }
            <PdfViewer 
                pdfFile={pdfFile}
                pdfFileName={pdfFileName}
                setAdmScrolledEnd={setAdmScrolledEnd}
                setAdmPdfPages={setAdmPdfPages}
            />
        </div>
        
    )
}
const mapStateToProps = (state) => ({
    admData: state.mandat.admData,
    pdfFile: state.mandat.admData?.pdfFile || null,
    pdfFileName: state.mandat?.admData?.pdfFileName || "mandat.pdf",
    userUid : state.auth.userId,
    userRole: state.auth.role
})
const mapDispatchToProps = dispatch => ({
    updateMandatData: (admNewData) => dispatch(actions.updateAdmData(admNewData)),
    setAdmScrolledEnd : (scrolledAnd) => dispatch(actions.setAdmScrolledEnd(scrolledAnd)),
    setAdmPdfPages : (numPages) => dispatch(actions.setAdmPdfPages(numPages))
})
export default connect(mapStateToProps, mapDispatchToProps) (ADMView)
