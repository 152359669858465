import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import {checkValidity, getNotyfObject, uuidv4} from '../../../../../shared/utility'
import Axios from '../../../../../axios-edipub'
import { Notyf } from 'notyf';
import { useTranslation } from 'react-i18next';

function IdentificationNumModal({handleClose, show, identNum, addIdentNum, updateIdentNum}) {
    const [identNumLoc , setIdentNumLoc] = useState(identNum);
    const [errors, setErrors] = useState(null);
    let notyf = getNotyfObject();
    const {t} = useTranslation();
    
    const idNumInputChangeHandler = (e) =>{
        let idNumTemp = {
            ...identNumLoc,
            [e.target.name] : e.target.value
        }
        setIdentNumLoc(idNumTemp)
    }

    const checkAddressFormValidity = (idNumTemp) => {
        let errors = []
        // if(!address.hasOwnProperty('label_primary') || !checkValidity(address?.label_primary, {required: true})){
        //     errors.push("Le champ 'Service ou identité du destinataire' est vide ") ;
        // }
        if(!idNumTemp.hasOwnProperty('number') || !checkValidity(idNumTemp?.number, {required: true})){
            errors.push(t("default:empty_identification_number")) ;
        }
        return errors;
    }

    const addIdentNumClickHandler = () => {
        let idNumTemp = {
            new: true,
            type : identNumLoc?.type || 'SIRET',
            ...identNumLoc
        }
        let errors = checkAddressFormValidity(idNumTemp);
        if(errors && errors.length > 0){
            setErrors(errors)
        }else{
            if(idNumTemp?.type !== "SIREN" && idNumTemp?.type !== "TVA" ){
                Axios.get('identtificationNumber//checkExistIdent',{
                    params: {
                        type: idNumTemp.type,
                        number: idNumTemp.number.trim()
                    }
                }).then(response => {
                    if(response.data.existIdent == true){
                        setErrors([t("default:identif_number_already_exist")]);
                    }else {
                        idNumTemp.number = idNumTemp.number.trim()
                        addIdentNum(idNumTemp)
                    }
                })
            }else {
                idNumTemp.number = idNumTemp.number.trim()
                addIdentNum(idNumTemp)
            }
        }
    }

    return (
        <div>
            <Modal show={show} onHide={handleClose} contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                <Modal.Title>{t('masterData:addIdentNumber', "Ajouter numéro d'identification")} </Modal.Title>
                </Modal.Header>
                <Modal.Body className="productsModal__body" style={{backgroundColor: "#ffffff", minWidth: "40rem", overflowY: "unset"}}>
                    {
                        errors && errors.length > 0 ? (
                            <div class="alert alert-danger mt-2" role="alert">
                                <ul>
                                    {
                                        errors.map(error => (<li>{error}</li>))
                                    }
                                </ul>
                            </div>
                        ) : null
                    }
                    <div >
                        <div class="modal-body row justify-content-center">
                            <div className="form-group col-3">
                                <label for="Pays">Type</label>
                                <select 
                                    style={{height: '33px'}} 
                                    class="form-control" 
                                    required 
                                    placeholder="Type" 
                                    name="type" 
                                    value={identNumLoc?.type || 'SIRET'} 
                                    onChange={(e) => idNumInputChangeHandler(e)}  
                                    aria-label=".form-select-lg example">
                                        <option value="SIRET">SIRET</option>
                                        <option value="GLN">GLN</option>
                                        <option value="DUNS">DUNS</option>
                                        <option value="SIREN">SIREN</option>
                                        <option value="TVA">TVA INTRACOMMUNAUTAIRE</option>
                                        <option value="AUT">AUT</option>
                                </select>
                            </div>
                            <div>
                                <label for="number">{t('masterData:identNumber', "Numéro d'identification")} </label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    name="number"  
                                    id="number" 
                                    required 
                                    placeholder="" 
                                    value={identNumLoc?.number || ''} 
                                    onChange={(e) => idNumInputChangeHandler(e)} 
                                    autoComplete="off"></input>
                            </div>
                        </div>
                    </div>
                    
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t("default:cancel", "Annuler")} 
                </Button>
                {
                    identNum === null ? (
                        <Button variant="primary" onClick={() => addIdentNumClickHandler()}>
                            {t("default:add", "Ajouter")}
                        </Button>
                    ) : (
                        <Button variant="primary" onClick={() => updateIdentNum(identNumLoc)} >
                            {t("default:update", "Modifier")}
                        </Button>
                    )
                }
                
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default IdentificationNumModal
