import React, { useEffect,useCallback, useState } from 'react';
import { connect } from 'react-redux'; 
import * as actions from '../../../store/actions'; 
import logo from '../../../assets/images/Icone.png' 
import { useParams } from 'react-router-dom';  
import Spinner from '../../../components/UI/Spinner/Spinner';


function SsoAccess(props) {
    const {error, authFromJwt,loading} = props; 
    let {token} = useParams(); 

    useEffect(() => {
        if(token)
             authFromJwt(token) 
    }, [token])  

    return (
        loading ? 
                <Spinner /> : 

        <div className="forgotPassword__container" style={{padding: "30px"}}>               
                { error?  <>
                    <img className="signin_logo_img" src={logo} alt="logo" />
                    <span>{error}</span>
                </> :''} 
        </div>
    )
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,       
    }
} 

const mapDispatchToProps = dispatch => {
    return {
        authFromJwt : (token) => dispatch(actions.authFromJwt(token))        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SsoAccess);

