import React, { useCallback, useEffect, useState } from 'react'
import Axios from '../../../../../../../../axios-edipub';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { Notyf } from 'notyf';
import CheckIcon from '@material-ui/icons/Check';
import { getNotyfObject } from '../../../../../../../../shared/utility';
import { useTranslation } from 'react-i18next';

function SubMandataireModal({sousMandataires, subAgencyAddHandler, mandataireUid, uncheckEntity, setUncheckEntity}) {
    const [subMandataires, setSubMandataires] = useState(null);
    const [selectedAgency, setSelectedAgency] = useState(null);
    const [searchQuery, setSearchQuery] = useState(null);
    const notyf = getNotyfObject();
    const {t} = useTranslation();

    useEffect(() => {
        let filter = {
            category: "agence",
            searchQuery: searchQuery
        }
        Axios.get('/entity//userSubMandatories', {
            params: filter
        })
            .then(res => {
                setSubMandataires(res.data.entities)
            })
            .catch(err => notyf.error("Une erreur s'est produite !"))
    }, [searchQuery])

    useEffect(() => {
        setSelectedAgency(null);
        setUncheckEntity(false);
    }, [uncheckEntity]) 

    const addSubAgency = useCallback(
        () => {
            if (!selectedAgency) 
                notyf.error('Aucune agence n\'a été sélectionnée')
            else if (sousMandataires === undefined || (sousMandataires?.findIndex(row => row.uid === selectedAgency) < 0 && mandataireUid !== selectedAgency) ){
                subAgencyAddHandler(selectedAgency)
            } else notyf.error('Cette agence est déjà ajoutée !'); 
        },
        [selectedAgency]
    )

    let subAgencyList = null;

    (subMandataires && subMandataires.length > 0) ? 
        subAgencyList = (
            <table className="table">
                <thead className="thead-light">
                    <tr>
                    <th scope="col"></th>
                    <th scope="col">{t('default:name', "NOM")} </th>
                    <th scope="col">{t('default:siret', "SIRET")} </th>
                    <th scope="col">{t('default:addressSS', "Adresse SS")}</th>
                    </tr>
                </thead>
                <tbody>
                    {subMandataires.map(row => (
                        <tr key={row.uid}>
                            <td>
                                <div className="productsModal__itemIcon" onClick={() => setSelectedAgency(row.uid)}>
                                    {(selectedAgency && selectedAgency === row.uid) ? 
                                        (<CheckIcon style={{backgroundColor: "#4BCA81", color: "white", borderRadius: "5px"}} />) :
                                        (<AddIcon style={{color: "blue",border: "1px solid rgb(112, 110, 107, 0.3)", borderRadius: "5px" }} />)
                                    }
                                    
                                </div>
                            </td>
                            <td>{row.commercial_name} </td>
                            <td>{row.siret} </td>
                            <td>{row.address_line} {row.post_code}, {row.label_primary} </td>
                        </tr>
                    ))}
                    

                </tbody>
            </table>
    ) : subAgencyList = (<h5>{t('mandat:noAgencies', "Aucune agence à afficher")}</h5>);    
    return (
        <div className="modal fade"  id="subMandataireModal" tabIndex="-1" role="dialog" aria-labelledby="subMandataireModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" style={{maxWidth: "700px"}} role="document">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="subMandataireModalLabel">
                        {t('mandat:addSubAgencyModal_title', "Ajout d'un Sous-Mandataire")}
                    </h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="ADMCreationModal__body modal-body">
                    <div className="ADMCreationModal__selectionContainer">
                        <div className="productsModal__header">
                            <h5>{t('mandat:selectSubAgency', "Selectionner un Sous-Mandataire")} </h5>
                            <div className="productsModal__searchInput input-group input-group-sm">
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder={t('default:modalCreation_searchPlaceholder', "Rechercher Annonceurs (Raison social, SIRET)")}  
                                    name="searchQuery" 
                                    style={{height: "100%"}}
                                    onChange={(e) => setSearchQuery(e.target.value)} />
                                <div 
                                    className="input-group-append" 
                                    style={{height: "100%"}}
                                >
                                    <span className="input-group-text" id="basic-addon2"><SearchIcon/> </span>
                                </div>
                            </div>
                            {/* <p className="productsModal_totalItems">0 item(s) selected</p> */}
                        </div>
                        
                        <div className="productsModal_items bg-white">

                            {subAgencyList}
                            
                        </div>
                    </div>
                    

                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">
                        {t('default:cancel', "Annuler")} 
                    </button>
                    <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => addSubAgency()} >
                        {t('default:add', "Ajouter")} 
                    </button>
                </div>
                </div>
            </div>
        </div>
    )
}

export default SubMandataireModal
