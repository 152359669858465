import React, { useCallback, useEffect, useState } from 'react'
import './MUserForm.css';
import Axios from '../../../../../axios-edipub';
import PhoneInput from 'react-phone-input-2'
import fr from 'react-phone-input-2/lang/fr.json'
import avatar from '../../../../../assets/images/avatar.svg'
import { checkValidity, getNotyfObject, phonenumberValidation, uuidv4 } from '../../../../../shared/utility'
import { useTranslation } from 'react-i18next';
import TooltipInfo from '../../../../UI/TooltipInfo/TooltipInfo'


function MUserForm({ updateUser, user, userIndex, deleteUser, users, emailsErrors, setEmailsErrors, isAuth }) {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (isAuth) {
            if (user?.email && user.email !== '' && (!user.hasOwnProperty('targetuser') || user.targetuser == null)) {
                Axios.get("/user/" + user?.email + "/checkLoginExist").then(response => {
                    if (response.data.exists == true) {
                        let emailsErrorsTemp = [...emailsErrors]
                        if (!emailsErrorsTemp.includes(user?.email)) {
                            setEmailsErrors([...emailsErrorsTemp, user?.email]);
                        }
                    } else {
                        let emailsErrorsTemp = [...emailsErrors]
                        let index = null
                        for (let i = 0; i < emailsErrors?.length; i++) {
                            if ((emailsErrors[i] === user?.email))
                                index = i
                        }
                        if (index != null) {
                            emailsErrorsTemp.splice(index, 1);
                            setEmailsErrors(emailsErrorsTemp);
                        }
                    }
                })
            }
        }
    }, [user?.email])

    const capitalizeFirstLetter = (string) => {
        if (string != null) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        } else
            return ''
    }
    const emailChangeHandler = (e) => {
        let email = e.target.value
        updateUserInput(e.target.name, e.target.value)
        checkValidity(email, { required: true, isEmail: true })
    }

    const updateUserInput = (name, value) => {
        updateUser(userIndex, name, value)
    }


    // useEffect(() => {
    //     if( (i18n.language || 'fr') == 'fr')
    //         setCountriesLocal(countries.sort(function(a, b){return  ((a.name > b.name) ? 1 : -1)}))
    //     else 
    //         setCountriesLocal(countries.sort(function(a, b){return  ((a.nameEn > b.nameEn) ? 1 : -1)}))
    // }, [])


    return (
        <>
            <div className="form_manual_user_container" style={{ marginBottom: "20px" }}>
                <div className="rightSide_header_container">
                    <div className="header_infos_container">
                        <img className="rightSide_header_logo" src={avatar} />
                        <div className="header_labels_container">
                            <strong>{capitalizeFirstLetter(user?.last_name)} </strong>
                            <strong>{capitalizeFirstLetter(user?.first_name) + ((user?.targetuser) ? ' (' + t("manualEntity:existingUser") + ')' : '')} </strong>
                        </div>
                    </div>
                </div>
                {
                    user.errors && user.errors.length > 0 ? (
                        <div className="alert alert-danger mt-2" role="alert">
                            <ul>
                                {
                                    user.errors.map(error => (<li key={error}>{error}</li>))
                                }
                            </ul>
                        </div>
                    ) : null
                }

                <div className="rightSide_form_container">
                    <form>
                        <div className="form_required_container">
                            <p className="form_required_label">
                                <span className="form_required_asterisk">*</span>
                                {t("masterData:requiredFields", "Champs obligatoires")}
                            </p>
                        </div>
                        <div className="form_twoElements_container">
                            <div className="form_element_container">
                                <label htmlFor="first_name">{t('default:firstName', "Prénom")} / {t('default:service', "Service")} <span className="form_required_asterisk">*</span></label>
                                <input type="text" className="form-control" required minLength="1" id="first_name" name="first_name"
                                    value={user?.first_name || ''} onChange={(e) => (updateUserInput(e.target.name, e.target.value))} disabled={(user?.targetuser) ? true : false}></input>
                            </div>
                            <div className="form_element_container">
                                <label htmlFor="last_name">{t('default:name', "Nom")} / {t('default:compService', "Complément service")} <span className="form_required_asterisk">*</span></label>
                                <input type="text" className="form-control" required minLength="1" id="last_name" name="last_name"
                                    value={user?.last_name || ''} onChange={(e) => (updateUserInput(e.target.name, e.target.value))} disabled={(user?.targetuser) ? true : false}></input>
                            </div>
                        </div>

                        <div className="form_twoElements_container">
                            <div className="form_element_container">
                                <label htmlFor="language">{t('default:language', "language")} <span className="form_required_asterisk">*</span> </label>

                                <select className="form-control" id="language" name="language"
                                    value={user?.language || 'FR'}
                                    onChange={(e) => updateUserInput(e.target.name, e.target.value)} disabled={(user?.targetuser) ? true : false} >
                                    <option value="FR">FR</option>
                                    <option value="EN">EN</option>
                                </select>
                            </div>
                            <div className="form_element_container">
                            </div>
                        </div>


                        <div className="form_twoElements_container">
                            <div className="form_element_container">
                                <label htmlFor="mail">{t('default:mailAddress', "Adresse mail")} <span className="form_required_asterisk">*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="mail"
                                    required
                                    minLength="1"
                                    name="email"
                                    value={user?.email || ''}
                                    onChange={(e) => emailChangeHandler(e)}
                                    disabled={(user?.targetuser) ? true : false}
                                />
                            </div>
                            <div className="form_element_container">
                                <label htmlFor="phone" style={{ display: "flex", alignItems: "baseline" }} >
                                    <span>{t('default:mobileNumber', "Numéro de portable")}</span>
                                    <TooltipInfo
                                        content={t('masterData:mobileNumberTooltip', "Le numéro de téléphone n’est pas obligatoire ici, mais il est préconisé de le saisir pour des signataires, pour éviter sa saisie au moment de la signature électronique. Il est possible de le masquer")}
                                        dropdownZindex={100}
                                    />
                                </label>
                                <PhoneInput
                                    localization={fr}
                                    country={'fr'}
                                    inputClass={"form-control w-100"}
                                    dropdownStyle={{ width: "auto" }}
                                    preferredCountries={['fr', 'us']}
                                    enableSearch={false}
                                    inputProps={{ name: "telephone_number" }}
                                    placeholder={"33 0 00 00 00 00"}
                                    value={user?.telephone_number?.substr(2) || ''}
                                    onChange={phone => (updateUserInput("telephone_number", phone ? "00" + phone : null))}
                                    disabled={(user?.targetuser) ? true : false}
                                />
                            </div>
                        </div>

                        <div className="form_twoElements_container">
                            <div className="form_element_container">
                                <label>{t('default:role', "Rôle")} <span className="form_required_asterisk">*</span> </label>
                                <select className="form-control" required id="role" aria-label="Default select example" name="role"
                                    value={user?.role || ''}
                                    onChange={(e) => updateUserInput(e.target.name, e.target.value)}
                                    disabled={(user?.targetuser || !isAuth) ? true : false}>
                                    <option value={"AG"}>{t("masterData:roleAG")}</option>
                                    <option value={"AC"}>{t("masterData:roleAC")}</option>
                                </select>
                                <div className="entityUser_roles" >
                                    <div className="" style={{ paddingLeft: "20px" }}>
                                        <input
                                            style={{ margin: "6px 8px 0px 2px" }}
                                            className="form-check-input"
                                            type="checkbox"
                                            name="is_signataire"
                                            value=""
                                            id="is_signataire"
                                            checked={(user?.is_signataire && user?.is_signataire == "1") ? true : false}
                                            onChange={(e) => updateUserInput(e.target.name, e.target.checked)}
                                            disabled={(user?.targetuser) ? true : false}
                                        />
                                        <label className="form-check-label" htmlFor="is_signataire">
                                            {t('manualEntity:signatoryWithPhoneNumber', "Signataire (nécéssite Numéro de portable)")}
                                        </label>
                                    </div>
                                    <div className="" style={{ paddingLeft: "20px" }}>
                                        <input
                                            style={{ margin: "6px 8px 0px 2px" }}
                                            className="form-check-input"
                                            type="checkbox"
                                            name="is_legal_representative"
                                            value=""
                                            id="is_legal_representative"
                                            checked={(user?.is_legal_representative && user?.is_legal_representative == "1") ? true : false}
                                            onChange={(e) => updateUserInput(e.target.name, e.target.checked)}
                                            disabled={(user?.targetuser) ? true : false}
                                        />
                                        <label className="form-check-label" htmlFor="is_legal_representative">
                                            {t('mandat:legalRepresentative', "Représentant légal")}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form_oneElement_container">
                            <label htmlFor="fonction">{t('default:function', "Fonction")}</label>
                            <input type="text" className="form-control" id="fonctionLabel" minLength="1" name="fonctionLabel" value={user?.fonctionLabel || ''}
                                onChange={(e) => (updateUserInput(e.target.name, e.target.value))}
                                disabled={(user?.targetuser) ? true : false}
                            ></input>
                        </div>
                        {
                            (users.length > 1 && (!user.hasOwnProperty('targetuser') || user.targetuser == null)) &&
                            <div className="rightSide_form_submit_container">
                                <input
                                    type="submit"
                                    value={t('default:delete', "Supprimer")}
                                    className="btn btn-primary mt-3 rightSide_form_submitBtn"
                                    onClick={(e) => deleteUser(e, userIndex)} />
                            </div>
                        }
                        {
                            (users.length > 1 && user.hasOwnProperty('targetuser')) &&
                            <div className="" style={{ paddingLeft: "20px" }}>
                                <input
                                    style={{ margin: "6px 8px 0px 2px" }}
                                    className="form-check-input"
                                    type="checkbox"
                                    name="is_targetuser_removable"
                                    value=""
                                    id="is_targetuser_removable"
                                    checked={(user?.is_targetuser_removable && user?.is_targetuser_removable == "1") ? true : false}
                                    onChange={(e) => updateUserInput(e.target.name, e.target.checked)}
                                />
                                <label className="form-check-label" htmlFor="is_signataire">
                                    {t('manualEntity:toDeleteFromMyMandat', "A 'supprimer' de MyMandat")}
                                </label>
                            </div>
                        }
                    </form>
                </div>
            </div>
        </>
    )

}

export default MUserForm
