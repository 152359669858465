import axios from 'axios';
import { env } from './env'

const instance = axios.create({
    headers: {
        Pragma: 'no-cache'
    },
    baseURL: env.REACT_APP_BASE_URL
})

export default instance