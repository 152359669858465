import React, { useCallback, useEffect, useState } from 'react'
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import './EntityAgenciesUserModal.css'
import Axios from '../../../../axios-edipub'
import { Button, Modal } from 'react-bootstrap';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';




function EntityAgenciesUserModal({ handleClose, show, addAuthorizedAgenciesUser, userEntities, userUid, userRole, role, authUserId }) {
    const [entities, setEntities] = useState([]);
    const [selectedEntities, setSelectedEntities] = useState(userEntities ? userEntities : []);
    const [searchQuery, setSearchQuery] = useState(null)
    const { t } = useTranslation();

    const getEntitiesList = useCallback(() => {
        let filter = {
            searchQuery: searchQuery,
            status: "ACTIVE",
            category: "Agence"
        }
        Axios.get('/entity?allagenciesforregie=', {
            params: filter
        })
            .then(res => {
                setEntities(res.data.entities)
            })
    }, [searchQuery])

    useEffect(() => {
        getEntitiesList()
    }, [searchQuery])


    const SelectEntity = (entity) => {
        let entityIndex = selectedEntities.findIndex(row => row.uid === entity.uid);
        let selectedTemp = [...selectedEntities];
        if (entityIndex >= 0)
            selectedTemp.splice(entityIndex, 1)
        else
            selectedTemp = [...selectedEntities, entity];
        setSelectedEntities(selectedTemp);
    }

    const addClickHandler = () => {
        addAuthorizedAgenciesUser("authorizedAgencies", selectedEntities)
        handleClose()
    }
    return (
        <>
            <Modal show={show} onHide={handleClose} dialogClassName="modal_entityUser" >
                <Modal.Header closeButton>
                    <Modal.Title>{t('masterData:userAgenciesModal', "Associer la personne aux entreprises")} </Modal.Title>
                </Modal.Header>
                <Modal.Body className="productsModal__body" style={{ minWidth: "40rem" }}>
                    <div className="productsModal__header">
                        <div class=" input-group input-group-sm">
                            <input
                                type="text"
                                class="form-control"
                                placeholder={t('masterData:findBySiretAndGroup', "Rechercher par SIRET, Nom, Groupe")}
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                onChange={(e) => setSearchQuery(e.target.value)}
                                value={searchQuery || ''}
                            />
                            <div class="input-group-append" >
                                <span class="input-group-text" id="basic-addon2"><SearchIcon /> </span>
                            </div>
                        </div>
                    </div>
                    <div className="productsModal_items bg-white">
                        <table class="table">
                            <thead class="thead-light">
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col">{t('default:name', "Nom")} </th>
                                    <th scope="col">{t('default:siret', "SIRET")}</th>
                                    <th scope="col">{t('default:headOffice', "SIEGE SOCIAL")}</th>
                                    <th scope="col">{t('default:group', "Groupe")} </th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    entities?.length > 0 ? entities.map(entity => (
                                        <tr>
                                            <td>
                                                {
                                                    (userEntities && userEntities.some(row => row.uid === entity.uid)) ? (
                                                        <div className="productsModal__itemIcon">
                                                            <ThumbUpIcon style={{ color: "gray" }} />
                                                        </div>
                                                    ) : (
                                                        <div className="productsModal__itemIcon"
                                                            onClick={() => SelectEntity({ uid: entity.uid, commercial_name: entity.commercial_name, new: true })}>
                                                            {
                                                                selectedEntities.some(row => row.uid === entity.uid) ? (
                                                                    <ThumbUpIcon style={{ color: "green" }} />
                                                                ) : <AddIcon style={{ color: "blue" }} />
                                                            }
                                                        </div>
                                                    )
                                                }
                                            </td>
                                            <td>{entity.commercial_name} </td>
                                            <td>{entity.siret} </td>
                                            <td>{entity.address_line} {entity.post_code} </td>
                                            <td>{entity.groupName}</td>
                                        </tr>
                                    )) : <div>{t('mandat:noAgencies', "Aucune entreprise à afficher")}  </div>
                                }
                            </tbody>
                        </table>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t('default:cancel', "Annuler")}
                    </Button>
                    <Button variant="primary" onClick={() => addClickHandler()}>
                        {t('default:associate', "Associer")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => ({
    role: state.auth.role,
    authUserId: state.auth.userId
})
const mapDispatchToProps = dispatch => ({
})
export default connect(mapStateToProps, mapDispatchToProps)(EntityAgenciesUserModal)
